import { Services } from "@/services"
import {  
  sleep,
  NEW_BOG_TOKEN_ADDR,
  BOG_PCS_V2_PAIR_ADDR,
  CHAIN_TOKENS,
} from "./constants"

class _WalletsLib {

  async touchWalletTokenList(context) {
    // shhhhhhh I know this is dumb. but force the object value to change so that watchers fire
    // learn how to catch a donut ok
    let walletTokensTemp = context.state.walletTokens[context.state.chain]
    context.state.walletTokens[context.state.chain] = {}
    context.state.walletTokens[context.state.chain] = walletTokensTemp
  }

  async getUserTransactions(context) {
    if (context.state.accounts && context.state.accounts[0]) {
        console.log('GETTING WALLET TXNS')
        let txns = await Services.getWalletTxnsBogApi(context.state.chain, context.state.accounts[0], context.state.token)
        if (txns) {
            for(var i=0, l=txns.length; i<l; i++) {
                let tx = txns[i]
                var pointDate = new Date(txns[i].timestamp*1000);
                var buy = false;
                var value_usd
                var ourToken = context.state.token.toLowerCase();
                var price_usd
                var token_amount
                if (ourToken == tx.t0) {
                    buy = tx.t0_lp_change <= 0
                    value_usd = tx.usd_value_t0
                    price_usd = tx.price_usd_t0
                    token_amount = Math.abs(tx.t0_lp_change)
                } else {
                    buy = tx.t1_lp_change <= 0
                    value_usd = tx.usd_value_t1
                    price_usd = tx.price_usd_t1
                    token_amount = Math.abs(tx.t1_lp_change)
                }
                if (buy) {
                    context.state.netBuyValue += value_usd
                } else {
                    context.state.netSellValue += value_usd
                }
                var pctChange = ((context.state.tokenPrice - price_usd) / price_usd * 100).toFixed(2)

                context.state.userTransactions.push({
                    id: tx.txhash,
                    time: pointDate.getTime(),
                    date: pointDate.toString(),
                    isSell: buy ? false : true,
                    tokenAmount: token_amount,
                    token: buy ? context.state.tokenSymbol : context.state.masterTokenSymbol,
                    value: value_usd,
                    txHash: tx.txhash,
                    isArbitage: false,
                    pool: tx.exchange_name,
                    pctChange: parseFloat(pctChange)
                });
            }
            console.log('USER TXNS (PARSED):')
            console.log(context.state.userTransactions)
        }
    }
  }

  async loadWalletTokens(context, walletAddress) {
    console.log(32.01)
    console.log('loadWalletTokens', walletAddress)
    
    if (!context.state.walletFirstLoadComplete) {
        let savedWalletTokens = localStorage.getItem("walletTokenDict");
        //console.log(savedWalletTokens)
        if (savedWalletTokens) {
            let allAccountWalletTokens = JSON.parse(savedWalletTokens)
            if ('bsc' in allAccountWalletTokens && 'polygon' in allAccountWalletTokens && 'avax' in allAccountWalletTokens && 'ftm' in allAccountWalletTokens) { // this is the new multichain dict
                if (allAccountWalletTokens['bsc'][walletAddress]) {
                    context.state.walletTokens['bsc'][walletAddress] = allAccountWalletTokens['bsc'][walletAddress]
                } else {
                    context.state.walletTokens['bsc'][walletAddress] = []
                }
                if (allAccountWalletTokens['polygon'][walletAddress]) {
                    context.state.walletTokens['polygon'][walletAddress] = allAccountWalletTokens['polygon'][walletAddress]
                } else {
                    context.state.walletTokens['polygon'][walletAddress] = []
                }
                if (allAccountWalletTokens['avax'][walletAddress]) {
                    context.state.walletTokens['avax'][walletAddress] = allAccountWalletTokens['avax'][walletAddress]
                } else {
                    context.state.walletTokens['avax'][walletAddress] = []
                }
                if (allAccountWalletTokens['ftm'][walletAddress]) {
                    context.state.walletTokens['ftm'][walletAddress] = allAccountWalletTokens['ftm'][walletAddress]
                } else {
                    context.state.walletTokens['ftm'][walletAddress] = []
                }
            } else { // this is the old bsc-only wallet dict. replace it.
                context.state.walletTokens['bsc'][walletAddress] = []
                context.state.walletTokens['polygon'][walletAddress] = []
                context.state.walletTokens['avax'][walletAddress] = []
                context.state.walletTokens['ftm'][walletAddress] = []
                context.state.walletTokens['csc'][walletAddress] = []
                localStorage.setItem("walletTokenDict", JSON.stringify({bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}}))
            }
            if ('csc' in allAccountWalletTokens) {
                if (allAccountWalletTokens['csc'][walletAddress]) {
                    context.state.walletTokens['csc'][walletAddress] = allAccountWalletTokens['csc'][walletAddress]
                } else {
                    context.state.walletTokens['csc'][walletAddress] = []
                }
            } else {
                context.state.walletTokens['csc'][walletAddress] = []
            }
        } else {
            context.state.walletTokens['bsc'][walletAddress] = []
            context.state.walletTokens['polygon'][walletAddress] = []
            context.state.walletTokens['avax'][walletAddress] = []
            context.state.walletTokens['ftm'][walletAddress] = []
            context.state.walletTokens['csc'][walletAddress] = []
            localStorage.setItem("walletTokenDict", JSON.stringify({bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}}))
        }
    }
    context.state.walletReloading = true;
    //console.log(32.02)
    //console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
    //console.log(context.state.chain)
    //console.log('wallet tokens:')
    //console.log(context.state.walletTokens[context.state.chain][walletAddress])
    //console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')

    if (walletAddress != context.state.accounts[0]) {
        // user switched wallet shortly after switching wallets.
        // Stop this loop, we will be called again for the new wallet soon
        return
    }

    let compStakeBalancePromise = Services.getCompStakeBalance(context, walletAddress)
    let soloStakeBalancePromise = Services.getSoloStakeBalance(context, walletAddress)
    let lpStakeBalancePromise = Services.getLPStakeBalance(context, walletAddress)
    let apeLpStakeBalancePromise = Services.getAPELPStakeBalance(context, walletAddress)
    let lpValProm = Services.getLPValue(context, NEW_BOG_TOKEN_ADDR, BOG_PCS_V2_PAIR_ADDR)
    let apeLpValProm = Services.getLPValue(context, NEW_BOG_TOKEN_ADDR, "0x9D8370C3E6833942b8c38478c84ef74374F28b9f")
   
    //let tokensPromise = getWalletTokens(context.state.accounts[0], context.state.chain)
    //let tokensPromise = getWalletTokens(context.state.accounts[0], "bsc") // wallet only supports bsc for now

    let allTokens = await Services.getWalletTokensBogApi(context.state.chain, context.state.accounts[0])            
    let moralisTokens = true
    console.log(context.state.walletTokens['bsc'][walletAddress])
    if (!allTokens) {
        moralisTokens = false
        console.log('!! Failed to get wallet tokens from bog api')
        let tokensPromise = getWalletTokens(context.state.accounts[0], context.state.chain)            

        allTokens = (await tokensPromise).data.ethereum.address[0].balances;                
        console.log('______')
        console.log('wallet tokens:')
        console.log(allTokens)
        console.log('______')
        console.log('______')
        console.log('______')
        console.log('______')
    }

    let compStakingBalance = await compStakeBalancePromise
    let soloStakingBalance = await soloStakeBalancePromise
    let lpStakingBalance = await lpStakeBalancePromise
    let apeLpStakingBalance = await apeLpStakeBalancePromise

    //let scamTokenArray = await Services.getScamTokenJson();
    await context.state.scamTokenArray
    let scamTokenArray = context.state.scamTokenArray

    // Remove scam tokens from wallet object so we don't keep displaying them
    let wallettoks = context.state.walletTokens[context.state.chain][walletAddress]
    let to_remove = []
    let w3 = context.state.web3
    for (let tokindex in wallettoks)
    {
        let tok = wallettoks[tokindex]
        if (tok.currency && tok.currency.address && (scamTokenArray.includes(tok.currency.address.toLowerCase()) || scamTokenArray.includes(tok.currency.address))  || (tok.currency.address.length == 42 && scamTokenArray.includes(w3.utils.toChecksumAddress(tok.currency.address)))) {
            to_remove.unshift(tokindex)
        }
    }            
    for (let tokindex of to_remove) {                
        context.state.walletTokens[context.state.chain][walletAddress].splice(tokindex,1)
    }            
    localStorage.setItem("walletTokenDict", JSON.stringify(context.state.walletTokens));
    

    console.log(32.03)

    context.state.bogPriority = (await compStakingBalance * 3) + (await lpStakingBalance * 12) + (await Services.getTokenBalance(context, "0xb09fe1613fe03e7361319d2a43edc17422f36b09"))
    if (context.state.bogPriority > 49.9) {
        Services.registerWallet(context.getters.account)
    } else {
        context.state.bogPriorityNext = 50 - context.state.bogPriority;
    }
    if (context.state.bogPriority < 100) {
        context.state.bogPriorityNext = 100 - context.state.bogPriority;
    } 
    else if (context.state.bogPriority < 500) {
        context.state.bogPriorityNext = 500 - context.state.bogPriority;
    }
    else if (context.state.bogPriority < 750) {
        context.state.bogPriorityNext = 750 - context.state.bogPriority;
    }
    else if (context.state.bogPriority < 5000) {
        context.state.bogPriorityNext = 5000 - context.state.bogPriority;
    } else {
        context.state.bogPriorityNext = 9999;
    }

    let lpValue = await lpValProm
    let apeLpValue = await apeLpValProm

    let bogStakeAddress = "0xB09FE1613fE03E7361319d2a43eDc17422f36B09-staked-bog"
    let bogSoloStakeAddress = "0xB09FE1613fE03E7361319d2a43eDc17422f36B09-solo-staked-bog"
    let bogLPStakeAddress = "0xB09FE1613fE03E7361319d2a43eDc17422f36B09-staked-bog-bnb"
    let bogAPELPStakeAddress = "0xB09FE1613fE03E7361319d2a43eDc17422f36B09-staked-bog-apelp"
    
    console.log(context.state.walletTokens)
    let stakeWalletPos = context.state.walletTokens['bsc'][walletAddress].findIndex(token => token && token.currency.address == bogStakeAddress)
    
    let bogStakeObj = {
        value: lpStakingBalance * lpValue,
        price: lpValue,
        balance: lpStakingBalance,
        isStake: 1,
        currency: {
            tokenImage: "https://static.bogged.finance/bsc/assets/0xB09FE1613fE03E7361319d2a43eDc17422f36B09/logo.png",
            address: bogStakeAddress,
            decimals: 18,
            name: "BOG-BNB (staked)",
            symbol: "BOG-BNB-staked"
        }
    }
    
    if (stakeWalletPos != -1) {
        context.state.walletTokens['bsc'][walletAddress][stakeWalletPos] = bogStakeObj
    } else {
        context.state.walletTokens['bsc'][walletAddress].push(bogStakeObj);
    }

    let soloStakeLPWalletPos = context.state.walletTokens['bsc'][walletAddress].findIndex(token => token && token.currency.address == bogSoloStakeAddress)
    let soloStakeObj = {
        value: soloStakingBalance * context.state.bogPrice,
        price: context.state.bogPrice,
        balance: soloStakingBalance,
        isStake: 1,
        currency: {
            tokenImage: "https://static.bogged.finance/bsc/assets/0xB09FE1613fE03E7361319d2a43eDc17422f36B09/logo.png",
            address: bogSoloStakeAddress,
            decimals: 18,
            name: "BOG (staked)",
            symbol: "BOG-staked"
        }
    }

    if (soloStakeLPWalletPos != -1) {
        context.state.walletTokens['bsc'][walletAddress][soloStakeLPWalletPos] = soloStakeObj
    } else {
        context.state.walletTokens['bsc'][walletAddress].push(soloStakeObj);
    }

    let stakeLPWalletPos = context.state.walletTokens['bsc'][walletAddress].findIndex(token => token && token.currency.address == bogLPStakeAddress)
    let lpStakeObj = {
        value: compStakingBalance * context.state.bogPrice,
        price: context.state.bogPrice,
        balance: compStakingBalance,
        isStake: 1,
        currency: {
            tokenImage: "https://static.bogged.finance/bsc/assets/0xB09FE1613fE03E7361319d2a43eDc17422f36B09/logo.png",
            address: bogLPStakeAddress,
            decimals: 18,
            name: "BOG (comp staked)",
            symbol: "BOG-Comp-staked"
        }
    }

    if (stakeLPWalletPos != -1) {
        context.state.walletTokens['bsc'][walletAddress][stakeLPWalletPos] = lpStakeObj
    } else {
        context.state.walletTokens['bsc'][walletAddress].push(lpStakeObj);
    }

    let apeStakeLPWalletPos = context.state.walletTokens['bsc'][walletAddress].findIndex(token => token && token.currency.address == bogAPELPStakeAddress)
    let apeLpStakeObj = {
        value: apeLpStakingBalance * apeLpValue,
        price: apeLpValue,
        balance: apeLpStakingBalance,
        isStake: 1,
        currency: {
            tokenImage: "https://static.bogged.finance/bsc/assets/0xB09FE1613fE03E7361319d2a43eDc17422f36B09/logo.png",
            address: bogAPELPStakeAddress,
            decimals: 18,
            name: "BOG-BNB (APE staked)",
            symbol: "BOG-APE-staked"
        }
    }

    if (apeStakeLPWalletPos != -1) {
        context.state.walletTokens['bsc'][walletAddress][apeStakeLPWalletPos] = apeLpStakeObj
    } else {
        context.state.walletTokens['bsc'][walletAddress].push(apeLpStakeObj);
    }

    if (!context.state.walletFirstLoadComplete) {        
        this.touchWalletTokenList(context)
    }

    if (!allTokens) {
        return
    }

    console.log(32.04)

    let partitionSize = 10

    let tokenPartitions = []
    while (allTokens.length > partitionSize) {
        tokenPartitions.push(allTokens.splice(0, partitionSize))
    }
    if (allTokens.length > 0) {
        tokenPartitions.push(allTokens)
    }

    var useBog24hPrice = true

    console.log(context.state.portfolioTokensHidden)
    for (let tokens of tokenPartitions) {
        let tokensPromises = []
        for(let i=0; i<tokens.length; i++) {
            //if (context.state.walletTokensWithFatalErrors.includes(tokens[i].currency.address) || scamTokenArray.includes(tokens[i].currency.address)) {
            if (scamTokenArray.includes(tokens[i].currency.address) || scamTokenArray.includes(tokens[i].currency.address.toLowerCase()) || (tokens[i].currency.address.length == 42 && scamTokenArray.includes(w3.utils.toChecksumAddress(tokens[i].currency.address)))) {
                console.log('skipping scam ' + tokens[i].currency.name)
                continue
            }
            if (context.state.walletTokensWithFatalErrors.includes(tokens[i].currency.address) || context.state.portfolioTokensHidden.includes(tokens[i].currency.address)) {
                //if (context.state.walletTokensWithFatalErrors.includes(tokens[i].currency.address) || context.state.portfolioTokensHidden.includes(tokens[i].currency.address)) {
                console.log('skipping hidden ' + tokens[i].currency.name)
                continue
            }
            if (tokens[i].value == 0) {
                console.log('skipping 0 balance ' + tokens[i].currency.name)
                continue
            }
            let tokinfo = context.state.walletTokens[context.state.chain][walletAddress]
            let currentWalletPos = tokinfo.findIndex(token => token && tokens[i] && token.currency.address == tokens[i].currency.address)                    
            
            if (currentWalletPos >= 0) {
                let last_updated = tokinfo[currentWalletPos].updated
                let holdings_usd = tokinfo[currentWalletPos].price * tokens[i].value
                let liq_usd = tokinfo[currentWalletPos].liq_usd
                if (last_updated) {
                    if (Date.now() - last_updated < 10*60*1000) {
                        // only update token info if we haven't updated in last 10 mins
                        console.log('skipping recently updated: ' + tokens[i].currency.name)
                        continue
                    } else if (Date.now() - last_updated < 30*60*1000 & holdings_usd < 10.0) {
                        console.log('skipping recently updated low value token: ' + tokens[i].currency.name)
                        continue                        
                    } else if (Date.now() - last_updated < 60*60*1000 & holdings_usd < 1.0) {
                        console.log('skipping recently updated low value token: ' + tokens[i].currency.name)
                        continue
                    } else if (Date.now() - last_updated < 60*60*1000 & liq_usd < 100.0) {
                        console.log('skipping recently updated low liquidity token: ' + tokens[i].currency.name)
                        continue
                    }
                }
            }
            //if (tokens[i].currency.address == '-' && tokens[i].currency.symbol == 'BNB') {
            if (tokens[i].currency.address == '-') {
                let _24hPricePromise
                if (useBog24hPrice) {
                    _24hPricePromise = Services.getPrice24hBogApi(CHAIN_TOKENS[context.state.chain],context.state.chain)
                }
                else {
                    _24hPricePromise = Services.getToken24hPrice(CHAIN_TOKENS[context.state.chain],context.state.chain)
                }
                tokensPromises.push(new Promise(async function(resolve, reject) {
                    try {
                        resolve({
                            address: tokens[i].currency.address,
                            _24hPriceRes: await _24hPricePromise,
                            //balance: await context.state.web3.eth.getBalance(walletAddress)
                            balance: await context.state.web3s[context.state.chain].eth.getBalance(walletAddress)
                        })
                    } catch (err) {
                        console.log('24hPricePromise error:')
                        console.log(err)
                        reject({err: err, address: tokens[i].currency.address})
                    }
                }))
            } else {
                let balancePromise = tokens[i].value
                if (balancePromise == undefined) {
                    balancePromise = Services.getTokenBalance(context, tokens[i].currency.address)
                }
                //let pricePromise = (tokens[i].currency.address == "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c")
                //    ? {usdPrice: context.state.masterTokenPrice}
                //    : Services.getTokenSpotPrice(context, tokens[i].currency.address);
                //let _24hPricePromise = Services.getToken24hPrice(tokens[i].currency.address)
                let pricePromise = (tokens[i].currency.address.toLowerCase() == CHAIN_TOKENS[context.state.chain].toLowerCase())
                    //? {usdPrice: context.state.masterTokenPrice}
                    ? {usdPrice: context.state.chainTokenPrice}
                    : Services.getTokenSpotPrice(context, tokens[i].currency.address);
                let _24hPricePromise
                if (useBog24hPrice) {
                    _24hPricePromise = Services.getPrice24hBogApi(tokens[i].currency.address,context.state.chain)
                }
                else {
                    _24hPricePromise = Services.getToken24hPrice(tokens[i].currency.address,context.state.chain)
                }                        
                
                let circSupplyPromise = Services.getTokenCircSupply(tokens[i].currency.address, context.state.chain, context.state.web3s[context.state.chain])

                tokensPromises.push(new Promise(async function(resolve, reject) {
                    try {
                        let addressValid = tokens[i].currency.address.startsWith("0x")
                        let imageUrl = "/unknown.svg"
                        if (addressValid && !context.state.noIconTokens.includes(tokens[i].currency.address)) {
                            imageUrl = "https://static.bogged.finance/"+context.state.chain+"/assets/" + context.state.web3.utils.toChecksumAddress(tokens[i].currency.address) + "/logo.png"
                        }
                        resolve({
                            address: tokens[i].currency.address,
                            balance: await balancePromise,
                            price: await pricePromise,
                            _24hPriceRes: await _24hPricePromise,
                            //_24hPriceRes: await Services.getPrice24hBogApi(tokens[i].currency.address,context.state.chain),
                            circSupply: (await circSupplyPromise).circSupply,
                            imageUrl: imageUrl
                        })
                    } catch (err) {
                        console.log(err)
                        reject({err: err, address: tokens[i].currency.address})
                    }
                }))
            }
            let sleepDur = context.state.walletFirstLoadComplete ? 1000 : 400;
            await sleep(sleepDur)
        }
        
        let promiseMap = new Map();
        tokensPromises.forEach(promise => promiseMap.set(
            promise,
            promise
                .then(value => [null, value, promise])
                .catch(error => [error, null, promise])
        ));

        console.log(32.05)

        while (promiseMap.size > 0) {
            let [error, promiseResult, promise] = await Promise.race(promiseMap.values());
            if (error) {
                let i = tokens.findIndex(token => token && token.currency.address == error.address)
                if(error.err.message.includes("execution reverted")) {
                    if (i != -1) {
                        tokens[i].value = 0
                    }
                    context.state.walletTokensWithFatalErrors.push(error.address)
                    console.log(`removing token from wallet calculations until refresh: ${error.address}`)
                }
            } else {
                let i = tokens.findIndex(token => token && token.currency.address == promiseResult.address)

                tokens[i].isStake = 0;
                if (context.state.walletTokensWithFatalErrors.includes(tokens[i].currency.address)) {
                    continue
                }

                if (walletAddress != context.state.accounts[0]) {
                    // user switched wallet shortly after switching wallets.
                    // Stop this loop, we will be called again for the new wallet soon
                    return
                }

                let currentWalletPos = context.state.walletTokens[context.state.chain][walletAddress].findIndex(token => token && tokens[i] && token.currency.address == tokens[i].currency.address)

                //if (tokens[i].currency.address == '-' && tokens[i].currency.symbol == 'BNB') {
                if (tokens[i].currency.address == '-') {
                    // getting BNB different from other tokens
                    //tokens[i].currency.tokenImage = "https://static.bogged.finance/bsc/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"
                    tokens[i].currency.tokenImage = "https://static.bogged.finance/"+context.state.chain+"/assets/"+CHAIN_TOKENS[context.state.chain]+"/logo.png"
                    tokens[i].balance = promiseResult.balance / Math.pow(10, 18);
                    //tokens[i].price = context.state.masterTokenPrice;
                    tokens[i].price = context.state.chainTokenPrice;
                    tokens[i].value = tokens[i].price * tokens[i].balance;
                    let _24hPriceRes = promiseResult._24hPriceRes
                    
                    //console.log(_24hPriceRes.data.price_usd)
                    
                    if (useBog24hPrice) {
                        if (_24hPriceRes.err || _24hPriceRes.data.price_usd == null || _24hPriceRes.data.price_usd == undefined) {
                            tokens[i]._24hPrice = context.state.masterTokenPrice
                        } else {
                            tokens[i]._24hPrice = _24hPriceRes.data.price_usd;
                        }
                    }
                    else {
                        if (_24hPriceRes.err || _24hPriceRes.price_usd == null || _24hPriceRes.price_usd == undefined) {
                            tokens[i]._24hPrice = context.state.masterTokenPrice
                        } else {
                            tokens[i]._24hPrice = _24hPriceRes.price_usd;
                        }
                    }
                    console.log(tokens[i].currency.symbol + ' $'+tokens[i]._24hPrice.toString()+' 24h price')
                    tokens[i].percentChange = -1 * (tokens[i]._24hPrice - tokens[i].price) / tokens[i]._24hPrice * 100
                    tokens[i].updated = Date.now()
                } else {
                    tokens[i].balance = promiseResult.balance;
                    tokens[i].price = promiseResult.price.usdPrice;
                    let _24hPriceRes = promiseResult._24hPriceRes
                    if (useBog24hPrice) {
                        if (_24hPriceRes.err || _24hPriceRes.data.price_usd == null || _24hPriceRes.data.price_usd == undefined) {
                            console.log('failed to get 24h price')
                            console.log(_24hPriceRes)
                            if (promiseResult.price.usdPrice == null || promiseResult.price.usdPrice == undefined) {
                                tokens[i]._24hPrice = 0
                            } else {
                                tokens[i]._24hPrice = promiseResult.price.usdPrice                                        
                            }
                        } else {
                            tokens[i]._24hPrice = _24hPriceRes.data.price_usd;
                        }
                        if (_24hPriceRes.trust_info != undefined) {
                            tokens[i].trustscore = _24hPriceRes.trust_info.trustscore
                            tokens[i].honeypot = _24hPriceRes.trust_info.honeypot
                            tokens[i].liq_usd = _24hPriceRes.trust_info.liq_usd
                            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                            console.log('trust info:')
                            console.log(_24hPriceRes.trust_info)
                            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                        }
                        console.log(tokens[i].currency.symbol + ' $'+tokens[i]._24hPrice.toString()+' 24h price')
                    }
                    else {
                        if (_24hPriceRes.err || _24hPriceRes._24hPrice == null) {
                            tokens[i]._24hPrice = -1
                        } else {
                            tokens[i]._24hPrice = _24hPriceRes._24hPrice;
                        }
                    }
                    tokens[i].circSupply = promiseResult.circSupply;
                    if (tokens[i].circSupply == undefined || tokens[i].circSupply == null) { tokens[i].mcap = 0; }
                    else { tokens[i].mcap = tokens[i].circSupply * tokens[i].price; }
                    tokens[i].percentChange = -1 * (tokens[i]._24hPrice - tokens[i].price) / tokens[i]._24hPrice * 100
                    
                    tokens[i].value = tokens[i].price * tokens[i].balance;
                    tokens[i].currency.tokenImage = promiseResult.imageUrl
                    tokens[i].updated = Date.now()
                }
                
                //console.log(tokens[i]._24hPrice)

                // if this is the charted token, add it to the topbar
                let addressValid = tokens[i].currency.address.startsWith("0x")
                if (addressValid && context.state.web3.utils.toChecksumAddress(tokens[i].currency.address) == context.state.token) {
                    context.state.tokenWalletValue = tokens[i].value;
                    context.state.tokenWalletBalance = tokens[i].balance;
                }

                // if it's already in the wallet, update it in place, otherwise, push it to the end of the list
                console.log('adding to wallet:')
                console.log(tokens[i])
                if (currentWalletPos != -1) {
                    context.state.walletTokens[context.state.chain][walletAddress][currentWalletPos] = tokens[i]
                } else {
                    context.state.walletTokens[context.state.chain][walletAddress].push(tokens[i]);
                    currentWalletPos = context.state.walletTokens[context.state.chain][walletAddress].length - 1
                }
            }
            promiseMap.delete(promise);
        }
        if (!context.state.walletFirstLoadComplete) {          
          this.touchWalletTokenList(context)
        }
    }

    if (walletAddress != context.state.accounts[0]) {
        // user switched wallet shortly after switching wallets.
        // Stop this loop, we will be called again for the new wallet soon
        return
    }

    console.log(32.06)
    console.log(context.state.walletTokens[context.state.chain][walletAddress])
    this.touchWalletTokenList(context)
    let allAccountWalletTokens = JSON.parse(localStorage.getItem("walletTokenDict")) || {bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}}            
    allAccountWalletTokens[context.state.chain][walletAddress] = context.state.walletTokens[context.state.chain][walletAddress]
    localStorage.setItem("walletTokenDict", JSON.stringify(allAccountWalletTokens));
    
    context.state.walletReloading = false;
    context.state.walletFirstLoadComplete = true;
    if (walletAddress == context.state.accounts[0]) {
        setTimeout(()=>{
            this.loadWalletTokens(context, walletAddress)
        }, 300000);
    }
  }
  
}

export const WalletsLib = new _WalletsLib();
