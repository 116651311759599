<template>
  <div style="height:calc(100% - 33px)" class="overflow-x-auto overflow-y-hidden">
    <div style="padding-right: 10px; height: 33px;" class="tableHeader md:flex hidden w-full dark:text-gray-400 font-semibold md:text-xs text-2xs text-gray-600 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800 text-left font-heading">
      <div class="userTradeTime flex whitespace-nowrap p-2 pl-2">{{ $t("charts.components.infobar.time") }}</div>
      <div class="userTradeTraded flex whitespace-nowrap p-2">{{ $t("charts.components.infobar.traded") }}</div>
      <div class="userTradePrice flex whitespace-nowrap p-2">{{ $t("charts.components.infobar.tokenprice") }}</div>
      <div class="userTradeValue flex whitespace-nowrap p-2">{{ $t("charts.components.infobar.value") }}</div>
      <div class="userTradeChange flex whitespace-nowrap p-2">% Change</div>
    </div>

    <div v-if="$store.state.userTransactions.length" id="tabUserTradesWrapper" class="w-full overflow-y-scroll bg-gray-50 dark:bg-gray-900 dark:bg-opacity-50">
      <template v-if="isMobile">
        <user-trades-row-mobile
          v-for="tx of userTransactions"
          :key="tx.id"
          v-bind:id="tx.id"
          v-bind:txHash="tx.txHash"
          v-bind:isSell="tx.isSell"
          v-bind:token="tx.token"
          v-bind:tokenAmount="tx.tokenAmount"
          v-bind:value="tx.value"
          v-bind:date="tx.date"
          v-bind:pool="tx.pool"
          v-bind:isArbitrage="tx.isArbitrage"
          v-bind:arbPools="tx.arbPools"
          v-bind:pctChange="tx.pctChange"
        />
      </template>
      <template v-else>
        <user-trades-row
          v-for="tx of userTransactions"
          :key="tx.id"
          v-bind:id="tx.id"
          v-bind:txHash="tx.txHash"
          v-bind:isSell="tx.isSell"
          v-bind:token="tx.token"
          v-bind:tokenAmount="tx.tokenAmount"
          v-bind:value="tx.value"
          v-bind:date="tx.date"
          v-bind:pool="tx.pool"
          v-bind:isArbitrage="tx.isArbitrage"
          v-bind:arbPools="tx.arbPools"
          v-bind:pctChange="tx.pctChange"
        />
      </template>
    </div>

    <div id="tabUserTradesWrapper" v-else class="flex flex-col h-full w-full items-center place-content-center z-20">
      <img class="invert hue-rotate-180 filter" src="@/assets/emptystate/validation.svg" />
      <span class="dark:text-gray-500 text-gray-300 mt-1 overline block">Looks like you havent made any trades.</span>
    </div>
  </div>
</template>

<script>
import userTradesRow from "@/views/Chart/components/Tabs/userTradesRow.vue";
import userTradesRowMobile from "@/views/Chart/components/Tabs/userTradesRowMobile.vue";
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: {
    userTradesRow,
    userTradesRowMobile,
  },
  computed: {
    userTransactions: function() {
      // trim txlist so page doesn't crawl
      let overallTXLimit = 100;
      let txs = this.$store.state.userTransactions;
      let txListSize = txs.length;
      if (txListSize > overallTXLimit) {
        txs = txs.slice(0, overallTXLimit);
      }
      return txs;
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: none;
  box-shadow: inset 0 0 10px 10px #313d50;
}

/* width */
::-webkit-scrollbar {
  background: #101722;
}
</style>
