<template>
  <div class="flex flex-wrap p-2 tabContentWrapper">
    <div class="flex flex-wrap p-2 w-full">
      <!-- Empty state -->
      <div v-if="!$store.state.tokenNews.length" class="flex flex-col h-full w-full items-center place-content-center z-20">
        <img class="invert hue-rotate-180 filter" src="@/assets/emptystate/nocontent.svg" />
        <span class="dark:text-gray-500 text-gray-300 mt-1 overline block">Sorry, no news available for this token.</span>
      </div>
      <a target="_blank" :href="'https://www.bsc.news/post/' + newsItem.slug" class="p-2 font-heading text-sm w-full md:w-72 justify-between" v-bind:key="newsItem" v-for="newsItem in $store.state.tokenNews">
        <div class="group rounded-xl h-full w-full border-gray-200 dark:hover:border-gray-600 dark:border-gray-700 transition-colors duration-200 dark:bg-gray-900 bg-white border">
          <span class="border-b border-gray-200 dark:border-gray-700 transition-colors duration-200 rounded-t-xl flex flex-col w-full">
            <img class="rounded-t-xl overflow-hidden md:max-h-52 max-h-64 flex-shrink-0" :src="newsItem.main_image.url" target="_blank" />
            <h5 class="dark:text-white p-4 text-gray-800">
              {{ newsItem.name }}
            </h5>
          </span>
          <div class="p-4">
            <p class="caption text-gray-500 dark:text-gray-300">{{ newsItem.post_summary }}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
