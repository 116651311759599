<template>
  <div class="flex flex-col font-mono">
    <div class="overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow">
          <table class="min-w-full">
            <thead v-if="$store.state.topMcapTokens.length" class="dark:bg-gray-800 bg-white overflow-hidden">
              <tr>
                <th
                  @click="
                    sortType = 'tokenIndex';
                    isReverse = !isReverse;
                  "
                  scope="col"
                  class="group cursor-pointer rounded-l-full px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  <div class="flex flex-row items-center whitespace-nowrap">
                    Position
                    <div :class="sortType == 'tokenIndex' ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
                      <SortAscendingIcon v-if="sortType == 'tokenIndex' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                      <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </div>
                  </div>
                </th>
                <th
                  @click="
                    sortType = 'mcap';
                    isReverse = !isReverse;
                  "
                  scope="col"
                  class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  <div class="flex flex-row items-center whitespace-nowrap">
                    Mcap
                    <div :class="sortType == 'mcap' ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
                      <SortAscendingIcon v-if="sortType == 'mcap' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                      <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </div>
                  </div>
                </th>
                <th
                  @click="
                    sortType = 'change_pct_24h';
                    isReverse = !isReverse;
                  "
                  scope="col"
                  class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  <div class="flex flex-row items-center whitespace-nowrap">
                    24h change
                    <div :class="sortType == 'change_pct_24h' ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
                      <SortAscendingIcon v-if="sortType == 'change_pct_24h' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                      <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </div>
                  </div>
                </th>
                <th
                  @click="
                    sortType = 'change_pct_7d';
                    isReverse = !isReverse;
                  "
                  scop
                  scope="col"
                  class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  <div class="flex flex-row items-center whitespace-nowrap">
                    7d change
                    <div :class="sortType == 'change_pct_7d' ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
                      <SortAscendingIcon v-if="sortType == 'change_pct_7d' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                      <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </div>
                  </div>
                </th>
                <th
                  @click="
                    sortType = 'trustscore';
                    isReverse = !isReverse;
                  "
                  scop
                  scope="col"
                  class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  <div class="flex flex-row items-center whitespace-nowrap">
                    Trust score
                    <div :class="sortType == 'trustscore' ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
                      <SortAscendingIcon v-if="sortType == 'trustscore' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                      <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </div>
                    <div class="whitespace-normal">
                      <InformationCircleIcon
                        v-tippy="{
                          content: 'Trust score is calculated from a number of factors including: token age, liquidity, associated rugpulls, source code analysis, holder distribution and more',
                          appendTo: 'parent',
                          theme: 'bog',
                          animation: 'shift-toward-subtle',
                          arrow: false,
                          maxWidth: '350px',
                        }"
                        class="h-5 w-5 text-gray-500 ml-2"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </th>
                <th scope="col" class="group cursor-pointer rounded-r-full px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Last 7 days
                </th>
              </tr>
            </thead>
            <tbody v-if="$store.state.topMcapTokens.length" class="divide-y divide-gray-200 dark:divide-gray-800">
              <tr class="my-4" v-for="item in sortTable" :key="item">
                <td class="px-6 py-6 whitespace-nowrap relative">
                  <div class="fade-in flex space-x-4 flex-shrink-0 items-center">
                    <toggleFavorite v-bind:address="item.address" v-bind:tokenImageUrl="item.tokenImageUrl" v-bind:tokenName="item.tokenName" />
                    <router-link v-on:click="$store.dispatch('loadToken', item.address)" :to="'/?c=' + $store.state.chain + '&t=' + item.address" class="fade-in flex space-x-4 flex-shrink-0 items-center">
                      <span v-if="item.tokenIndex == 1" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-gold bg-opacity-40 font-semibold dark:text-gold-light text-gold-dark border-2 border-gold-dark">{{
                        item.tokenIndex
                      }}</span>
                      <span v-else-if="item.tokenIndex == 2" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-gray-200 bg-opacity-40 font-semibold dark:text-gray-100 text-gray-400 border-2 border-gray-400">{{
                        item.tokenIndex
                      }}</span>
                      <span v-else-if="item.tokenIndex == 3" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-bronze bg-opacity-40 font-semibold dark:text-bronze-light text-bronze-dark border-2 border-bronze-dark">{{
                        item.tokenIndex
                      }}</span>
                      <span v-else class="text-2xs font-heading h-7 w-7 rounded-md items-center flex place-content-center from-gray-200 to-gray-300 bg-gradient-to-br dark:from-gray-800 dark:to-gray-700 font-semibold dark:text-gray-500">{{ item.tokenIndex }}</span>
                      <img class="w-8 h-8 rounded-full cursor-default bg-white" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
                      <div class="ml-4">
                        <div class="text-sm font-medium font-heading text-gray-900 dark:text-white">
                          {{ item.tokenName }}
                        </div>
                        <div v-html="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" class="text-sm text-gray-600 dark:text-gray-300 mt-1"></div>
                      </div>
                    </router-link>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                  <div v-html="bogintificPrice(item.mcap, $store.state.useTruncatedBogintific)" class="fade-in"></div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.change_pct_24h >= 0, 'dark:text-error-bright text-error': item.change_pct_24h < 0 }" class="fade-in flex flex-row items-center text-xs">
                    <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.change_pct_24h >= 0 }" class="h-4 w-4" aria-hidden="true" />
                    <span class="text-sm">{{ item.change_pct_24h }}%</span>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.change_pct_7d >= 0, 'dark:text-error-bright text-error': item.change_pct_7d < 0 }" class="fade-in flex flex-row items-center text-xs">
                    <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.change_pct_7d >= 0 }" class="h-4 w-4" aria-hidden="true" />
                    <span class="text-sm">{{ item.change_pct_7d }}%</span>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  <trust-score v-bind:trustscore="item.trustscore" />
                </td>
                <td style="min-width: 164px;" class="px-6 py-4 whitespace-nowrap">
                  <div class="fade-in">
                    <img style="width: 164px; height: 48px;" @error="minichartUnavailable" v-bind:src="'https://cdn.bogged.finance/' + $store.state.chain + '/_minicharts/' + item.address + '.png'" v-if="item.address" />
                  </div>
                </td>
              </tr>
            </tbody>

            <div v-else class="w-full space-y-4">
              <div v-for="index in 8" :key="index">
                <div class="bg-gray-200 dark:bg-gray-800 rounded-md w-full h-20 animate-pulse-disable skeleton-loader"></div>
              </div>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import { ArrowSmDownIcon, SortAscendingIcon, SortDescendingIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon } from "@heroicons/vue/outline";
import toggleFavorite from "@/components/Favorite/toggleFavorite";

import trustScore from "./trustScore.vue";

export default {
  data: function() {
    return {
      sortType: "",
      isReverse: false,
    };
  },
  components: {
    bogintificPrice,
    maxDigits,
    ArrowSmDownIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    toggleFavorite,
    InformationCircleIcon,
    trustScore,
  },
  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    minichartUnavailable(event) {
      event.target.src = "/unknown.svg";
    },
  },
  computed: {
    sortTable: function() {
      var app = this;
      this.$store.state.topMcapTokens.sort(function(a, b) {
        if (a[app.sortType] < b[app.sortType]) {
          if (app.isReverse) return 1;
          else return -1;
        } else {
          if (app.isReverse) return -1;
          else return 1;
        }
      });
      return this.$store.state.topMcapTokens;
    },
  },
};
</script>
