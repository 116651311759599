<template>
  <div v-dragscroll class="items-center overflow-hidden lg:mt-0 mt-2 grabbable pr-12 flex flex-row space-x-2 md:space-x-3 w-full">
    <template v-for="item in order" v-bind:key="item.id" class="relative h-full">
      <component v-bind:is="item.card"></component>
    </template>
  </div>
</template>
<script>
import marketcapCard from "./Cards/marketcapCard";
import volumeCard from "./Cards/volumeCard";
import liquidityCard from "./Cards/liquidityCard";
import ratioCard from "./Cards/ratioCard";
import circSupplyCard from "./Cards/circSupplyCard";
import balanceCard from "./Cards/balanceCard";
import trustScoreCard from "./Cards/trustScoreCard";
import socialScoreCard from "./Cards/socialScoreCard";
import holdersCard from "./Cards/holdersCard";
import masterTokenPriceCard from "./Cards/masterTokenPriceCard";

export default {
  data() {
    return {
      order: this.$store.state.topbarOrder,
    };
  },
  components: {
    marketcapCard,
    volumeCard,
    liquidityCard,
    ratioCard,
    circSupplyCard,
    balanceCard,
    trustScoreCard,
    socialScoreCard,
    holdersCard,
    masterTokenPriceCard,
  },
};
</script>
