<template>
  <TransitionRoot appear :show="settingsModalIsOpen" as="template">
    <Dialog as="div">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild @click="closeSettingsModal" as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <div class="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 flex justify-between w-full items-center text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-700 px-6 py-4">
                Settings
                <button @click="closeSettingsModal" type="button" class="p-0.5 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none focus:ring-0">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </DialogTitle>
              <div class="p-6">
                <ul role="list" class="mt-2 divide-y divide-gray-100 dark:divide-gray-700">
                  <li class="pb-6 flex justify-between">
                    <div class="w-full flex flex-col">
                      <p class="text-sm font-medium text-gray-900 dark:text-white mb-2">Portfolio Piechart</p>
                      <p class="text-sm text-gray-500">Min. % to show in portfolio piechart (requires refresh).</p>
                      <vue3-slider
                        class="slider mt-6"
                        ref="portfolioSlider"
                        v-model="this.$store.state.portfolioCutoffPercent"
                        :data="sliderRange"
                        v-on:change="setPortfolioCutoff"
                        :height="12"
                        trackColor="#4C5C75"
                        color="#1BC870"
                        :min="min"
                        :max="max"
                        :step="1"
                        :orientation="orientation"
                      />
                    </div>
                    <div class="text-base w-12 text-right fade-in font-semibold text-secondary">{{ this.$store.state.portfolioCutoffPercent }}%</div>
                  </li>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                        Use Modified Scientific Notation
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Truncated (0..043) vs Modfied Scientific Notation (0.43 e-5).
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="setTruncatedBogintific"
                      v-model="truncatedToggle"
                      :class="[
                        this.$store.state.useTruncatedBogintific ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[this.$store.state.useTruncatedBogintific ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"
                      />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                        Keep BogSwap open
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Open BogSwap on Launch (Desktop Only).
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="setBogSwap"
                      v-model="useBogSwapToggle"
                      :class="[
                        this.$store.state.setBogSwap ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.setBogSwap ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                        Disable Ads
                        <span class="ml-2 bg-secondary bg-opacity-10 px-2 py-1 rounded-md text-secondary">Requires 5000 BOG</span>
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Toggling this setting disables all the ads on the site if you hold 5000+ BOG.
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="disableAd"
                      v-model="disableAdToggle"
                      :class="[
                        this.$store.state.disableAd ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.disableAd ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                        Disable Notification Sound
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Disable sound when you receive a price alert notification.
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="disableNotificationSound"
                      v-model="disableNotificationSoundToggle"
                      :class="[
                        this.$store.state.disableNotificationSound ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.disableNotificationSound ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                   <SwitchGroup as="li" class="py-6 flex items-center justify-between" v-if="!this.$store.state.disableNotificationSound">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white" passive>
                        Answer The Call?
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Changes notification sound for Price Alerts.
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="answerTheCall"
                      v-model="answerTheCallToggle"
                      :class="[
                        this.$store.state.answerTheCall ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.answerTheCall ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white" passive>
                        Gap Filling
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Displays a candle for every interval even when there is no volume.
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="gapFill"
                      v-model="gapFillToggle"
                      :class="[
                        this.$store.state.gapFill ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.gapFill ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white" passive>
                         Performance Mode
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Disables some features, displays fewer animations &amp; uses fewer resources to improve performance on older hardware.
                      </SwitchDescription>
                    </div>
                    <Switch
                      @click="performanceMode"
                      v-model="performanceModeToggle"
                      :class="[
                        this.$store.state.performanceMode ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                        'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                      ]"
                    >
                      <span aria-hidden="true" :class="[this.$store.state.performanceMode ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                  </SwitchGroup>
                  <SwitchGroup as="li" class="py-6 flex items-center justify-between">
                    <div class="flex flex-col">
                      <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                        Open Chart Settings
                      </SwitchLabel>
                      <SwitchDescription class="text-sm text-gray-500">
                        Configure the chart topbar.
                      </SwitchDescription>
                    </div>
                    <router-link class="button overline text-secondary hover:opacity-80 duration-200 transition-all" @click="openChartSettingsModal" :to="'/?c=' + $store.state.chain + '&t=' + $store.state.token">
                      Open
                    </router-link>
                  </SwitchGroup>

                 
                </ul>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import slider from "vue3-slider";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { useToast } from 'vue-toastification'
const toast = useToast();

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    XIcon,
    "vue3-slider": slider,
  },
  created: function() {
    this.truncatedToggle = !this.$store.state.useTruncatedBogintific;
    this.useBogSwapToggle = this.$store.state.setBogSwap;
    this.disableAdToggle = this.$store.state.disableAd;
    this.disableNotificationSoundToggle = this.$store.state.disableNotificationSound;
    this.answerTheCallToggle = this.$store.state.answerTheCall;
    this.gapFillToggle = this.$store.state.gapFill
    this.totalValueToggle = this.$store.state.showPortfolioTotalOnly;
    this.performanceModeToggle = this.$store.state.performanceMode;
  },
  data() {
    return {
      expand: false,
      showInputs: false,
      min: 0,
      max: 100,
      step: 1,
      orientation: "horizontal",
      sticky: false,
      repeat: false,
      flip: false,
      circleOffset: 0,
      value: 0,
      totalValueToggle: false,
      sliderRange: Array(400)
        .fill()
        .map((_, idx) => parseFloat((0.1 + idx * 0.01).toFixed(2))),
    };
  },
  computed: {
    settingsModalIsOpen: function() {
      return this.$store.getters.settingsModalOpen;
    },
  },
  methods: {
    answerTheCall: function(e) {
      this.$store.state.answerTheCall = !this.$store.state.answerTheCall;
      localStorage.setItem("answerTheCall", this.$store.state.answerTheCall);
      var audioURL = this.$store.state.answerTheCall ? "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/phoneBuzz.mp3?alt=media&token=17cdb20b-ba2e-42d4-9eca-aed88e26cfd1" : "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/notifcation_1.mp3?alt=media&token=2b6dcaa0-7740-4437-be98-891adb30a870"
      var audio = new Audio(audioURL);
      audio.volume = 0.8
      audio.play();

    },

    disableNotificationSound: function(e) {
      this.$store.state.disableNotificationSound = !this.$store.state.disableNotificationSound;
      localStorage.setItem("disableNotificationSound", this.$store.state.disableNotificationSound);
    },
    openChartSettingsModal: function() {
      this.$store.commit("openChartSettingsModal");
      this.$store.commit("closeSettingsModal");
    },
    setTruncatedBogintific: function(e) {
      this.$store.state.useTruncatedBogintific = !this.$store.state.useTruncatedBogintific;
      localStorage.setItem("useTruncatedBogintific", this.$store.state.useTruncatedBogintific);
    },
    setBogSwap: function(e) {
      this.$store.state.setBogSwap = !this.$store.state.setBogSwap;
      console.log(this.$store.state.setBogSwap);
      localStorage.setItem("setBogSwap", this.$store.state.setBogSwap);
    },
    disableAd: function(e) {
      this.$store.state.disableAd = !this.$store.state.disableAd;
      localStorage.setItem("disableAd", this.$store.state.disableAd);
      if(this.$store.state.bogPriority < 5000) {
        localStorage.setItem("disableAd", false)
        this.$store.state.disableAd  = false;
        toast.error("You need 5000+ BOG in a connected wallet to enable this feature.")
      }
    },
    setPortfolioTotalOlyToggle: function(e) {
      this.$store.state.showPortfolioTotalOnly = e.target.checked;
      localStorage.setItem("showPortfolioTotalOnly", this.$store.state.showPortfolioTotalOnly);
    },
    gapFill: function(e) {
      this.$store.state.gapFill = !this.$store.state.gapFill
      localStorage.setItem("gapFill", this.$store.state.gapFill);
      this.$store.commit('reloadChart')
    },
    performanceMode: function(e) {
      this.$store.state.performanceMode = !this.$store.state.performanceMode
      localStorage.setItem("performanceMode", this.$store.state.performanceMode);
    },
    setPortfolioCutoff: function(val) {
      this.$store.state.portfolioCutoffPercent = val;
      localStorage.setItem("portfolioCutoffPercent", val);
    },
    openSettingsModal: function() {
      this.$store.commit("openSettingsModal");
    },
    closeSettingsModal: function() {
      this.$store.commit("closeSettingsModal");
    },
  },
};
</script>
