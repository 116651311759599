<template>
  <div class="flex flex-row space-x-2 whitespace-wrap">
    <div class="flex flex-row items-center space-x-1">
      <span class="text-gray-400 whitespace-wrap text-2xs">Marketcap</span>
      <Switch
        @click="toggleMarketCap"
        :class="[
          activateMarketcap ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
          'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
        ]"
      >
        <span
          :class="[
            activateMarketcap ? 'translate-x-5 bg-white' : 'translate-x-0 dark:bg-gray-400 bg-white',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200',
          ]"
        >
        </span>
      </Switch>
    </div>

    <div class="flex flex-row items-center space-x-1">
      <span class="text-gray-400 whitespace-wrap text-2xs">Single Blocks</span>
      <Switch
        @click="toggleSingleBlocks"
        :class="[
          $store.state.singleBlock ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
          'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
        ]"
      >
        <span
          :class="[$store.state.singleBlock ? 'translate-x-5 bg-white' : 'translate-x-0 dark:bg-gray-400 bg-white', 'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200']"
        >
        </span>
      </Switch>
    </div>

    <div class="flex flex-row items-center space-x-1">
      <span class="text-gray-400 text-2xs">Trades</span>
      <Switch
        @click="togglePlotTrades"
        :class="[
          activatePlotTrades ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
          'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
        ]"
      >
        <span
          :class="[
            activatePlotTrades ? 'translate-x-5 bg-white' : 'translate-x-0 dark:bg-gray-400 bg-white',
            'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200',
          ]"
        >
        </span>
      </Switch>
    </div>
  </div>
</template>

<script>
import { Switch } from "@headlessui/vue";
export default {
  data() {
    return {
      activateMarketcap: this.$store.state.chartMarketcap,
      activateSingle: this.$store.state.singleBlock,
      activatePlotTrades: this.$store.state.supportsMarks,
      animated: false,
    };
  },
  components: {
    Switch,
  },
  methods: {
    toggleMarketCap: function() {
      this.$store.commit("toggleChartMarketcap");
      this.activateMarketcap = !this.activateMarketcap;
    },
    toggleSingleBlocks: function() {
      this.$store.commit("toggleSingleBlocks");
      this.activateSingle = !this.activateSingle;
    },
    togglePlotTrades: function() {
      this.$store.commit("togglePlotTrades");
      this.activatePlotTrades = !this.activatePlotTrades;
    },
  },
};
</script>

<style></style>
