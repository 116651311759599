import Web3 from "web3"
import Vuex from "vuex"
import theme from "./modules/theme"
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import { register } from "register-service-worker"
import { useToast } from 'vue-toastification'
const toast = useToast()
import { Services } from "@/services"
import { TradebookLib } from '@/tradebookLib'
import { WalletsLib } from '@/walletsLib'
import { PricesLib } from '@/pricesLib'
import { PairsLib } from '@/pairsLib'
import {
    PANCAKEPAIR_ABI,
    NEW_BOG_TOKEN_ADDR,
    NETWORKS,
    quoteTokens,
    exchangeTable,
    sleep,
    notificationsAPI_URL,
    CHAIN_TOKENS,
    CHAIN_TOKEN_SYMBOLS,    
    USD_TOKENS,
    USD_PEG_TOKENS,
} from "./constants"
import {
    getUSDOHLCData,
} from "./getohlcdata";

import router from "./router";
import { PCSV2_ROUTER_ABI } from "@/constants"
import InputDataDecoder from "ethereum-input-data-decoder"

console.log = function() {}

const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "D"]
const supportsMarks = true;
const configurationData  = {
    supported_resolutions: supportedResolutions,
    supports_marks: supportsMarks,
};

export default new Vuex.Store({
    modules: {
        theme
    },
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0',
        tokenlist: [],
        network: 56,
        chain: 'bsc',
        oos: false,
        initializing: null,
        sidebarOpen: false,
        swapOpen: false,
        swapOverlayOpen: false,
        settingsModalOpen: false,
        chartSettingsModalOpen: false,
        walletModalOpen: false,
        searchOpen: false,
        priceAlertOverlayOpen: false,
        tradesOpen: true,
        setBogSwap: true,
        locale: '',
        disableAd: false,
        disableNotificationSound: false,
        clientversion: null,
        answerTheCall: false,
        useTruncatedBogintific: true,
        showPortfolioTotalOnly: false,
        defaultTab: 1,
        portfolioCutoffPercent: 2,
        portfolioTokensHidden: [],
        loadedLocalStorage: false,
        rpcProviderCount: 1,

        dataFeed: null,
        priceUpdateInterval: null,

        allTokenBasicInfo: {},

        web3: null,
        web3_polygon: null,
        web3_avax: null,
        web3_ftm: null,
        web3_csc: null,
        web3s: {},
        wallet: null,
        accounts: null,
        walletTokens: {bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}},
        walletTokensWithFatalErrors: [],
        walletReloading: true,
        walletFirstLoadComplete: false,
        noIconTokens: [],
        gapFill: false,
        performanceMode: false, 
        lowSpecMachine: false,

        favoritedTokens: [],
        promotedTokens: [],

        masterTokenPrice: null, // this is the quote token in the base/quote pair
        chainTokenPrice: null,  // this is the primary chain token (e.g. wbnb, wmatic)
        bnbPrice: null,
        bogPrice: null,
        bogPriority: 0,
        bogPriorityNext: 0,
        masterToken: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        masterTokenSymbol: "BNB",
        chainToken: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        chainTokenSymbol: "BNB",        

        exchangeTable: {},
        currentExchange: "0xca143ce32fe78f1f7019d7d551a6402fc5350c73",
        currentExchangeName: "PancakeSwapV2",
        exchangeSpecified: null,
        
        quoteTokens: {},

        token: null,
        tokenDeployer: null,
        tokenOwner: null,
        ownershipRenounced: null,
        tokenLoadingAdr: null,
        tokenImage: null,
        tokenDescription: null,
        tokenWebsite: null,
        tokenTelegram: null,
        tokenDiscord: null,
        tokenTwitter: null,
        tokenSymbol: null,
        tokenName: null,
        tokenDecimals: null,
        tokenSupply: null,
        tokenWaterfall: null,
        tokenNews: [],
        tokenBurnt: 0,
        tokenCirculatingSupply: null,
        tokenMarketCap: null,
        tokenLiqBackingPct: null,
        tokenPairContract: null,
        tokenPairIndex: null, // 0 or 1 depending if token is token0 or token1 in the pair
                              // currently only used for coinex (until boginfo is deployed)
        masterPairContract: null, // currently only used for coinex (until boginfo is deployed)
        masterPairIndex: null, // 0 or 1 depending if token is token0 or token1 in the pair
                               // currently only used for coinex (until boginfo is deployed)
        nativePairContract: '0xcF634030726A2d3DFe6A468405C0bC2a92DBabb4',
        nativePairIndex: 0, // 0 or 1 depending if token is token0 or token1 in the pair
                            // currently only used for coinex (until boginfo is deployed)
        tokenContractVerificationLoaded: false,
        tokenContractVerified: false,
        tokenFavorited: false,

        tokenPrice: 0,
        lastTokenPrice: 0.0000012345, // dummy value for testing
        tokenMasterPairPrice: 0, 
        tokenHigh: null,
        tokenLow: null,
        tokenVolume: 0,
        tokenLiquidity: 0,
        //percentChange: 0,
        percentChange: null,
        percentChangeAndVolUsingBogApi: undefined,
        getTransactionsRunning: false,
        getTransactionsIndex: 1,

        autoUpdateArray: [],
        prevClosePrice: null,
        masterPriceArray: [],
        latestTime: 0,
        currentVolumeAmt: 0.00001,
        pricePair: 'USD',
        isTokenPair: false,

        screenWidth: window.innerWidth,

        transactions: [],
        transactionsByHash: {},
        estimateTxTimes: true,
        lastTransactionBlock: {},
        lastTransactionBlockMint: {},
        lastTransactionBlockBurn: {},
        lastTransactionID: {},
        transactionIntervalID: null,
        transactionsNetBuy: 0,
        transactionsNetSell: 0,
        tokenWalletValue: 0,
        transactionListBogApi: null,

        userTransactions:[],
        userTxnNetCost: 0,
        userTxnNetProfit: 0,
        netBuyValue: 0,
        netSellValue: 0,

        tokenSymbolsList: {},
        tokenSymbols: {},
        tokenDecimalsList: {},
        holders: null,
        top100: null,
        top100ByAddress: {},
        top100IsContract: null,
        tokenInfoBscscan: null,
        triggerChartSave: null,
        triggerChartLoad: null,
        triggerChartReset: null,
        chartLayouts: [],

        topbarOrder: JSON.parse(localStorage.getItem("order")) || [
            { toggle: "volume-toggle", card: "volume-card", id: 1 },
            { toggle: "liquidity-toggle", card: "liquidity-card", id: 2 },
            { toggle: "marketcap-toggle", card: "marketcap-card", id: 3 },
            { toggle: "ratio-toggle", card: "ratio-card", id: 4 },
            { toggle: "balance-toggle", card: "balance-card", id: 5 },
            { toggle: "circ-supply-toggle", card: "circ-supply-card", id: 6 },
            { toggle: "holders-toggle", card: "holders-card", id: 7 },
            { toggle: "trust-score-toggle", card: "trust-score-card", id: 8 },
            { toggle: "social-score-toggle", card: "social-score-card", id: 9, },
            { toggle: "master-token-price-toggle", card: "master-token-price-card", id: 9, },
        ],

        embed: '0', 
        embedhidebuttons: false,
        singleBlock: false,
        singleDataGotten: false,
        txnDataOnly: false,
        supportsMarks: true,
        chartMarketcap: false,

        mostViewedDailyTokens: [],
        topMcapTokens: [],
        trending2hTokens: [],
        topMcapTokensObj: {},
        trendingTokens: [],

        interval: "1",

        chartLoading: true,
        loadTokenRunning: false,
        chartSkip: true,
        loadFromPortfolio: false,
        blockExplorer: 'bscscan.com',
        liquidityData: null,
        liquidityPools: null,
        liquidityPoolsBog: {},
        trustscore: null,
        overrideTrustscore: null,
        socialScore: null,
        honeypot: null,
        localHoneypotTestResults: null,
        localHoneypotTestStarted: null,
        honeypotResults: {}, // stores the tests we've run previously per token
        route: null,
        intervalIds: [], // keeps track of setInterval / setTimeout loops that need to be cleared every token load
        timeoutIds: [],
        popstateIsSet: false,        
        initialized: false,
        priceAlerts: [],        
        beamsClient: null,
        notificationsID: null,
        bogData: null,
        bogDataUsed: false,
        switchedInterval: false,
        whitelistData: null,
        alertText: null,
        alertType: null,
        tokenAnalytics: null,
        tokenLocks: {},
        earliestBlockTs: null,
        bogApiMaxTxnTimestamp: 0,
        exchangesWithLiquidity: {},
        blockiesData: {},
        blockiesDisabled: false,
        txhashesMissingWallet: [],
        txhashesMissingWalletComputing: false,
        decoder: new InputDataDecoder(PCSV2_ROUTER_ABI),
        loadLargestExchangeContractTries: 0,
        firstChartData: true,
        chartReady: false,
        firstLoad: true,
        customExchangeSet: false,
        currentExchangeQt: null,
        marqueeTimoutIds: [],
        defaultTabIndex:0, // defaults to chart
        page:"chart",
        rerenderTabs: false,
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion
        },
        activeAlerts: state => {
            return state.priceAlerts.filter(alert => !alert.triggered).sort((a,b) => b.dateCreated - a.dateCreated);
        },
        triggeredAlerts: state => {
            return state.priceAlerts.filter(alert => alert.triggered).sort((a,b) => b.triggeredTime - a.triggeredTime);
        },
        walletConnected: state => {
            return state.wallet !== null;
        },
        walletTokens: state => state.walletTokens[state.chain],
        accountWalletTokens: state => state.accounts === null ? null : state.walletTokens[state.chain][state.accounts[0]],
        sidebarOpen: state => {
            return state.sidebarOpen;
        },
        swapOpen: state => {
            return state.swapOpen;
        },
        swapOverlayOpen: (state) => {
            return state.swapOverlayOpen;
        },
        settingsModalOpen: (state) => {
            return state.settingsModalOpen;
        },
        chartSettingsModalOpen: (state) => {
            return state.chartSettingsModalOpen;
        },
        walletModalOpen: (state) => {
            return state.walletModalOpen;
        },
        searchOpen: (state) => {
            return state.searchOpen;
        },
        priceAlertOverlayOpen: (state) => {
            return state.priceAlertOverlayOpen;
        },
        tradesOpen: state => {
            return state.tradesOpen;
        },
        account: state => {
            return state.accounts === null ? null : state.accounts[0];
        },
        symbol: (state) => (token) => {
            return state.tokenSymbols.hasOwnProperty(token) ? state.tokenSymbols[token] : null;
        },
        decimals: (state) => (token) => {
            return state.tokenDecimalsList.hasOwnProperty(token) ? state.tokenDecimalsList[token] : null;
        },
        tokenContractVerificationIsLoaded: (state) => {
            return state.tokenContractVerificationLoaded
        },
        tokenContractIsVerified: (state) => {
            return state.tokenContractVerified
        },
    },    
    mutations: {
        SET_ROUTE(state, route) {
            state.route = route;
        },
        cacheSymbol(state, {
            token,
            symbol
        }) {
            state.tokenSymbols[token] = symbol;
        },
        async connectWallet(state, provider) {
            console.log('____________')
            console.log('!! connect wallet fired')
            console.log('____________')
            provider.on('accountsChanged', async () => {
                this.dispatch("loadAccounts");
            })

            provider.on('disconnect', () => {
                console.log('Disconnect fired')
                state.wallet = null
                state.accounts = []
                state.walletTokens = {bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}}
                state.portfolioTokensHidden = []
                // removing this ensures we will not auto-connect wallet anymore
                //localStorage.removeItem("walletTokenDict")
            })

            state.wallet = new Web3(provider);
            console.log('wallet connected')
            //console.log(state.wallet)
            await this.dispatch("switchChain", {chain:state.chain, token_to_load:null})

            await this.dispatch("loadAccounts")
            
            
            if(!state.chartLoading){
                WalletsLib.getUserTransactions(context)
            }
        },
        disconnectWallet(state) {
            state.wallet = null
            state.accounts = []
            state.walletTokens = {bsc: {}, polygon: {}, avax: {}, ftm: {}, csc: {}}            
            state.portfolioTokensHidden = []
            // removing this ensures we will not auto-connect wallet anymore
            localStorage.removeItem("walletTokenDict")
        },
        toggleVolume(state) {
            state.showVolume = localStorage.showVolume = !state.showVolume;
        },
        toggleLiquidity(state) {
            state.showLiquidity = localStorage.showLiquidity = !state.showLiquidity;
        },
        toggleMarketcap(state) {
            state.showMarketcap = localStorage.showMarketcap = !state.showMarketcap;
        },
        toggleRatio(state) {
            state.showRatio = localStorage.showRatio = !state.showRatio;
        },
        toggleBalance(state) {
            state.showBalance = localStorage.showBalance = !state.showBalance;
        },
        toggleCircSupply(state) {
            state.showCircSupply = localStorage.showCircSupply = !state.showCircSupply;
        },
        toggleHolders(state) {
            state.showHolders = localStorage.showHolders = !state.showHolders;
        },
        toggleTrustScore(state) {
            state.showTrustScore = localStorage.showTrustScore = !state.showTrustScore;
        },
        toggleSocialScore(state) {
            state.showSocialScore = localStorage.showSocialScore = !state.showSocialScore;
        },
        toggleMasterTokenPrice(state) {
            state.showMasterTokenPrice = localStorage.showMasterTokenPrice = !state.showMasterTokenPrice;
        },
        toggleFullWidthChart(state) {
            state.enableFullWidth = localStorage.enableFullWidth = !state.enableFullWidth;
        },
        toggleSidebar(state) {
            state.sidebarOpen = localStorage.sidebarOpen = !state.sidebarOpen;
        },
        closeSwap(state) {
            state.swapOpen = false;
        },
        toggleSwap(state) {
            state.swapOpen = localStorage.swapOpen = !state.swapOpen;
        },
        closeSearch(state) {
            state.searchOpen = false;
        },
        openSearch(state) {
            state.searchOpen = true;
        },
        openSwapOverlay(state) {
            state.swapOverlayOpen = localStorage.swapOverlayOpen = true;
        },
        closeSwapOverlay(state) {
            state.swapOverlayOpen = localStorage.swapOverlayOpen = false;
        },
        openSettingsModal(state) {
            state.settingsModalOpen = localStorage.settingsModalOpen = true;
        },
        closeSettingsModal(state) {
            state.settingsModalOpen = localStorage.settingsModalOpen = false;
        },
        openChartSettingsModal(state) {
            state.chartSettingsModalOpen = localStorage.chartSettingsModalOpen = true;
        },
        closeChartSettingsModal(state) {
            state.chartSettingsModalOpen = localStorage.chartSettingsModalOpen = false;
        },
        openWalletModal(state) {
            state.walletModalOpen = localStorage.walletModalOpen = true;
        },
        closeWalletModal(state) {
            state.walletModalOpen = localStorage.walletModalOpen = false;
        },
        openPriceAlertOverlay(state) {
            state.priceAlertOverlayOpen = localStorage.priceAlertOverlayOpen = true;    
            //reload data everytime panel is opened    
            if(this.notificationsID != null){
                this.dispatch("getAllPriceAlerts");
            }  
        },
        closePriceAlertOverlay(state) {
            state.priceAlertOverlayOpen = localStorage.priceAlertOverlayOpen = false;
        },
        toggleTrades(state) {
            state.tradesOpen = localStorage.tradesOpen = !state.tradesOpen;
        },
        togglePricePair(state) {
            if(state.pricePair == 'BNB'){
                state.pricePair = 'USD';
            }
            else{
                state.pricePair = 'BNB';
            }
            state.isTokenPair = !state.isTokenPair;

            state.autoUpdateArray = [];
            state.masterPriceArray = [];
            state.chartLoading = true;
            this.dispatch("loadChartData");
        },
        toggleSingleBlocks(state) {
            state.singleBlock = !state.singleBlock;
            //localStorage.setItem('singleBlock', state.singleBlock)
            
            if (state.singleBlock) {
                state.interval = "1s" 
                if (state.currentVolumeAmt == 0) {
                    state.currentVolumeAmt = 0.000000001
                }
            } else {
                state.interval = JSON.parse(localStorage.getItem('interval') || 1)
            }

            state.autoUpdateArray = [];
            state.masterPriceArray = [];
            state.chartLoading = true;
            this.dispatch("loadChartData");
        },
        toggleChartMarketcap(state) {
            state.chartMarketcap = !state.chartMarketcap;
            state.autoUpdateArray = [];
            state.masterPriceArray = [];
            state.chartLoading = true;
            this.dispatch("loadChartData");
        },
        togglePlotTrades(state) {
            state.supportsMarks = !state.supportsMarks;

            state.autoUpdateArray = [];
            state.masterPriceArray = [];
            state.chartLoading = true;
            this.dispatch("loadChartData");
        },
        reloadChart(state) {
            state.chartLoading = true;
            console.log('reloadChart fired')
            this.dispatch("loadChartData");
        },
        storeInitialization(state, initialization) {
            state.initializing = initialization;
            try {
                state.clientversion = JSON.parse(localStorage.getItem("latestVersion") || 3.4)
                //change this version to clear localstorage to avoid issues with major revisions.
                var currentVersion = "4.10"
                if (state.clientversion != currentVersion) {
                    // localStorage.clear();
                    // console.log("Old local storage detected, Clearing Local Storage.")
                    // console.error("Old local storage detected, Clearing Local Storage.")
                    localStorage.setItem('latestVersion', currentVersion)
                }

                // if favorited token doesn't have a chain then default to bsc
                let favorites = JSON.parse(localStorage.getItem("favoriteTokens"));
                if (favorites !== null) {
                  let updateChain = favorites.map((favorite) => {
                    let defaultChain = "bsc";
                    if (favorite.tokenChain == null) {
                      return {
                        ...favorite,
                        tokenChain: defaultChain,
                      };
                    } else {
                      return {
                        ...favorite,
                      };
                    }
                  });
                  localStorage.setItem("favoriteTokens", JSON.stringify(updateChain));
                }

                state.useTruncatedBogintific = JSON.parse(localStorage.getItem('useTruncatedBogintific') || "true")
                if (localStorage.getItem('setBogSwap') == undefined) {
                    if (window.innerWidth > 1920 && state.chain != 'csc') {
                        state.setBogSwap = true
                    } else {
                        state.setBogSwap = false
                    }                    
                } else {
                    state.setBogSwap = JSON.parse(localStorage.getItem('setBogSwap') || "false")
                }                
                state.interval = JSON.parse(localStorage.getItem('interval') || 1)
                state.gapFill = JSON.parse(localStorage.getItem('gapFill') || false)
                state.performanceMode = JSON.parse(localStorage.getItem('performanceMode') || false)
                state.disableAd = JSON.parse(localStorage.getItem('disableAd') || "false")
                state.disableNotificationSound = JSON.parse(localStorage.getItem('disableNotificationSound') || "false")
                state.answerTheCall = JSON.parse(localStorage.getItem('answerTheCall') || "false")
                state.showVolume = JSON.parse(localStorage.getItem('showVolume') || "true")
                state.showLiquidity = JSON.parse(localStorage.getItem('showLiquidity') || "true")
                state.showMarketcap = JSON.parse(localStorage.getItem('showMarketcap') || "true")
                state.showRatio = JSON.parse(localStorage.getItem('showRatio') || "false")
                state.showBalance = JSON.parse(localStorage.getItem('showBalance') || "true")
                state.showCircSupply = JSON.parse(localStorage.getItem('showCircSupply') || "false")
                state.showHolders = JSON.parse(localStorage.getItem('showHolders') || "true")
                state.showTrustScore = JSON.parse(localStorage.getItem('showTrustScore') || "true")
                state.showSocialScore = JSON.parse(localStorage.getItem('showSocialScore') || "false")
                state.showMasterTokenPrice = JSON.parse(localStorage.getItem('showMasterTokenPrice') || "false")
                state.enableFullWidth = JSON.parse(localStorage.getItem('enableFullWidth') || "false")
                state.swapOpen = state.setBogSwap
                state.showPortfolioTotalOnly = JSON.parse(localStorage.getItem('showPortfolioTotalOnly') || "false")
                state.portfolioCutoffPercent = JSON.parse(localStorage.getItem('portfolioCutoffPercent') || 2)
                state.portfolioTokensHidden = JSON.parse(localStorage.getItem("hiddenTokens") || "[]");
                state.honeypotResults = JSON.parse(localStorage.getItem("honeypotResults") || "{}");
                state.tokenDecimalsList = JSON.parse(localStorage.getItem("tokenDecimalsList") || "{}");
                state.chartLayouts = JSON.parse(localStorage.getItem("chartLayouts") || "[]");
                state.defaultTab = JSON.parse(localStorage.getItem("defaultTab") || "0");
                state.exchangeTable = exchangeTable
                state.notificationsID = localStorage.getItem("notificationsID");
                //state.singleBlock = JSON.parse(localStorage.getItem("singleBlock") || false);
                if(state.notificationsID){
                    //if beams id, start beams client first
                    if(!state.notificationsID.includes("telegramID_")){
                        //make sure beams login is successful before loading alerts
                        this.dispatch("loginBeams").then(success =>{
                            if(!success) state.notificationsID = null;
                            else this.dispatch("getAllPriceAlerts");
                        })
                    }
                    //user is logged in through tg, so no extra steps necessary
                    else{
                        this.dispatch("getAllPriceAlerts");
                    }
                }
                
            } catch (error) {
                localStorage.clear();
                console.error("There was an error retreiving your settings. Local Storage has been cleared");
                console.error(error);
            }
            state.loadedLocalStorage = true
        },
        cacheDecimals(state, {
            token,
            decimals
        }) {
            state.tokenDecimalsList[token] = decimals;
        },
    },
    actions: {
        async registerServiceWorker(context){
            
            if (process.env.NODE_ENV === "production" && !Services.isSafari()) {
                register(`./service-worker.js`, {
                    ready() {
                        console.log("Service Worked Loaded Successfully");
                        console.log("Initializing Beams Client");

                        //initialize beams client
                        context.state.beamsClient = new PusherPushNotifications.Client({
                            instanceId: "ad545995-9648-42fc-958a-58da4370d0e3",
                        });
                        
                    },
                    error(error) {
                    console.error("Error during service worker registration:", error);
                    }
                });

            }
                
        },

        async startTelegramLogin(context, loginID) {

            if(!loginID) return;

            const API_URL = `${notificationsAPI_URL}/tgLoginCreate`;
            
            const res = await fetch(API_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ loginID})
            });
            const resData = await res.json();
        },      
        async pollTelegramLogin(context, loginID) {
            if(!loginID) return;

            const API_URL = `${notificationsAPI_URL}/tgLoginPoll`;
            
            const res = await fetch(API_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({loginID})
            });
            const resData = await res.json();

            return resData && resData.data.userID || null;
        },      
        async loginTelegram(context,{loginID,userID}) {

            if(!loginID || !userID) return;

            const notificationsID = `telegramID_${userID}`;
            //set state
            context.state.notificationsID = notificationsID;
            //set local storage
            localStorage.setItem("notificationsID",notificationsID)

            this.dispatch("deleteTelegramLoginID",loginID)
        },      
        async deleteTelegramLoginID(context,loginID) {

            if(!loginID) return;

            //delete loginID in DB
            const API_URL = `${notificationsAPI_URL}/tgLoginDelete`;
            fetch(API_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({loginID})
            });  
        },      
        async loginBeams(context){
            try {
                console.log("starting")
                //start beams client and get device id
                await context.state.beamsClient.start();
                const deviceID = await context.state.beamsClient.getDeviceId();
                
                //set ID in store
                context.state.notificationsID = deviceID;
                
                console.log("getting user")
                //if the device id hasnt been set in beams as a userID, go ahead and set it
                const beamsUserID = await context.state.beamsClient.getUserId();
                if (!beamsUserID) {
                    const beamsTokenProvider = new PusherPushNotifications.TokenProvider(
                        {
                            url: `${notificationsAPI_URL}/createBeamsToken`
                        }
                        );
                        await context.state.beamsClient.setUserId(deviceID, beamsTokenProvider);
                        console.log("Successfully set beamsUserID: ", deviceID);
                    }
                    
                    console.log("setting up data")
                //subscribe to notification updates from service worker
                const broadcast = new BroadcastChannel('notifications');
                broadcast.onmessage = (e) => {
                    //refresh price alerts
                    if(!context.state.disableNotificationSound){
                        const audioURL = context.state.answerTheCall ? "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/phoneBuzz.mp3?alt=media&token=17cdb20b-ba2e-42d4-9eca-aed88e26cfd1" : "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/notifcation_1.mp3?alt=media&token=2b6dcaa0-7740-4437-be98-891adb30a870"
                        const audio = new Audio(audioURL);
                        audio.volume = 0.8
                        audio.play();
                    }
                    
                    const alertData = e.data.data;
                    
                    //activate toast
                    toast.info(
                        `${alertData.symbol} reached $${alertData.price}`, 
                        {
                            timeout:false,
                            onClick: ()=>{
                                //load token on click
                                router.push(`/?c=${alertData.chain}&t=${alertData.token}`)
                                context.dispatch('loadToken', alertData.token);
                            }
                        }
                    );

                    //reload notifications
                    context.dispatch("getAllPriceAlerts");

                }
                //set local storage
                localStorage.setItem("notificationsID",beamsUserID)

                //return successful
                return true;
            } catch (error) {
                console.error("Beams Login Error: ", error)
                //return unsucessful
                return false;
            }
        },
        async logoutNotifications(context) {
            //set state
            context.state.notificationsID = null;
            //set local storage
            localStorage.removeItem("notificationsID")
        },
        async getAllPriceAlerts(context){
            const userID = context.state.notificationsID;
            if(!userID) return;

            const API_URL = `${notificationsAPI_URL}/getAlerts?userID=${userID}`
            const res = await fetch(API_URL);
            const resData = await res.json();
            context.state.priceAlerts = resData.data;
        },
        async deletePriceAlert(context,alert){
            const API_URL = `${notificationsAPI_URL}/deleteAlert`
            await fetch(API_URL, 
                {
                  method: 'POST',
                  headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({id: alert.id})
                });
            
            context.state.priceAlerts = context.state.priceAlerts.filter(_alert => _alert.id !== alert.id);
        },
        async createPriceAlert(context,alert){

            //add usreid to alert
            const userID = context.state.notificationsID;
            // const userID = context.state.beamsDeviceID;
            if(!userID) return;
            alert.userID = userID;

            const API_URL = `${notificationsAPI_URL}/addAlert`
            await fetch(API_URL, 
                {
                  method: 'POST',
                  headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({...alert})
                });
            
            context.state.priceAlerts = [...context.state.priceAlerts, alert]
        },
        async reenablePriceAlert(context,alert){
            const API_URL = `${notificationsAPI_URL}/resetAlert`
            await fetch(API_URL, 
                {
                  method: 'POST',
                  headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({id: alert.id})
                });
            
            //find alert of id and set triggered to false
            const priceAlerts = [...context.state.priceAlerts];
            const i = priceAlerts.findIndex(_alert => _alert.id === alert.id);
            priceAlerts[i].triggered = false;

            context.state.priceAlerts = priceAlerts;
        },
        async getSymbol(context, token){
            if(context.getters.symbol(token) !== null){ return context.getters.symbol(token); }
            context.commit("cacheSymbol", {
                token, symbol: await Services.getSymbol(token, context)
            });            
            return context.getters.symbol(token);
        },
        async getDecimals(context, token){
            if(context.getters.decimals(token) !== null){ return context.getters.decimals(token); }
            context.commit("cacheDecimals", {                
                token, decimals: token === "BNB" ? 18 : await Services.getDecimals(context, token)
            });
            return context.getters.decimals(token);
        },
        async loadLiquidity(context) {
            console.log('______________________')
            console.log('liq pools:')
            //if (context.state.chain == 'csc') { return }
            if (context.state.token == null) return;
            let w3 = context.state.web3s[context.state.chain]

            var liq_factories = []
            var factory_names = {}
            var liq_quote_toks = []
            var quote_tok_names = {}
            console.log(quote_tok_names)
            let max_exchanges = 20
            let factories_to_ignore = [
                "0x5De74546d3B86C8Df7FEEc30253865e1149818C8".toLowerCase(),
                "0x1B8E12F839BD4e73A47adDF76cF7F0097d74c14C".toLowerCase()
            ]

            if (context.state.chain == 'csc') {
                while (!context.state.liquidityPoolsBog) {
                    await sleep(50)
                    console.log('waiting for liquidityPoolsBog')
                }
            }

            if (context.state.chain == 'polygon') { max_exchanges = 10 }
            if (Object.keys(context.state.exchangesWithLiquidity).length > 0) {
                let n=0
                for (const [key,amm] of Object.entries(context.state.exchangesWithLiquidity)) {
                    if (factories_to_ignore.includes(amm.address.toLowerCase())) { // this factory causes execution reverted
                        console.log('skipping factory')
                        continue
                    }
                    n += 1
                    if (n > max_exchanges) {
                        break
                    }
                    liq_factories.push(w3.utils.toChecksumAddress(amm.address));
                    factory_names[amm.factory] = amm.name
                }
                console.log('liq_factories:')
                console.log(liq_factories)
            } else {
                let n = 0
                for (const [key,amm] of Object.entries(context.state.exchangeTable[context.state.chain])) {
                    n += 1
                    if (n > max_exchanges) {
                        break
                    }
                    liq_factories.push(w3.utils.toChecksumAddress(amm.address));
                    factory_names[amm.factory] = amm.name
                }
                console.log('liq_factories (...):')
                console.log(liq_factories)
            }
            for (const [key,qt] of Object.entries(quoteTokens[context.state.chain])) {
                liq_quote_toks.push(qt.address);
                quote_tok_names[qt.address] = qt.name
            }

            if (!(context.state.masterToken in quoteTokens[context.state.chain])) {
                liq_quote_toks.push(context.state.masterToken);
                quote_tok_names[context.state.masterToken] = context.state.masterTokenSymbol
            }
            //liq_factories = liq_factories.slice(0,15)
            //liq_quote_toks = [quoteTokens[context.state.chain][context.state.masterToken].address]
            //quote_tok_names = [context.state.masterTokenSymbol]
            
            console.log(quote_tok_names)
            var poolAdrs = []
                var newPairData = []
            if (context.state.chain == "csc") {                
                for (const [key,pool] of Object.entries(context.state.liquidityPoolsBog)) {
                    newPairData.push({
                        contractName: context.state.exchangesWithLiquidity[pool.factory.toLowerCase()].name,
                        contract: null,
                        factory: pool.factory,
                        contractObj: null,
                        token: {
                            name: context.state.tokenSymbol,
                            reserve: null,
                            pooled: null,
                        },
                        quoteToken: {
                            name: pool.qt_symbol,
                            reserve: null,
                            pooled: null,
                            address: pool.qt
                        },
                        lpHalfValue: null,
                        totalValue: null,
                        totalLPs: null,
                        token0: context.state.token,
                        token1: pool.qt,
                        pooled_usd: pool.liq_usd,
                    })
                }
                newPairData.sort((b, a) => {
                    return a.pooled_usd - b.pooled_usd;
                });

            } else {
                var filtered_pools = await Services.getTokenPools(context.state.web3s[context.state.chain],context.state.chain,context.state.token,liq_factories,liq_quote_toks,1000000)
                        
                console.log(filtered_pools)
                var liq_usd_total = 0
                for (var i = 0; i < filtered_pools.all.length; i++) {                
                    var pool = filtered_pools.all[i]
                    let quoteTokenDecimals
                    var quoteTokenAdr
                    if (pool.token0.adr.toLowerCase() == context.state.token.toLowerCase()) {                    
                        liq_usd_total += parseInt(pool.reserve0) / (10**parseInt(pool.token0.decimals)) * context.state.tokenPrice
                        quoteTokenDecimals = pool.token1.decimals
                        quoteTokenAdr = pool.token1.adr
                    } else if (pool.token1.adr.toLowerCase() == context.state.token.toLowerCase()) {                    
                        liq_usd_total += parseInt(pool.reserve1) / (10**parseInt(pool.token1.decimals)) * context.state.tokenPrice
                        quoteTokenDecimals = pool.token0.decimals
                        quoteTokenAdr = pool.token0.adr
                    }

                    let factory = pool.factory.toLowerCase()
                    if (factory && !(factory in context.state.exchangesWithLiquidity)) {
                        console.log('added exchange to list')
                        console.log(factory)
                        
                        context.state.exchangesWithLiquidity[factory] = exchangeTable[context.state.chain][factory]
                        console.log(context.state.exchangesWithLiquidity[factory])
                    }

                    /*
                    let quoteTokenValueUSD = context.state.chainTokenPrice;
                    let stablecoinNames = ["BUSD", "USDT", "USDC", "WUSD", "DAI"]
                    if (stablecoinNames.includes(quote_tok_names[quoteTokenAdr])) {
                        quoteTokenValueUSD = 1;
                    } else if (quoteTokenAdr != context.state.chainToken) {
                        continue
                    }
                    */
                    let baseTokenPriceUSD = context.state.tokenPrice
                    let pairSupply = pool.totalSupply
                    let pairDecimals = 18
                    let pairSupplyNormalized = pairSupply / Math.pow(10, pairDecimals);

                    let token0FromPairAddr = pool.token0.adr
                    
                    let tokenDecimals = context.state.tokenDecimals
                    //let tokenDecimals = 18
                    
                    let tokenIdx = token0FromPairAddr == context.state.token ? 0 : 1;
                    
                    let quoteTokenIdx = tokenIdx == 1 ? 0 : 1;

                    // normalize token according to it's decimals
                    let pairReserves = [parseInt(pool.reserve0), parseInt(pool.reserve1)]
                    let tokenReservesNormalized = pairReserves[tokenIdx] / Math.pow(10, tokenDecimals);
                    let quoteTokenReservesNormalized = pairReserves[quoteTokenIdx] / Math.pow(10, quoteTokenDecimals);

                    // finally, divide by LP supply to get number of each per LP token
                    let tokenPerLP = tokenReservesNormalized / pairSupplyNormalized;
                    let quoteTokenPerLP = quoteTokenReservesNormalized / pairSupplyNormalized;

                    let pairData = {
                        contractName: context.state.exchangesWithLiquidity[pool.factory.toLowerCase()].name,
                        contract: pool.adr,
                        factory: pool.factory,
                        contractObj: await new w3.eth.Contract(PANCAKEPAIR_ABI, pool.adr),
                        token: {
                            name: context.state.tokenSymbol,
                            reserve: tokenPerLP,
                            pooled: tokenReservesNormalized,
                        },
                        quoteToken: {
                            name: quote_tok_names[quoteTokenAdr],
                            reserve: quoteTokenPerLP,
                            pooled: quoteTokenReservesNormalized,
                            address: quoteTokenAdr
                        },
                        //lpHalfValue: quoteTokenPerLP * quoteTokenValueUSD,
                        lpHalfValue: tokenPerLP * baseTokenPriceUSD,
                        //totalValue: quoteTokenPerLP * quoteTokenValueUSD * 2,
                        totalValue: tokenPerLP * baseTokenPriceUSD * 2,
                        totalLPs: pairSupplyNormalized,
                        token0: pool.token0.adr,
                        token1: pool.token1.adr,
                        //pooled_usd: quoteTokenReservesNormalized * quoteTokenValueUSD * 2,
                        pooled_usd: tokenReservesNormalized * baseTokenPriceUSD * 2,
                    };
                    //totalLiquidity += quoteTokenPerLP * quoteTokenValueUSD * 2 * pairSupplyNormalized;
                    newPairData.push(pairData);
                    poolAdrs.push(pool.adr.toLowerCase())
                
                }
                liq_usd_total *= 2.0
                if (!context.state.tokenLiquidity) {
                    context.state.tokenLiquidity = liq_usd_total
                    context.state.liquidityUpdated = Date.now()
                }
                
                console.log('!!!!!!!!!!!!!!!!!!!!!!')
                console.log('total liq usd: $'+liq_usd_total.toString())
                console.log(filtered_pools)

                newPairData.sort((b, a) => {
                    return a.totalValue * a.totalLPs - b.totalValue * b.totalLPs;
                });
            }

            

            console.log("newPairData", newPairData)

            //context.state.tokenLiquidity = totalLiquidity;
            context.state.liquidityPools = poolAdrs;
            context.state.liquidityData = newPairData;   
            
            
            if (newPairData) {
                for (let pool of newPairData) {
                    let factory = pool.factory.toLowerCase()
                    if (factory && !(factory in context.state.exchangesWithLiquidity)) {
                        console.log('added exchange to list')
                        console.log(factory)
                        
                        context.state.exchangesWithLiquidity[factory] = exchangeTable[context.state.chain][factory]
                        console.log(context.state.exchangesWithLiquidity[factory])
                    }
                }
            }
        },
        async initialize(context) {
            context.dispatch("registerServiceWorker");
            context.dispatch("loadChartData");            

            return new Promise(async (resolve) => {
                let count = 0;
                let maxTries = 15;
                console.log('initialize')       

                if (!(context.state.popStateIsSet)) {
                    window.addEventListener('popstate', function(event) {
                        if (event.state && event.state.token) {
                            context.dispatch("loadToken", event.state.token);
                        }
                    });
                    context.state.popStateIsSet = true
                }

                Services.performanceTest(context)
                
                context.state.scamTokenArray = Services.getScamTokenJson(context)
                context.state.favoritedTokens = JSON.parse(localStorage.getItem('favoriteTokens') || "[]")                
                context.state.performanceMode = JSON.parse(localStorage.getItem('performanceMode') || false)                
                var performanceMultiplier = 1; 
                if (context.state.performanceMode == true) {
                    performanceMultiplier = 2
                    console.info("Performance mode activated.")
                }
                if (context.state.screenWidth >= 1400) {
                    if (localStorage.sidebarOpen !== undefined) {
                        context.state.sidebarOpen = JSON.parse(localStorage.sidebarOpen);
                    } else {
                        context.state.sidebarOpen = localStorage.sidebarOpen = true;
                    }
                }

                if (window.ethereum) {
                    window.ethereum.on('accountsChanged', function (accounts) {
                        context.state.walletFirstLoadComplete = false
                        context.dispatch("loadAccounts");                       
                    })

                    window.ethereum.on('chainChanged', async function (chainId) {
                        var chain = null
                        for (const [key, value] of Object.entries(NETWORKS))
                        {
                            if (chainId == value) {
                                chain = key
                                break                                
                            }
                        }
                        if (!(chain)) { console.log('Error: unknown chain ID'+chainId.toString()); }
                        else { context.dispatch("switchChain", {chain:chain, token_to_load:null}); }
                    })
                }
                
                while(true) {
                    try {
                        let token = await Services.processRouteParams(context)
                        let provideruri;

                        provideruri = Services.getProviderUri('bsc',count)
                        let provider = new Web3.providers.HttpProvider(provideruri);
                        context.state.web3 = new Web3(provider);
                        context.state.web3s["bsc"] = context.state.web3

                        provideruri = Services.getProviderUri('polygon',count)
                        provider = new Web3.providers.HttpProvider(provideruri);
                        context.state.web3_polygon = new Web3(provider);
                        context.state.web3s["polygon"] = context.state.web3_polygon

                        provideruri = Services.getProviderUri('csc',count)
                        provider = new Web3.providers.HttpProvider(provideruri);
                        context.state.web3_csc = new Web3(provider);
                        context.state.web3s["csc"] = context.state.web3_csc
                        
                        /*
                        provideruri = Services.getProviderUri('avax',count)
                        provider = new Web3.providers.HttpProvider(provideruri);
                        context.state.web3_avax = new Web3(provider);
                        context.state.web3s["avax"] = context.state.web3_avax
                        

                        provideruri = Services.getProviderUri('ftm',count)
                        provider = new Web3.providers.HttpProvider(provideruri);
                        context.state.web3_ftm = new Web3(provider);
                        context.state.web3s["ftm"] = context.state.web3_ftm
                        */

                        context.state.rpcProviderCount = count

                        console.log('web3 objects created')
                        

                        let route = context.state.route
                        if (context.state.route.name == 'wallet-tracker') {
                            console.log('wallet tracking page 1')
                            return
                        }
                        if (route.query.embed != undefined && route.query.embed == '1') {
                            context.state.embed = route.query.embed
                            context.state.embedhidebuttons = route.query.hidebuttons

                            if (context.state.route.name.toLowerCase() == 'chart') {
                                await this.dispatch("loadToken", token);
                                    setInterval(()=>{
                                        PricesLib.loadTokenPrice(context)
                                    }, (6000 * performanceMultiplier));                                
                            }
                            setInterval(()=> {
                                PricesLib.loadMasterTokenPrice(context)
                            }, (25000 * performanceMultiplier));
                        }
                        else{
                            await this.dispatch("loadToken", token);
                            if (context.state.route.name.toLowerCase() == 'chart') {
                                setInterval(()=>{
                                    PricesLib.loadTokenPrice(context)
                                }, (3000 * performanceMultiplier));

                            }
                            setInterval(()=> {
                                this.dispatch("loadTrendingTokens")
                            }, (600000 * performanceMultiplier));
                            
                            // this will make the liquidity in top bar change
                            // do this too often it is very busy so don't do it as frequently
                            setInterval(()=> {
                                PricesLib.loadMasterTokenPrice(context)
                            }, (12000 * performanceMultiplier));
                            
                        }
                        console.log('Initialised')
                        context.state.initialized = true
                        
                        break;
                    } catch (e) {
                        console.log(`@@@@@@@@@@@@@@@@@@@@`)
                        console.log(e)
                        count += 1
                        context.state.rpcProviderCount = count
                        if (count == maxTries) {                            
                            if (e.toString().includes('too many requests') || e.toString().includes('Rate limit exceeded')) {
                                alert("Unfortunately, your connection to "+context.state.chain.toUpperCase() + " is experiencing issues. If you have other chart tabs open, close them and wait a few minutes.");
                                context.state.lastRpcError = Date.now()
                                context.state.rpcError = e.toString()
                            } else {
                                alert("Unfortunately, "+context.state.chain.toUpperCase() + " is experiencing issues and we were unable to connect you. If you are on a VPN, it's possible your VPN has been blocked by the network. Please try again later.");
                            }
                        }
                    }
                    await sleep(50)
                }
                resolve();
            });
        },

        

        async updateTrendingArea_UNUSED(context, data) {
            let promise = data.promise
            let target = data.target
            let result
            if (target == "favorites") {
                result = context.state.favoritedTokens
            } else {
                result = await promise
            
                for (let idx in result.trends) {
                    result.trends[idx].price = "-"
                    result.trends[idx].mcap = "-"
                }
            }

            let targetDataStructure
            if (target == "trending") {
                context.state.trendingTokens = result.trends
                targetDataStructure = context.state.trendingTokens
            } else if (target == "daily") {
                context.state.mostViewedDailyTokens = result.trends
                targetDataStructure = context.state.mostViewedDailyTokens
            } else if (target == "favorites") {
                targetDataStructure = context.state.favoritedTokens
            } else if (target == "promoted") {
                targetDataStructure = context.state.promotedTokens
            }
            
            for (let obj of targetDataStructure) {
                try {
                    let address = obj.address || obj.tokenAddress
                    if (Object.keys(context.state.allTokenBasicInfo).includes(address)) {
                        let existing = context.state.allTokenBasicInfo[address]
                        obj.price = existing.price
                        obj.mcap = existing.mcap
                    } else {
                        let price = (address == context.state.masterToken) ? context.state.masterTokenPrice : (await Services.getTokenSpotPrice(context, address)).usdPrice
                        let tokenInfo = await Services.getTokenInfo(context.state.web3, address, "bsc")
                        let tokenName = obj.tokenName
                        
                        let tokenDecimals = tokenInfo.decimals;
                        let tokenSupply = tokenInfo.supply / Math.pow(10, tokenDecimals);
                        let tokenBurnt = tokenInfo.burnt / Math.pow(10, tokenDecimals);
                        let tokenMarketcap = (tokenSupply - tokenBurnt) * price
                        
                        obj.price = price
                        obj.mcap = tokenMarketcap
                        
                        let newTokenBasicInfoObj = {
                            address: address,
                            name: tokenName,
                            decimals: tokenDecimals,
                            supply: tokenSupply,
                            burnt: tokenBurnt,
                            mcap: tokenMarketcap,
                            price: price,
                        }
                        context.state.allTokenBasicInfo[address] = newTokenBasicInfoObj
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            
            // I am tired
            if (target == "favorites") {
                let temp = context.state.favoritedTokens
                context.state.favoritedTokens = null
                context.state.favoritedTokens = temp
            }

            //console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;')
            //console.log(context.state.allTokenBasicInfo)
            //console.log(context.state.trendingTokens)
            //console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;')
        },

        async upvoteAllFavoritedTokens(context) {
            for (let token of context.state.favoritedTokens) {
                await Services.incrementTokenLikeCount(token.tokenAddress)
            }
        },

        async loadTrendingTokens(context) {
            if (true) {
                //await context.dispatch("update_trending_area_bog_api", {})
                await Services.update_trending_area_bog_api(context, {})
            }
        },

        async loadFromPortfolio(context, address) {
            context.state.loadFromPortfolio = true;
            await this.dispatch("loadToken", address);
        },
        async addtomm(){
                try {
                    web3.currentProvider.sendAsync({
                        method: 'wallet_watchAsset',
                        params: {
                            'type': "ERC20",
                            'options': {
                                'address': this.state.token,
                                'symbol': this.state.tokenSymbol,
                                'decimals': this.state.tokenDecimals,
                                'image': this.state.tokenImage,
                            },
                        },
                        id: Math.round(Math.random() * 100000)
                    }, function(err, data) {
                        if (!err) {
                            if (data.result) {
                                console.log('Token added');
                            } else {
                                console.log(data);
                                console.log('Some error');
                            }
                        } else {
                            console.log(err.message);
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
        },
        async loadToken(context, address) {
            if (context.state.loadLiquidityID) {
                clearTimeout(context.state.loadLiquidityID)
            }

            

            let hptest = context.state.hptest
            console.log('loadToken fired')
            if (hptest) {
                console.log('__________________-')
                console.log('hptest')
                console.log(address)
                console.log('__________________-')
            }
            if (hptest && address=='') {
                return
            }
            if (context.state.walletTrackingPage) {
                console.log('wallet tracking page')
                return
            }
            if (address.includes("-")) {
                address = address.split("-")[0]
            }
            address = context.state.web3.utils.toChecksumAddress(address)
            if (context.state.loadTokenRunning) {
                if (context.state.tokenLoadingAdr == address) {
                    console.log('loadToken already running')
                    return
                }
            }
            context.state.tokenLoadingAdr = address
            context.state.loadTokenRunning = true
            context.state.chartLoading = true;
            //if (address == '-') { address = "0xB09FE1613fE03E7361319d2a43eDc17422f36B09"; }
            if (address == '-') { address = CHAIN_TOKENS[context.state.chain] }
            context.state.masterToken = CHAIN_TOKENS[context.state.chain]
            let routeToken = ''
            let sleptFor = 0            

            if (context.state.firstLoad) {
                context.state.firstLoad = false                
            } else {
                context.dispatch('loadChartData')
            }
            this.dispatch('getDecimals', address)
            let tokenInfoPromise = Services.getTokenInfo(context.state.web3s[context.state.chain], address, context.state.chain);

            
            let interval_mins
            if(context.state.interval == '1D'){ interval_mins = 1440 }
            else{ interval_mins = parseInt(context.state.interval); }

            let denom = null
            //let lastConnectedWallet = await Services.guessLastConnectedWallet(context)
            let bogdataPromise = Services.getOHLCDataBogAPILargestPool(context.state.chain,address,interval_mins,denom)

            if (!hptest && !context.state.walletTrackingPage) {
                // route can take a bit to update after navigating so we wait for it:
                while (routeToken.toLowerCase() != address.toLowerCase() && sleptFor < 3000) {
                    routeToken = await Services.processRouteParams(context)
                    if (routeToken.toLowerCase() == address.toLowerCase()) {
                        break
                    }
                    else {
                        await sleep(50)
                        sleptFor += 50
                        //console.log('.')
                    }
                }
                if (routeToken.toLowerCase() != address.toLowerCase()) {
                    console.log('loadToken passed address does not match route address: '+address + ' ' + routeToken)
                    alert("Unfortunately, we were unable to load the chart for this token on "+context.state.chain.toUpperCase()+".");
                    await sleep(2500);
                    context.state.loadTokenRunning = false
                    return
                }
            } else {
                routeToken = address
            }
            let chainTokenSpotPriceProm = Services.getChainTokenSpotPrice(context)            
            
            console.log(address + ' ' + routeToken)
            
            
            context.state.tokenFavorited = context.state.favoritedTokens.map(x => x.tokenAddress).includes(address)
            

            context.state.trustscore = null
            context.state.overrideTrustscore = null
            context.state.socialScore = null
            context.state.honeypot = null
            context.state.localHoneypotTestResults = null
            context.state.localHoneypotTestStarted = null
            context.state.transactionListBogApi = null
            context.state.tokenLocks = {}
                  
            

            context.state.token = address;
            context.state.tokenSymbol = '-';
            context.state.tokenName = '-';
            context.state.tokenWaterfall = "";
            context.state.tokenNews = [];
            context.state.tokenDecimals = 18;
            context.state.tokenSupply = 0;
            context.state.tokenBurnt = 0;
            context.state.tokenPrice = 0;
            context.state.tokenLiquidity = 0;
            context.state.percentChange = null;            
            //context.state.percentChange = 0;
            context.state.tokenVolume = null;
            context.state.percentChangeAndVolUsingBogApi = undefined;
            context.state.tokenCirculatingSupply = null;
            context.state.liquidityData = null;
            context.state.liquidityPools = null,
            context.state.liquidityPoolsBog = {},
            context.state.top100 = null;
            context.state.top100ByAddress = {};
            context.state.top100IsContract = null;
            context.state.holdersHitCap = false;
            context.state.tokenInfoBscscan = null;
            context.state.tokenMarketCap = null;
            context.state.tokenLiqBackingPct = 0;
            context.state.autoUpdateArray = [];
            context.state.masterPriceArray = [];
            context.state.tokenWalletBalance = 0;
            context.state.tokenWalletValue = 0;
            context.state.transactions = [];
            context.state.transactionsByHash = {};
            context.state.userTransactions = [];
            context.state.userTxnNetCost = 0;
            context.state.userTxnNetProfit = 0;
            context.state.transactionsNetBuy = 0;
            context.state.transactionsNetSell = 0;
            context.state.tokenContractVerified = false
            context.state.tokenContractVerificationLoaded = false
            context.state.tokenDeployer = null
            context.state.ownershipRenounced = null
            context.state.tokenOwner = null
            context.state.getTransactionsRunning = false
            context.state.getTransactionsIndex = 1
            context.state.bogData = null
            context.state.bogDataUsed = false
            context.state.switchedInterval = false
            context.state.alertText = null
            context.state.alertType = null
            context.state.tokenAnalytics = null            
            context.state.earliestBlockTs = null
            
            context.state.estimateTxTimes = true
            context.state.lastTransactionBlock = {}
            context.state.lastTransactionBlockMint = {}
            context.state.lastTransactionBlockBurn = {}
            context.state.lastTransactionID = {}
            context.state.transactions = []
            context.state.bogApiMaxTxnTimestamp = 0
            context.state.exchangesWithLiquidity = {}
            context.state.blockiesDisabled = false
            context.state.txhashesMissingWallet = []
            context.state.loadLargestExchangeContractTries = 0
            context.state.currentVolumeAmt = 0.00001
            context.state.firstChartData = true
            context.state.chartReady = false
            context.state.masterTokenSymbol = null
            context.state.currentExchangeQt = null

            context.state.tokenWebsite = null
            context.state.tokenWebsite = null
            context.state.tokenTelegram = null
            context.state.tokenDiscord = null
            context.state.tokenTwitter = null
            context.state.tokenDescription = null
            context.state.masterTokenPrice = null
            
            if (context.state.transactionIntervalID) {
                clearInterval(context.state.transactionIntervalID)
            }
            
            context.state.holders = null;         
            context.state.tokenImage = "https://static.bogged.finance/"+context.state.chain +"/assets/" + address + "/logo.png";
            let maxTries = 15;

            console.log(0.01)
  
            let w3 = context.state.web3s[context.state.chain]
            
            let fasttrack = false
            let poolSet = false
            let poolIndex = null
            if (!USD_PEG_TOKENS[context.state.chain].includes(context.state.token.toLowerCase()) || context.state.singleBlock) {
                // fasttrack data from bog api so we can chart it asap

                let bd = await bogdataPromise
                console.log(0.02)
                context.state.bogData = bd
                console.log(bd)
                
                let i = 0
                if (bd && bd.status == 1 && bd.data && bd.data.length > 0 && bd.lp_address && bd.factory && bd.quote_token) {
                    let blacklist = {
                        '0x32ce36f6ea8d97f9fc19aab83b9c6d2f52d74470': true,
                    }                    
                    let topPool = null
                    console.log(context.state.exchangeSpecified)
                    for (let f of bd.liq_pools) {                 
                        let factory = f[0]
                        let liq_usd = f[1]
                        let exchange_name = f[2]
                        let qt = w3.utils.toChecksumAddress(f[3])
                        let qt_symbol = f[4]
                        let qt_decimals = f[5]
                        if (!(factory in blacklist) && liq_usd && (liq_usd >= 10 || context.state.liquidityPoolsBog.length < 30)) {
                            if (!topPool) { topPool = factory }
                            if (!poolSet && !context.state.exchangeSpecified) {
                                context.state.currentExchange = factory                         
                                context.state.masterToken = qt
                                context.state.tokenDecimalsList[context.state.masterToken] = qt_decimals
                                context.state.masterTokenSymbol = qt_symbol
                                context.state.currentExchangeQt = factory+qt
                                poolSet = true
                                poolIndex = i
                            }
                            if (context.state.exchangeSpecified && context.state.exchangeSpecified.toLowerCase() == factory.toLowerCase() && !poolSet) {
                                context.state.customExchangeSet = true
                                console.log('setting specified exchange',context.state.exchangeSpecified)
                                context.state.currentExchange = factory                                                               
                                context.state.masterToken = qt
                                context.state.tokenDecimalsList[context.state.masterToken] = qt_decimals
                                context.state.masterTokenSymbol = qt_symbol
                                context.state.currentExchangeQt = factory+qt
                                poolSet = true
                                poolIndex = i
                            }
                            if (context.state.chain == 'csc' && poolSet) {
                                context.state.tokenPairContract = Web3.utils.toChecksumAddress(f[6]);
                            }
                            let exchangeData = {
                                "name": exchange_name,
                                "address": factory,
                                "contract": null,
                                "pairs": {},
                                "bogApiSupported": true,
                            }
                            context.state.exchangesWithLiquidity[factory] = exchangeData
                            let poolData = {
                                "name": exchange_name,
                                "factory": factory,
                                "pool_address": null,
                                "qt": qt,
                                "qt_symbol": qt_symbol,
                                "qt_decimals": qt_decimals,
                                "liq_usd": Math.round(liq_usd),
                            }
                            context.state.liquidityPoolsBog[factory+qt] = poolData
                        }
                        i += 1
                    }

                    let bypassFastTrack = false
                    if (((context.state.exchangeSpecified || poolIndex > 0) && poolSet) || !poolSet) {
                        bypassFastTrack = true
                        console.log('bypass fast track')
                    }

                    if (!bypassFastTrack) {
                        if (context.state.exchangeSpecified && !poolSet) {
                            // specified exchange wasn't found in list of exchanges with liq
                            console.log('specified exchange not found in list of exchanges with liq')
                            context.state.currentExchange = topExchange
                        }

                        if (context.state.masterToken.toLowerCase() != CHAIN_TOKENS[context.state.chain].toLowerCase()) {
                            context.state.pricePair = 'USD';
                        }
                        context.state.masterTokenPrice = bd.quote_token_price_usd
                        if (poolIndex == 0) {
                            context.state.tokenPairContract = w3.utils.toChecksumAddress(bd.lp_address)
                        }

                        context.state.tokenMasterPairPrice = bd.data[bd.data.length-1].quote_base_close
                        
                    }
                    context.state.tokenSymbol = bd.token_symbol
                    context.state.tokenPrice = bd.data[bd.data.length-1].usd_base_close
                    context.state.tokenDecimals = bd.token_decimals
                    context.state.tokenName = bd.token_name
                    context.state.tokenDecimalsList[address] = bd.token_decimals
                    
                    context.state.whitelistData = bd.metadata
                    context.state.earliestBlockTs = bd.earliest_block_ts
                    context.state.tokenLiquidity = bd.liq_usd
                    context.state.transactionListBogApi = bd.txn_list
                    context.state.liquidityUpdated = Date.now()

                    
                    
                    
                    console.log(0.03)
                   
                    let wl = bd.metadata
                    // whitelist & alerts info is obfuscated coming from api.            
                    // wl[2] is alert text
                    // wl[3] is alert type (red, yellow or informational)
                    // wl[4] is trustscore override
                    if (wl) {
                        if (wl[2]) {
                            context.state.alertText = wl[2]
                            if (!wl[3]) { context.state.alertType = 'RED' }
                        }
                        if (wl[3]) {
                            context.state.alertType = wl[3]
                        }                        
                        if (wl[4]) {
                            context.state.trustscore = Math.round(parseFloat(wl[4]))
                            context.state.overrideTrustscore = true
                        }
                    }
                    
                    if (bd.txn_list && bd.txn_list.length > 0) {
                        for (let tx of bd.txn_list) {
                            if (tx.t0 == context.state.token.toLowerCase()) {
                                tx.quoteToken = tx.t1
                            } else {
                                tx.quoteToken = tx.t0
                            }
                        }
                    }
                    console.log(0.04)
                    if (context.state.currentExchange && !context.state.exchangeSpecified && !bypassFastTrack) {
                        console.log('!!!! fast track')
                        if (context.state.chain == 'csc') {

                        }
                        context.state.chartReady = true                     
                        context.state.exstart = Date.now()
                        TradebookLib.getTransactions(context, context.state.tokenSymbol)
                        fasttrack = true 
                    }
                    
                    
                }                
            }
            let tokenInfo
            if (fasttrack) {
                tokenInfo = await tokenInfoPromise
                context.state.tokenDecimals = tokenInfo.decimals;
                context.state.tokenSupply = tokenInfo.supply / Math.pow(10, context.state.tokenDecimals);
                context.state.tokenBurnt = tokenInfo.burnt / Math.pow(10, context.state.tokenDecimals);
                context.state.tokenCirculatingSupply = context.state.tokenSupply - context.state.tokenBurnt
                context.state.tokenMarketCap = context.state.tokenCirculatingSupply * context.state.tokenPrice
            }

            if (context.state.chain == 'csc') {
                await PairsLib.setPoolInfo(context)
            }
            context.dispatch("loadTrendingTokens")
            PricesLib.loadMasterTokenPrice(context)

            if (poolSet && poolIndex != 0) {
                Services.setPair(context, context.state.web3s[context.state.chain], context.state.token, context.state.masterToken, context.state.currentExchange)
            }

            let verifiedPromise = Services.getTokenIsVerified(address)
            Services.get_trustscore(address,context)
            Services.get_tokeninfo_bscscan(address,context)      
            Services.getOwner(context,address)
            Services.get_holders(address, context)
            

            if (!fasttrack) {
                context.state.transactionListBogApi = []
            }
            

            console.log(1.01)
            if (Object.keys(context.state.exchangesWithLiquidity).length == 0) {
                console.log('loadLargestExchangeContract')
                await PairsLib.loadLargestExchangeContract(context, context.state.token)
            }
            if (!context.state.currentExchange) {
                console.log('no pools found')
            }
            Services.getUnicryptLocks(context)
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            await chainTokenSpotPriceProm
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            console.log("loadTokenPrice");

            if (fasttrack) {
                PricesLib.loadTokenPrice(context)
            } else {
                await PricesLib.loadTokenPrice(context)
            }
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            
            for(let count = 0; count <= maxTries; ++count) {
                try { 
                    console.log(1.02)                    
                    if (!fasttrack) {
                        //context.dispatch("getTransactions", context.state.tokenSymbol)
                        TradebookLib.getTransactions(context, context.state.tokenSymbol)
                    }
                    
                    //let tokenInfo = await Services.getTokenInfo(context.state.web3s[context.state.chain], address, context.state.chain);
                    if (!fasttrack) {
                        tokenInfo = await tokenInfoPromise
                    }
                    if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
                    if (!fasttrack) {
                        this.dispatch("loadLastDayVolume");
                    }
                    console.log(1.03)
                    context.state.tokenSymbol = tokenInfo.symbol;
                    context.state.tokenName = tokenInfo.name;
                    
                    document.title = context.state.tokenSymbol + " $" + ((value)=>{
                        let stringedValue = parseFloat(value).toFixed(20).toString();
                        let truncated = "";
                        let pastLeading = false;
                        let counter = 0;
        
                        for (let i = 0; i < stringedValue.length; i++) {
                            truncated += stringedValue[i];
        
                            if (!pastLeading) {
                                if (stringedValue[i] != '0' && stringedValue[i] != '.') {
                                    pastLeading = true;
                                }
                            } else {
                                counter++;
                                if (counter == 5) {
                                    break;
                                }
                            }
                        }
                        return truncated;
                    }) (context.state.tokenPrice) + " Price Chart  (" + context.state.tokenName + ") - BOGCharts";
                    
                    context.state.tokenDecimals = tokenInfo.decimals;
                    context.state.tokenSupply = tokenInfo.supply / Math.pow(10, context.state.tokenDecimals);
                    context.state.tokenBurnt = tokenInfo.burnt / Math.pow(10, context.state.tokenDecimals);
                    context.state.tokenCirculatingSupply = context.state.tokenSupply - context.state.tokenBurnt

                    context.state.tokenMarketCap = context.state.tokenCirculatingSupply * context.state.tokenPrice

                    await this.dispatch('loadLiquidity')
                    context.state.chartReady = true
                    
                    console.log('route')
                    console.log(context.state.route.name)
                    if (context.state.route.name.toLowerCase() == 'chart') {
                        await Services.updateURL(context)
                    }
                    context.state.exchangeSpecified = false
                    if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }

                    
                    if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
                    console.log(1.04)
                    //if(context.state.transactions.length > 0 && context.state.transactions.length < 30)
                    context.state.transactionIntervalID = setInterval(() => {
                        //context.dispatch("getTransactions", context.state.tokenSymbol)
                        TradebookLib.getTransactions(context, context.state.tokenSymbol)
                    }, 5000)

                    

                    let liqRefreshInterval
                    if (context.state.tokenLiquidity < 50000) liqRefreshInterval = 60000;
                    else if (context.state.tokenLiquidity < 200000) liqRefreshInterval = 120000;
                    else if (context.state.tokenLiquidity < 500000) liqRefreshInterval = 300000;
                    else liqRefreshInterval = 30000000;
                    context.state.loadLiquidityID = setInterval(() => {
                        context.dispatch("loadLiquidity")
                    }, liqRefreshInterval)

                    break;
                } catch (err) {
                    console.log(err)
                    if (true) {
                        context.state.rpcProviderCount += 1
                        let provideruri = Services.getProviderUri(context.state.chain,context.state.rpcProviderCount)                        
                        console.log('switching provider and trying again')
                        console.log(provideruri)
                        let provider = new Web3.providers.HttpProvider(provideruri);                        
                        context.state.web3s[context.state.chain] = new Web3(provider)
                        if (context.state.chain == 'bsc') {
                            context.state.web3 = context.state.web3s[context.state.chain]
                        }
                    }
                    if (++count == maxTries) {
                        context.state.loadTokenRunning = false
                        alert("Unfortunately, we were unable to load the chart for this token on "+context.state.chain.toUpperCase()+".");                        
                        return
                    }
                    await sleep(2500);
                    if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
                }
            }

            await context.state.scamTokenArray
            let scamTokenArray = context.state.scamTokenArray
            if ((scamTokenArray.includes(address.toLowerCase()) || scamTokenArray.includes(address)) || (address.length == 42 && scamTokenArray.includes(w3.utils.toChecksumAddress(address)))) {
                context.state.trustscore = 0
                context.state.overrideTrustscore = true
                context.state.alertText = "This token has been flagged as a possible scam."
                context.state.alertType = "RED"
            }
            
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            if(context.state.accounts){
                WalletsLib.getUserTransactions(context)
            }
            console.log(1.05)
            
            // This isn't properly being delayed till after token load...
            let tokenData = await Services.getTokenJson(address);
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            console.log(1.06)
            console.log(tokenData)
            if (tokenData.site) {
                context.state.tokenWebsite = tokenData.site;
            }
            if (!context.state.tokenWebsite && tokenData.site) { context.state.tokenWebsite = tokenData.site; }
            context.state.tokenAlert = tokenData.alertinfo;
            context.state.tokenAlertURL = tokenData.alerturl;
            context.state.tokenAlertURLDesc = tokenData.alerturldesc;
            context.state.tokenAlertColour = tokenData.alertcolour;
            if (tokenData.tg) { context.state.tokenTelegram = tokenData.tg; }            
            if (tokenData.discord) { context.state.tokenDiscord = tokenData.discord; }
            if (tokenData.twitter) { context.state.tokenTwitter = tokenData.twitter; }
            if (tokenData.desc) { context.state.tokenDescription = tokenData.desc; }
            this.dispatch("loadIsVerified", verifiedPromise);
            // (We can do this last.)
            let tokenWaterfall = await Services.getTokenWaterfall(address);
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            console.log(1.07)
            context.state.tokenWaterfall = tokenWaterfall;

            let tokenNews = await Services.getTokenNews(address);
            if (address != context.state.tokenLoadingAdr) { console.log('navigated to new token during load'); return; }
            console.log(1.08)
            context.state.tokenNews = tokenNews;

            Services.incrementTokenViewCount(address);
            context.state.loadTokenRunning = false
        },

        async loadAccounts(context) {
            context.state.accounts = await context.state.wallet.eth.getAccounts();            
            context.state.walletFirstLoadComplete = false
            WalletsLib.loadWalletTokens(context, context.state.accounts[0])
        },
        
        async switchExchange(context, exchange) {
            console.log('switchExchange fired')
            context.state.currentExchange = exchange.toLowerCase();
            context.state.chartLoading = true;

            // get rid of the specified exchange in the url if switching exchanges. it's only meant for linking, so will only apply at first page load
            console.log(exchange)

            context.state.exchangeSpecified = null
            await Services.updateURL(context)
            await PairsLib.loadLargestPoolForExchange(context)            
          
            await PricesLib.loadMasterTokenPrice(context)
            context.dispatch("loadChartData");
            
        },
        async switchPool(context, exchangeQt) {
            console.log('switchPool fired')
            let exchange = exchangeQt.substring(0,42)
            let qt = exchangeQt.substring(42)
            context.state.currentExchange = exchange.toLowerCase();
            context.state.masterToken = qt
            context.state.currentExchangeQt = exchangeQt
            context.state.masterTokenSymbol = context.state.liquidityPoolsBog[exchangeQt]['qt_symbol']
            context.state.chartLoading = true;

            // get rid of the specified exchange in the url if switching exchanges. it's only meant for linking, so will only apply at first page load
            console.log(exchange)
            console.log(qt)

            context.state.exchangeSpecified = null
            await Services.updateURL(context)

            await Services.setPair(context, context.state.web3s[context.state.chain], context.state.token, qt, exchange)
            if (context.state.chain == 'csc') {
                await PairsLib.setPoolInfo(context)
            }
            await PricesLib.loadMasterTokenPrice(context)
            PricesLib.loadTokenPrice(context)
            context.dispatch("loadChartData");
            

        },
        async switchChain(context, params) {
            var chain = params.chain      
            context.state.chainToken = CHAIN_TOKENS[chain]      
            context.state.walletFirstLoadComplete = false;
            context.state.network = NETWORKS[chain];
            //console.log(context.state.network)
            console.log("SwitchChain fired," + chain)
            var loadToken = false
            var reloadTrending = chain != context.state.chain
            if (chain != context.state.chain) {
                loadToken = true;
            }
            context.state.chain = chain
            
            context.state.chainTokenSymbol = CHAIN_TOKEN_SYMBOLS[chain]
            if (window.ethereum == undefined) {
                // Metamask is probably not installed so need to do things a bit differently
                context.state.oos = false;
            }
            else if (chain != context.state.chain) { // Metamask is installed
                let userschain = await window.ethereum.request({ method: 'eth_chainId' })
                var hexchain = chain.toString(16)
                if(userschain == chain)
                {
                    console.log("Correct Chain on Metamask");
                } else {
                    try {
                        window.ethereum.request({ method: 'eth_chainId' }).then(async ()=>{
                            try {
                                if (chain == 'bsc') {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0x38' }],
                                    });
                                } else if (chain == 'eth') {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0x1' }],
                                    });
                                } else if (chain == 'polygon')
                                {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0x89' }],
                                    });
                                } else if (chain == 'avax')
                                {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0xa86a' }],
                                    });
                                } else if (chain == 'ftm')
                                {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0xfa' }],
                                    });
                                } else if (chain == 'csc')
                                {
                                    await window.ethereum.request({
                                        method: 'wallet_switchEthereumChain',
                                        params: [{ chainId: '0x34' }],
                                    });
                                }
                                context.state.oos = false;
                            } catch (error) {
                                console.log(error)
                                if (error.code === 4902) {
                                    if(chain == 'eth')
                                    {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0x1', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Ethereum Mainnet',
                                                    nativeCurrency: {
                                                        name: "ETH",
                                                        symbol: "ETH", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
                                                    blockExplorerUrls: ["https://etherscan.io/"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;
                                        } catch (addError) {
                                            context.state.oos = true;
                                            console.log("Out of sync with selection.")
                                        }
                                    } else if (chain == 'bsc') {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0x38', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Binance Smart Chain',
                                                    nativeCurrency: {
                                                        name: "BNB",
                                                        symbol: "BNB", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
                                                    blockExplorerUrls: ["https://bscscan.com/"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;

                                        } catch (addError) {
                                            context.state.oos = true;
                                        }
                                    } else if (chain == 'polygon') {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0x89', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Matic(Polygon) Mainnet',
                                                    nativeCurrency: {
                                                        name: "MATIC",
                                                        symbol: "MATIC", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://rpc-mainnet.matic.network"],
                                                    blockExplorerUrls: ["https://polygonscan.com"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;

                                        } catch (addError) {
                                            context.state.oos = true;
                                        }
                                    } else if (chain == 'avax') {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0xa86a', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Avalanche Mainnet',
                                                    nativeCurrency: {
                                                        name: "AVAX",
                                                        symbol: "AVAX", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                                                    blockExplorerUrls: ["https://snowtrace.io/"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;

                                        } catch (addError) {
                                            context.state.oos = true;
                                        }
                                    } else if (chain == 'ftm') {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0xfa', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Fantom Mainnet',
                                                    nativeCurrency: {
                                                        name: "FTM",
                                                        symbol: "FTM", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://rpc.ftm.tools/"],
                                                    blockExplorerUrls: ["https://ftmscan.com/"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;

                                        } catch (addError) {
                                            context.state.oos = true;
                                        }
                                    } else if (chain == 'csc') {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [{
                                                    chainId: '0x34', // A 0x-prefixed hexadecimal string
                                                    chainName: 'Coinex Smart Chain',
                                                    nativeCurrency: {
                                                        name: "CoinEx Token",
                                                        symbol: "CET", // 2-6 characters long
                                                        decimals: 18,
                                                    },
                                                    rpcUrls: ["https://rpc.coinex.net"],
                                                    blockExplorerUrls: ["https://coinex.net/"],
                                                }],
                                            });
                                            context.state.chain = chain
                                            context.state.oos = false;

                                        } catch (addError) {
                                            context.state.oos = true;
                                        }
                                    }
                                    


                                }else{
                                    context.state.oos = true;
                                }
                            }
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }
            }
            if (params.token_to_load) {
                if (reloadTrending) {
                    //this.dispatch("update_trending_area_bog_api", {})
                    Services.update_trending_area_bog_api(context, {})
                }
                await PricesLib.loadMasterTokenPrice(context)
                this.dispatch('loadToken', params.token_to_load)
            }
            else if (loadToken) { // we don't run this code if switchchain was triggered by connectWallet
                if (reloadTrending) {
                    //this.dispatch("update_trending_area_bog_api", {})
                    Services.update_trending_area_bog_api(context, {})
                }
                
                context.state.masterToken = CHAIN_TOKENS[context.state.chain]
                await PricesLib.loadMasterTokenPrice(context)

                if (context.state.chain == 'bsc') {
                    console.log('switchchain loading bog')
                    router.push('/?c=bsc&t='+NEW_BOG_TOKEN_ADDR)
                    this.dispatch('loadToken', NEW_BOG_TOKEN_ADDR)
                }
                else if (context.state.chain == 'polygon') {
                    console.log('switchchain loading wmatic')
                    router.push('/?c=polygon&t='+CHAIN_TOKENS['polygon'])
                    this.dispatch('loadToken', CHAIN_TOKENS['polygon'])
                }
                else if (context.state.chain == 'avax') {
                    console.log('switchchain loading avax')
                    router.push('/?c=avax&t='+CHAIN_TOKENS['avax'])
                    this.dispatch('loadToken', CHAIN_TOKENS['avax'])
                } else if (context.state.chain == 'ftm') {
                    console.log('switchchain loading ftm')
                    router.push('/?c=ftm&t='+CHAIN_TOKENS['ftm'])
                    this.dispatch('loadToken', CHAIN_TOKENS['ftm'])
                } else if (context.state.chain == 'csc') {
                    console.log('switchchain loading csc')
                    router.push('/?c=csc&t='+CHAIN_TOKENS['csc'])
                    this.dispatch('loadToken', CHAIN_TOKENS['csc'])
                }
            }


        },
        initTheme({
            commit
        }) {

            const cachedTheme = localStorage.theme ? localStorage.theme : false;
            //  `true` if the user has set theme to `dark` on browser/OS
            const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

            if (cachedTheme)
                commit('SET_THEME', cachedTheme)
            else if (userPrefersDark)
                commit('SET_THEME', 'dark')
            else
                commit('SET_THEME', 'dark')

        },
        async loadIsVerified(context, verifiedPromise) {
            let verified = await verifiedPromise
            if (verified) {
              
              context.state.tokenContractVerified = verified.isVerified
              context.state.tokenContractVerificationLoaded = true
            }
        },        
        async loadChartData(context) {
            console.log('loadChartData')
            console.log(2.01)
            
            var maxPrice = 0, minPrice = 0, priceOpen = 0, curTime = 0, priorClose = -1, priorTxnClose = -1;
            context.state.singleDataGotten = false;
            context.state.forceDisplay = false;
            context.state.txnDataOnly = false;
            //context.state.chartLoading = true;
            //var pricePair = 'USD';
            var newMasterPriceArray = [];

            var today = new Date();  
            var localoffset = -(today.getTimezoneOffset()/60);
            localoffset = localoffset * 3600 * 1000;

            var configurationData  = {
                supported_resolutions: supportedResolutions,
                supports_marks: context.state.supportsMarks,
            };

            console.log(2.02)

            context.state.dataFeed = {
                onReady: (callback) => {
                    console.log('[onReady]: Method call');
                    setTimeout(() => callback(configurationData));
                },

                resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback,) => {
                    //console.log('[resolveSymbol]: Method call', symbolName);
                    //const symbols = await getAllSymbols();
                    /*const symbolItem = symbols.find(({
                      full_name,
                    }) => full_name === symbolName);
                    if (!symbolItem) {
                      console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
                      onResolveErrorCallback('cannot resolve symbol');
                      return;
                    }*/
                    console.log(3.01)
                    while (!context.state.chartReady) {
                        await sleep(20)
                    }

                    var priceDecimals
                    var mcapFactor = 1.0
                    if (context.state.chartMarketcap) { mcapFactor = context.state.tokenCirculatingSupply; }
                    var priceForDecimals = context.state.tokenPrice
                    if(context.state.pricePair == 'BNB'){ priceForDecimals = context.state.tokenMasterPairPrice; }

                    if (true) {
                        if (context.state.screenWidth < 780) {
                            if (context.state.chartMarketcap) {
                                priceDecimals = await Services.getPriceDecimals(priceForDecimals * mcapFactor, 2);
                            } else {
                                priceDecimals = await Services.getPriceDecimals(priceForDecimals * mcapFactor, 3);
                            }
                        } else {
                            if (context.state.chartMarketcap) {
                                priceDecimals = await Services.getPriceDecimals(priceForDecimals * mcapFactor, 2);
                            } else {
                                priceDecimals = await Services.getPriceDecimals(priceForDecimals * mcapFactor, 3);
                            }
                            
                        }
                    }

                    //if (priceDecimals > 22) { priceDecimals = 22; } // tv errors out when priceScale is set larger than this
                    if (priceDecimals > 20) { priceDecimals = 20; } // tv errors out when priceScale is set larger than this

                    //if(context.state.pricePair == 'BNB'){
                    //   priceDecimals = await Services.getPriceDecimals(context.state.tokenMasterPairPrice);
                    //}
                    
                    var priceScale = Number("1".padEnd(priceDecimals+1, 0));
                    var volPrecision = priceDecimals;

                    //var exchangeName = context.state.exchangeTable[context.state.currentExchange].name;

                    //var tokenPairName = 'BogCharts: ' + context.state.tokenSymbol + "/" + context.state.pricePair;
                    var tokenPairName = context.state.tokenName + ': ' + context.state.tokenSymbol + "/" + context.state.pricePair;
                    var singleBlockResolutions = ["1S"]

                    //fullName = await getName(token); context.state.singleBlock
                    const symbolInfo = {
                        ticker: context.state.tokenSymbol,
                        name: tokenPairName,
                        description: '',
                        type: 'crypto',
                        session: '24x7',
                        timezone: "Etc/UTC",
                        minmov: 1,
                        pricescale: priceScale,
                        has_intraday: true,
                        intraday_multipliers: ['1', '3', '5', '15', '60'],
                        has_seconds: context.state.singleBlock ? true : false,
                        seconds_multipliers: ['1'],
                        //has_no_volume: true,
                        has_weekly_and_monthly: false,
                        supported_resolutions:  context.state.singleBlock ? singleBlockResolutions : supportedResolutions,
                        volume_precision: volPrecision,
                        data_status: 'streaming',
                    };

                    //console.log('[resolveSymbol]: Symbol resolved', symbolName);
                    console.log(3.02)
                    onSymbolResolvedCallback(symbolInfo);
                },
                getMarks: async(symbolInfo, from, to, onDataCallback, resolution) => {
                    if (!(context.state.accounts)) {
                        onDataCallback([]);
                        return
                    }
                    if (context.state.accounts[0] == undefined) {
                        onDataCallback([]);
                        return
                    }
                    let txns = await Services.getWalletTxnsBogApi(context.state.chain,context.state.accounts[0],context.state.token,from,to)
                    console.log('____ GET MARKS TXNS ____')
                    console.log(txns)
                    console.log('________________________')
                    if (txns) {
                        var marksArray = [];
                        for (let i=0;i<txns.length;i++) {
                            let tx = txns[i]
                            let buy = false
                            let tokenAmount
                            let valueUsd
                            let priceUsd
                            if (context.state.token.toLowerCase() == tx.t0.toLowerCase()) {
                                tokenAmount = Math.abs(tx.t0_lp_change)
                                valueUsd = tx.usd_value_t0
                                priceUsd = tx.price_usd_t0
                                if (tx.t0_lp_change <= 0) {
                                    buy = true
                                }
                            } else {
                                tokenAmount = Math.abs(tx.t1_lp_change)
                                valueUsd = tx.usd_value_t1
                                priceUsd = tx.price_usd_t1
                                if (tx.t1_lp_change <= 0) {
                                    buy = true
                                }
                            }
                            marksArray.push({
                                id: i,
                                time: tx.timestamp,
                                color: buy ? {
                                    background: "#189756",
                                    border: "transparent"
                                } : {
                                    background: "#B71D1D",
                                    border: "transparent",
                                },
                                text: buy ? "Bought at $" + priceUsd + "<br><br>Amount: " + tokenAmount.toFixed(4) + "<br><br>Value: " + "$" + valueUsd.toFixed(4): 
                                "Sold at $" + priceUsd + "<br><br>Amount: " + tokenAmount.toFixed(4) + "<br><br>Value: " + "$" + valueUsd.toFixed(4),
                                label: buy ? "B" : "S",
                                labelFontColor: buy ? "#189756" : "#B71D1D",
                                minSize: 8
                            });
                        }
                        onDataCallback(marksArray);
                    } else{
                        //No trades in this timeframe
                        onDataCallback([]);
                    }
                },
                getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
                    // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
                    console.log('countBack:',periodParams.countBack)
                    while (!context.state.chartReady) {
                        await sleep(20)
                    }
                    console.log(5.01)                    
                    console.log('singleBlock:',context.state.singleBlock)
                    let this_token = context.state.token
                    if (context.state.interval != resolution) {
                        context.state.switchedInterval = true
                        if (resolution != '1s') {
                            localStorage.setItem("interval", JSON.stringify(resolution))
                        }
                    } else {
                        context.state.switchedInterval = false
                    }
                    context.state.interval = resolution;                    
                    //context.state.gapFill = true; // hard set this on for now

                    //No need for all this if we've gotten the single block data already while in that mode TESTING
                    //if(context.state.singleDataGotten){
                    //    onHistoryCallback([], {noData: true});
                    //}

                     // This forces tv to display whatever data we have after first load from bog api even if it doesn't fill the viewport
                     if (context.state.forceDisplay) {
                        console.log('******* FORCE DISPLAY & RESET VAR ********')
                        context.state.forceDisplay = false // reset this var to allow bq data after the first data load is forced to display
                        onHistoryCallback([], {noData: true});
                        setTimeout(onHistoryCallback([], {noData: false}), 2000)
                        return
                    }

                    //if(context.state.txnDataOnly){
                    //    onHistoryCallback([], {noData: true});
                    //}

                    //Standardize only September 2020 and after
                    if(periodParams.from < 1599019200){
                        periodParams.from = 1599019200;
                    }
                    var x1 = new Date(periodParams.from*1000);
                    var y1 = x1.toISOString().split('T')[0];                    

                    var x2 = new Date(periodParams.to*1000);
                    var y2 = x2.toISOString().split('T')[0];

                    var min_bars = 500
                    var interval_mins
                    
                    if(resolution == '1D'){
                        interval_mins = 1440
                    }
                    else{
                        interval_mins = parseInt(resolution);
                    }                    
                    var bars_expected = parseInt((x2/1000 - x1/1000) / (60*interval_mins))
                        if (bars_expected < min_bars) {
                            x1 = new Date(x2 - interval_mins*60*min_bars*1000)
                            y1 = x1.toISOString().split('T')[0];
                        }
                    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                    console.log(bars_expected)
                    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

                    //console.log(exchangeTable)
                    console.log(context.state.currentExchange)
                    while (!context.state.currentExchange) {
                        await sleep(20)
                    }

                    
                    var bogapiTokensDisabled = []
                    //var bog_api_enabled_for_ohlcv = !(bogapiTokensDisabled.includes(context.state.token.toLowerCase())) && context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].bogApiSupported
                    var bog_api_enabled_for_ohlcv = true
                    if (context.state.force_bog_api != undefined) { bog_api_enabled_for_ohlcv = true; }
                    if (context.state.force_bq != undefined) { bog_api_enabled_for_ohlcv = false; }
                    var bog_api_enabled_for_single_blocks = true && context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].bogApiSupported
                    console.log('bog api supported:')
                    console.log(context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].bogApiSupported)

                    //if (context.state.bogData && context.state.bogData.status != 1) { bog_api_enabled_for_ohlcv = false }
                    

                    /*****************************************/
                    /*               BOG API                 */
                    /*****************************************/
                    // hit bog api for first data load                    
                    if (true) { // no longer using bitquery
                        console.log('___________________')
                        console.log('using bog api')
                        console.log('___________________')                        

                        context.state.percentChangeAndVolUsingBogApi = true
                        var denom
                        if (context.state.pricePair == 'USD') { denom = 'usd'; }
                        else { denom = 'quote'; }
                        console.log('chain: '+ context.state.chain)
                        var barConf = {
                            quote: context.state.masterToken,
                            base: context.state.token,
                            chain: context.state.chain,
                            since: periodParams.from,
                            till: periodParams.to,
                            limit: periodParams.limit,
                            timeType: "minute",
                            timeInterval: 1,
                            exchange: context.state.currentExchange,
                            singleBlocks: context.state.singleBlock,
                            gapFill: context.state.gapFill,
                            denom: denom,
                            firstDataRequest: periodParams.firstDataRequest,
                        }

                        if(resolution == '1D'){
                            barConf.timeType = "day";
                            barConf.timeInterval =  1;
                        }
                        else{
                            barConf.timeType = "minute";
                            barConf.timeInterval = resolution;
                        }

                        let result = context.state.bogData
                        console.log(result)
                        if (context.state.singleBlock) {
                            if (!result || !result.txn_list || result.txn_list.length == 0) {
                                result = {}
                                result.txn_list = []
                                //result.txn_list = await Services.getOHLCDataBogAPI(barConf)
                            } else {
                                console.log('FASTTRACK')
                                context.state.bogDataUsed = true
                                //result.txn_list = result.data
                            }
                        } else {
                            if (!result || !result.data || result.data.length == 0 || context.state.bogDataUsed) {
                                result = await Services.getOHLCDataBogAPI(barConf)
                            } else {
                                console.log('FASTTRACK')
                            context.state.bogDataUsed = true
                            }
                        }

                        //Turn txn data into block data
                        var start = Date.now()/1000
                        if(context.state.singleBlock){

                            //var txns = await Services.getOHLCDataBogAPI(barConf)
                            var txns = result.txn_list
                            var exName = context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].name;
                            console.log('txns',txns)
                            txns = txns.filter(tx => tx.exchange_name == exName && !tx.fn_name.toLowerCase().includes('addliquidity') && !tx.fn_name.toLowerCase().includes('removeliquidity') && tx.quoteToken.toLowerCase() == context.state.masterToken.toLowerCase());
                            if (context.state.token != this_token) { return; }
                            var mcapFactor = 1.0
                            if (context.state.chartMarketcap) {
                                mcapFactor = context.state.tokenCirculatingSupply
                            }
                            
                            if (txns && (txns.length > context.state.transactions.length))
                            {
                                
                                console.log('mcapFactor:')
                                console.log(mcapFactor)
                                for (let tx of txns) {
                                    if (tx.t0 == context.state.token.toLowerCase()) {
                                        tx.price_usd_base = tx.price_usd_t0
                                        tx.price_quote_base = tx.t1_per_t0
                                        tx.usd_value = tx.usd_value_t0
                                        tx.base_lp_change = tx.lp_change_t0
                                    } else {
                                        tx.price_usd_base = tx.price_usd_t1
                                        tx.usd_value = tx.usd_value_t1
                                        if (tx.t1_per_t0) { tx.price_quote_base = 1 / tx.t1_per_t0 }
                                        else { tx.price_quote_base = 0 }                                        
                                        tx.base_lp_change = tx.lp_change_t1
                                    }
                                }
                                var single_block_ohlcv = await Services.consolidateTxnsToSingleBlocksBogAPI(txns, context.state.pricePair == 'USD', mcapFactor)
                                if (context.state.chain == "polygon" && periodParams.firstDataRequest) {
                                    // set fudged autoupdate price
                                    if (single_block_ohlcv.length > 0) {
                                        context.state.lastTokenPrice = single_block_ohlcv[single_block_ohlcv.length - 1].close
                                    }
                                }
                                
                                //context.state.singleDataGotten = true;                                
                                console.log('****************')
                                console.log('BOG API')
                                console.log(txns.length)
                                console.log(txns)
                                console.log(single_block_ohlcv.length)
                                console.log(single_block_ohlcv)
                                console.log('****************')

                                if (periodParams.firstDataRequest) {
                                    context.state.prevClosePrice = single_block_ohlcv[single_block_ohlcv.length - 1].close
                                }
                                context.state.forceDisplay = true

                                single_block_ohlcv = single_block_ohlcv.sort((a, b) => { return a.time - b.time })

                                if (single_block_ohlcv.length == 0) {
                                    single_block_ohlcv = [
                                        {
                                            open: context.state.tokenPrice * mcapFactor,
                                            high: context.state.tokenPrice * mcapFactor,
                                            low: context.state.tokenPrice * mcapFactor,
                                            close: context.state.tokenPrice * mcapFactor,
                                            time: Date.now(),
                                            volume: 0
                                        }
                                    ]
                                    onHistoryCallback(single_block_ohlcv, {noData: true})
                                } else {
                                    onHistoryCallback(single_block_ohlcv, {noData: false})
                                }
                                context.state.firstChartData = false
                            }
                            else {
                                while (context.state.transactions.length == 0) {
                                    await sleep(20)
                                }
                                // try using txn data                                
                                var txnListData = [];
                                var txnDataMaster = [];
                                console.log(context.state.transactions)
                                var exName = context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].name;

                                for (let tx of context.state.transactions) {
                                    if (!tx.quoteToken) {
                                        console.log('!')
                                        console.log(tx)
                                    }
                                }

                                var gapPriceArray = context.state.transactions.filter(tx => !tx.isArbitrage && tx.pool == exName && tx.txType != 'Add Liq' && tx.txType != 'Remove Liq' && tx.quoteToken.toLowerCase() == context.state.masterToken.toLowerCase());
                                console.log(gapPriceArray)


                                if(context.state.pricePair == 'BNB'){
                                    txnListData = (await Services.getGapOHLCPrices(gapPriceArray, context, 1)).bnbArray;
                                }
                                else{
                                    txnListData = (await Services.getGapOHLCPrices(gapPriceArray, context, 1)).usdArray;
                                }
                                if (context.state.token != this_token) { return; }

                                if(txnListData.length > 0){

                                    txnDataMaster = await Services.configureGapData(txnListData, true);
                                    context.state.singleDataGotten = true;
                                    console.log(txnListData)
                                    console.log(txnDataMaster)

                                    if (context.state.chain == "polygon" && periodParams.firstDataRequest) {
                                        // set fudged autoupdate price
                                        if (txnDataMaster.length > 0) {
                                            context.state.lastTokenPrice = txnDataMaster[txnDataMaster.length - 1].close
                                        }
                                    }

                                    if (periodParams.firstDataRequest) {
                                        context.state.prevClosePrice = txnDataMaster[txnDataMaster.length - 1].close
                                    }
                                    
                                    for (let txn of txnDataMaster) {
                                        txn.open *= mcapFactor
                                        txn.high *= mcapFactor
                                        txn.low *= mcapFactor
                                        txn.close *= mcapFactor
                                    }
                                    context.state.forceDisplay = true

                                    txnDataMaster = txnDataMaster.sort((a, b) => { return a.time - b.time })

                                    let prevtime = 0
                                    for (let tx of txnDataMaster) {
                                        if (prevtime == tx.time) {
                                            console.log(tx)
                                            prevtime = tx.time
                                        }
                                    }
                                    
                                    if (txnDataMaster.length == 0) {
                                        txnDataMaster = [
                                            {
                                                open: context.state.tokenPrice * mcapFactor,
                                                high: context.state.tokenPrice * mcapFactor,
                                                low: context.state.tokenPrice * mcapFactor,
                                                close: context.state.tokenPrice * mcapFactor,
                                                time: Date.now(),
                                                volume: 0
                                            }
                                        ]
                                        onHistoryCallback(txnDataMaster, {noData: true})
                                    } else {
                                        onHistoryCallback(txnDataMaster, {noData: false})
                                    }
                                    context.state.firstChartData = false
                                }
                                else{
                                    onHistoryCallback([], {noData: true});
                                }
                            } 
                        }
                        else{
                            //Not in Single Block Mode
                            //Fill in the gap data if in 1min interval and below, but only on the first request if we're in 1 min mode
                            var gapDataMaster = [];

                            // Disabled for bog api
                            if(barConf.timeInterval === '1' && periodParams.firstDataRequest && false){
                                var gapData = [];
                                
                                var exName = context.state.exchangeTable[context.state.currentExchange].name;
                                var gapPriceArray = context.state.transactions.filter(tx => !tx.isArbitrage && tx.pool == exName);

                                if(context.state.pricePair == 'BNB'){
                                    gapData = (await Services.getGapOHLCPrices(gapPriceArray, context)).bnbArray;
                                }
                                else{
                                    gapData = (await Services.getGapOHLCPrices(gapPriceArray, context)).usdArray;
                                }
                                
                                if(gapData.length > 0){
                                    gapDataMaster = await Services.configureGapData(gapData, false);
                                }
                            }
                            
                            
                            if (context.state.token != this_token) { return; }
                            console.log(1.02)
                            var candleArray = [];
            
                            if (result) {
                                if(periodParams.firstDataRequest && result.lp_address) {
                                    context.state.tokenPairContract = result.lp_address
                                }

                                
                                
                                if (result.price_24h_usd != undefined && !(context.state.token == '-')) {
                                    if (!result.price_24h_usd) {
                                        context.state.percentChange = 0
                                    } else {
                                        context.state.percentChange = Math.round((100.0 * (context.state.tokenPrice - result.price_24h_usd) / result.price_24h_usd)*100)/100
                                    }                                    
                                    
                                }
                                if(result.vol_24h_usd != undefined) {
                                    context.state.tokenVolume = result.vol_24h_usd
                                }
                                
                                if(result.data){
                                    console.log(result)
                                    newMasterPriceArray = result.data
                                    //Bit redundant, but make sure there's actually some data
                                    if (newMasterPriceArray.length > 0) {

                                        if (periodParams.firstDataRequest) {
                                            periodParams.from = newMasterPriceArray[0].open_timestamp
                                        }

                                        var curClose = 0;
                                        var offset = -(3600 * 1000 * today.getTimezoneOffset()/60)

                                        for(var i=0, l=newMasterPriceArray.length; i<l; i++) {
                                            var pointDate = new Date(newMasterPriceArray[i].open_timestamp*1000);

                                            var mcapFactor = 1.0
                                            if (context.state.chartMarketcap) { mcapFactor = context.state.tokenCirculatingSupply; }

                                            if(context.state.pricePair == 'USD'){
                                                var open = newMasterPriceArray[i].usd_base_open * mcapFactor
                                                var close = newMasterPriceArray[i].usd_base_close * mcapFactor
                                                var high = newMasterPriceArray[i].usd_base_high * mcapFactor
                                                var low = newMasterPriceArray[i].usd_base_low * mcapFactor
                                                var volume = newMasterPriceArray[i].usd_volume_base
                                            }
                                            else {
                                                var open = newMasterPriceArray[i].quote_base_open * mcapFactor
                                                var close = newMasterPriceArray[i].quote_base_close * mcapFactor
                                                var high = newMasterPriceArray[i].quote_base_high * mcapFactor
                                                var low = newMasterPriceArray[i].quote_base_low * mcapFactor
                                                var volume = newMasterPriceArray[i].base_volume
                                            }

                                            candleArray.push({
                                                time: pointDate.getTime(),
                                                open: open,
                                                high: high,
                                                low: low,
                                                close: close,
                                                volume: volume
                                            }); 
                                            curClose = close;


                                        }
                                        
                                        //Sort it out
                                        candleArray.sort(function(a, b) { 
                                            return a.time- b.time;
                                        });

                                        var tvCandlestickArray = candleArray;
                                        
                                        //Remove duplicates
                                        const seen = new Set();

                                        tvCandlestickArray = tvCandlestickArray.filter(ar => {
                                            const duplicate = seen.has(ar.time);
                                            seen.add(ar.time);
                                            return !duplicate;
                                        });

                                        // fill gaps
                                        if (context.state.gapFill) {
                                            var gap_filled_array = []
                                            var this_ts = tvCandlestickArray[0].time
                                            var prev_close_price = tvCandlestickArray[0].close
                                            var i=0
                                            var max_trailing_gaps = 40
                                            var n_trailing_gaps = 0
                                            
                                            while (this_ts < x2.getTime()) {
                                                if (i >= tvCandlestickArray.length || this_ts < tvCandlestickArray[i].time) {
                                                    if (this_ts + 60*1000*interval_mins >= x2.getTime()) {
                                                        // Stop gapfilling 1 bar before current time
                                                        break
                                                    }
                                                    if (i >= tvCandlestickArray.length) {
                                                        n_trailing_gaps++
                                                        if (n_trailing_gaps > max_trailing_gaps) { break; }                                                        
                                                    }
                                                    gap_filled_array.push({
                                                        time: this_ts,
                                                        open: prev_close_price,
                                                        high: prev_close_price,
                                                        low: prev_close_price,
                                                        close: prev_close_price,
                                                        volume: 0
                                                    });
                                                    this_ts += 60*1000*interval_mins                                                    
                                                }
                                                else {
                                                    gap_filled_array.push(tvCandlestickArray[i])
                                                    this_ts = tvCandlestickArray[i].time + 60 * 1000 * interval_mins
                                                    prev_close_price = tvCandlestickArray[i].close
                                                    i++
                                                }
                                            }

                                            console.log('****************')
                                            console.log(gap_filled_array)
                                            console.log(tvCandlestickArray.length)
                                            console.log(gap_filled_array.length)
                                            console.log('****************')

                                            gap_filled_array.sort(function(a, b) { 
                                                return a.time- b.time;
                                            });

                                            tvCandlestickArray = gap_filled_array
                                        }
                                        
                                        // join bars
                                        for(var i=1, l=tvCandlestickArray.length; i<l; i++) {
                                            tvCandlestickArray[i].open = tvCandlestickArray[i-1].close;
                                            if (tvCandlestickArray[i].open > tvCandlestickArray[i].high) {
                                                tvCandlestickArray[i].high = tvCandlestickArray[i].open
                                            }
                                            if (tvCandlestickArray[i].open < tvCandlestickArray[i].low) {
                                                tvCandlestickArray[i].low = tvCandlestickArray[i].open
                                            }
                                        }
                                        
                                        
                                        var bars_expected = parseInt((Date.now()/1000 - barConf.since) / (60*interval_mins))
                                        // The bars received will not fill the viewing window, but we don't want tv to wait on bq for more data that doesn't exist.
                                        // So, forece it to display this data by telling it no more data exists on the next getBars pass.
                                        if (periodParams.firstDataRequest && tvCandlestickArray.length > 0 && tvCandlestickArray.length < bars_expected && bars_expected <= 350) {
                                            //if (tvCandlestickArray[0].time > (Date.now()-1000*60*60*24*30)) {
                                                if (context.state.earliestBlockTs && tvCandlestickArray[0].time > (1000*(context.state.earliestBlockTs+60*60*24*3))) {                                                
                                                // the first bar is some time after we first started capturing data, so this should represent all available bars.
                                                context.state.forceDisplay = true
                                            }                                            
                                        }
                                        if (resolution == '1D') {
                                            context.state.forceDisplay = true
                                        }

                                        if (context.state.chain == "polygon" && periodParams.firstDataRequest) {
                                            // set fudged autoupdate price
                                            if (tvCandlestickArray.length > 0) {
                                                context.state.lastTokenPrice = tvCandlestickArray[tvCandlestickArray.length - 1].close
                                            }
                                        }

                                        if (context.state.token != this_token) { return; }

                                        console.log('****************')
                                        console.log('BOG API')
                                        console.log('Received '+tvCandlestickArray.length.toString()+' bars')
                                        console.log('Expected '+bars_expected.toString()+' bars')
                                        console.log(periodParams.firstDataRequest)
                                        console.log(tvCandlestickArray)
                                        if (context.state.forceDisplay) {
                                            console.log('Forcing display')
                                        }
                                        console.log('****************')

                                        if (periodParams.firstDataRequest) {
                                            context.state.prevClosePrice = tvCandlestickArray[tvCandlestickArray.length - 1].close
                                        }

                                        if (resolution == '1D') {
                                            //onHistoryCallback(tvCandlestickArray, {noData: false, nextTime: tvCandlestickArray[0].time-1000})
                                            onHistoryCallback(tvCandlestickArray, {noData: false})
                                            
                                        } else {
                                            onHistoryCallback(tvCandlestickArray, {noData: false})
                                        }
                                        context.state.firstChartData = false
                                    }
                                    else{
                                        if (context.state.token != this_token) { return; }
                                        console.log('XXXXXXXXXXXXXXX NO DATA XXXXXXXXXXXXXXXX')
                                        if(false && resolution == "1"){
                                            //If our candle array isn't empty we can send the txn data at least
                                            if(candleArray.length > 0){
                                                context.state.txnDataOnly = true;
                                                onHistoryCallback(candleArray, {noData: false})
                                            }
                                            else if (context.state.transactions.length > 0) {
                                                console.log('setting single block mode')
                                                context.state.singleBlock = true
                                                //context.state.forceDisplay = true
                                                onHistoryCallback([], {noData: false});
                                            }
                                            else
                                            {
                                                //There's just no data anywhere for this token
                                                onHistoryCallback([], {noData: true});
                                            }
                                            
                                        }
                                        else{
                                            //if we got no data on first call to bitquery
                                            if(periodParams.firstDataRequest && context.state.exchangesWithLiquidity && Object.keys(context.state.exchangesWithLiquidity).length <= 1){
                                                //Switch to single block mode
                                                // Note: this reloads the chart and shows the top pool
                                                console.log('setting single block mode')
                                                context.state.singleBlock = true
                                                context.state.interval = "1s"
                                                context.dispatch("loadToken", context.state.token);
                                                onHistoryCallback([], {noData: false});
                                            }
                                            else if (false && context.state.transactions.length > 0) {
                                                console.log('setting single block mode')
                                                context.state.singleBlock = true
                                                onHistoryCallback([], {noData: false});
                                            }
                                            else{
                                                //otherwise we're presumably at the token's launch so no more data
                                                onHistoryCallback([], {noData: true});
                                            }
                                        } 
                                    }

                                }
                            }
                            else {
                                console.log('****************')
                                console.log('Error getting ohlcv data from bog api')
                                console.log('****************')
                            }
                        }
                    }
                    /******************************************/
                    /*               BITQUERY                 */
                    /******************************************/
                    else { // disabled bitquery for now
                        console.log(27.01)
                        if (context.state.transactionListBogApi == null) {
                            context.state.transactionListBogApi = '__PROCESSED__'
                        }

                        context.state.percentChangeAndVolUsingBogApi = false
                        let mcapFactor = 1.0
                        if (context.state.chartMarketcap) { mcapFactor = context.state.tokenCirculatingSupply; }
                    
                        //Set our variables (can be cleaned up a bit)
                        let optimisedRange = Services.getOptimisedRange(periodParams.from, periodParams.to, interval_mins)
                        var usdToken = USD_TOKENS[context.state.chain]
                        if (context.state.currentExchange.toLowerCase() == '0x86407bea2078ea5f5eb5a52b2caa963bc1f889da') { usdToken = '0x55d398326f99059fF775485246999027B3197955'.toLowerCase() } // babyswap has no bnb/busd volume so we use bnb/usdt instead
                        var z = context.state.tokenDecimals;                        
                        var barConf = {
                            base: context.state.masterToken.toLowerCase(),
                            quote: context.state.token.toLowerCase(), // BOG                        
                            usdToken: usdToken.toLowerCase(),
                            chain: context.state.chain,
                            since: optimisedRange.from_isostr,
                            till: optimisedRange.to_isostr,
                            from_ts: optimisedRange.from_ts,
                            to_ts: optimisedRange.to_ts,
                            requested_to_ts: periodParams.to*1000,
                            firstDataRequest: periodParams.firstDataRequest,
                            timeType: "minute",
                            timeInterval: 1,
                            interval_mins: interval_mins,
                            exchange: context.state.currentExchange,
                            singleBlocks: context.state.singleBlock
                        }

                        var usdBarConf = {
                            base: context.state.token.toLowerCase(),
                            quote: context.state.masterToken.toLowerCase(),
                            usdToken: usdToken.toLowerCase(),
                            chain: context.state.chain,
                            since: optimisedRange.from_isostr,
                            till: optimisedRange.to_isostr,
                            from_ts: optimisedRange.from_ts,
                            to_ts: optimisedRange.to_ts,
                            requested_to_ts: periodParams.to*1000,
                            firstDataRequest: periodParams.firstDataRequest,
                            timeType: "minute",
                            timeInterval: 1,
                            interval_mins: interval_mins,
                            exchange: context.state.currentExchange,
                            singleBlocks: context.state.singleBlock
                        }
                        console.log('base: ' + context.state.token)
                        console.log('quote: ' + context.state.masterToken)

                        if(resolution == '1D'){
                            barConf.timeType = usdBarConf.timeType = "day";
                            barConf.timeInterval = usdBarConf.timeInterval = 1;
                        }
                        else{
                            barConf.timeType = usdBarConf.timeType = "minute";
                            barConf.timeInterval = usdBarConf.timeInterval = resolution;
                        }

                        //Turn txn data into block data 
                        if(context.state.singleBlock){
                            var txnListData = [];
                            var txnDataMaster = [];

                            var exName = context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].name;
                            var gapPriceArray = context.state.transactions.filter(tx => !tx.isArbitrage && tx.pool == exName);

                            if(context.state.pricePair == 'BNB'){
                                txnListData = (await Services.getGapOHLCPrices(gapPriceArray, context, 1)).bnbArray;
                            }
                            else{
                                txnListData = (await Services.getGapOHLCPrices(gapPriceArray, context, 1)).usdArray;
                            }
                            console.log(gapPriceArray)
                            if (context.state.token != this_token) { return; }

                            if(txnListData.length > 0){

                                txnDataMaster = await Services.configureGapData(txnListData, true);
                                context.state.singleDataGotten = true;

                                if (context.state.chain == "polygon" && periodParams.firstDataRequest) {
                                    // set fudged autoupdate price
                                    if (txnDataMaster.length > 0) {
                                        context.state.lastTokenPrice = txnDataMaster[txnDataMaster.length - 1].close
                                    }
                                }

                                if (periodParams.firstDataRequest) {
                                    context.state.prevClosePrice = txnDataMaster[txnDataMaster.length - 1].close
                                }

                                for (let txn of txnDataMaster) {
                                    txn.open *= mcapFactor
                                    txn.high *= mcapFactor
                                    txn.low *= mcapFactor
                                    txn.close *= mcapFactor
                                }
                                context.state.forceDisplay = true
                                console.log(txnDataMaster)
                                
                                onHistoryCallback(txnDataMaster, {noData: false})
                                context.state.firstChartData = false
                            }
                            else{
                                onHistoryCallback([], {noData: true});
                            }
                            
                        }
                        else{
                            //Not in Single Block Mode
                            //Fill in the gap data if in 1min interval and below, but only on the first request if we're in 1 min mode
                            var gapDataMaster = [];

                            console.log(27.02)

                            if(barConf.timeInterval === '1' && periodParams.firstDataRequest){
                                var gapData = [];

                                //console.log(context.state.exchangeTable[context.state.chain])
                                //console.log(context.state.currentExchange)
                                
                                var exName = context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].name;
                                var gapPriceArray = context.state.transactions.filter(tx => !tx.isArbitrage && tx.pool == exName);

                                if(context.state.pricePair == 'BNB'){
                                    gapData = (await Services.getGapOHLCPrices(gapPriceArray, context)).bnbArray;
                                }
                                else{
                                    gapData = (await Services.getGapOHLCPrices(gapPriceArray, context)).usdArray;
                                }
                                
                                if(gapData.length > 0){
                                    gapDataMaster = await Services.configureGapData(gapData, false);
                                }
                            }
                            if (context.state.token != this_token) { return; }

                            //Set the promises for caching 
                            //let barPromise = getOHLCData(barConf)
                            console.log(27.03)
                            let barPromise = getUSDOHLCData(usdBarConf)
                            

                            var candleArray = [];

                            if(periodParams.firstDataRequest){
                                candleArray = gapDataMaster.slice();
                            }

                            if (context.state.token != this_token) { return; }

                            //Refactored Take 2
                            barPromise.then(function(result){
                                console.log('____________________________-')
                                console.log(27.04)
                                console.log(result)
                                console.log('____________________________-')
                                if (context.state.token != this_token) { return; }
                                //Make sure we didn't get an error
                                if(result.data.ethereum.dexTrades){
                                    newMasterPriceArray = result.data.ethereum.dexTrades;
                                    console.log(result.data.ethereum.dexTrades)


                                    //Bit redundant, but make sure there's actually some data
                                    if (newMasterPriceArray.length > 0) {

                                        // remove any overlap with already fetched data
                                        if (context.state.bogData && context.state.bogData.data.length > 0 && !periodParams.firstDataRequest) {
                                            let earliest_bog_bar = context.state.bogData.data[0].open_timestamp
                                            console.log('earliest bog bar:')
                                            console.log(earliest_bog_bar)
                                            let i=0
                                            for (let txn of newMasterPriceArray) {
                                                i += 1
                                                let ts = new Date(txn.timeInterval[barConf.timeType].replace(/\s/, 'T')) / 1000
                                                //console.log(ts)
                                                if (ts >= earliest_bog_bar) {
                                                    break
                                                }                                                
                                            }
                                            newMasterPriceArray = newMasterPriceArray.slice(0,i)
                                            context.state.bogData = null
                                        }

                                        //var last = new Date(new Date(newMasterPriceArray[newMasterPriceArray.length-1].timeInterval[barConf.timeType].replace(/\s/, 'T')).getTime()+localoffset);
                                        newMasterPriceArray = newMasterPriceArray.filter(e => new Date(new Date(e.timeInterval[barConf.timeType].replace(/\s/, 'T')).getTime()+localoffset) < x2);

                                        //console.log(newMasterPriceArray)

                                        var ourToken = context.state.token.toLowerCase();
                                        var firstInstance = newMasterPriceArray.findIndex(e => e.buyCurrency.address === ourToken);
                                        
                                        //Used for data testing
                                        //var numOurToken = newMasterPriceArray.filter(e => e.buyCurrency.address === ourToken);
                                        
                                        //Make sure our token has some txns via bitquery
                                        if (firstInstance != -1) { 

                                            var curClose = 0;

                                            for(var i=firstInstance, l=newMasterPriceArray.length-1; i<l; i++) {

                                                var pointDate = new Date(newMasterPriceArray[i].timeInterval[barConf.timeType].replace(/\s/, 'T'));
                                                
                                                //Account for instances where there was no txns for the token
                                                if (context.state.masterToken.toLowerCase() == CHAIN_TOKENS[context.state.chain].toLowerCase()) {
                                                    if(newMasterPriceArray[i].buyCurrency.address === ourToken && newMasterPriceArray[i+1].buyCurrency.address.toLowerCase() === context.state.masterToken.toLowerCase()){
                                                        var open = Number(newMasterPriceArray[i].open);
                                                        var close = Number(newMasterPriceArray[i].close);
                                                        var high = newMasterPriceArray[i].maximum;
                                                        var low = newMasterPriceArray[i].minimum;
    
                                                        if(context.state.pricePair == 'USD'){
                                                            var bnbAvg = 1;                                         
                                                        
                                                            if (context.state.token != CHAIN_TOKENS[context.state.chain]) { 
                                                                //bnbAvg = (newMasterPriceArray[i+1].maximum + newMasterPriceArray[i+1].minimum)/2;
                                                                let bnbMax = newMasterPriceArray[i+1].buyCurrency.symbol === "WBNB" && newMasterPriceArray[i+1].sellCurrency.symbol === "BUSD" && newMasterPriceArray[i+1].maximum > 10000 ? newMasterPriceArray[i+1].minimum : newMasterPriceArray[i+1].maximum;
                                                                bnbAvg = (bnbMax + newMasterPriceArray[i+1].minimum)/2;
                                                            }
    
                                                            var open = open*bnbAvg;
                                                            var close = close*bnbAvg;
                                                            var low = low*bnbAvg;
                                                            var high = high*bnbAvg;
                                                        }

                                                        
    
                                                        candleArray.push({
                                                            time: pointDate.getTime()+localoffset,
                                                            open: open * mcapFactor,
                                                            high: high * mcapFactor,
                                                            low: low * mcapFactor,
                                                            close: close * mcapFactor,
                                                            volume: newMasterPriceArray[i].tradeAmount
                                                        }); 
    
                                                        //priorClose = close;
                                                        curClose = close * mcapFactor;
    
                                                    }
                                                    else{
                                                        if (context.state.gapFill) {
                                                            candleArray.push({
                                                                time: pointDate.getTime()+localoffset,
                                                                open: curClose,
                                                                high: curClose,
                                                                low: curClose,
                                                                close: curClose,
                                                                volume: 0
                                                            }); 
                                                        }
                                                    } 
                                                }
                                                else {
                                                    // quote token is a usd token
                                                    if(newMasterPriceArray[i].buyCurrency.address === ourToken) {
                                                        candleArray.push({
                                                            time: pointDate.getTime()+localoffset,
                                                            open: Number(newMasterPriceArray[i].open) * mcapFactor,
                                                            high: newMasterPriceArray[i].maximum * mcapFactor,
                                                            low: newMasterPriceArray[i].minimum * mcapFactor,
                                                            close: Number(newMasterPriceArray[i].close) * mcapFactor,
                                                            volume: newMasterPriceArray[i].tradeAmount
                                                        }); 
                                                    }
                                                }
                                            }
                                            
                                            //Sort it out
                                            candleArray.sort(function(a, b) { 
                                                return a.time- b.time;
                                            });

                                            var tvCandlestickArray = candleArray;
                                            
                                            //Remove duplicates
                                            const seen = new Set();
        
                                            tvCandlestickArray = tvCandlestickArray.filter(ar => {
                                                const duplicate = seen.has(ar.time);
                                                seen.add(ar.time);
                                                return !duplicate;
                                            });
                                            
                                            for(var i=1, l=tvCandlestickArray.length; i<l; i++) {
                                                tvCandlestickArray[i].open = tvCandlestickArray[i-1].close;
                                                if (tvCandlestickArray[i].open > tvCandlestickArray[i].high) {
                                                    tvCandlestickArray[i].high = tvCandlestickArray[i].open
                                                }
                                                if (tvCandlestickArray[i].open < tvCandlestickArray[i].low) {
                                                    tvCandlestickArray[i].low = tvCandlestickArray[i].open
                                                }
                                            }

                                            if (context.state.chain == "polygon" && periodParams.firstDataRequest) {
                                                // set fudged autoupdate price
                                                if (tvCandlestickArray.length > 0) {
                                                    context.state.lastTokenPrice = tvCandlestickArray[tvCandlestickArray.length - 1].close
                                                }
                                            }

                                            if (periodParams.firstDataRequest) {
                                                context.state.prevClosePrice = tvCandlestickArray[tvCandlestickArray.length - 1].close
                                            }
                                            if (context.state.token != this_token) { return; }

                                            onHistoryCallback(tvCandlestickArray, {noData: false})
                                            context.state.firstChartData = false
                                        }
                                        else{
                                            if(resolution == "1"){
                                                //If our candle array isn't empty we can send the txn data at least
                                                if(candleArray.length > 0){
                                                    context.state.txnDataOnly = true;
                                                    onHistoryCallback(candleArray, {noData: false})
                                                }
                                                else if (false && context.state.transactions.length > 0) {
                                                    console.log('setting single block mode')
                                                    context.state.singleBlock = true
                                                    context.state.interval = "1s"
                                                    context.dispatch("loadToken", context.state.token);
                                                    //context.state.forceDisplay = true
                                                    //onHistoryCallback([], {noData: false});
                                                }
                                                else
                                                {
                                                    //There's just no data anywhere for this token
                                                    onHistoryCallback([], {noData: true});
                                                }
                                                
                                            }
                                            else{
                                                //if we got no data on first call to bitquery
                                                if (context.state.token != this_token) { return; }
                                                if(periodParams.firstDataRequest){
                                                    //Switch to 1m interval to try and get somedata
                                                    context.state.interval = "1";
                                                    //context.dispatch("reloadChart");
                                                    this.dispatch("loadChartData")
                                                }
                                                else if (false && context.state.transactions.length > 0) {
                                                    console.log('setting single block mode')
                                                    context.state.singleBlock = true
                                                    context.state.interval = "1s"
                                                    context.dispatch("loadToken", context.state.token);
                                                    //context.state.forceDisplay = true
                                                    //onHistoryCallback([], {noData: false});
                                                }
                                                else{
                                                    //otherwise we're presumably at the token's launch so no more data
                                                    onHistoryCallback([], {noData: true});
                                                }
                                            } 
                                        }
                                    }
                                    else{
                                        if (context.state.token != this_token) { return; }
                                        onHistoryCallback([], {noData: true});
                                    }
                                }
                            }).catch(function(e){
                                console.log(e);
                                console.log('Error getting data for USD Pair tv bars')
                            });
                        }
                    }
                },

                subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback,) => {
                    console.log(11.01)
                    if(!context.state.loadFromPortfolio){
                        context.state.chartLoading = false;
                    }
                    context.state.loadFromPortfolio = false;

                    maxPrice = 0, minPrice = 0, priceOpen = 0, curTime = 0;
                    var timeInterval = 6000;
                    var interval = 60 * 1000;

                    if(context.state.singleBlock){
                        timeInterval = 3000;
                        interval = 3000;
                    }
                    else{
                        interval = resolution * interval;
                    }

                    //interval = resolution * interval;

                    context.state.priceUpdateInterval = setTimeout(function updateData() {
                        console.log('price update tick', context.state.currentVolumeAmt)
                        var pointDate = new Date();
                        pointDate = new Date(Math.floor(pointDate.getTime() / interval ) * interval);
                        var newTime = pointDate.getTime();

                        //In case the array is empty
                        if(context.state.autoUpdateArray.length == 0){
                            if (context.state.pricePair == 'BNB') {
                                context.state.autoUpdateArray.push(context.state.tokenMasterPairPrice);
                            }
                            else{
                                context.state.autoUpdateArray.push(context.state.tokenPrice);
                            }
                        }
                        

                        // console.log(context.state.currentVolumeAmt)
                        if (context.state.prevClosePrice == null) {
                            context.state.prevClosePrice = context.state.tokenPrice
                        }
                        
                        if(newTime > curTime){
                            curTime = newTime;
                            //priceOpen = context.state.autoUpdateArray[0];
                            priceOpen = context.state.prevClosePrice
                            minPrice = 0;
                            maxPrice = 0;
                            //context.state.currentVolumeAmt = 0;
                        }

                        var newMinprice = Math.min(...context.state.autoUpdateArray);
                        var newMaxPrice = Math.max(...context.state.autoUpdateArray);

                        maxPrice = (newMaxPrice > maxPrice) ? newMaxPrice : maxPrice;

                        if(minPrice > 0){
                            minPrice = (newMinprice < minPrice) ? newMinprice : minPrice;
                        }
                        else{
                            minPrice = newMinprice;
                        }

                        var priceClose = context.state.autoUpdateArray[context.state.autoUpdateArray.length-1]

                        console.log(context.state.autoUpdateArray)

                        context.state.autoUpdateArray = [];
                        //console.log("Updated subscribebar, autoupdateArray cleared")
                        
                        if(context.state.singleBlock && context.state.currentVolumeAmt == 0 && !context.state.gapFill){
                            // don't return a bar if no vol                            
                        }
                        else {
                            var mcapFactor = 1.0
                            if (context.state.chartMarketcap) {
                                mcapFactor = context.state.tokenCirculatingSupply
                            }
                            let data = {
                                time: newTime,
                                close: priceClose * mcapFactor,
                                open: priceOpen * mcapFactor,
                                low: minPrice * mcapFactor,
                                high: maxPrice * mcapFactor,
                                volume: context.state.currentVolumeAmt
                            }
                            console.log(data)
                            onRealtimeCallback(data);
                            context.state.currentVolumeAmt = 0;
                            context.state.prevClosePrice = priceClose
                            if (context.state.firstChartData && context.state.transactions.length > 0) {
                                context.state.firstChartData = false
                                //context.dispatch("loadChartData") // this is a dumb hack to get the chart to not be blank after token launches
                            }
                        }
                        

                        context.state.priceUpdateInterval = setTimeout(updateData, timeInterval);
                    }, timeInterval);
                    console.log(11.02)
                },

                unsubscribeBars: (subscriberUID) => {
                    clearTimeout(context.state.priceUpdateInterval)
                },
            };
            
            console.log('datafeed set')
            //context.state.chartLoading = false;

        },
    }
})

class hoop{

}

new hoop();