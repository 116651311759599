<template>
  <div v-for="item in $store.state.favoritedTokens" :key="'promoted-favorited-' + item.tokenAddress" class="font-heading relative py-1.5 flex flex-row justify-between dark:hover:bg-grey-100 dark:hover:bg-opacity-10 hover:bg-gray-100">
    <div class="space-x-3 w-full flex flex-row items-center pl-2 pr-4">
      <toggleFavorite v-bind:address="item.tokenAddress" v-bind:tokenImageUrl="item.tokenImageUrl" v-bind:tokenName="item.tokenName" />
      <div class="w-full">
        <a :href="'/?c=' + item.tokenChain + '&t=' + item.tokenAddress">
          <div
            class="flex flex-row items-center space-x-3 cursor-pointer"
            @click="
              // reset tab to chart on token load:
              this.$store.state.defaultTabIndex = 0;
              this.$store.state.page = 'chart';
              //this.Services.updateURL(this.$store);
              this.$store.state.rerenderTabs = true;
              $store.dispatch('loadToken', item.address);
              closeSearch();
            "
          >
            <span class="text-xs w-full flex flex-row items-center justify-between dark:text-white whitespace-nowrap overflow-hidden">
              <div class="flex flex-row items-center">
                <img class="w-7 h-7 mr-3 rounded-full bg-gray-900" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
                <div class="flex flex-col space-y-1 items-start">
                  <span class="flex flex-row items-center">
                    {{ maxLength(item.tokenName, 32) }}
                    <span v-if="item.tokenName.length > 32">...</span>
                  </span>
                  <span v-if="item.price" v-html="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" class="text-2xs text-gray-400"></span>
                  <span v-else class="text-2xs text-gray-400">Price not available</span>
                </div>
              </div>
              <div class="flex flex-row items-center space-x-4">
                <span
                  v-bind:class="{
                    'bg-purple-bright dark:text-purple-bright text-purple': item.tokenChain === 'polygon',
                    'bg-primary dark:text-primary text-primary-dark': item.tokenChain === 'bsc',
                  }"
                  class="rounded-md px-2 py-0.5 uppercase bg-opacity-20 dark:bg-opacity-10 text-2xs"
                  >{{ item.tokenChain }}</span
                >
              </div>
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import toggleFavorite from "@/components/Favorite/toggleFavorite";
import { bogintificPrice, maxLength } from "@/filters";
export default {
  components: {
    toggleFavorite,
  },
  methods: {
    bogintificPrice,
    maxLength,
    closeSearch: function() {
      this.$store.commit("closeSearch");
    },
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
