<template>
  <TransitionRoot as="template" :show="swapOverlayIsOpen">
    <Dialog as="div">
      <div class="absolute max-w-lg right-0 overflow-hidden">
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-lg border-l border-gray-700">
              <div class="h-full flex flex-col py-6 bg-gray-800 shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-end">
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-gray-900 p-1 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-600" @click="closeSwapOverlay">
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-6 relative flex-1 px-4">
                  <Swap />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Swap from "@/components/Swap/swapForm.vue";
export default {
  components: {
    Swap,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  computed: {
    src: function() {
      return "https://app.bogged.finance/swap?embed=1&token=" + this.$store.state.token;
    },
    swapOverlayIsOpen: function() {
      return this.$store.getters.swapOverlayOpen;
    },
  },
  methods: {
    openSwapOverlay: function() {
      this.$store.commit("openSwapOverlay");
    },
    closeSwapOverlay: function() {
      this.$store.commit("closeSwapOverlay");
    },
  },
};
</script>
