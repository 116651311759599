<template>
  <Menu as="div" class="flex-shrink-0 relative">
    <div>
      <MenuButton class="items-center flex">
        <div class="flex-shrink-0 p-1 md:block hidden text-gray-400 rounded-full hover:text-gray-500 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-50 dark:hover:bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-gray-600">
          <BellIcon class="h-6 w-6" aria-hidden="true" />
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="origin-top-right absolute divide-y divide-gray-700 right-4 mt-2 w-64 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-gray-100 dark:ring-gray-700 ring-opacity-100 focus:outline-none">
        <div v-if="notifications.length">
          <MenuItem v-for="item in notifications" v-bind:key="item">
            <a class="flex space-x-3 flex-row items-center cursor-pointer py-3 px-3 text-sm text-gray-800 dark:text-gray-100 hover:gray-100 dark:hover:bg-gray-700">
              <BellIcon v-if="item.type == 'pricealert'" class="h-5 w-5 text-gray-400 dark:text-gray-600" aria-hidden="true" />
              <TrendingUpIcon v-else-if="item.type == 'launch'" class="h-5 w-5 text-gray-400 dark:text-gray-600" aria-hidden="true" />
              <div class="flex flex-col">
                <div class="text-sm">{{ item.title }}</div>
                <div class="caption mt-1 text-gray-400">{{ item.time }}</div>
              </div>
            </a>
          </MenuItem>
        </div>
        <div v-else class="flex flex-col h-full w-full items-center place-content-center z-20 p-6">
          <img class="invert hue-rotate-180 filter" src="@/assets/emptystate/nonotifications.svg" />
          <span class="dark:text-gray-500 text-gray-300 mt-1 overline block">No notifications.</span>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { BellIcon, TrendingUpIcon } from "@heroicons/vue/solid";
import { bogintificPrice } from "@/filters";
import { Services } from "@/services";

export default {
  methods: {
    bogintificPrice,
  },
  computed: {
    notifications() {
      const priceAlertNotifs = this.$store.getters.triggeredAlerts.map((alert) => {
        return {
          title: `${alert.symbol} reached ${bogintificPrice(alert.priceUSD, 2)}`,
          time: Services.timeAgo(alert.triggeredTime),
          type: "pricealert",
        };
      });
      const otherNotifications = [];
      return [...priceAlertNotifs, ...otherNotifications];
    },
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    TrendingUpIcon,
  },
};
</script>
