<template>
  <div class="__modalBg z-50" >
    <div class="bg-gray-900 bg-opacity-75 fixed w-full h-screen inset-0 flex justify-center items-center p-4">
      <div style="max-width: 700px;" class="lg:left-50-256 lg:absolute w-full flex flex-col bg-gray-800 rounded-lg p-6">
    <a @click="$emit('close')" class="text-gray-500 text-right float-right cursor-pointer">X</a>

        <!-- Title and Description -->
        <div class="flex flex-col justify-center items-center mb-4">
          <img src="/octavia/meet.png" class="mb-4"/>
          <h2 class="text-white text-2xl mb-2">Octavia by Bogged</h2>
          <p class="text-gray-200"><strong>Exclusive private sale LIVE NOW!</strong><br /><span class="text-sm">Octavia is your web3 assistant, and AI designed to make trading easier. Founded by the co-founder of Bogged.</span><br /><br /><span class="text-xs">Audited by Hacken, KYC with AssureDeFi.</span></p>
        </div>

        <!-- Buy Token Button -->
        <a href="https://gempad.app/special/0xc34f59ED59B8DE4Ae096757e22DE6996a5Fa952A?chainId=1" style="background: linear-gradient( 90deg, rgba(0, 255, 213,0.6) 0%, rgba(50, 253, 141,0.6) 100% );" class="mb-4 w-full  justify-center hover:bg-opacity-100 hover:text-white rounded-lg py-3 px-4  bg-opacity-20 primary button text-white border border-white bg-green-600
      ">
            Buy $VIA Now! &raquo;
        </a>
        <i><a @click="$emit('close')" class="text-gray-500 underline cursor-pointer">Close & Don't show again...</a></i>

        <!-- Close Button -->
        
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
