<template>
  <div class="flex flex-wrap p-2 tabContentWrapper">
    <div class="p-2 font-heading text-sm flex lg:w-1/3 md:w-1/2 w-full justify-between" v-bind:key="pair" v-for="pair in tokenPairData">
      <div class="group rounded-xl w-full border-gray-200 dark:hover:border-gray-600 dark:border-gray-700 transition-colors duration-200 dark:bg-gray-900 bg-white border">
        <span v-if="$store.state.chain != 'csc'" class="p-4 border-b border-gray-200 dark:border-gray-700 transition-colors duration-200 rounded-t-xl flex w-full">
          <a class="dark:text-white text-gray-800 overline" :href="'https://' + $store.state.blockExplorer + '/token/' + pair.contract + '#balances'" rel="noopener noreferrer" target="_blank">
            {{ pair.token.name }} - {{ pair.quoteToken.name }} LP {{ pair.contractName }}
          </a>
        </span>
        <span v-else class="p-4 border-b border-gray-200 dark:border-gray-700 transition-colors duration-200 rounded-t-xl flex w-full">
          <div class="dark:text-white text-gray-800 overline" >
            {{ pair.token.name }} - {{ pair.quoteToken.name }} LP {{ pair.contractName }}
          </div>
        </span>
        <div class="p-4">
          <span v-if="$store.state.chain != 'csc'" class="overline text-secondary">{{ $t("charts.components.infobar.info.valuelp") }} {{ priceMinDigits(pair.totalValue, 2) }}</span>
          <p v-if="$store.state.chain != 'csc'" class="caption text-gray-500 dark:text-gray-300">{{ toLocaleStringMinDigits(pair.token.reserve, 2) }} {{ pair.token.name }} + {{ toLocaleStringMinDigits(pair.quoteToken.reserve, 2) }} {{ pair.quoteToken.name }}</p>
          <div v-if="$store.state.chain != 'csc'" class="mt-4 space-y-1">
            <p class="overline dark:text-primary text-primary-dark">Pool adds ${{ toLocaleStringMinDigits(pair.totalValue * pair.totalLPs, 2) }} to total liquidity</p>
            

            <p class="caption text-gray-500 dark:text-gray-300">{{ pair.token.name }} pooled: {{ toLocaleStringMinDigits(pair.token.pooled, 2) }}</p>
            <p class="caption text-gray-500 dark:text-gray-300">{{ pair.quoteToken.name }} pooled: {{ toLocaleStringMinDigits(pair.quoteToken.pooled, 2) }}</p>
            <p class="caption text-gray-500 dark:text-gray-300">Total LP Tokens: {{ toLocaleStringMinDigits(pair.totalLPs, 2) }}</p>
          </div>
          <div v-else class="mt-4">
            <p class="overline dark:text-primary text-primary-dark">Pool adds ${{ toLocaleStringMinDigits(pair.pooled_usd, 0) }} to total liquidity</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toLocaleStringMinDigits, priceMinDigits } from "@/filters";
import { PANCAKEPAIR_ABI, FACTORY_ABI, BEP20_ABI, quoteTokens } from "@/constants";

export default {
  watch: {
    "$store.state.token": function() {
      console.log("__watch token fired");
      this.loadData(true);
    },
    //"this.$store.state.initialized": function() {
    //  this.loadData(true);
    //},

    "this.$store.state.masterTokenPrice": function() {
      console.log("__watch masterTokenPrice fired");
      this.loadData();
    },
    "$store.state.liquidityData": function() {
      console.log("__watch liquidityData fired");
      this.loadData(true);
    },
  },
  data: function() {
    return {
      tokenPairData: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      tokenName: "tokenName",
      tokenSymbol: "tokenSymbol",
    }),
  },
  async created() {
    //Promise.resolve(this.$store.state.initializing).then(async () => {
    //  this.loadData(true);
    //});
    await this.$store.state.initializing;
    this.loadData(true);
  },
  methods: {
    toLocaleStringMinDigits,
    priceMinDigits,
    async loadData(reset = false) {
      if (reset) {
        this.tokenPairData = [];
      }
      this.tokenPairData = this.$store.state.liquidityData;
      console.log(this.tokenPairData);
    },
  },
};
</script>
