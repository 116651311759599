import SHA256 from "crypto-js/sha256";
const fetch = require("node-fetch");
const moment = require('moment')
var { graphql, buildSchema } = require('graphql');
const {
    CACHE_BQ_API,
    CACHE_IS_VERIFIED_API,
    HASH_SHARED_SECRET,
    sleep,
    retryBackoffTimeMs
} = require('./constants')




//Testing, not final
const deterministicUSDOHLCQuery = (conf) => {
    // in order for the cache to work, we need the query string to always be the same given the same inputs
    // otherwise, we might use an object and JSON.dump etc.

    //date: {since: "2021-06-02T11:05:00.000Z", till: "2021-06-04T11:05:00.000Z"} \
    var network = conf.chain
    if (network == "polygon") { network = "matic"; }
    if (network == "avax") { network = "avalanche"; }
    if (network == "ftm") { network = "fantom"; }
    if (network == 'csc') { return null }

    var query = '{ethereum(network: '+network+') { dexTrades(';
             
            if(conf.singleBlocks == false){
                if (typeof (conf.since) !== 'undefined') {
                    query = query + 'date: {since:"' + conf.since + '"';
                    if (typeof (conf.till) != 'undefined') {
                        query = query + ',till:"' + conf.till + '"'
                    }
                    
                    query = query + '}';
                }
            }
            
            if(conf.singleBlocks == true){
                query = query + 'options: {limit: 5000, desc: "block.height"}'
            }

            query = query + 'tradeAmountUsd: {gt: 10} \
            exchangeAddress: {is: "'+ conf.exchange.toLowerCase() +'"} \
            any: [{baseCurrency: {is: "' + conf.base.toLowerCase() + '"}, quoteCurrency: {is: "'+conf.quote.toLowerCase()+'"}}, {baseCurrency: {is: "'+conf.quote.toLowerCase()+'"}, quoteCurrency: {is: "'+conf.usdToken.toLowerCase()+'"}}] \
            ) {';

            /*
            query = query + 'tradeAmountUsd: {gt: 10} \
            exchangeAddress: {is: "'+ conf.exchange +'"} \
            any: [{baseCurrency: {is: "' + conf.base + '"}, quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}}, {baseCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}, quoteCurrency: {is: "0xe9e7cea3dedca5984780bafc599bd69add087d56"}}] \
            ) {';
            */

            if(conf.singleBlocks == false){
                query = query + 'timeInterval { \
                    ' + conf.timeType + '(count: ' + conf.timeInterval + ') \
                }'
            }
            
            if(conf.singleBlocks == true){
                query = query + 'block { \
                    height \
                    timestamp {time} \
                }';
            }

            query = query + 'buyCurrency: baseCurrency { \
                symbol \
                address \
            } \
            sellCurrency: quoteCurrency { \
                symbol \
                address \
            } \
            tradeAmount(in: USD) \
            volume: quoteAmount \
            trades: count \
            maximum: quotePrice(calculate: maximum) \
            minimum: quotePrice(calculate: minimum) \
            open: minimum(of: block, get: quote_price) \
            close: maximum(of: block, get: quote_price) \
            } \
        } \
    }'
    
    return query;
}

const deterministicOHLCQuery = (conf) => {
    // in order for the cache to work, we need the query string to always be the same given the same inputs
    // otherwise, we might use an object and JSON.dump etc.
    var network = conf.chain
    if (network == "polygon") { network = "matic"; }
    if (network == "avax") { network = "avalanche"; }
    if (network == "ftm") { network = "fantom"; }
    if (network == 'csc') { return null }
    var query = '{ethereum(network: '+network+') {dexTrades(options: {';
        
    if(conf.singleBlocks == false){
        if (typeof (conf.limit) !== 'undefined') {
            query = query + 'limit: ' + conf.limit + ',';
        }
    
        query = query + 'desc: "timeInterval.'+ conf.timeType +'"},';

        if (typeof (conf.since) !== 'undefined') {
            query = query + 'date: {since:"' + conf.since + '"';
            if (typeof (conf.till) != 'undefined') {
                query = query + ',till:"' + conf.till + '"'
            }
            
            query = query + '},';
        }
        if (typeof (conf.sinceTime) !== 'undefined') {
            query = query + 'time: {since:"' + conf.sinceTime + '"';
            if (typeof (conf.tillTime) != 'undefined') {
                query = query + ',till:"' + conf.tillTime + '"'
            }
            
            query = query + '},';
        }
    }
    
    if(conf.singleBlocks == true){
        query = query + 'limit: 2000, desc: "block.height"}'
    }
    
    
    if (typeof(conf.exchange) == 'string') {
        query = query + 'exchangeAddress: {is: "'+ conf.exchange.toLowerCase() +'"}, '
    } else if (Array.isArray(conf.exchange)) {
        var exchangesStr = '["' + conf.exchange.join('","') + '"]'
        query = query + 'exchangeAddress: {in: '+exchangesStr.toLowerCase()+'}, '
    }

    if (typeof(conf.base) == 'string') {
        query = query + ' baseCurrency: {is: "'+ conf.base.toLowerCase() +'"}, '
    } else if (Array.isArray(conf.base)) {
        var basesStr = '["' + conf.base.join('","') + '"]'
        query = query + ' baseCurrency: {in: '+basesStr.toLowerCase()+'}, '
    }
    if (typeof(conf.quote) == 'string') {
        query = query + ' quoteCurrency: {is: "'+ conf.quote.toLowerCase() +'"}, '
    } else if (Array.isArray(conf.quote)) {
        var quotesStr = '["' + conf.quote.join('","') + '"]'
        query = query + ' quoteCurrency: {in: '+quotesStr.toLowerCase()+'}, '
    }

    query += ') {'
            
    if(conf.singleBlocks == false){
        query = query + 'timeInterval { \
            ' + conf.timeType + '(count: ' + conf.timeInterval + ') \
        }'
    }
    
    if(conf.singleBlocks == true){
        query = query + 'block { \
            height \
            timestamp {time} \
        }';
    }
    
    query = query + 'baseCurrency { \
                symbol \
                address \
            } \
            quoteCurrency { \
                symbol \
                address \
            } \
            trades: count \
            tradeAmount(in: USD) \
            volume: quoteAmount \
            quotePrice \
            \
            maximum_price: quotePrice(calculate: maximum) \
            minimum_price: quotePrice(calculate: minimum) \
            open_price: minimum(of: block get: quote_price) \
            close_price: maximum(of: block get: quote_price) \
            } \
        } \
    }';
    return query;
}

async function runBogCacheQuery(query, url_suffix, get_historical, attempt=0) {

    let hashable = JSON.stringify({
        query: query,
        get_historical: get_historical // this affects the cache expiry
    })
    //console.log(query)
    let ss = HASH_SHARED_SECRET // shared secret
    let t = moment().unix()                       // timestamp
    let sth = `${hashable}-${t}-${ss}`             // string to hash
    let h = SHA256(sth)                           // hash

    const opts = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: hashable
    };

    const url = CACHE_BQ_API + url_suffix + `/query?h=${h}&t=${t}`
    let fetchResult = {ok: false}
    try {
        fetchResult = await fetch(url, opts)
    } catch (e) {
         console.log(e)
         console.log(query)
    }
    if (fetchResult.ok) {
        let json = await fetchResult.json()
        return json;
    } else {
        if (attempt > 5) {
            //TODO: show some error
            return null
        } else {
            await sleep(retryBackoffTimeMs(attempt))
            return await runBogCacheQuery(query, url_suffix, false, attempt + 1)
        }
    }
}

async function runQuery(query) {
    const url = "https://graphql.bitquery.io/";
    const opts = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-API-KEY" : "BQYKQjWuseSD61u7MRM9MnEgWUDIrN1L"
        },
        body: JSON.stringify({
            query
        })};

    var result = '';
    await fetch(url, opts).then(res => res.json())
    .then(response => {
        result = response;
        
    })
    .catch(error => result = error);
    
    return result;
}

export async function getOHLCData(conf){
    let query = deterministicOHLCQuery(conf);
    //console.log(query)
    return await runBogCacheQuery(query, '', false);
}

export async function getUSDOHLCData(conf){
    let query = deterministicUSDOHLCQuery(conf);
    let get_historical = !conf.firstDataLoad    
    if (conf.requested_to_ts && Date.now() - conf.requested_to_ts < 1000*60*15) { get_historical = false; }

    // If we are fetching data for today as a historical query, we'll be saving an incomplete day's data
    // to cache with a long expiry. This is ok for longer intervals but we need to 
    // make shorter intervals not be historical queries.
    let today_midnight_ts = Math.floor(Date.now() / (1000*60*60*24)) * 1000*60*60*24
    let to_ts_rounded = Math.floor(conf.to_ts / (1000*60*60*24)) * 1000*60*60*24 // rounded to nearest day    
    if (conf.to_ts && to_ts_rounded >= today_midnight_ts && conf.interval_mins <= 3) { get_historical = false; }
    if (get_historical) { 
        console.log('HISTORICAL')
        console.log(query)
    } else {
        console.log('NOT HISTORICAL')
        console.log(query)
    }
    
    return await runBogCacheQuery(query, '', get_historical);
}

async function runWalletQuery(query) {
    const url = "https://graphql.bitquery.io/";
    const opts = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-API-KEY" : "BQYKQjWuseSD61u7MRM9MnEgWUDIrN1L"
        },
        body: JSON.stringify({
            query
        })};
    var result = '';
    await fetch(url, opts).then(res => res.json())
    .then(response => {
        result = response;
        
    })
    .catch(error => result = error);
    
    return result;
}

export async function getWalletTokens(address, chain) {    
    var network = chain
    if (network == "polygon") { network = "matic"; }
    if (network == "avax") { network = "avalanche"; }
    if (network == "ftm") { network = "fantom"; }
    if (network == 'csc') { return null }
    var query = '{ethereum(network: '+network+') {';
    
    if (typeof(address) !== 'undefined') {
        query = query + 'address(address: {is: "' + address + '"}';
    }
    
    query = query + ') { \
                address \
                \
                balances { \
                    currency { \
                        address \
                        decimals \
                        name \
                        symbol \
                    } \
                    value \
                    \
                } \
            } \
        } \
    }';

    console.log('______')
    console.log('______')
    console.log(query)
    console.log('______')
    console.log('______')

    var walletResult = await runBogCacheQuery(query, '/wallet', false);
    
    console.log('______')
    console.log('wallet tokens:')
    console.log(walletResult)
    console.log('______')
    console.log('______')
    console.log('______')
    console.log('______')

    return walletResult
}

export async function getUserTrades(address, token, from, to, buy, chain) {
    var network = chain
    if (network == "polygon") { network = "matic"; }
    if (network == "avax") { network = "avalanche"; }
    if (network == "ftm") { network = "fantom"; }
    if (network == 'csc') { return null }
    var query = '{ethereum(network: '+network+') { dexTrades( options: {desc: ["block.height", "tradeIndex"]} ';
    
    if (typeof (from) !== 'undefined') {
        query = query + 'time: {since:"' + from + '"';
        if (typeof (to) != 'undefined') {
            query = query + ',till:"' + to + '"'
        }
        
        query = query + '}';
    }
    
    if (typeof(address) !== 'undefined') {
        query = query + 'txSender: {is: "'+ address +'"}';
    }

    if (typeof(token) !== 'undefined') {
        if(buy){
            query = query + 'sellCurrency: {is: "'+ token +'"}';
        }
        else{
            query = query + 'buyCurrency: {is: "'+ token +'"}';
        }
    }
    
    query = query + ') { \
                block { \
                    timestamp { \
                        time(format: "%Y-%m-%d %H:%M:%S") \
                    } \
                    height \
                } \
                tradeIndex \
                exchange { \
                    fullName \
                    address { \
                        address \
                    } \
                } \
                buyAmountUSD: buyAmount(calculate: average, in: USD) \
                buyAmount \
                buyCurrency { \
                    address \
                    symbol \
                } \
                sellAmountUSD: sellAmount(calculate: average, in: USD) \
                sellAmount \
                sellCurrency { \
                    address \
                    symbol \
                } \
                transaction { \
                    hash \
                } \
            } \
        } \
    }';

    return await runBogCacheQuery(query, '', false);
}