<template>
  <div class="flex fixed w-screen h-full" v-if="$store.state.embed == false">

    <helpModal v-if="showModal" @close="closeModal"></helpModal>
    <div class="md:block hidden"></div>
    <Sidebar />

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />

      <main id="main_page" class="overflow-x-hidden overflow-y-auto mt-12 lg:mt-16 z-10">
        <div class="mx-auto">
          <slot />
        </div>
      </main>
    </div>
  </div>
  <div v-else>
    <embedded-chart />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Header from "@/components/Header/Header.vue";
import helpModal from "@/components/helpModal";
import embeddedChart from "@/components/embeddedChart";
import bogChart from "@/views/Chart/components/bogChart";

export default {
  components: { Header, Sidebar, helpModal, bogChart, embeddedChart },

  
  data() {
  return {
    showModal: false,
    startTime: new Date(Date.UTC(2023, 8, 4, 11)).getTime(), // 4th September 12pm UTC
    endTime: new Date(Date.UTC(2023, 8, 7, 12)).getTime() // 72 hours later
  };
},

  methods: {
    closeModal() {
      this.showModal = false;
      // Store the state in local storage
      localStorage.setItem('modalClosed4', 'true');
    }
  },
  created() {
  const now = new Date().getTime();

  // Check local storage on component creation
  const modalClosed = localStorage.getItem('modalClosed4');

  if (!modalClosed && now >= this.startTime && now <= this.endTime) {
    this.showModal = true;
  }
}

};
</script>
