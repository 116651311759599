<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton class="relative w-full flex items-center bg-gray-700 border border-gray-600 rounded-full shadow-sm pl-2 pr-7 py-1.5 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500">
      <span class="w-full inline-flex truncate">
        <ShareIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
      </span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
        <ChevronDownIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
      </span>
    </MenuButton>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="origin-top-right absolute z-20 right-0 mt-2 w-56 rounded-md shadow-lg overflow-hidden bg-gray-800 border border-gray-700">
        <div class="divide-y divide-gray-700">
          <MenuItem v-slot="{ active }">
            <a :href="$store.state.tokenWebsite" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-300', 'block p-3 text-sm']">Website</a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a :href="$store.state.tokenTelegram" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-300', 'block p-3 text-sm']">Telegram</a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a :href="$store.state.tokenTwitter" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-300', 'block p-3 text-sm']">Twitter</a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a :href="$store.state.tokenDiscord" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-300', 'block p-3 text-sm']">Discord</a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a :href="$store.state.tokenWaterfall" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-300', 'block p-3 text-sm']">Waterfall</a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { ShareIcon } from "@heroicons/vue/outline";

export default {
  data() {
    return {
      options: [],
    };
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    CheckIcon,
    ChevronDownIcon,
    ShareIcon,
  },
};
</script>
