export default {
  "charts.common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "charts.common.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
  "charts.common.copyaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制地址"])},
  "charts.common.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断接"])},
  "charts.common.yourwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的钱包"])},
  "charts.common.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
  "charts.common.sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赞助商"])},
  "charts.common.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买"])},
  "charts.components.topbar.24hrvol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时交易量"])},
  "charts.components.topbar.liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流动池"])},
  "charts.components.topbar.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市值"])},
  "charts.components.topbar.bal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
  "charts.components.topbar.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币交换"])},
  "charts.components.topbar.trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限价单交易"])},
  "charts.components.topbar.bscscan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSCScan"])},
  "charts.components.topbar.singleblocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单块"])},
  "charts.components.topbar.plottrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情节交易"])},
  "charts.components.infobar.info.valuelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个LP值: "])},
  "charts.components.infobar.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "charts.components.infobar.usertrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的交易"])},
  "charts.components.infobar.tokeninfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币细节"])},
  "charts.components.infobar.liqinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流动池细节"])},
  "charts.components.infobar.time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时段"])},
  "charts.components.infobar.traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "charts.components.infobar.tokenprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
  "charts.components.infobar.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价值"])},
  "charts.components.infobar.dex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX"])},
  "charts.components.infobar.tradebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易书"])},
  "charts.components.infobar.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻"])},
  "charts.components.infobar.connecttoviewtrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请连接钱包以查看您的交易"])},
  "charts.components.infobar.transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易转移"])},
  "charts.components.infobar.holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有者"])},
  "charts.components.infobar.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约"])},
  "charts.components.sidebar.goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退回"])},
  "charts.components.sidebar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表盘"])},
  "charts.components.sidebar.chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格表"])},
  "charts.components.sidebar.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
  "charts.components.sidebar.tokenexplorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币浏览器"])},
  "charts.components.sidebar.multichart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多重价格表"])},
  "charts.components.sidebar.othertools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他工具"])},
  "charts.components.sidebar.madeby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "charts.components.sidebar.bogtools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bog科学家用爱制造"])},
  "charts.components.sidebar.version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本"])},
  "charts.components.infobar.tokeninfo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实时价格图表"])},
  "charts.components.infobar.tokeninfo.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站"])},
  "charts.components.infobar.tokeninfo.telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电报群"])},
  "charts.components.infobar.tokeninfo.twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推特"])},
  "charts.components.infobar.tokeninfo.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "charts.components.infobar.tokeninfo.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约"])},
  "charts.components.infobar.tokeninfo.waterfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易瀑布"])},
  "charts.components.infobar.tokeninfo.xsupplyof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有供应"])},
  "charts.components.infobar.tokeninfo.xcircsupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["循环供应"])},
  "charts.components.infobar.tokeninfo.liqback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的流动池占有"])},
  "charts.components.infobar.tokeninfo.ofthemcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的市值"])},
  "charts.views.portfolio.clicktoconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
  "charts.views.portfolio.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
  "charts.views.portfolio.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "charts.views.portfolio.totalvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总价值"])},
  "charts.views.portfolio.24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时"])},
  "charts.views.portfolio.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市值"])},
  "charts.views.portfolio.stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押"])},
  "charts.views.portfolio.viewchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看价格图表"])},
  "charts.views.portfolio.hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["掩盖"])},
  "charts.views.portfolio.yourportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的投资组合"])},
  "charts.views.portfolio.portfoliooverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合概览"])},
  "charts.views.portfolio.favorited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
  "charts.components.infobar.tokeninfo.basicdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是币安智能链上的代币."])},
  "charts.views.home.welcometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎使用 Bog价格表v3"])},
  "charts.views.home.welcometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者可以通过Bogged.金融的价格表免费查看PancakeSwap中具有流动量的任何代币的价格表. 这包括重要信息，例如流动量和市值. 在未来几个月内，Bogged.金融将继续推出对价格表的更新和改进."])},
  "charts.views.home.latestnews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新消息"])},
  "charts.views.home.latestnews.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币交换v2刚刚推出!"])},
  "charts.views.home.latestnews.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 年 4 月 22 日"])},
  "charts.views.home.latestnews.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Bogged.金融交换任何Pancakeswapv1和v2的代币而您的订单将自动通过最佳DEX进行路由，无需额外费用."])},
  "charts.views.home.promote.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广您的代币"])},
  "charts.views.home.promote.titlelong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Bogged.金融 推广您的代币"])},
  "charts.views.home.promote.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有多种科学家工具和选项可以通过 Bogged.金融推广您的代币，包括免费和付费的选择。请单击下方以了解有关向您项目的电报添加价格机器人、将您的徽标和信息添加到我们的价格表、在 Bogged 上获取 #trending 以及在 Bogged.金融上获取广告."])},
  "charts.views.home.promote.learnmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
  "charts.views.home.trending.trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["趋势"])},
  "charts.views.home.trending.promoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广"])},
  "charts.views.home.trending.viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最受关注"])},
  "charts.views.chart.loadingchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载价格图表"])},
  "charts.views.chart.lowliqwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所绘制的代币的流动性极低，这意味着小额交易会显着影响价格。这对于最近推出的代币来说是正常的。"])},
  "charts.views.chart.lpchartwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您正在尝试绘制 LP 代币图表。这些不应交易，而是在 Pancakeswap 或 APESwap 的流动性界面中分解为它们的组件。"])},
  "charts.topics.tools.stoploss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止损"])},
  "charts.topics.tools.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狙击手"])},
  "charts.topics.tools.staking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押"])},
  "charts.topics.tools.telegrampricebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电报价格机器人"])},
  "charts.topics.titles.thirdparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第三方工具"])},
  "charts.topics.titles.promote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广您的代币"])},
  "charts.home.premium.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要"])},
  "charts.home.premium.unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开锁"])},
  "charts.home.premium.unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解锁"])},
  "charts.home.premium.locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁"])},
  "charts.home.premium.connectwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
  "charts.home.connectwalletshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
  "charts.home.premium.connectwalletfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包查看您的解锁高级功能。"])},
  "charts.home.premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级功能"])},
  "charts.home.premium.congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜！您已解锁 Bogged.金融的所有功能"])},
  "charts.home.premium.nextunlockpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个高级功能解锁于"])},
  "charts.home.premium.nextunlockafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多$BOG"])},
  "charts.home.premium.holdstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有或质押$BOG以解锁BOG图表和Bogged.金融上的高级功能"])},
  "charts.home.premium.porttrackbasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本投资组合追踪器"])},
  "charts.home.premium.porttrackpro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业投资组合追踪器"])},
  "charts.home.premium.porttrackbasic.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用每日投资组合价值图表随着时间的推移跟踪您的投资组合。"])},
  "charts.home.premium.porttrackpro.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级功能"])},
  "charts.home.premium.limitorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限价单"])},
  "charts.home.premium.limitorders.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在Bogged.金融上对PancakeSwapv1/v2和ApeSwap上的任何代币进行限价订单。"])},
  "charts.home.premium.stoplosses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止损订单"])},
  "charts.home.premium.stoplosses.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在Bogged.金融上放置止损订单"])},
  "charts.home.premium.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOG狙击手"])},
  "charts.home.premium.sniper.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用BOG狙击手狙击即将开盘的代币。"])},
  "charts.home.premium.bogswapadv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogSwap高级版"])},
  "charts.home.premium.bogswapadv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用高级 BogSwap 功能更快、更有效地进行交易。"])},
  "charts.home.premium.bogswapadv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保您的购买不超过任何代币的交易限额。"])},
  "charts.home.premium.bogswapadv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绕过币安智能链上每个代币的交易限制。"])},
  "charts.home.premium.trailingstop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追踪止损单"])},
  "charts.home.premium.trailingstop.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在Bogged.金融上启用追踪止损单。"])}
}