<template>
  <TransitionRoot appear :show="chartSettingsModalIsOpen" as="template">
    <Dialog as="div">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild @click="closeChartSettingsModal" as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <div class="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 flex justify-between w-full items-center text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-700 px-6 py-4">
                Chart Settings
                <button @click="closeChartSettingsModal" type="button" class="p-0.5 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none focus:ring-0">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </DialogTitle>

              <draggable v-bind="dragOptions" v-model="order" @update="saveLocalStorage" class="p-6 space-y-5 dragArea list-group w-full">
                <div v-for="item in order" v-bind:key="item.id" class="relative h-full">
                  <component v-bind:is="item.toggle"></component>
                </div>
              </draggable>

              <div class="mx-6">
                <div class="h-px w-full bg-gray-100 dark:bg-gray-700"></div>
              </div>

              <SwitchGroup as="li" class="p-6 flex items-center justify-between">
                <div class="flex flex-col">
                  <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                    Full Width Chart
                  </SwitchLabel>
                  <SwitchDescription class="text-sm text-gray-500">
                    Removes the fixed width from the chart page.
                  </SwitchDescription>
                </div>
                <Switch
                  @click="toggleFullWidthChart"
                  v-model="enableFullWidth"
                  :class="[
                    this.$store.state.enableFullWidth ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
                    'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
                  ]"
                >
                  <span aria-hidden="true" :class="[this.$store.state.enableFullWidth ? 'translate-x-5 bg-white' : 'translate-x-0 bg-gray-400', 'inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
              </SwitchGroup>

              <SwitchGroup as="li" class="p-6 pt-0 flex items-center justify-between">
                <div class="flex flex-col">
                  <SwitchLabel as="p" class="text-sm font-medium text-gray-900 dark:text-white mb-2" passive>
                    Default Tab
                  </SwitchLabel>
                  <SwitchDescription class="text-sm text-gray-500">
                    Open a different tab by default on chart load.
                  </SwitchDescription>
                </div>
                <select @change="changeDefaultTab" v-model="defaultTab" class="rounded-md caption text-gray-600 dark:text-gray-200 py-1.5 pl-3 pr-8 border-none transition-colors duration-200 cursor-pointer bg-gray-100 dark:bg-gray-900">
                  <option class="bg-gray-800 text-white" value="0">Tradebook</option>
                  <option class="bg-gray-800 text-white" value="1">Your Trades</option>
                  <option class="bg-gray-800 text-white" value="2">Holders</option>
                  <option class="bg-gray-800 text-white" value="3">Details</option>
                  <option class="bg-gray-800 text-white" value="4">Liquidity</option>
                  <option class="bg-gray-800 text-white" value="5">News</option>
                </select>
              </SwitchGroup>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import slider from "vue3-slider";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { VueDraggableNext } from "vue-draggable-next";
import volumeToggle from "./Settings/volumeToggle.vue";
import liquidityToggle from "./Settings/liquidityToggle.vue";
import marketcapToggle from "./Settings/marketcapToggle.vue";
import ratioToggle from "./Settings/ratioToggle.vue";
import balanceToggle from "./Settings/balanceToggle.vue";
import circSupplyToggle from "./Settings/circSupplyToggle.vue";
import holdersToggle from "./Settings/holdersToggle.vue";
import trustScoreToggle from "./Settings/trustScoreToggle.vue";
import socialScoreToggle from "./Settings/socialScoreToggle.vue";
import masterTokenPriceToggle from "./Settings/masterTokenPriceToggle.vue";

export default {
  data() {
    return {
      order: this.$store.state.topbarOrder,
      defaultTab: this.$store.state.defaultTab,
      enableFullWidth: this.$store.state.enableFullWidth,
    };
  },
  components: {
    volumeToggle,
    liquidityToggle,
    marketcapToggle,
    ratioToggle,
    balanceToggle,
    circSupplyToggle,
    holdersToggle,
    trustScoreToggle,
    socialScoreToggle,
    masterTokenPriceToggle,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    XIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    "vue3-slider": slider,
    draggable: VueDraggableNext,
  },
  computed: {
    chartSettingsModalIsOpen: function() {
      return this.$store.getters.chartSettingsModalOpen;
    },
    dragOptions() {
      return {
        animation: 0,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    created: function() {
      this.enableFullWidth = this.$store.state.enableFullWidth;
    },
  },
  methods: {
    toggleFullWidthChart: function() {
      this.$store.commit("toggleFullWidthChart");
    },
    changeDefaultTab: function() {
      localStorage.setItem("defaultTab", this.defaultTab);
      this.$store.state.defaultTab = this.defaultTab;
    },
    saveLocalStorage: function() {
      localStorage.setItem("order", JSON.stringify(this.order));
      this.$store.state.topbarOrder = this.order;
    },
    openChartSettingsModal: function() {
      this.$store.commit("openChartSettingsModal");
    },
    closeChartSettingsModal: function() {
      this.$store.commit("closeChartSettingsModal");
    },
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.3;
}
</style>
