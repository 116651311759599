<template>
  <div class="rounded-lg overflow-hidden transition-all duration-300 bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-700 border dark:bg-opacity-50">
    <div class="px-3 py-2.5 dark:bg-gray-900 justify-between flex flex-row items-center border-b border-gray-200 dark:border-gray-700 bg-gray-100">
      <h4 class="dark:text-white font-semibold text-gray-600 flex flex-row items-center">
        <span v-if="!walletFirstLoadComplete && walletReloading && isWalletConnected" class="flex relative mr-2 h-3 w-3 top-0 right-0">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
        </span>
        <div v-html="bogintificPrice(walletValue | 0, $store.state.useTruncatedBogintific)"></div>
      </h4>
      <router-link to="/portfolio" class="text-2xs pr-1 dark:hover:text-gray-200 transition-colors text-gray-500 hover:text-gray-400 duration-200 dark:text-gray-400"
        >{{ $t("charts.components.sidebar.viewportfolio") }}{{ $t("charts.components.sidebar.portfolio") }}</router-link
      >
    </div>

    <div class="max-h-64 rounded-lg overflow-x-hidden overflow-y-auto">
      <router-link
        :to="'/?c='+$store.state.chain+'&t=' + item.currency.address"
        v-for="(item, i) in tokenlist"
        v-bind:key="'tokenlist-pfo-' + i"
        class="miniPortfolioRow px-1 py-2.5 entry-counter dark:hover:bg-gray-500 dark:hover:bg-opacity-10 hover:bg-gray-100 hover:bg-opacity-50 items-center flex flex-row"
      >
        <div style="flex: 1 0 calc(55% - 24px);" class="pl-2 flex flex-col">
          <div style="max-width: 125px;" class="text-xs overflow-ellipsis truncate font-heading text-gray-800 dark:text-gray-300 cursor-pointer" @click="$store.dispatch('loadToken', item.currency.address)">
            {{ item.currency.symbol }}
          </div>

          <div
            v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.percentChange >= 0, 'dark:text-error-bright text-error': item.percentChange < 0 }"
            class="text-xs text-gray-900 dark:text-white text-left"
            @click="$store.dispatch('loadToken', item.currency.address)"
          >
            <div v-html="bogintificPrice(item.price, $store.state.useTruncatedBogintific)"></div>
          </div>
        </div>
        <div style="flex: 1 0 calc(45% - 24px);">
          <div class="text-xs font-semibold text-gray-800 dark:text-white cursor-pointer pr-3 text-right" @click="$store.dispatch('loadToken', item.currency.address)">
            <div v-html="bogintificPrice(Math.floor(item.value))"></div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { bogintificPrice } from "@/filters";
export default {
  beforeMount() {
    this.$store.dispatch("initTheme");
  },
  components: {
    bogintificPrice,
  },
  methods: {
    bogintificPrice,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    hideToken: async function(token) {
      this.$store.state.portfolioTokensHidden.push(token.currency.address);
      localStorage.setItem("hiddenTokens", JSON.stringify(this.$store.state.portfolioTokensHidden));
    },
    showHiddenTokens: async function() {
      this.$store.state.portfolioTokensHidden = [];
      localStorage.setItem("hiddenTokens", []);
    },
  },
  computed: {
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
    walletReloading: function() {
      return this.$store.state.walletReloading;
    },
    walletFirstLoadComplete: function() {
      return this.$store.state.walletFirstLoadComplete;
    },
    walletValue: function() {
      if (this.$store.getters.accountWalletTokens == null) {
        return 0;
      }
      let totalValue = 0;
      let filteredTokens = this.$store.getters.accountWalletTokens.filter((t) => {
        return t && t.balance && t.value && !this.$store.state.portfolioTokensHidden.includes(t.currency.address);
      });
      for (let token of filteredTokens) {
        totalValue += token.value;
      }
      return totalValue;
    },
    tokenlist: function() {
      return this.$store.getters.accountWalletTokens == null
        ? []
        : this.$store.getters.accountWalletTokens
            .filter((t) => {
              return t && t.balance && t.value && !this.$store.state.portfolioTokensHidden.includes(t.currency.address);
            })
            .sort((a, b) => {
              let stakeMismatch = a.isStake != b.isStake;
              if (stakeMismatch) {
                return b.isStake ? 1 : -1;
              }
              return b.value - a.value;
            });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
