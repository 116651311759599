export default {
  "charts.common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "charts.common.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
  "charts.common.copyaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Address"])},
  "charts.common.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "charts.common.yourwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Wallet"])},
  "charts.common.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "charts.common.sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor"])},
  "charts.common.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "charts.components.topbar.24hrvol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24hr Volume"])},
  "charts.components.topbar.vol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "charts.components.topbar.liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidity"])},
  "charts.components.topbar.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketcap"])},
  "charts.components.topbar.bal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "charts.components.topbar.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
  "charts.components.topbar.trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Order"])},
  "charts.components.topbar.bscscan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSCScan"])},
  "charts.components.topbar.singleblocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Blocks"])},
  "charts.components.topbar.plottrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plot Trades"])},
  "charts.components.infobar.info.valuelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One LP is worth: "])},
  "charts.components.infobar.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
  "charts.components.infobar.usertrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Trades"])},
  "charts.components.infobar.tokeninfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Info"])},
  "charts.components.infobar.liqinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidity Info"])},
  "charts.components.infobar.time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "charts.components.infobar.traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traded"])},
  "charts.components.infobar.tokenprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Price"])},
  "charts.components.infobar.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "charts.components.infobar.dex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX"])},
  "charts.components.infobar.tradebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
  "charts.components.infobar.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "charts.components.infobar.connecttoviewtrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please connect wallet to view your trades"])},
  "charts.components.infobar.transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
  "charts.components.infobar.holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holders"])},
  "charts.components.infobar.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
  "charts.components.sidebar.goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Bogged.Finance"])},
  "charts.components.sidebar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "charts.components.sidebar.chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart"])},
  "charts.components.sidebar.viewportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View "])},
  "charts.components.sidebar.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
  "charts.components.sidebar.tokenexplorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
  "charts.components.sidebar.multichart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multichart"])},
  "charts.components.sidebar.othertools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Tools"])},
  "charts.components.sidebar.madeby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with ❤ by "])},
  "charts.components.sidebar.bogtools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bogtools"])},
  "charts.components.sidebar.version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "charts.components.infobar.tokeninfo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Price Chart"])},
  "charts.components.infobar.tokeninfo.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "charts.components.infobar.tokeninfo.telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "charts.components.infobar.tokeninfo.twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "charts.components.infobar.tokeninfo.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "charts.components.infobar.tokeninfo.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
  "charts.components.infobar.tokeninfo.waterfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterfall"])},
  "charts.components.infobar.tokeninfo.xsupplyof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has a supply of"])},
  "charts.components.infobar.tokeninfo.xcircsupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a circulating supply of"])},
  "charts.components.infobar.tokeninfo.liqback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'s liquidity backs"])},
  "charts.components.infobar.tokeninfo.liqbackpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "charts.components.infobar.tokeninfo.ofthemcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the marketcap."])},
  "charts.views.portfolio.clicktoconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Your Wallet to View Portfolio"])},
  "charts.views.portfolio.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "charts.views.portfolio.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "charts.views.portfolio.totalvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Value"])},
  "charts.views.portfolio.24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
  "charts.views.portfolio.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mcap"])},
  "charts.views.portfolio.stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake"])},
  "charts.views.portfolio.viewchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Chart"])},
  "charts.views.portfolio.hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide token"])},
  "charts.views.portfolio.yourportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Portfolio"])},
  "charts.views.portfolio.portfoliooverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio Overview"])},
  "charts.views.portfolio.favorited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourited"])},
  "charts.components.infobar.tokeninfo.basicdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is a token on"])},
  "charts.views.home.welcometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to BogCharts v3"])},
  "charts.views.home.welcometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view charts for any token with liquidity in PancakeSwap for free with Bogged Finance's Charts. This includes vital information such as liquidity and marketcap. Updates and improvements to the chart platform will continue to be rolled out in the coming weeks and months."])},
  "charts.views.home.latestnews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
  "charts.views.home.latestnews.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap v2 is here!"])},
  "charts.views.home.latestnews.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22 April 2021"])},
  "charts.views.home.latestnews.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap any Pancakeswap v1 and v2 tokens with Bogged.Finance, and your order is automatically routed through the best DEX, with no additional fees."])},
  "charts.views.home.promote.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promote Your Token"])},
  "charts.views.home.promote.titlelong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promote Your Token with Bogged.Finance"])},
  "charts.views.home.promote.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are a variety of tools and options to promote your token with Bogged.Finance, both free and paid. Click below to find out more about adding a price bot to your telegram, adding your logo and information to our charts, getting #trending on Bogged, and getting ads on Bogged.Finance"])},
  "charts.views.home.promote.learnmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
  "charts.views.home.trending.trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending"])},
  "charts.views.home.trending.promoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoted"])},
  "charts.views.home.trending.viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Viewed"])},
  "charts.views.chart.loadingchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Chart"])},
  "charts.views.chart.lowliqwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The token you are charting has extremely low liquidity, this means that small trades can impact the price significantly. This can be normal for very recently launched tokens."])},
  "charts.views.chart.lpchartwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are attempting to Chart an LP Token. These should not be traded, but instead broken into their components in the Liquidity Interface of Pancakeswap or APESwap."])},
  "charts.topics.tools.stoploss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss"])},
  "charts.topics.tools.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniper"])},
  "charts.topics.tools.staking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staking"])},
  "charts.topics.tools.telegrampricebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram Price Bot"])},
  "charts.topics.titles.thirdparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Tools"])},
  "charts.topics.titles.promote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promote Your Token"])},
  "charts.home.premium.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "charts.home.premium.unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock"])},
  "charts.home.premium.unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlocked"])},
  "charts.home.premium.locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
  "charts.home.premium.connectwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your wallet"])},
  "charts.home.connectwalletshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Wallet"])},
  "charts.home.premium.connectwalletfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your wallet to view your unlocked features."])},
  "charts.home.premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Features"])},
  "charts.home.premium.congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrats! You've unlocked all of Bogged.Finance's features"])},
  "charts.home.premium.nextunlockpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next feature unlocked at"])},
  "charts.home.premium.nextunlockafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more BOG"])},
  "charts.home.premium.holdstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold or Stake $BOG to unlock advanced features on BogCharts and Bogged.Finance"])},
  "charts.home.premium.porttrackbasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio Tracker Basic"])},
  "charts.home.premium.porttrackpro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio Tracker Pro"])},
  "charts.home.premium.porttrackbasic.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your portfolio over time with a daily portfolio value chart."])},
  "charts.home.premium.porttrackpro.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get access to more granular data about your portfolio."])},
  "charts.home.premium.limitorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Orders"])},
  "charts.home.premium.limitorders.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Orders on Bogged.Finance for any token on PancakeSwap v1/v2 and ApeSwap."])},
  "charts.home.premium.stoplosses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Losses"])},
  "charts.home.premium.stoplosses.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Stop Loss orders on Bogged.Finance"])},
  "charts.home.premium.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOGSniper"])},
  "charts.home.premium.sniper.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe token launches with the BOGSniper on Bogged.Finance."])},
  "charts.home.premium.bogswapadv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogSwap Advanced"])},
  "charts.home.premium.bogswapadv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade faster & more effectively with advanced BogSwap features."])},
  "charts.home.premium.bogswapadv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensure your buys go through by not exceeding the transaction limit of any token."])},
  "charts.home.premium.bogswapadv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bypass Transaction Limits on every token on BSC."])},
  "charts.home.premium.trailingstop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing Stop Losses."])},
  "charts.home.premium.trailingstop.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enables Trailing Stop Losses on Bogged.Finance."])}
}