<template>
  <div v-if="$store.state.showVolume" class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4">
    <span class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path
          d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
        /></svg
    ></span>
    <span class="flex flex-col">
      <span class="text-sm block font-heading whitespace-nowrap dark:text-gray-300 text-gray-500">{{ $t("charts.components.topbar.24hrvol") }}</span>
      <div id="textfade" v-if="$store.state.tokenVolume != null">
        <h4 v-html="bogintificPrice($store.state.tokenVolume, this.$store.state.useTruncatedBogintific)" class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg"></h4>
      </div>
      <div v-else class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable skeleton-loader"></div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
