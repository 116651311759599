<template>
  <div class="flex flex-col justify-between p-4 tabContentWrapper overflow-y-auto">
    <div class="xl:max-w-2xl">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <h1 class="dark:text-white text-gray-700 mt-1 leading-none text-lg">{{ $store.state.tokenSymbol }} {{ $t("charts.components.infobar.tokeninfo.title") }}</h1>
        </div>

        <p v-if="$store.state.tokenDescription" class="caption text-gray-700 dark:text-white mt-3">{{ $store.state.tokenDescription }}</p>
        <p v-else class="text-sm text-gray-700 dark:text-white mt-3">
          {{ $store.state.tokenName }} {{ $t("charts.components.infobar.tokeninfo.basicdesc") + ' ' + this.chainName() + '.'
          }}<sup
            ><span class="text-gray-300 block mt-4">Is this your token? <a class="text-primary" target="_blank" href="https://github.com/boggedfinance/bogged-token-list">Get a Listing on Bogged.Finance for free*.</a></span></sup
          >
        </p>

        <div class="mt-4 p-4 rounded-xl w-full border-gray-200 dark:hover:border-gray-600 dark:border-gray-700 transition-colors duration-200 dark:bg-gray-900 bg-white border">
          <p v-if="$store.state.tokenLiqBackingPct" class="overline text-gray-700 dark:text-white text-white">
            {{ $t("charts.components.infobar.tokeninfo.liqbackpre") }} {{ $store.state.tokenSymbol }}{{ $t("charts.components.infobar.tokeninfo.liqback") }}
            <span class="text-secondary font-bold"> {{ percent($store.state.tokenLiqBackingPct) }} </span>
            {{ $t("charts.components.infobar.tokeninfo.ofthemcap") }}
          </p>
        </div>

        <div class="mt-4 p-4 rounded-xl w-full border-gray-200 dark:hover:border-gray-600 dark:border-gray-700 transition-colors duration-200 dark:bg-gray-900 bg-white border">
          <p v-if="$store.state.tokenLiqBackingPct" class="overline text-gray-700 dark:text-white text-white">
            {{ $store.state.tokenSymbol }} {{ $t("charts.components.infobar.tokeninfo.xsupplyof") }} <span class="text-primary">{{ number($store.state.tokenSupply) }}</span> {{ $t("charts.components.infobar.tokeninfo.xcircsupply") }}
            <span class="text-primary">{{ numberfloor($store.state.tokenCirculatingSupply) }}</span
            >. <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { percent, numberfloor, number } from "@/filters";
import chains from "@/chains";
export default {
  methods: {
    chainName() {
      console.log(chains)
      console.log(this.$store.state.chain)
      return chains[this.$store.state.chain].name;
    },    
    percent,
    numberfloor,
    number,
  },
};
</script>
