<template>
  <div class="left-0 absolute w-screen z-40 h-16">
    <header class="z-40 h-12 lg:h-16 flex justify-between items-center py-1 border-b transition-colors duration-200 dark:border-gray-700 border-gray-200 dark:bg-gray-800 bg-white">
      <div class="flex items-center w-full">
        <div @click="toggleSidebar" class="hover:text-opacity-70 duration-300 dark:text-primary text-gray-700 cursor-pointer transition-all w-16 h-16 flex items-center xl:border-r dark:border-gray-700 place-content-center focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="fill-current" fill="none" viewBox="0 0 24 24">
            <path d="M2 5v2h20V5H2zm0 6v2h20v-2H2zm0 6v2h20v-2H2z" />
          </svg>
        </div>
        <a class="hidden lg:block ml-4 flex-shrink-0" href="/">
          <img class="h-10 w-10" src="@/assets/logo.png" />
        </a>
        <Menu />
      </div>
      

      <token-search class="xl:w-full" />

      

      <div class="flex items-center space-x-2 w-full justify-end">
        <div  v-tippy="{
            content: '<b>You may experience connection issues.</b><br>If you have other charts windows open, try closing them.<br><br>Last error message:<br>'+$store.state.rpcError,
            allowHTML: true,
            appendTo: 'parent',
            theme: 'bog',
            animation: 'shift-toward-subtle',
            arrow: false,
            maxWidth: 'none',
          }"
          style="cursor: pointer;"
          v-if="$store.state.rpcError && $store.state.nowTimestamp - $store.state.lastRpcError < 60000" 
          class="fade-in justify-end text-error-bright text-xl"
          >
          ⚠
        </div>
        <button @click="openSearch()" type="button" class="xl:hidden flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white hover:bg-gray-50 hover:bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </button>
        <button
          @click="openSettingsModal"
          type="button"
          class="flex-shrink-0 p-1 md:block hidden text-gray-400 rounded-full hover:text-gray-500 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-50 dark:hover:bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-gray-600"
        >
          <CogIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <notifications-dropdown />
        <networkToggler class="md:block hidden" />
        <profile-dropdown />
      </div>
    </header>
    <settings-modal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeToggler from "@/components/Sidebar/themeToggler";
import settingsModal from "@/components/Settings/settingsModal";
import profileDropdown from "@/components/Header/profileDropdown";
import notificationsDropdown from "@/components/Header/notificationsDropdown";
import networkToggler from "@/components/Header/networkToggler";
import Menu from "@/components/Header/Menu";
import tokenSearch from "@/components/Search/tokenSearch";
import { CogIcon } from "@heroicons/vue/solid";
export default {
  components: { themeToggler, networkToggler, settingsModal, tokenSearch, CogIcon, profileDropdown, notificationsDropdown, Menu },
  beforeMount: async function() {
    this.$store.dispatch("initTheme");
  },
  mounted() {

    setInterval(function() {
      this.$store.state.nowTimestamp = Date.now()
    }.bind(this), 100);
  },
  data: function() {
    return {
      activeClass: "text-primary-xtra_light",
      inactiveClass: "text-gray-500 hover:text-gray-300",
      dropdownOpen: false,
      token: this.$store.state.token,
    };
  },
  methods: {
   
    openSearch: function() {
      this.$store.commit("openSearch");
    },
    toggleSidebar: function() {
      this.$store.commit("toggleSidebar");
    },
    loadToken() {
      this.$store.dispatch("loadToken", this.token);
    },
    openSettingsModal: function() {
      this.$store.commit("openSettingsModal");
      var audioURL = "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/notifcation_1.mp3?alt=media&token=2b6dcaa0-7740-4437-be98-891adb30a870";
      var audio = new Audio(audioURL);
      audio.volume = 0;
      audio.play();
      var audioURL2 = "https://firebasestorage.googleapis.com/v0/b/bog-push-notifications.appspot.com/o/phoneBuzz.mp3?alt=media&token=17cdb20b-ba2e-42d4-9eca-aed88e26cfd1";
      var audio2 = new Audio(audioURL2);
      audio2.volume = 0;
      audio2.play();
      //This preloads the audio for the settings toggle, so that its not delayed by 1-3 sec.
    },
  },
  computed: {
    isOpen: function() {
      return this.$store.getters.sidebarOpen;
    },    
    ...mapGetters({ theme: "getTheme" }),
  },
  watch: {
    theme(newTheme, oldTheme) {
      newTheme === "light" ? document.querySelector("html").classList.remove("dark") : document.querySelector("html").classList.add("dark");
    },
    "$store.state.token": function() {
      this.token = this.$store.state.token;
    },
  },
};
</script>
