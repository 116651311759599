<template>
  <not-available v-if="!onSupportedChain" />
  <div v-else class="container mx-auto my-8 px-4">
    <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">Upcoming Launches</h1>
    <h3 class="text-gray-500 text-xl mb-10">
      Find the best launches on <span :class="$store.state.chain == 'bsc' ? 'uppercase' : 'capitalize'">{{ $store.state.chain.toUpperCase() }}</span>
    </h3>
    <launch-cards class="mt-8" />
  </div>
  <!--
  <warning-modal @close="showWalletModal = false" />
  -->
</template>

<script>
import launchCards from "./components/launchCards.vue";
import warningModal from "./components/warningModal.vue";
import Popper from "vue3-popper";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";

export default {
  components: {
    Popper,
    launchCards,
    warningModal,
    notAvailable,
  },
  computed: {
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("upcomingLaunches");
    },
  },
};
</script>
