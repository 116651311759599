<template>
  <div
    class="tradebookRow font-mono text-xs py-0.5 font-light items-center flex flex-row"
    v-bind:class="{
      'text-gray-500': isArbitrage,
      'dark:text-secondary text-secondary-dark hover:bg-secondary-dark dark:hover:bg-secondary dark:hover:text-secondary hover:bg-opacity-70 dark:hover:bg-opacity-20 he-bought': !isSell && !isArbitrage,
      'dark:text-error-bright text-error hover:bg-error dark:hover:bg-error dark:hover:text-error-bright hover:bg-opacity-70 dark:hover:bg-opacity-20 he-sold': isSell && !isArbitrage,
    }"
  >
    <div class="userTradeTime flex items-center p-2">
      <div class="md:hidden flex flex-row space-x-1 mr-2">
        <div v-if="!isSell" :class="value < 1000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
        <div v-else :class="value < 1000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
        <div v-if="!isSell" :class="value < 5000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
        <div v-else :class="value < 5000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
        <div v-if="!isSell" :class="value < 10000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
        <div v-else :class="value < 10000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
      </div>
      <a class="flex flex-row items-center space-x-2" rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
        <svg width="12" height="12" class="md:inline-block hidden" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            v-if="!isSell"
            d="M5.168 2.9455L2.168 7.4455C2.0565 7.6125 2 7.806 2 8C2 8.162 2.039 8.324 2.1185 8.472C2.2925 8.797 2.631 9 3 9L9 9C9.369 9 9.7075 8.797 9.8815 8.472C9.961 8.324 10 8.162 10 8C10 7.806 9.9435 7.6125 9.832 7.445L6.832 2.945C6.6465 2.667 6.3345 2.5 6 2.5C5.6655 2.5 5.3535 2.667 5.168 2.9455Z"
            v-bind:fill="isArbitrage ? '#61738e' : '#1BC870'"
          />
          <path
            v-if="isSell"
            d="M6.832 9.0545L9.832 4.5545C9.9435 4.3875 10 4.194 10 4C10 3.838 9.961 3.676 9.8815 3.528C9.7075 3.203 9.369 3 9 3L3 3C2.631 3 2.2925 3.203 2.1185 3.528C2.039 3.676 2 3.838 2 4C2 4.194 2.0565 4.3875 2.168 4.555L5.168 9.055C5.3535 9.333 5.6655 9.5 6 9.5C6.3345 9.5 6.6465 9.333 6.832 9.0545Z"
            v-bind:fill="isArbitrage ? '#61738e' : '#FF6A6A'"
          />
        </svg>
        <div class="flex flex-col">
          {{ shortFromNow }}
          <span class="text-2xs text-gray-500" v-if="!isArbitrage">
            {{ pool }}
          </span>
        </div>
      </a>
    </div>
    <div class="userTradeTraded flex items-center p-2">
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
        <span class="flex flex-col" v-if="!isArbitrage">
          <span v-html="bogintific(tokenAmount)"></span><span class="dark:text-gray-500 text-2xs text-gray-400 sm:inline-block hidden">{{ token }}</span>
        </span>

        <span v-else>
          ARB
        </span>
      </a>
    </div>
    <div class="userTradePrice flex items-center p-2">
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
        <span v-if="!isArbitrage">${{ maxDigits(value / tokenAmount, 3) }} </span>
        <span v-else>
          ARB
        </span>
      </a>
    </div>

    <div class="userTradeValue flex items-center p-2">
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
        <span class="flex flex-row items-center md:space-x-4">
          <div class="md:flex hidden flex-row space-x-1">
            <div v-if="!isSell" :class="value < 1000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
            <div v-else :class="value < 1000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
            <div v-if="!isSell" :class="value < 5000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
            <div v-else :class="value < 5000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
            <div v-if="!isSell" :class="value < 10000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'" class="w-1 h-4 rounded-sm"></div>
            <div v-else :class="value < 10000 ? 'dark:bg-gray-800 bg-gray-200' : 'dark:bg-error-bright bg-error'" class="w-1 h-4 rounded-sm"></div>
          </div>
          <div class="flex flex-col">
            {{ priceMinDigits(value, 3) }}
            <span v-if="this.$store.state.chain == 'bsc'" class="text-2xs text-gray-500" v-html="maxDigits(value / $store.state.masterTokenPrice, 3) + ' BNB'"></span>
          </div>
        </span>
      </a>
    </div>

    <div class="userTradeChange flex items-center p-2">
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
        <span class="py-1 text-2xs px-1.5 rounded-md bg-opacity-10" v-bind:class="{ 'dark:text-secondary bg-secondary text-secondary-dark': pctChange >= 0, 'dark:text-error-bright bg-error-bright text-error': pctChange < 0 }">
          {{ pctChange }} %</span
        >
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { priceMinDigits, maxDigits, bogintific } from "@/filters";
export default {
  props: ["token", "tokenAmount", "isSell", "date", "value", "id", "pool", "txHash", "isArbitrage", "arbPools", "pctChange"],
  setup() {},
  data: function() {
    return {
      fromNow: "",
      intervalID: null,
    };
  },
  methods: {
    priceMinDigits,
    maxDigits,
    bogintific,
  },
  computed: {
    shortFromNow() {
      return this.$data.fromNow
        .replace("a few seconds ago", "Just now")
        .replace(" seconds", "s")
        .replace(" minutes", "m")
        .replace(" minute", "m")
        .replace("am", "1m")
        .replace(" hours", "h")
        .replace("an hour", "1h")
        .replace("a day", "1d")
        .replace(" days", "d");
    },
  },
  async created() {
    // moment.relativeTimeThreshold('ss', 3);  // enable this if you want to show "5 seconds ago" etc
    window.moment = moment;
    let self = this; // to pass to setInterval
    // set initial fromNow
    let mo = moment(self.$props.date).fromNow();
    self.$data.fromNow = mo;
    // update it periodically
    this.$data.intervalID = setInterval(function() {
      let mo = moment(self.$props.date).fromNow();
      self.$data.fromNow = mo;
    }, 5000);
  },
};
</script>
