import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "web3";
import { dragscrollNext } from "vue-dragscroll";
import VueGtag from "vue-gtag";
import i18n from "./i18n";
import VueApexCharts from "vue3-apexcharts";
import VueTippy from "vue-tippy";
import lodash from "lodash";
import VueGridLayout from "vue-grid-layout";
import "tippy.js/animations/shift-toward-subtle.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import "@boggedfinance/bogged-components/dist/bogged-components.css";
const app = createApp(App);

import "./assets/style.css";
import "./assets/main.css";

// Button "ripple" effect
import VWave from "v-wave";

import toggleFavorite from "@/components/Favorite/toggleFavorite";
app.component("toggleFavorite", toggleFavorite);

import miniSpinner from "@/components/miniSpinner.vue";

app.component("miniSpinner", miniSpinner);

var css0 = "color: #FDC113;" + "font-size: 48px;" + "font-weight: bold;" + "font-family: 'Jost', sans-serif;";
console.log("%cBogTools", css0);
var css1 = "color: #041133;" + "font-size: 12px;" + "font-family: 'Jost', sans-serif;";
console.log("%cCopyright © BogTools & Bogged.Finance 2021. \nTo view open source code, content and ABI's, visit github.com/bogtools or contact us for licensing. You may embed our site as an iframe.", css1);





createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueApexCharts)
  .use(Donut)
  .use(lodash)
  .use(VueGridLayout)
  .directive("dragscroll", dragscrollNext)
  .use(VueGtag, { config: { id: "G-C4XV890JCM" } })
  .use(VWave, {
    initialOpacity: 0.3,
    easing: "ease-in",
  })
  .use(VueTippy)
  .use(Toast, { containerClassName: "custom-toast" })
  .use(VueVirtualScroller)
  .mount("#app");
