<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 64 64">
    <path fill="#E2761B" stroke="#E2761B" stroke-linecap="round" stroke-linejoin="round" d="M55.06 7.131L35.075 21.976l3.696-8.758L55.06 7.13z" />
    <path
      fill="#E4761B"
      stroke="#E4761B"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M8.92 7.131l19.826 14.986-3.516-8.9L8.92 7.132zM47.87 41.542l-5.324 8.155 11.39 3.134 3.274-11.108-9.34-.181zM6.81 41.723l3.254 11.108 11.39-3.134-5.323-8.155-9.321.18z"
    />
    <path
      fill="#E4761B"
      stroke="#E4761B"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M20.811 27.762l-3.174 4.8 11.31.503-.402-12.153-7.734 6.85zM43.169 27.762l-7.834-6.991-.261 12.294 11.289-.502-3.194-4.802zM21.454 49.697l6.79-3.314-5.866-4.58-.924 7.895zM35.736 46.383l6.81 3.314-.944-7.894-5.866 4.58z"
    />
    <path fill="#D7C1B3" stroke="#D7C1B3" stroke-linecap="round" stroke-linejoin="round" d="M42.546 49.697l-6.81-3.314.543 4.44-.06 1.867 6.327-2.993zM21.454 49.697l6.328 2.993-.04-1.868.502-4.44-6.79 3.315z" />
    <path fill="#233447" stroke="#233447" stroke-linecap="round" stroke-linejoin="round" d="M27.882 38.87l-5.665-1.667 3.998-1.828 1.667 3.495zM36.098 38.87l1.667-3.495 4.018 1.828-5.685 1.667z" />
    <path
      fill="#CD6116"
      stroke="#CD6116"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M21.454 49.697l.964-8.155-6.287.18 5.323 7.975zM41.582 41.542l.964 8.155 5.323-7.975-6.287-.18zM46.363 32.563l-11.29.502 1.045 5.805 1.667-3.495 4.018 1.828 4.56-4.64zM22.217 37.203l4.018-1.828 1.647 3.495 1.065-5.805-11.31-.502 4.58 4.64z"
    />
    <g>
      <path
        fill="#E4751F"
        stroke="#E4751F"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.637 32.563l4.74 9.24-.16-4.6-4.58-4.64zM41.803 37.203l-.201 4.6 4.76-9.24-4.56 4.64zM28.947 33.065l-1.065 5.805 1.326 6.85.301-9.02-.562-3.635zM35.073 33.065l-.542 3.616.241 9.04 1.346-6.85-1.045-5.806z"
      />
    </g>
    <path fill="#F6851B" stroke="#F6851B" stroke-linecap="round" stroke-linejoin="round" d="M36.118 38.87l-1.346 6.85.964.663 5.866-4.58.2-4.6-5.684 1.667zM22.217 37.203l.16 4.6 5.867 4.58.964-.663-1.326-6.85-5.665-1.667z" />
    <path fill="#C0AD9E" stroke="#C0AD9E" stroke-linecap="round" stroke-linejoin="round" d="M36.218 52.69l.06-1.868-.502-.442h-7.573l-.462.442.04 1.868-6.327-2.993 2.21 1.808 4.48 3.114h7.693l4.5-3.114 2.21-1.808-6.329 2.993z" />
    <path fill="#161616" stroke="#161616" stroke-linecap="round" stroke-linejoin="round" d="M35.736 46.383l-.964-.663h-5.564l-.964.663-.503 4.44.462-.443h7.574l.502.442-.543-4.44z" />
    <g>
      <path
        fill="#763D16"
        stroke="#763D16"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M55.905 22.94l1.707-8.196-2.551-7.613-19.325 14.343 7.433 6.288 10.506 3.073 2.33-2.712-1.004-.723 1.607-1.466-1.246-.965 1.607-1.225-1.064-.804zM6.388 14.745l1.707 8.195-1.084.804 1.607 1.225-1.226.964L9 27.4l-1.004.723 2.31 2.712 10.506-3.073 7.433-6.288L8.919 7.131l-2.531 7.614z"
      />
    </g>
    <path
      fill="#F6851B"
      stroke="#F6851B"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M53.675 30.835L43.169 27.76l3.194 4.801-4.76 9.24 6.267-.08h9.34l-3.535-10.887zM20.811 27.761l-10.506 3.074L6.81 41.722h9.32l6.248.08-4.74-9.24 3.173-4.8zM35.073 33.065l.663-11.591 3.054-8.256H25.23l3.014 8.256.703 11.59.24 3.657.02 8.999h5.565l.04-9 .261-3.655z"
    />
  </svg>
</template>
