<template>
  <div
    v-if="$store.state.showCircSupply && $store.state.showCircSupply != null"
    class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4"
  >
    <span class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z" />
        <path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z" />
        <path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z" /></svg
    ></span>
    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        >Circ. supply
        <div class="whitespace-normal">
          <InformationCircleIcon
            v-tippy="{
              placement: 'bottom',
              content:
                $store.state.tokenSymbol +
                ' ' +
                $t('charts.components.infobar.tokeninfo.xsupplyof') +
                ' ' +
                bogintific(Math.floor($store.state.tokenSupply), this.$store.state.useTruncatedBogintific) +
                ' ' +
                $t('charts.components.infobar.tokeninfo.xcircsupply') +
                ' ' +
                bogintific(Math.floor($store.state.tokenCirculatingSupply), this.$store.state.useTruncatedBogintific),
              allowHTML: true,
              appendTo: 'parent',
              theme: 'bog',
              animation: 'shift-toward-subtle',
              arrow: false,
              maxWidth: '350px',
            }"
            class="h-4 w-4 text-gray-500 ml-1"
            aria-hidden="true"
          /></div
      ></span>

      <div id="textfade" v-if="$store.state.tokenCirculatingSupply">
      <h4
        v-if="$store.state.tokenCirculatingSupply"
        v-html="bogintific(Math.floor($store.state.tokenCirculatingSupply), this.$store.state.useTruncatedBogintific)"
        class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg"
      ></h4>
      </div>
      <div
        v-else
        class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable skeleton-loader"
      ></div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
