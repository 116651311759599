<template>
  <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': value >= 0, 'dark:text-error-bright text-error': value < 0 }" class="fade-in flex flex-row items-center text-xs">
    <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': value >= 0 }" class="h-4 w-4" aria-hidden="true" />
    <span class="text-sm">{{ value }}%</span>
  </div>
</template>

<script>
import { ArrowSmDownIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ArrowSmDownIcon,
  },
  props: ["value"],
  data() {
    return {};
  },
};
</script>
