<template>
  <div class="w-full">
    <TabGroup>
      <div class="overflow-x-auto block whitespace-nowrap">
        <TabList class="flex space-x-2">
          <Tab v-for="tab in Object.keys(tabs)" as="template" :key="tab" v-slot="{ selected }">
            <button
              :class="['py-1.5 px-3 text-sm transition-colors duration-200  rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10', selected ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-900 hover:text-white']"
            >
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>

      <TabPanels class="h-full">
        <TabPanel class="h-full" v-for="(tabs, idx) in Object.values(tabs)" :key="idx">
          <div v-for="tab in tabs" v-bind:key="tab.id" class="relative h-full">
            <component v-bind:is="tab.content" class="tab"></component>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import activeAlerts from "./activeAlerts";
import triggeredAlerts from "./triggeredAlerts.vue";
import { ref } from "vue";

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    activeAlerts,
    triggeredAlerts,
  },
  setup() {
    let tabs = ref({
      Active: [
        {
          id: 1,
          content: "activeAlerts",
        },
      ],
      Triggered: [
        {
          id: 2,
          content: "triggeredAlerts",
        },
      ],
    });

    return { tabs };
  },
};
</script>
