<template>
  <div class="relative mt-4 w-full z-0 inline-flex place-content-center shadow-sm">
    <button
      @click="alertMode = 'Percentage'"
      type="button"
      :class="alertMode == 'Percentage' ? 'dark:text-white dark:bg-gray-700 text-gray-900 bg-gray-200' : 'dark:hover:bg-opacity-70 dark:text-gray-300  dark:bg-gray-900 hover:bg-opacity-70 text-white bg-gray-500'"
      class="w-full relative inline-flex items-center place-content-center px-4 py-2 rounded-l-md text-sm font-medium text-white focus:z-10 focus:outline-none"
    >
      %
    </button>
    <button
      @click="alertMode = 'Dollar'"
      type="button"
      :class="alertMode == 'Dollar' ? 'dark:text-white dark:bg-gray-700 text-gray-900 bg-gray-200' : 'dark:hover:bg-opacity-70 dark:text-gray-300  dark:bg-gray-900 hover:bg-opacity-70 text-white bg-gray-500'"
      class="w-full relative inline-flex items-center place-content-center px-4 py-2 rounded-r-md text-sm font-medium text-white focus:z-10 focus:outline-none"
    >
      $
    </button>
  </div>

  <div v-if="alertMode == 'Percentage'" class="relative mt-4 max-w-xs w-full mx-auto z-0 flex shadow-sm">
    <button
      @click="percentageChangeType = 'Increase'"
      type="button"
      :class="percentageChangeType == 'Increase' ? 'text-secondary border-opacity-25 border-secondary bg-secondary bg-opacity-20' : 'dark:hover:bg-opacity-70 hover:bg-opacity-70 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-900'"
      class="w-full relative inline-flex items-center place-content-center px-4 py-2 rounded-l-full text-sm font-medium focus:z-10 focus:outline-none"
    >
      Increase
    </button>

    <button
      @click="percentageChangeType = 'Decrease'"
      type="button"
      :class="percentageChangeType == 'Decrease' ? 'text-error-bright border-opacity-25 border-error-bright bg-error-bright bg-opacity-20' : 'dark:hover:bg-opacity-70 hover:bg-opacity-70 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-900'"
      class="w-full relative inline-flex items-center place-content-center px-4 py-2 rounded-r-full text-sm font-medium focus:z-10 focus:outline-none"
    >
      Decrease
    </button>
  </div>

  <div v-if="alertMode == 'Percentage'" class="mt-8">
    <div class="flex flex-row text-sm font-medium dark:text-gray-200 text-gray-700 justify-between">
      <label>Alert Me When {{this.$store.state.tokenSymbol}} Price {{ percentageChangeType }}s </label>
      <span>Current price: ${{ maxDigits($store.state.tokenPrice, 5) }}</span>
    </div>
    <div class="mt-1 rounded-md shadow-sm flex">
      <input v-model="percentageChange" type="text" class="dark:focus:border-gray-600 focus:border-gray-200 text-gray-900 dark:text-white focus:ring-0 flex-grow block w-full min-w-0 bg-gray-100 dark:bg-gray-900 rounded-none rounded-l-md sm:text-sm border-gray-200 dark:border-gray-700" />
      <span class="dark:bg-gray-900 bg-gray-200 border border-l-0 dark:border-gray-700 border-gray-200 rounded-r-md px-3 inline-flex items-center text-gray-600 dark:text-gray-500 sm:text-sm">
        %
      </span>
    </div>
  </div>

  <div v-else-if="alertMode == 'Dollar'" class="mt-8">
    <label  class="block text-sm font-medium dark:text-gray-200 text-gray-700">Alert Me When {{this.$store.state.tokenSymbol}} Price Crosses</label>
    <!-- <label v-else-if="this.dollarChange <= this.$store.state.tokenPrice" class="block text-sm font-medium text-gray-200">When price is equal or smaller than</label>
    <label v-else-if="this.dollarChange >= this.$store.state.tokenPrice" class="block text-sm font-medium text-gray-200">When price is equal or greater than</label> -->
    <div class="mt-1 rounded-md shadow-sm flex">
      <input v-model="dollarChange" type="text" class="dark:focus:border-gray-600 focus:border-gray-200 text-gray-900 dark:text-white focus:ring-0 flex-grow block w-full min-w-0 bg-gray-100 dark:bg-gray-900 rounded-none rounded-l-md sm:text-sm border-gray-200 dark:border-gray-700" />
      <span class="dark:bg-gray-900 bg-gray-200 border border-l-0 dark:border-gray-700 border-gray-200 rounded-r-md px-3 inline-flex items-center text-gray-600 dark:text-gray-500 sm:text-sm">
        $
      </span>
    </div>
  </div>
  <div class="mt-4">
    <span v-html="'Trigger Price: $' + maxDigits(this.calculateAlert, 5)" v-if="alertMode == 'Percentage'" class="dark:text-white text-gray-900 text-sm"></span>
    <span v-html="'Percentage change: ' + maxDigits(this.calculatePercentageChange, 5) + '%'" v-else-if="alertMode == 'Dollar'" class="dark:text-white text-gray-900 text-sm"></span>
  </div>

  <button 
    @click="createAlert()" 
    class="w-full py-4 bg-opacity-10 text-secondary bg-secondary hover:bg-opacity-20 rounded-lg mt-4"
    v-if="(alertMode === 'Percentage' ? percentageChangeValid : dollarChangeValid) "
    >Create Alert
  </button>

  <div class="mt-4 text-center text-error-bright" v-if="priorityError">{{priorityError}}</div>
</template>

<script>
import { maxDigits, bogintificPrice } from "@/filters";
import { Services } from '@/services';

const inputRegexTest = /^(0|[1-9]\d*)(\.\d+)?$/;

export default {
  props: [ 'toggleCreateAlert' ],
  components: {},
  data() {
    return {
      alertMode: "Percentage",
      percentageChangeType: "Increase",
      percentageChange: "",
      dollarChange: "",
      percentageChangeValid: false,
      dollarChangeValid: false,
      priorityError: ""
    };
  },
  watch: {
    percentageChange(val){
      this.percentageChangeValid = !!val.match(inputRegexTest);
    },
    dollarChange(val){
     this.dollarChangeValid = !!val.match(inputRegexTest);
    }
  },
  methods:{
    async createAlert() { 
      

      const priorityCheck = Services.evaluatePriceAlertScale(this.$store.state.priceAlerts.length, this.$store.state.bogPriority);
      if(!priorityCheck.valid){
        this.priorityError = priorityCheck.error;
        return;
      }else{
        this.priorityError = "";
      }

      const alertPrice = parseFloat(this.calculateAlert);
      const alert = {
         token: this.$store.state.token,
         priceUSD: alertPrice,
         sign: alertPrice > this.$store.state.tokenPrice ? ">" : "<",
         symbol: this.$store.state.tokenSymbol,
         chain: this.$store.state.chain === "polygon" ? "matic" : this.$store.state.chain
      }
      
      this.$store.dispatch("createPriceAlert",alert)
      this.toggleCreateAlert();

    },
     maxDigits,
    bogintificPrice,
  },
  computed: {
    calculateAlert: function() {
      if (this.alertMode === "Percentage") {
        if (this.percentageChangeType === "Increase") {
          return (1 + this.percentageChange / 100) * this.$store.state.tokenPrice;
        } else if (this.percentageChangeType === "Decrease") {
          return (1 - this.percentageChange / 100) * this.$store.state.tokenPrice;
        }
      }
      if (this.alertMode === "Dollar") {
        return this.dollarChange;
      }
    },
    calculatePercentageChange: function() {
      if (this.dollarChange) {
        return ((this.dollarChange - this.$store.state.tokenPrice) / this.$store.state.tokenPrice) * 100;
      }
    },
  },

};
</script>
