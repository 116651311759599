<template>

  <not-available v-if="!onSupportedChain" />
  <div v-else class="container mx-auto my-8 px-4">
    <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">
      Top Gainers and Losers on <span :class="$store.state.chain == 'bsc' ? 'uppercase' : 'capitalize'">{{ $store.state.chain.toUpperCase() }}</span>
    </h1>
    <h3 class="text-gray-500 text-xl mb-10">Today's best and worst performing tokens</h3>
    <div class="mt-8 flex overflow-x-auto flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
      <gainers />
      <losers />
    </div>
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import Gainers from "./components/Gainers.vue";
import Losers from "./components/Losers.vue";
import Popper from "vue3-popper";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";

export default {
  components: {
    bogintificPrice,
    maxDigits,
    Popper,
    Gainers,
    Losers,
    notAvailable,
  },
  computed: {
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("gainerLosers");
    },
  },
  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
