<template>
  <div v-if="articles.length" class="relative grabbable">
    <div class="z-10 right-0 h-full top-0 absolute w-24 bg-gradient-to-r from-transparent to-background dark:to-gray-900"></div>
    <swiper
      :breakpoints="{
        '640': {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 2.5,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3.5,
          spaceBetween: 48,
        },
        '1440': {
          slidesPerView: 4.5,
          spaceBetween: 48,
        },
      }"
      :slides-per-view="2.2"
      :space-between="12"
      :loop="true"
    >
      <swiper-slide v-for="article in articles" v-bind:key="article.sys.id">
        <img class="rounded-2xl overflow-hidden" v-bind:src="article.coverImage.url" />
        <div class="mt-3">
          <a :href="article.link">
            <div class="overline text-gray-400">{{ article.category }}</div>
            <div class="dark:text-white text-gray-900 truncate overflow-ellipsis max-w-full text-lg font-heading">{{ article.title }}</div>
          </a>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <div v-else class="flex space-x-10 mb-4 flex-row">
    <swiper
      :breakpoints="{
        '640': {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 2.5,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3.5,
          spaceBetween: 48,
        },
        '1440': {
          slidesPerView: 4.5,
          spaceBetween: 48,
        },
      }"
      :slides-per-view="1"
      :space-between="32"
      :loop="true"
    >
      <swiper-slide v-for="index in 10" :key="index">
        <div style="height: 190px;" class="bg-gray-200 dark:bg-gray-800 rounded-md w-full animate-pulse-disable skeleton-loader"></div>
        <div style="height: 12px;" class="bg-gray-200 dark:bg-gray-800 w-24 rounded-md my-3 animate-pulse-disable skeleton-loader"></div>
        <div style="height: 24px;" class="bg-gray-200 dark:bg-gray-800 rounded-md w-full animate-pulse-disable skeleton-loader"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, SwiperNavigation } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

export default {
  data() {
    return {
      articles: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    SwiperNavigation,
  },
  async created() {
    this.articles = await this.getArticles();
  },
  methods: {
    getArticles: async () => {
      const query = `{
  articleCollection {
    items {
      sys {
        id
      }
      title
      coverImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      category
      link
    }
  }
}`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) => response.json());
        return response.data.articleCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
};
</script>

<style></style>
