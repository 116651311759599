<template>
  <not-available v-if="$store.state.chain != 'bsc'" />  
  <div v-if="$store.state.chain == 'bsc'" style="height:calc(100% - 33px)" class="overflow-x-auto overflow-y-hidden">
    <div style="height: 33px;" class="tableHeader md:block hidden w-full">
      <div style="padding-right: 10px;" class="flex w-full dark:text-gray-400 font-semibold md:text-xs text-2xs text-gray-600 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800 text-left font-heading">
        <div class="holderBalance flex whitespace-nowrap p-2">Balance</div>
        <div class="holderSupply flex whitespace-nowrap p-2">Percentage</div>
        <div class="holderAddress flex whitespace-nowrap p-2 pl-2">Address</div>
      </div>
    </div>

    <div v-if="$store.state.top100" id="tabUserTradesWrapper" class="w-full overflow-y-scroll bg-gray-50 dark:bg-gray-900 dark:bg-opacity-50">
      <template v-if="isMobile">
        <holders-row-mobile />
      </template>
      <template v-else>
        <holders-row />
      </template>
    </div>

    <div id="tabUserTradesWrapper" v-else class="flex flex-col h-full w-full items-center place-content-center z-20">
      <mini-spinner />
      <span class="text-gray-500 mt-3 overline block">Loading Holders</span>
    </div>
  </div>
</template>

<script>
import holdersRow from "./holdersRow.vue";
import holdersRowMobile from "./holdersRowMobile.vue";
import miniSpinner from "@/components/miniSpinner.vue";
import notAvailable from "@/components/notAvailable";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: { notAvailable, holdersRow, holdersRowMobile, miniSpinner },
  computed: {
    isMobile() {
      return this.windowWidth <= 768;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>
