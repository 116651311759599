<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 41C35.598 41 45 33.3888 45 24C45 14.6112 35.598 7 24 7C12.402 7 3 14.6112 3 24C3 33.3888 12.402 41 24 41Z" fill="url(#paint0_radial_1219_1249)" />
    <path d="M24 38C31.732 38 38 31.732 38 24C38 16.268 31.732 10 24 10C16.268 10 10 16.268 10 24C10 31.732 16.268 38 24 38Z" fill="#65C8FF" />
    <path d="M24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17C20.134 17 17 20.134 17 24C17 27.866 20.134 31 24 31Z" fill="url(#paint1_radial_1219_1249)" />
    <path d="M19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z" fill="white" />
    <defs>
      <radialGradient id="paint0_radial_1219_1249" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.1407 18.6691) scale(22.2842 22.5494)">
        <stop offset="0.55" stop-color="#E4E4E6" />
        <stop offset="0.73" stop-color="#E1E2E4" />
        <stop offset="0.854" stop-color="#D8DADC" />
        <stop offset="0.961" stop-color="#C9CDCF" />
        <stop offset="1" stop-color="#C1C6C9" />
      </radialGradient>
      <radialGradient id="paint1_radial_1219_1249" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.876 13.758) scale(18.169)">
        <stop stop-color="#4B4B4B" />
        <stop offset="0.531" stop-color="#393939" />
        <stop offset="1" stop-color="#252525" />
      </radialGradient>
    </defs>
  </svg>
</template>
