<template>
  <tableContainer>
    <tableHeader v-if="sortTable">
      <headerCell
        @click="
          sortType = 'tokenIndex';
          isReverse = !isReverse;
        "
        :name="'Position'"
        :sortType="sortType"
        :sortBy="'tokenIndex'"
        :isReverse="isReverse"
      />
      <headerCell
        @click="
          sortType = 'mcap';
          isReverse = !isReverse;
        "
        :name="'Mcap'"
        :sortType="sortType"
        :sortBy="'mcap'"
        :isReverse="isReverse"
      />
      <headerCell
        @click="
          sortType = 'change_pct_24h';
          isReverse = !isReverse;
        "
        :name="'24h Change'"
        :sortType="sortType"
        :sortBy="'change_pct_24h'"
        :isReverse="isReverse"
      />
      <headerCell
        @click="
          sortType = 'change_pct_7d';
          isReverse = !isReverse;
        "
        :name="'7d Change'"
        :sortType="sortType"
        :sortBy="'change_pct_7d'"
        :isReverse="isReverse"
      />
      <headerCell
        @click="
          sortType = 'trustscore';
          isReverse = !isReverse;
        "
        :name="'Trust score'"
        :sortType="sortType"
        :sortBy="'trustscore'"
        :isReverse="isReverse"
        :tooltip="'Trust score is calculated from a number of factors including: token age, liquidity, associated rugpulls, source code analysis, holder distribution and more'"
      />

      <headerCell :name="'Last 7 days'" />
    </tableHeader>

    <tableBody v-if="sortTable.length">
      <Row v-for="item in sortTable" :key="item">
        <dataCell :type="'token'" :tokenImageUrl="item.tokenImageUrl" :address="item.address" :tokenName="item.tokenName" :tokenIndex="item.tokenIndex" :price="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" />
        <dataCell :type="'text'" :value="bogintificPrice(item.mcap, $store.state.useTruncatedBogintific)" />
        <dataCell :type="'percentage'" :value="item.change_pct_24h" />
        <dataCell :type="'percentage'" :value="item.change_pct_7d" />
        <dataCell :type="'trustScore'" :value="item.trustscore" />
        <dataCell :type="'graph'" :value="'https://cdn.bogged.finance/' + $store.state.chain + '/_minicharts/' + item.address + '.png'" v-if="item.address" />
      </Row>
    </tableBody>

    <div v-else class="w-full space-y-4">
      <div v-for="index in 8" :key="index">
        <div class="bg-gray-200 dark:bg-gray-800 rounded-md w-full h-20 animate-pulse-disable skeleton-loader"></div>
      </div>
    </div>
  </tableContainer>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import dataCell from "@/components/Table/dataCell";
import Row from "@/components/Table/Row";
import tableBody from "@/components/Table/tableBody";
import tableHeader from "@/components/Table/tableHeader";
import headerCell from "@/components/Table/headerCell";
import tableContainer from "@/components/Table/tableContainer";

export default {
  data: function() {
    return {
      sortType: "",
      isReverse: false,
    };
  },
  components: {
    dataCell,
    Row,
    tableBody,
    tableHeader,
    headerCell,
    tableContainer,
  },
  methods: {
    bogintificPrice,
    maxDigits,
  },
  computed: {
    sortTable: function() {
      var app = this;
      this.$store.state.mostViewedDailyTokens.sort(function(a, b) {
        if (a[app.sortType] < b[app.sortType]) {
          if (app.isReverse) return 1;
          else return -1;
        } else {
          if (app.isReverse) return -1;
          else return 1;
        }
      });
      return this.$store.state.mostViewedDailyTokens;
    },
  },
};
</script>
