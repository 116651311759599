<template>
  <div class="flex flex-row relative rounded-lg dark:bg-gray-800 bg-white h-8">
    <div class="relative flex flex-row w-full">
      <div
        class="flex items-center h-8 select-none px-3 text-2xs uppercase font-bold text-gray-400 dark:text-gray-500 z-20 rounded-l-lg"
      >
        <div class="flex flex-row text-gray-300 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 fill-current mr-1" viewBox="0 0 384 512">
            <path
              d="M384 319.1C384 425.9 297.9 512 192 512s-192-86.13-192-192c0-58.67 27.82-106.8 54.57-134.1C69.54 169.3 96 179.8 96 201.5v85.5c0 35.17 27.97 64.5 63.16 64.94C194.9 352.5 224 323.6 224 288c0-88-175.1-96.12-52.15-277.2c13.5-19.72 44.15-10.77 44.15 13.03C215.1 127 384 149.7 384 319.1z"
            />
          </svg>

          <span class="font-bold md:block hidden">Hot</span>
        </div>
      </div>

      <dragscroll-custom class="w-full overflow-hidden mx-auto">
        <div class="flex flex-row space-x-3 h-8">
          <div v-for="(item, i) in $store.state.trendingTokens.slice(0, 10)" class="flex fade-in">
          <router-link
            v-if="i !== 0 && item.tokenName !== 'Octavia'"
            :to="'/?t=' + item.address + '&c=' + thisChain"
            v-on:click="$store.dispatch('switchChain', { chain: $store.state.chain, token_to_load: item.address })"
            :key="'promoted-promoted-' + i"
            class="flex w-full items-center"
          >
              <img
                v-bind:src="item.tokenImageUrl"
                @error="altSymbol"
                class="rounded-full lg:block hidden h-5 w-5 dark:bg-gray-900 bg-gray-200 bg-opacity-50 p-1"
              />
              <div
                :class="{
                  'dark:text-gold text-gold-dark': i === 0,
                  'dark:text-gray-500 text-gray-400': i > 0,
                }"
                class="text-2xs pl-1"
              >
                #{{ i + 1 }}
              </div>

              <div
                :class="{
                  'dark:text-gold text-gold-dark': i === 0,
                  'text-gray-900 dark:text-white ': i > 0,
                }"
                class="px-1 text-2xs cursor-pointer whitespace-nowrap"
              >
                {{ item.tokenSymbol }}
              </div>
      
          </router-link>
          <div
            v-else
            
            v-on:click="$store.dispatch('switchChain', { chain: $store.state.chain, token_to_load: item.address })"
            :key="'promoted-promoted-' + i"
            class="flex w-full items-center"
          > <div
                :class="{
                  'dark:text-gold text-gold-dark': i === 0,
                  'dark:text-gray-500 text-gray-400': i > 0,
                }"
                class="text-2xs pl-1"
              >
                #{{ i + 1 }}
              </div><a href="https://octavia.one/" class="rounded-full block  h-5 w-5 dark:bg-gray-900 bg-gray-200 bg-opacity-50 p-1">
              <img
                v-bind:src="item.tokenImageUrl"
                @error="altSymbol"
               
              />
          </a>

              <div
                :class="{
                  'dark:text-gold text-gold-dark': i === 0,
                  'text-gray-900 dark:text-white ': i > 0,
                }"
                class="px-1 text-2xs cursor-pointer whitespace-nowrap"
              >
                <a href="https://octavia.one/"><b>Octavia</b> - The web3 AI assistant</a>
              </div>
             
          


              <a content="Telegram" target="_blank" v-tippy="{delay : 1000}"  href="https://t.me/octaviaai" class="rounded-full block  h-5 w-5 bg-opacity-50 opacity-80 hover:opacity-50 p-1">
                <svg fill="#ffffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xml:space="preserve" stroke="#ffffff"><g  stroke-width="0"></g><g istroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">  <path d="M19.2,4.4L2.9,10.7c-1.1,0.4-1.1,1.1-0.2,1.3l4.1,1.3l1.6,4.8c0.2,0.5,0.1,0.7,0.6,0.7c0.4,0,0.6-0.2,0.8-0.4 c0.1-0.1,1-1,2-2l4.2,3.1c0.8,0.4,1.3,0.2,1.5-0.7l2.8-13.1C20.6,4.6,19.9,4,19.2,4.4z M17.1,7.4l-7.8,7.1L9,17.8L7.4,13l9.2-5.8 C17,6.9,17.4,7.1,17.1,7.4z" ></path></g></svg>
              </a> 
              <a  content="Discord" target="_blank" v-tippy="{delay : 1000}"  href="https://discord.gg/octavia" class="rounded-full block h-5 w-5 opacity-80 hover:opacity-50  bg-opacity-50 p-1">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g  stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z" fill="#ffffff"></path> </g></svg>              </a>
              <a   content="Website" target="_blank" v-tippy="{delay : 1000}"  href="https://octavia.one" class="rounded-full hidden lg:block  h-5 w-5 opacity-80 hover:opacity-50  bg-opacity-50 p-1">
                <svg viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 22.3201C17.5228 22.3201 22 17.8429 22 12.3201C22 6.79722 17.5228 2.32007 12 2.32007C6.47715 2.32007 2 6.79722 2 12.3201C2 17.8429 6.47715 22.3201 12 22.3201Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2 12.3201H22" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 22.3201C13.933 22.3201 15.5 17.8429 15.5 12.3201C15.5 6.79722 13.933 2.32007 12 2.32007C10.067 2.32007 8.5 6.79722 8.5 12.3201C8.5 17.8429 10.067 22.3201 12 22.3201Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              </a>    

              <a target="_blank" content='<div class="text-align-center" style="text-align:center"><svg viewBox="0 0 43 45" class="w-6 h-6 justify-center m-auto" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9931 5.59519L21.5 0.281738L16.0069 5.59519L8.4409 4.52447L7.11962 12.0503L0.36908 15.6336L3.72544 22.4999L0.36908 29.3639L7.11962 32.9472L8.4409 40.473L16.0069 39.4046L21.5 44.7181L26.9931 39.4046L34.5591 40.473L35.8804 32.9472L42.6309 29.3639L39.2745 22.4999L42.6309 15.6336L35.8804 12.0503L34.5591 4.52447L26.9931 5.59519ZM10.2349 23.4997L18.3374 31.6023L31.77 16.9336L28.5862 13.9861L18.1862 25.3197L13.2887 20.4223L10.2349 23.4997Z" fill="url(#paint0_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.466 7.21421L21.5 2.40894L16.534 7.21421L9.69124 6.24512L8.49761 13.0524L2.39233 16.2906L5.42724 22.4998L2.39233 28.7091L8.49761 31.9473L9.69124 38.7546L16.534 37.7855L21.5 42.5908L26.466 37.7855L33.3087 38.7546L34.5023 31.9473L40.6076 28.7091L37.5727 22.4998L40.6076 16.2906L34.5023 13.0524L33.3087 6.24512L26.466 7.21421ZM10.2325 23.4997L18.3374 31.6022L31.77 16.9335L28.5862 13.986L18.1862 25.3197L13.2887 20.4222L10.2325 23.4997Z" fill="url(#paint1_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2326 23.4998L18.3375 31.6023L31.692 17.0187L31.7818 16.9407L31.77 20.245L18.3375 34.9138L10.2326 26.8089L10.2255 23.4879L10.2373 23.495L10.2326 23.4998Z" fill="#FFA600"/>
              <defs>
              <linearGradient id="paint0_linear_72_1037" x1="5.92599" y1="2.39719" x2="38.2416" y2="47.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E8DB29"/>
              <stop offset="0.539" stop-color="#FAFF00"/>
              <stop offset="0.68" stop-color="#CB7B00"/>
              <stop offset="0.744792" stop-color="#F3C620"/>
              <stop offset="1" stop-color="#F4E72A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_72_1037" x1="8.15724" y1="4.52439" x2="34.1714" y2="41.7233" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFF6BC"/>
              <stop offset="0.406" stop-color="#FDE99E"/>
              <stop offset="0.989" stop-color="#E2B719"/>
              </linearGradient>
              </defs>
              </svg>    <strong>Octavia x Bogged</strong><br /><span class="text-sm">Octavia is a partner of Bogged, and founded by one of the co-founders of Bogged.</span><br /><br /><span class="text-xs">Audited by Hacken, KYC with Assure, Proven track record.</span></div>'  v-tippy='{ allowHTML : true }' href="https://octavia.one" class="rounded-full  block h-5 w-5  hover:opacity-50  bg-opacity-50 p-1">
               
                            <svg viewBox="0 0 43 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9931 5.59519L21.5 0.281738L16.0069 5.59519L8.4409 4.52447L7.11962 12.0503L0.36908 15.6336L3.72544 22.4999L0.36908 29.3639L7.11962 32.9472L8.4409 40.473L16.0069 39.4046L21.5 44.7181L26.9931 39.4046L34.5591 40.473L35.8804 32.9472L42.6309 29.3639L39.2745 22.4999L42.6309 15.6336L35.8804 12.0503L34.5591 4.52447L26.9931 5.59519ZM10.2349 23.4997L18.3374 31.6023L31.77 16.9336L28.5862 13.9861L18.1862 25.3197L13.2887 20.4223L10.2349 23.4997Z" fill="url(#paint0_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.466 7.21421L21.5 2.40894L16.534 7.21421L9.69124 6.24512L8.49761 13.0524L2.39233 16.2906L5.42724 22.4998L2.39233 28.7091L8.49761 31.9473L9.69124 38.7546L16.534 37.7855L21.5 42.5908L26.466 37.7855L33.3087 38.7546L34.5023 31.9473L40.6076 28.7091L37.5727 22.4998L40.6076 16.2906L34.5023 13.0524L33.3087 6.24512L26.466 7.21421ZM10.2325 23.4997L18.3374 31.6022L31.77 16.9335L28.5862 13.986L18.1862 25.3197L13.2887 20.4222L10.2325 23.4997Z" fill="url(#paint1_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2326 23.4998L18.3375 31.6023L31.692 17.0187L31.7818 16.9407L31.77 20.245L18.3375 34.9138L10.2326 26.8089L10.2255 23.4879L10.2373 23.495L10.2326 23.4998Z" fill="#FFA600"/>
              <defs>
              <linearGradient id="paint0_linear_72_1037" x1="5.92599" y1="2.39719" x2="38.2416" y2="47.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E8DB29"/>
              <stop offset="0.539" stop-color="#FAFF00"/>
              <stop offset="0.68" stop-color="#CB7B00"/>
              <stop offset="0.744792" stop-color="#F3C620"/>
              <stop offset="1" stop-color="#F4E72A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_72_1037" x1="8.15724" y1="4.52439" x2="34.1714" y2="41.7233" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFF6BC"/>
              <stop offset="0.406" stop-color="#FDE99E"/>
              <stop offset="0.989" stop-color="#E2B719"/>
              </linearGradient>
              </defs>
              </svg>     
              </a>      



              
            </div>
        </div>
        </div>
      </dragscroll-custom>

      <div class="px-2 h-8 rounded-lg flex justify-between items-center flex-shrink-0 dark:bg-gray-700 bg-gray-200">
        <router-link
          :to="'/?t=' + item.address + '&c=bsc'"
          v-for="(item, i) in $store.state.promotedTokens.slice(tokenIndex, Math.min(10, tokenIndex + tokensPerSlice))"
          v-on:click="$store.dispatch('switchChain', { chain: 'bsc', token_to_load: item.address })"
          :key="'promoted-promoted-' + i"
          class="flex fade-in"
        >
          <div class="flex w-full items-center">
            <img
              v-bind:src="item.tokenImageUrl"
              @error="altSymbolBsc"
              class="rounded-full border-primary border md:h-5 h-4 md:w-5 w-4 dark:bg-gray-900 bg-white md:p-1 p-0.5 mr-2"
            />
            <div class="pl-0 font-semibold dark:text-primary text-gray-900 text-xs cursor-pointer">
              <span style="max-width: 150px" class="md:block hidden overflow-ellipsis overflow-hidden whitespace-nowrap">{{
                item.tokenName
              }}</span>
              <span style="max-width: 70px" class="md:hidden block overflow-ellipsis sm:text-xs text-2xs overflow-hidden whitespace-nowrap">{{ item.tokenSymbol }}</span>
            </div>
          </div>
        </router-link>
        <Tooltip placement="bottom-end" tooltip="This is a promoted token.">
          <template v-slot:custom>
            <svg
              class="dark:text-gray-300 text-gray-500 w-3 h-3 ml-2"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="circle-info"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                class=""
                fill="currentColor"
                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"
              ></path>
            </svg>
          </template>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "@boggedfinance/bogged-components";
import { ArrowSmDownIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import { sleep } from "@/constants";
import dragscrollCustom from "./DragscrollCustom.vue";


export default {
  data() {
    return {
      marqueeTimoutId: null,
      tokens: this.$store.state.trendingTokens.slice(0, 10),
      tokenIndex: 0,
      tokensPerSlice: 1,
      transitionTime: 10000,
      marqueeReady: false,
      thisChain: this.$store.state.chain,
      screenWidthWatcherFired: 0, // timestamp of last time the screen width watcher was fired
    };
  },
  methods: {
    altSymbol(event) {
      event.target.src = "/img/chains/" + this.thisChain + "-logo.svg";
    },
    altSymbolBsc(event) {
      event.target.src = "/img/chains/bsc-logo.svg";
    },
    async setMarqueeVars(screenWidthInitial) {
      this.marqueeReady = false;
      this.trending = false;
      this.tokenIndex = 0;
      this.tokensPerSlice = 1;
      this.transitionTime = 8000;
      await sleep(50);
      this.marqueeReady = true;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    async toggleTrending() {
      this.marqueeReady = false;
      this.trending = false;
      //this.tokenIndex = 0
      this.tokensPerSlice = 1;
      this.transitionTime = 15000;
      if (this.tokenIndex < 3) {
        this.tokenIndex += 1;
      } else {
        this.tokenIndex = 0;
      }
      await sleep(50);
      this.marqueeReady = true;

      if (this.$store.state.marqueeTimoutIds.length > 0) {
        for (let tid of this.$store.state.marqueeTimoutIds) {
          clearTimeout(tid);
          const index = this.$store.state.marqueeTimoutIds.indexOf(tid);
          if (index > -1) {
            this.$store.state.marqueeTimoutIds.splice(index, 1);
          }
        }
      }
      this.$store.state.marqueeTimoutIds.push(
        setTimeout(
          function () {
            this.toggleTrending();
          }.bind(this),
          this.transitionTime,
        ),
      );
    },
  },
  components: {
    ArrowSmDownIcon,
    dragscrollCustom,
    Tooltip,
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },
  async created() {},
  mounted() {
    console.log("marquee mounted");
    this.$store.state.currentTheme = this.theme;
  },
  watch: {
    "$store.state.trendingTokens": function () {
      console.log(">>>>> trending tokens watcher fired");
      sleep(20).then(() => {
        this.setMarqueeVars(this.$store.state.screenWidth);
        if (this.$store.state.marqueeTimoutIds.length > 0) {
          for (let tid of this.$store.state.marqueeTimoutIds) {
            clearTimeout(tid);
            const index = this.$store.state.marqueeTimoutIds.indexOf(tid);
            if (index > -1) {
              this.$store.state.marqueeTimoutIds.splice(index, 1);
            }
          }
        }
        this.$store.state.marqueeTimoutIds.push(
          setTimeout(
            function () {
              this.toggleTrending();
            }.bind(this),
            this.transitionTime,
          ),
        );
      });
    },
    "$store.state.screenWidth": function () {
      console.log(">>>>> screen width watcher fired");
      this.setMarqueeVars(this.$store.state.screenWidth);
    },
    "$store.state.swapOpen": function () {
      console.log(">>>>> swap open watcher fired");
      sleep(200).then(() => {
        this.setMarqueeVars(this.$store.state.screenWidth);
      });
    },
    "$store.state.chain": function () {
      this.marqueeReady = false;
      this.trending = true;
      this.tokenIndex = 0;
    },
  },
};
</script>

<style style="text/css" scoped>
.fade-in {
  animation: fadeIn 1000ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}


</style>
