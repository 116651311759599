<template>
  <TransitionRoot as="template" :show="priceAlertOverlayIsOpen">
    <Dialog as="div">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild @click="closePriceAlertOverlay()" as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-lg border-l border-gray-100 dark:border-gray-700">
              <div class="h-full flex flex-col py-6 bg-white dark:bg-gray-800 shadow-xl overflow-y-scroll">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-500">
                      <button @click="toggleCreateAlert()" v-wave class="px-3 py-2 rounded-md bg-secondary bg-opacity-20 text-secondary">
                        <span v-if="!createAlert">Create Alert</span>
                        <span v-if="createAlert">Go Back</span>
                      </button>
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="dark:bg-gray-900 bg-gray-100 p-1 rounded-md text-gray-300 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-600" @click="closePriceAlertOverlay()">
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-6 relative flex flex-1 flex-col px-4 sm:px-6">
                  <div v-if="!$store.state.notificationsID" class="flex flex-1 flex-col items-center justify-center  ">

                      <button v-if="!pollingForTelegram" class="cursor-pointer dark:bg-blue px-4 py-3 rounded text-white"  @click="telegramLoginStart($event)">Login With Telegram</button>

                      <div v-else class="flex flex-col items-center" >
                        <span class="text-center text-white mb-4 px-6">Follow the link below to open Telegram and click the start button to login.</span>

                        <a class="mb-4" v-bind:href="'https://t.me/BogPriceAlertsBot?start=loginID_' + telegramLoginID" target="_blank"><button class="cursor-pointer text-white dark:bg-blue px-4 py-3 rounded text-white">Open Telegram</button></a>

                        <button @click="cancelTelegramLogin()" class="block m-auto opacity-25 text-center text-white">Go Back</button>
                      </div>
                      
                      <div v-if="pollingExpired" class="text-white text-centered text-sm mt-1.5" >Login attempt expired, try again.</div>

                    <template v-if="$store.state.beamsClient && !pollingForTelegram">
                      <hr class="border-t-2 opacity-25 w-3/4 my-8">

                      <button @click="beamsLogin()" class="button text-white dark:bg-gray-900 md:dark:hover:bg-gray-700 rounded-full py-3 px-4">Or Use Browser Notifications</button>
                    </template>
              
                  </div>

                  <template v-else>
                    <div class="flex-1">
                     <price-alert-tabs v-if="!createAlert" />
                    <price-alert-form v-else v-bind:toggleCreateAlert="toggleCreateAlert"/>
                    </div>

                   <button @click="notificationsLogout()" class="block m-auto opacity-25 text-center text-white mt-2">Logout</button>
                  </template>
                  
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import priceAlertForm from "./priceAlertForm.vue";
import priceAlertTabs from "./priceAlertTabs.vue";
import { useToast } from 'vue-toastification'
const toast = useToast();

export default {
  data() {
    return {
      createAlert: "",
      telegramLoginID: window.crypto.getRandomValues(new Uint32Array(2)).join("_"),
      pollingForTelegram: false,
      pollingExpired: false
    };
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    priceAlertForm,
    priceAlertTabs,
  },
  computed: {
    priceAlertOverlayIsOpen: function() {
      return this.$store.getters.priceAlertOverlayOpen;
    },
  },
  methods: {
    cancelTelegramLogin(){
      this.pollingForTelegram = false;    
      this.$store.dispatch("deleteTelegramLoginID",this.telegramLoginID)
    },
     telegramLoginStart () {
      this.$store.dispatch("startTelegramLogin",this.telegramLoginID);

      //setup polling for server (1 min max)
      this.pollingForTelegram = true;
      this.pollingExpired = false;

      let totalPolls = 0;

      const poll = async()=>{
        if(!this.pollingForTelegram) return;

        totalPolls++;

        if(totalPolls > 60){
          this.pollingExpired = true;
          this.cancelTelegramLogin();
          return;
        }

        const userID = await this.$store.dispatch("pollTelegramLogin",this.telegramLoginID);

        //user successfully logged in
        if(userID){
          this.pollingForTelegram = false;
          await this.$store.dispatch("loginTelegram",{loginID:this.telegramLoginID, userID});    
          this.$store.dispatch("getAllPriceAlerts");
        }
        else{
          setTimeout(poll,1000);
        }
      }

      poll();
    },
    async beamsLogin(){

      const success = await this.$store.dispatch("loginBeams");
      if(!success) toast.error("Unable to use browser notifications.")
      else this.$store.dispatch("getAllPriceAlerts");    
    },
    notificationsLogout: function(){
      this.$store.dispatch("logoutNotifications");
    },
    closePriceAlertOverlay: function() {
      if(this.pollingForTelegram){
        this.cancelTelegramLogin();
      }
      this.$store.commit("closePriceAlertOverlay");
    },
    toggleCreateAlert: function() {
      this.createAlert = !this.createAlert;
    },
  },
};
</script>
