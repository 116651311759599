<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selected">
    <div class="mt-1 relative">
      <ListboxButton class="relative w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
        <span class="block truncate text-gray-300">{{ selected.value }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="origin-top-right absolute z-10 right-0 mt-2 w-40 rounded-md shadow-lg divide-y divide-gray-700 overflow-hidden bg-gray-800 border border-gray-700">
          <ListboxOption as="template" v-for="timespan in times" :key="timespan.id" :value="timespan" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-gray-700' : 'text-secondary', 'cursor-default select-none font-semibold text-sm relative py-4 pl-4 pr-9']">
              <span :class="[selected ? 'text-white' : 'text-gray-300']">
                {{ timespan.value }}
              </span>
              <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref } from "vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

const times = [
  { id: 1, value: "24h" },
  { id: 2, value: "7d" },
  { id: 3, value: "All time" },
];

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  setup() {
    const selected = ref(times[0]);

    return {
      times,
      selected,
    };
  },
};
</script>
