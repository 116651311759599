<template>
  <div class="space-x-2 lg:flex hidden ml-8 font-heading flex-row">
    <router-link 
      :class="[$route.name === 'Home' ? activeClass : inactiveClass]"
      to="Home"
      >Dashboard</router-link
    >
    <tradeMenu/>
    <router-link 
      :class="[$route.name !== 'Home' && $route.name !== 'Portfolio' && $route.name !== 'Cryptocurrencies' ? activeClass : inactiveClass]"
      to="chart"
      >Charts</router-link>
  </div>
</template>

<script>
import tradeMenu from "@/components/Header/tradeMenu";
export default {
  components: { tradeMenu },
    data: function() {
    return {
      activeClass: "dark:text-primary-xtra_light text-gray-900 transition-colors duration-200 cursor-pointer",
      inactiveClass: "text-gray-500 hover:text-gray-300 transition-colors duration-200"

  }
    }
};
</script>
