<template>
  <div class="container">
    <div class="shape shape-1"></div>
    <div class="shape shape-2"></div>
    <div class="shape shape-3"></div>
    <div class="shape shape-4"></div>
  </div>
</template>

<style scoped>
.container {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  -webkit-animation: rotation 1s infinite;
  animation: rotation 1s infinite;
}
.container .shape {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.container .shape.shape-1 {
  left: 0;
  background-color: #d3f2da;
}
.container .shape.shape-2 {
  right: 0;
  background-color: #1bc870;
}
.container .shape.shape-3 {
  bottom: 0;
  background-color: #1bc870;
}
.container .shape.shape-4 {
  bottom: 0;
  right: 0;
  background-color: #d3f2da;
}
.container .shape-1 {
  -webkit-animation: shape1 0.5s infinite alternate;
  animation: shape1 0.5s infinite alternate;
}
.container .shape-2 {
  -webkit-animation: shape2 0.5s infinite alternate;
  animation: shape2 0.5s infinite alternate;
}
.container .shape-3 {
  -webkit-animation: shape3 0.5s infinite alternate;
  animation: shape3 0.5s infinite alternate;
}
.container .shape-4 {
  -webkit-animation: shape4 0.5s infinite alternate;
  animation: shape4 0.5s infinite alternate;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes shape1 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(20px, 20px, 0);
    transform: translate3d(20px, 20px, 0);
  }
}
@keyframes shape1 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(20px, 20px, 0);
    transform: translate3d(20px, 20px, 0);
  }
}
@-webkit-keyframes shape2 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
}
@keyframes shape2 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }
}
@-webkit-keyframes shape3 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(20px, -20px, 0);
    transform: translate3d(20px, -20px, 0);
  }
}
@keyframes shape3 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(20px, -20px, 0);
    transform: translate3d(20px, -20px, 0);
  }
}
@-webkit-keyframes shape4 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-20px, -20px, 0);
    transform: translate3d(-20px, -20px, 0);
  }
}
@keyframes shape4 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-20px, -20px, 0);
    transform: translate3d(-20px, -20px, 0);
  }
}
</style>
