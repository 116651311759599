<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
    <path fill="url(#paint0_linear)" d="M39 30c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C9 20.515 20.152 6.935 23.224 3.374a1.02 1.02 0 011.551 0C27.848 6.935 39 20.515 39 30z" />
    <path fill="url(#paint1_linear)" d="M33 36a9 9 0 01-9 9 9 9 0 01-9-9c0-5.691 6.691-13.839 8.535-15.976a.612.612 0 01.931 0C26.309 22.161 33 30.309 33 36z" />
    <defs>
      <linearGradient id="paint0_linear" x1="19.19" x2="26.613" y1="3.655" y2="44.409" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF6A6A" />
        <stop offset=".443" stop-color="#EE3D4A" />
        <stop offset="1" stop-color="#E52030" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="21.114" x2="25.568" y1="20.193" y2="44.646" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FED100" />
        <stop offset=".156" stop-color="#FDCB00" />
        <stop offset=".365" stop-color="#F9BA00" />
        <stop offset=".604" stop-color="#F29E00" />
        <stop offset=".863" stop-color="#E97701" />
        <stop offset="1" stop-color="#E36001" />
      </linearGradient>
    </defs>
  </svg>
</template>
