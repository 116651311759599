<script setup>
// Avoid emitting click event when scrolldragging
let dragging = false;
let timer = null;

function start() {
  timer = setTimeout(() => (dragging = true), 100);
}

function end() {
  clearTimeout(timer);
  setTimeout(() => (dragging = false));
}

function click(event) {
  if (dragging) {
    event.stopPropagation();
    event.preventDefault()
  } else {
	  console.log("not dragging")
  }
}
</script>

<template>
  <div
    v-dragscroll
    @dragscrollstart="start"
    @dragscrollend="end"
    @click.capture="click"
  >
    <slot />
  </div>
</template>