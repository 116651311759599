<template>
  <thead class="dark:bg-gray-800 bg-white overflow-hidden">
    <slot></slot>
  </thead>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
};
</script>
