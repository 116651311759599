<template>
  <div id="tradebook" style="height:calc(100% - 33px); width:100%;" class="tradebook overflow-x-auto overflow-y-hidden">
    <div style="height: 33px;" class="tableHeader md:block hidden w-full">
      <div
        style="padding-right: 10px;"
        class="flex w-full dark:text-gray-400 font-semibold md:text-xs text-2xs text-gray-600 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800 text-left font-heading"
      >
        <div class="flex max-w-10xl mx-auto w-full">
          <div class="tradeTime flex whitespace-nowrap p-2">
            {{ $t("charts.components.infobar.time") }}
          </div>
          <div class="tradeValue flex whitespace-nowrap p-2">
            {{ $t("charts.components.infobar.value") }}
          </div>
          <div class="tradeTraded flex whitespace-nowrap p-2">
            {{ $t("charts.components.infobar.traded") }}
          </div>
          <div class="tradePrice lg:flex hidden whitespace-nowrap p-2">
            {{ $t("charts.components.infobar.tokenprice") }}
          </div>
          <div class="tradeType lg:flex hidden whitespace-nowrap p-2">
            Type
          </div>
          <div class="tradeExchange flex whitespace-nowrap p-2">
            Exchange
          </div>
          <div class="walletAddress justify-end flex whitespace-nowrap p-2">
            Wallet
          </div>
        </div>
      </div>
    </div>

    <div v-if="!$store.state.currentExchange && $store.state.loadLargestExchangeContractTries > 1" 
      class="flex w-full dark:text-gray-400 font-semibold md:text-sm text-sm text-gray-600 text-left font-heading"
      >Waiting for liquidity
    </div>
    <div v-else id="tabTradebookWrapper" class="w-full overflow-x-hidden bg-gray-50 dark:bg-gray-900 dark:bg-opacity-50">
      <template v-if="isMobile">
        <!--<RecycleScroller :items="transactions" :item-size="64" key-field="scrollID" v-slot="{ item }">-->
        <div v-for="item in transactions" v-bind:key="item.id">
          <tradebook-row-mobile
            v-bind:id="item.id"
            v-bind:txHash="item.txHash"
            v-bind:isSell="item.isSell"
            v-bind:token="item.token.substring(0,8)"
            v-bind:tokenAmount="item.tokenAmount"
            v-bind:value="item.value"
            v-bind:pool="item.pool"
            v-bind:isArbitrage="item.isArbitrage"
            v-bind:arbPools="item.arbPools"
            v-bind:shortFromNow="item.shortFromNow"
            v-bind:walletAddress="item.walletAddress"
            v-bind:txType="item.txType"
            v-bind:blockieColour1="item.blockieColour1"
            v-bind:blockieColour2="item.blockieColour2"
          />
        </div>
        <!--</RecycleScroller>-->
      </template>
      <template v-else>
        <div v-for="item in transactions" v-bind:key="item.id">  
          <tradebook-row
            v-bind:id="item.id"
            v-bind:txHash="item.txHash"
            v-bind:isSell="item.isSell"
            v-bind:token="item.token.substring(0,8)"
            v-bind:tokenAmount="item.tokenAmount"
            v-bind:value="item.value"
            v-bind:pool="item.pool"
            v-bind:isArbitrage="item.isArbitrage"
            v-bind:arbPools="item.arbPools"
            v-bind:shortFromNow="item.shortFromNow"
            v-bind:walletAddress="item.walletAddress"
            v-bind:txType="item.txType"
            v-bind:blockieColour1="item.blockieColour1"
            v-bind:blockieColour2="item.blockieColour2"
          />
          
          
          
        <!--</RecycleScroller>-->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import tradebookRow from "./tradebookRow.vue";
import tradebookRowMobile from "./tradebookRowMobile.vue";
import { Services } from "@/services";
import { Tooltip } from "@boggedfinance/bogged-components";
import { maxDigits, priceMinDigits, bogintific, bogintificPrice, roundToNSigFigs, get16Colour } from "@/filters";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      intervalID: "",
      transactions: [],
    };
  },
  components: { tradebookRow, tradebookRowMobile, Tooltip },
  methods: {
    maxDigits,
    priceMinDigits,
    bogintific,
    bogintificPrice,
    roundToNSigFigs,
    computeTransactions() {      
      // trim txlist so page doesn't crawl
      let overallTXLimit = 1000;
      
      if (this.$store.state.performanceMode == true || this.$store.state.lowSpecMachine) {
        overallTXLimit = 50;
      } else if (this.$store.state.performanceTestResult >= 4) {
        overallTXLimit = 100;
      } else if (this.$store.state.performanceTestResult >= 3) {
        overallTXLimit = 200;
      } else if (this.$store.state.performanceTestResult >= 2) {
        overallTXLimit = 500;
      }
      if (this.windowWidth <= 768 && overallTXLimit > 100) {
        overallTXLimit = 100
      } 
      if (this.$store.state.firstTxListDraw) {
        overallTXLimit = 10
        this.$store.state.firstTxListDraw = false
      }
      

      let txs = this.$store.state.transactions;

      if (false) {
        for (let tx of txs.slice(0,8)) {
          let wallet = tx.walletAddress
          let txHash = tx.txHash
          if (!wallet) {
            this.$store.state.txhashesMissingWallet.push(txHash)
          }
          //console.log(this.$store.state.txhashesMissingWallet)
          //console.log(this.$store.state.txhashesMissingWalletComputing)
          
          if (!this.$store.state.txhashesMissingWalletComputing) {          
            this.$store.state.txhashesMissingWalletComputing = true
            while (this.$store.state.txhashesMissingWallet.length > 0) {
              try {
                let txhash = this.$store.state.txhashesMissingWallet.pop()
                if (txhash in this.$store.state.transactionsByHash && this.$store.state.transactionsByHash[txhash].length > 0 && !this.$store.state.transactionsByHash[txhash][0].walletAddress) {                
                  Services.getWalletFromTxhash(this.$store, txhash).then((res) => {                  
                    if (res) {
                      // txns without a wallet address will always be swaps
                      console.log(txhash)
                      let walletAddress = res
                      let this_txns = this.$store.state.transactionsByHash[txhash]
                      for (let this_tx of this_txns) {
                          this_tx.walletAddress = walletAddress
                          this_tx.txType = 'Swap'
                      }
                    }
                  })
                }
                //console.log(receipt)
                //console.log(txhash)
              } catch (e) {
                //console.log(e)
                break
              }
            }
            this.$store.state.txhashesMissingWalletComputing = false
          }
        }
      }


      let txListSize = txs.length;
      if (txListSize > overallTXLimit) {
        txs = txs.slice(0, overallTXLimit);
      }
      //add scrollID
      this.transactions = txs.map(tx => {
        //add scroll id
        tx.scrollID = tx.id + tx.pool + tx.swapID;

        //add short time here so it doesnt have to be calculated so many times in the component
        tx.shortFromNow = Services.timeAgo(tx.date);
        return tx;
      });
      
      
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 768;
    },  
  },
  
  watch: {
    "$store.state.txHashesMissingWallet": function() {      
      //console.log('!',this.$store.state.item.txHashesMissingWallet)
      
    },
    "$store.state.transactions": function() {
      console.log('transactions watcher fired')
      this.computeTransactions()
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalID);
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.fade-in-inner {
  animation: 0.6s ease 0s normal forwards 1 fadeIn;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


</style>
