<template>
  <div class="fade-in">
    <img style="width: 160px; height: 48px;" @error="minichartUnavailable" v-bind:src="value" />
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {};
  },
  methods: {
    minichartUnavailable(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
