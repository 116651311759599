<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <div class="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 flex justify-between w-full items-center text-white bg-gray-700 px-6 py-4">
                Disclaimer
                <button @click="closeModal()" type="button" class="p-0.5 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-0">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </DialogTitle>
              <div class="p-6">
                <p class="text-white caption">
                  This feature is not avaliable in the beta.
                </p>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import { XIcon } from "@heroicons/vue/outline";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  data() {
    return {
      isOpen: true,
    };
  },
  setup() {
    const isOpen = ref(true);
    return {
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
    };
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    XIcon,
  },
};
</script>
