<template>
  <div
    v-if="$store.state.showLiquidity"
    class="flex flex-row group dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4"
  >
    <a
      class="text-center md:mr-0 mr-3"
      target="_blank"
      :href="'https://app.unicrypt.network/amm/pancake-v2/token/' + $store.state.token"
      v-if="$store.state.tokenLocks.percentOfTotalLiqLocked"
      ><div class="text-secondary font-heading font-semibold">
        {{ Math.round($store.state.tokenLocks.percentOfTotalLiqLocked) }}%
      </div>
      <div class="text-2xs w-10">
        <div class="group-hover:hidden dark:text-gray-300">Locked</div>
        <div class="group-hover:block hidden dark:text-secondary-xtra_light">View</div>
      </div>
    </a>
    <span v-else class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
          clip-rule="evenodd"
        /></svg
    ></span>

    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        >{{ $t("charts.components.topbar.liq") }}
        <div class="whitespace-normal">
          <InformationCircleIcon
            v-tippy="{
              placement: 'bottom',
              content: 'Liquidity includes both ' + $store.state.tokenSymbol + ' and BNB value from LP total',
              allowHTML: true,
              appendTo: 'parent',
              theme: 'bog',
              animation: 'shift-toward-subtle',
              arrow: false,
              maxWidth: '350px',
            }"
            class="h-4 w-4 text-gray-500 ml-1"
            aria-hidden="true"
          /></div
      ></span>
      <div id="textfade" v-if="$store.state.tokenLiquidity">
      <h4
        
        v-html="bogintificPrice(Math.floor($store.state.tokenLiquidity), this.$store.state.useTruncatedBogintific)"
        class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg"
      ></h4>
      </div>
      <div
        v-else
        class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable skeleton-loader"
      ></div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
