<template>
  <transition appear name="fade">
    <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full">
      <div class="lg:w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
        <router-link
          to="/promote"
          class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:hover:text-gray-100 dark:border-gray-700 dark:bg-gray-800 text-gray-300 transition-colors duration-200 h-auto p-4 text-center cursor-pointer button"
          >Back to Promotion</router-link
        >
        <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
          <div class="px-6 py-8 bg-white dark:bg-transparent">
            <h4 class="mb-2 text-gray-800 dark:text-primary">Buy Ads on Bogged.Finance</h4>
            <h6 class="leading-relaxed text-gray-700 dark:text-gray-100">
              You can have ads running in minutes on Bogged.Finance
            </h6>
          </div>
          <div class="flex flex-wrap">
            <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701154&selected_source_type=ad_unit&partner=1701154" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Chart Ad - Desktop - 336 x 280</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                🔥 Our hottest ad format, get in front of people trading actively.
              </p>
            </a>
<a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701157&selected_source_type=ad_unit&partner=1701157" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Chart Ad - Mobile - 320 x 50</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                🔥 Our hottest ad format, get in front of people trading actively on mobile.
              </p>
            </a>

              <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701147&selected_source_type=ad_unit&partner=1701147" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Home Ad - Desktop - 970 x 90</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                
              </p>
            </a>
               <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701149&selected_source_type=ad_unit&partner=1701149" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Home Ad - Mobile - 320 x 100</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                
              </p>
            </a>
               <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701150&selected_source_type=ad_unit&partner=1701150" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Token Explorer - Desktop - 336 x 280</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                
              </p>
            </a>
               <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701152&selected_source_type=ad_unit&partner=1701152" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6" target="_blank">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Portfolio  Ad - Desktop - 970 x 90</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                Get in front of people managing and viewing their portfolio.
              </p>
            </a>
               <a href="https://a-ads.com/campaigns/new?selected_ad_unit_id=1701153&selected_source_type=ad_unit&partner=1701153" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
              <div class="flex flex-row items-center mb-3">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Portfolio  Ad - Mobile - 320 x 100</h6>
              </div>
              <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                                Get in front of people managing and viewing their portfolio.
              </p>
            </a>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
