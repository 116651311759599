<template>
  <div :class="$store.state.sidebarOpen ? 'xl:left-75 left-0' : 'xl:left-16 left-0'" class="flex w-full absolute h-screen">
    <iframe
      loading="lazy"
      style="
  border: 0;
  height: 100%;
  width: 100%;"
      src="https://tgbot.bogged.finance/"
    ></iframe>
  </div>
</template>
