<template>
  <div class="pr-4">
    <Menu v-if="isWalletConnected" as="div" class="flex-shrink-0 relative">
      <div>
        <MenuButton class="cursor-pointer relative w-full dark:bg-gray-900 bg-gray-200 hover:bg-opacity-80 rounded-md shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-gray-600 focus:border-gray-600 sm:text-sm">
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 flex-shrink-0 text-gray-500 fill-current" fill="none" viewBox="0 0 25 24">
              <path opacity="0.35" d="M18 21H6C4.343 21 3 19.657 3 18V6H18C19.657 6 21 7.343 21 9V18C21 19.657 19.657 21 18 21Z" />
              <path d="M17.5 15C18.3284 15 19 14.3284 19 13.5C19 12.6716 18.3284 12 17.5 12C16.6716 12 16 12.6716 16 13.5C16 14.3284 16.6716 15 17.5 15Z" />
              <path d="M3 6C3 4.343 4.343 3 6 3H15C16.657 3 18 4.343 18 6H3Z" />
            </svg>
            <span class="ml-3 md:block hidden text-gray-800 dark:text-gray-100">{{ maxLength(address, 6) }}</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-gray-400 dark:text-gray-500" aria-hidden="true" />
          </span>
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-gray-700 ring-opacity-100 focus:outline-none">
          <MenuItem>
            <a @click="openWalletModal" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">Wallet</a>
          </MenuItem>
          <MenuItem class="md:hidden block">
            <a @click="openSettingsModal" class="flex items-center justify-between cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">Settings</a>
          </MenuItem>
          <MenuItem>
            <a @click="disconnectWallet" class="flex items-center justify-between cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">Disconnect <LogoutIcon class="h-4 w-4 ml-1 text-white" aria-hidden="true"/></a>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>

    <button v-if="!isWalletConnected" class="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-secondary bg-secondary bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus:ring-1 focus:ring-secondary" @click="connectWallet">
      <span class="md:block hidden"> {{ $t("charts.home.connectwalletshort") }} </span>
      <span class="md:hidden block">Connect</span>
    </button>
  </div>

  <wallet-modal />
</template>

<script>
import { Services } from "@/services";
import { maxDigits, maxLength } from "@/filters";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon, LogoutIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon, UserIcon } from "@heroicons/vue/solid";
import walletModal from "@/components/Header/walletModal";

export default {
  beforeMount: async function() {
    if (localStorage.getItem("walletTokenDict")) {
      await this.connectWallet();
    }
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
    walletModal,
    ChevronDownIcon,
    LogoutIcon,
    UserIcon,
  },
  methods: {
    maxDigits,
    maxLength,
    disconnectWallet: async function() {
      this.$store.commit("disconnectWallet");
      this.$emit("close");
      window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER") //Unstucks stuck wallets.

    },
    connectWallet: async function() {
      let web3 = await Services.getWeb3(this.$store.state.chain);
      this.$store.commit("connectWallet", web3);
    },
    openWalletModal: function() {
      this.$store.commit("openWalletModal");
    },
    openSettingsModal: function() {
      this.$store.commit("openSettingsModal");
    },
  },
  computed: {
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
    address: function() {
      return this.$store.getters.account || "";
    },
  },
};
</script>
