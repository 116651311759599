<template>
  <div v-for="(holder, index) in $store.state.top100" :key="holder.address" class="py-2 px-3 my-2 mx-2 dark:bg-gray-800 bg-gray-200 rounded-xl text-xs">
    <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/address/' + holder.address">
      <div class="flex flex-row justify-between w-full items-center mb-1.5">
        <div class="space-x-1">
          <span class="text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300">#{{ index + 1 }}</span>
          <span class="text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300">{{ bogintific(holder.balance) }} {{ $store.state.tokenSymbol }}</span>
          <span class="text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300">{{ maxDigits((holder.balance / $store.state.tokenCirculatingSupply) * 100, 2) }}%</span>
        </div>

        <div class="space-x-1 whitespace-nowrap">
          <span
            class="bg-gray-300 dark:bg-opacity-10 bg-opacity-50 dark:text-gray-300 text-gray-700 text-2xs py-0.5 px-1.5 rounded-md"
            v-if="holder.address == '0x000000000000000000000000000000000000dead' || holder.address == '0x0000000000000000000000000000000000000000' || holder.address == '0x0000000000000000000000000000000000000001'"
          >
            Burn Address</span
          >
          <span class="bg-primary dark:bg-opacity-10 bg-opacity-20 dark:text-primary text-primary-dark text-2xs py-0.5 px-1.5 rounded-md" v-else-if="holder.address == $store.state.tokenDeployer"> Deployer</span>
          <span class="bg-primary dark:bg-opacity-10 bg-opacity-20 dark:text-primary text-primary-dark text-2xs py-0.5 px-1.5 rounded-md" v-else-if="holder.address == $store.state.tokenOwner">Contract Owner</span>
          <span class="bg-blue-bright dark:bg-opacity-10 bg-opacity-20 dark:text-blue-bright text-blue text-2xs py-0.5 px-1.5 rounded-md" v-else-if="$store.state.liquidityPools && $store.state.liquidityPools.includes(holder.address)">
            Liquidity Pool</span
          >
          <span
            class="bg-purple-bright dark:bg-opacity-10 bg-opacity-20  dark:text-purple-bright text-purple text-2xs py-0.5 px-1.5 rounded-md"
            v-else-if="$store.state.top100IsContract.length == $store.state.top100.length && $store.state.top100IsContract[index]"
          >
            Contract</span
          >
        </div>
      </div>

      <span class="text-gray-900 dark:text-white">{{ maxLength(holder.address, 8) }}...{{ holder.address.substr(holder.address.length - 8) }}</span>
    </a>
  </div>
</template>

<script>
import { bogintific, maxDigits, maxLength } from "@/filters";

export default {
  methods: {
    bogintific,
    maxDigits,
    maxLength,
  },
};
</script>
