<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60">
    <path fill="#4E8EE9" d="M30 60c16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30z" />
    <path fill="#C0CBF6" d="M30.3 8l-.3.99v28.72l.3.29L44 30.12 30.3 8z" />
    <path fill="#fff" d="M30 8L16 30.12 30 38V8z" />
    <path fill="#C0CBF6" d="M30.17 40.966l-.17.202v10.347l.17.485L44 33l-13.83 7.966z" />
    <path fill="#fff" d="M30 52V40.966L16 33l14 19z" />
    <path fill="#8197EE" d="M30 38l14-7.914L30 24v14z" />
    <path fill="#C0CBF6" d="M16 30.086L30 38V24l-14 6.086z" />
  </svg>
</template>
