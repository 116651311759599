<template>
  <span v-if="!verificationLoaded && this.$store.state.chain == 'bsc'">
    <svg viewBox="0 0 24 24" fill="none" class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0"
      />
      
    </svg>
  </span>
  <span v-if="verificationLoaded && this.$store.state.chain == 'bsc'">
    <div class="relative">
      <div class="flex flex-row space-x-1">
        <span
          v-tippy="{
            placement: 'bottom',
            content: 'Verified on BscScan',
            appendTo: 'parent',
            theme: 'bog-nowrap',
            animation: 'shift-toward-subtle',
            arrow: false,
            maxWidth: 'none',
          }"
          v-if="verified"
          class="h-5 w-5 text-secondary"
        >
          <svg viewBox="0 0 24 24" fill="none" class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.35"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.991 3.89701C9.562 2.80501 10.869 1.98801 12.342 1.98801C13.815 1.98801 15.123 2.80401 15.693 3.89701C16.869 3.52801 18.37 3.87501 19.413 4.91701C20.455 5.95901 20.802 7.46001 20.433 8.63701C21.525 9.20801 22.342 10.515 22.342 11.988C22.342 13.461 21.526 14.768 20.433 15.339C20.802 16.515 20.455 18.016 19.413 19.059C18.371 20.101 16.87 20.448 15.693 20.079C15.122 21.171 13.815 21.988 12.342 21.988C10.869 21.988 9.562 21.172 8.991 20.079C7.815 20.448 6.313 20.101 5.271 19.059C4.229 18.017 3.882 16.515 4.251 15.339C3.159 14.768 2.342 13.461 2.342 11.988C2.342 10.515 3.158 9.20701 4.251 8.63701C3.882 7.46101 4.229 5.96001 5.271 4.91701C6.313 3.87501 7.815 3.52901 8.991 3.89701Z"
            />
            <path
              d="M11 16C10.744 16 10.488 15.902 10.293 15.707L7.293 12.707C6.902 12.316 6.902 11.684 7.293 11.293C7.684 10.902 8.316 10.902 8.707 11.293L11 13.586L15.293 9.29301C15.684 8.90201 16.316 8.90201 16.707 9.29301C17.098 9.68401 17.098 10.316 16.707 10.707L11.707 15.707C11.512 15.902 11.256 16 11 16Z"
            />
          </svg>
        </span>

        <span
          v-tippy="{
            placement: 'bottom',
            content: 'Unverified on BscScan',
            appendTo: 'parent',
            theme: 'bog-nowrap',
            animation: 'shift-toward-subtle',
            arrow: false,
            maxWidth: 'none',
          }"
          v-else-if="!verified"
          class="h-5 w-5 text-error-bright"
        >
          <svg viewBox="0 0 24 24" fill="none" class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.35"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            />
            <path
              d="M13.42 16.489C13.42 16.914 13.173 18 11.993 18C10.813 18 10.581 16.914 10.581 16.489C10.581 16.074 10.844 14.96 11.993 14.96C13.142 14.96 13.42 16.074 13.42 16.489ZM10.698 12.499V7.259C10.698 6.54 11.281 5.957 12 5.957C12.719 5.957 13.302 6.54 13.302 7.259V12.5C13.302 13.219 12.719 13.802 12 13.802C11.281 13.801 10.698 13.218 10.698 12.499Z"
            />
          </svg>
        </span>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  computed: {
    verificationLoaded: function () {
      return this.$store.getters.tokenContractVerificationIsLoaded;
    },
    verified: function () {
      return this.$store.getters.tokenContractIsVerified;
    },
  },
};
</script>
