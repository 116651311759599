<template>
  <div class="flex mx-4 my-4 justify-between flex-col md:flex-row items-center flex-wrap z-30">
    <div class="flex flex-col md:flex-row items-center">
      <div class="flex flex-row space-x-3 w-auto mr-6">
        <span class="flex flex-row bg-transparent w-auto transition-colors duration-200 px-0 border-0 items-center">
          <div class="relative flex flex-shrink-0 py-0">
            <img :src="$store.state.tokenImage" @error="altSymbol" class="w-12 h-12 mr-4 rounded-full cursor-default bg-white" />
          </div>

          <span class="flex flex-col w-auto relative py-0">
            <div class="flex flex-row space-x-2 items-center">
              <h4 class="dark:text-primary-xtra_light text-gray-800 text-lg 2xl:text-xl">
                {{ maxDigits($store.state.tokenPrice, 5) }}
              </h4>
              <h4
                v-bind:class="{
                  'dark:text-secondary text-secondary-dark': $store.state.percentChange >= 0,
                  'dark:text-error-bright text-error': $store.state.percentChange < 0,
                }"
                class="text-xs font-semibold lg:font-normal lg:text-base"
              >
                {{ $store.state.percentChange }}%
              </h4>
            </div>
          </span>
        </span>
      </div>
      <div
        class="items-center h-16 bg-transparent dark:bg-transparent flex flex-row text-left justify-start space-x-3 pt-0 my-1.5 w-auto"
      >
        <span class="flex lg:flex-col flex-row items-center lg:items-start">
          <span class="caption lg:block hidden font-heading dark:text-primary lg:dark:text-gray-300 text-gray-500">{{
            $t("charts.components.topbar.24hrvol")
          }}</span>
          <span class="text-2xs lg:hidden pr-1 block font-heading dark:text-primary lg:dark:text-gray-300 text-primary-dark">{{
            $t("charts.components.topbar.vol")
          }}</span>
          <h4
            v-if="$store.state.tokenVolume != null"
            v-html="bogintificPrice($store.state.tokenVolume, this.$store.state.useTruncatedBogintific)"
            class="dark:text-primary-xtra_light text-gray-900 text-sm lg:text-base 2xl:text-lg"
          ></h4>
        </span>
        <span class="flex lg:flex-col flex-row items-center lg:items-start">
          <span class="caption lg:block hidden font-heading dark:text-primary lg:dark:text-gray-300 text-gray-500">{{
            $t("charts.components.topbar.liq")
          }}</span>
          <span class="text-2xs lg:hidden pr-1 block font-heading dark:text-primary lg:dark:text-gray-300 text-primary-dark">{{
            $t("charts.components.topbar.liq")
          }}</span>
          <h4
            v-if="$store.state.tokenLiquidity"
            v-html="bogintificPrice(Math.floor($store.state.tokenLiquidity), this.$store.state.useTruncatedBogintific)"
            class="dark:text-primary-xtra_light text-gray-900 text-sm lg:text-base 2xl:text-lg"
          ></h4>
        </span>
        <span class="flex lg:flex-col flex-row items-center lg:items-start">
          <span class="caption lg:block hidden font-heading dark:text-primary lg:dark:text-gray-300 text-gray-500">{{
            $t("charts.components.topbar.mcap")
          }}</span>
          <span class="text-2xs lg:hidden pr-1 block font-heading dark:text-primary lg:dark:text-gray-300 text-primary-dark">{{
            $t("charts.views.portfolio.mcap")
          }}</span>
          <h4
            v-if="$store.state.tokenMarketCap"
            v-html="bogintificPrice(Math.floor($store.state.tokenMarketCap), this.$store.state.useTruncatedBogintific)"
            class="dark:text-primary-xtra_light text-gray-900 text-sm lg:text-base 2xl:text-lg"
          ></h4>
        </span>
      </div>
    </div>

    <div class="flex flex-row space-x-4">
      <a
        v-if="!$store.state.embedhidebuttons"
        v-wave
        class="flex-none button md:ml-10 flex h-8 flex-row items-center group hover:bg-opacity-30 bg-secondary bg-opacity-20 dark:hover:text-secondary-light transition-colors duration-300 py-2 px-2 font-heading text-sm text-secondary-xtra_dark dark:text-secondary rounded-md cursor-pointer"
        rel="noopener noreferrer"
        target="_blank"
        :href="'https://app.bogged.finance/swap?token=' + token"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="fill-current duration-300 h-4 w-4 lg:mr-2 group dark:group-hover:text-secondary-light text-secondary-xtra_dark dark:text-secondary"
        >
          <path
            fill=""
            d="M21.914 11.586l-6.572-6.572 1.507-1.507c.545-.545.162-1.477-.609-1.481L10.76 2A1.255 1.255 0 009.5 3.26l.026 5.48c.004.77.936 1.154 1.481.609l1.507-1.507 6.572 6.572a2 2 0 102.828-2.828z"
            opacity=".35"
          ></path>
          <path
            fill=""
            d="M3.086 12.414l6.572 6.572-1.507 1.507c-.545.545-.161 1.477.609 1.481l5.48.026a1.255 1.255 0 001.26-1.26l-.026-5.48c-.004-.771-.936-1.154-1.481-.609l-1.507 1.507-6.572-6.572a2 2 0 10-2.828 2.828z"
          ></path></svg
        ><span class="ml-2"> Swap</span>
      </a>

      <a
        v-if="!$store.state.embedhidebuttons"
        v-wave
        class="flex-none button flex h-8 ml-2 flex-row justify-right items-center group hover:bg-opacity-30 bg-secondary bg-opacity-20 dark:hover:text-secondary-light transition-colors duration-300 py-2 px-2 font-heading text-sm text-secondary-xtra_dark dark:text-secondary rounded-md cursor-pointer"
        rel="noopener noreferrer"
        target="_blank"
        :href="'https://app.bogged.finance/swap?token=' + token"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          class="fill-current duration-300 h-4 w-4 lg:mr-2 group dark:group-hover:text-secondary-light text-secondary-xtra_dark dark:text-secondary"
        >
          <path fill="" d="M17 21H7a3 3 0 01-3-3V6a3 3 0 013-3h7l6 6v9a3 3 0 01-3 3z" opacity=".35"></path>
          <path
            fill=""
            d="M14 7V3l6 6h-4a2 2 0 01-2-2zM23.411 19.894l-6.964 3.482A1 1 0 0115 22.482v-6.964a1 1 0 011.447-.894l6.964 3.482a1 1 0 010 1.788zM16 12.517c.461 0 .925.11 1.341.318l.194.097.245-.307a1 1 0 00-1.562-1.25l-1.008 1.261c.254-.07.515-.119.79-.119zM13.014 15.383l-.029.036-1.716-2.06a1.006 1.006 0 00-.757-.359c-.319.01-.573.12-.765.342l-3.5 4a1 1 0 001.506 1.316l2.729-3.118 1.75 2.101c.19.226.459.398.768.359v-2.482c0-.046.012-.089.014-.135z"
          ></path></svg
        ><span class="md:block hidden ml-2">View Full Chart</span>
        <span class="md:hidden block ml-2">Full Chart</span>
      </a>
    </div>
  </div>
  <bogChart />

  <div
    id="bogChart"
    class="p-0 h-full w-full relative flex dark:border-transparent border bg-white dark:bg-gray-900 text-white"
    style="height: calc(100vh - 90px)"
  >
    <div
      v-if="$store.state.chartLoading"
      style="backdrop-filter: blur(4px)"
      class="flex items-center place-content-center flex-col z-20 rounded-lg w-full left-0 h-full bg-gray-900 bg-opacity-70"
    >
      <mini-spinner></mini-spinner>
      <div class="animate-pulse-disable text-center">
        <span class="text-primary overline block">Loading: Powered by Bogged Finance</span>
      </div>
      <div class="text-center md:max-w-md sm:max-w-sm max-w-50">
        <span class="text-2xs mt-1 block text-gray-100">{{ text }}</span>
      </div>
    </div>
    <div id="tv_chart_container" style="flex: 1 1 0%; height: calc(100%)"></div>
  </div>
</template>

<script>
import bogChart from "@/views/Chart/components/bogChart";
import { maxDigits, bogintificPrice } from "@/filters";

export default {
  components: { bogChart },

  methods: {
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    maxDigits,
    bogintificPrice,
  },
};
</script>
