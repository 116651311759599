<template>
  <div
    v-for="(holder, index) in $store.state.top100"
    :key="holder.address"
    class="tradebookRow font-mono text-xs py-0.5 font-light items-center flex flex-row text-gray-700 dark:text-gray-200"
  >
    <div class="holderBalance flex items-center p-2">
      <div class="text-gray-400 mr-3 w-6">{{ index + 1 }}</div>
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/address/' + holder.address"> {{ bogintific(holder.balance) }} {{ $store.state.tokenSymbol }} </a>
    </div>

    <div class="holderSupply flex items-center p-2">
      <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/address/' + holder.address"> {{ maxDigits((holder.balance / $store.state.tokenCirculatingSupply) * 100, 2) }}% </a>
    </div>

    <div class="holderAddress flex items-center p-2">
      <a class="flex flex-row items-center" rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/address/' + holder.address">
        {{ holder.address }}
        <div class="ml-2 inline-block whitespace-nowrap">
          <span
            class="bg-gray-300 dark:bg-opacity-10 bg-opacity-50 dark:text-gray-300 text-gray-700 rounded-md py-0.5 px-1"
            v-if="holder.address == '0x000000000000000000000000000000000000dead' || holder.address == '0x0000000000000000000000000000000000000000' || holder.address == '0x0000000000000000000000000000000000000001'"
          >
            Burn Address</span
          >
          <span class="bg-primary dark:bg-opacity-10 bg-opacity-20 dark:text-primary text-primary-dark rounded-md py-0.5 px-1" v-else-if="holder.address == $store.state.tokenDeployer">Deployer</span>
          <span class="bg-primary dark:bg-opacity-10 bg-opacity-20 dark:text-primary text-primary-dark rounded-md py-0.5 px-1" v-else-if="holder.address == $store.state.tokenOwner">Contract Owner</span>
          <span class="bg-blue-bright dark:bg-opacity-10 bg-opacity-20 dark:text-blue-bright text-blue rounded-md py-0.5 px-1" v-else-if="$store.state.liquidityPools && $store.state.liquidityPools.includes(holder.address)"> Liquidity Pool</span>
          <span class="bg-purple-bright dark:bg-opacity-10 bg-opacity-20  dark:text-purple-bright text-purple rounded-md py-0.5 px-1" v-else-if="$store.state.top100IsContract.length == $store.state.top100.length && $store.state.top100IsContract[index]"> Contract</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { bogintific, maxDigits } from "@/filters";

export default {
  methods: {
    bogintific,
    maxDigits,
  },
};
</script>
