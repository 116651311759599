<template>
  <div class="w-full relative h-96 p-4 bg-gray-800 rounded-b-lg overflow-hidden rounded-lg">
    <div class="absolute left-0 space-y-6 z-10 top-0 text-center flex flex-col place-content-center items-center w-full h-full">
      <h2 class="font-semibold text-white">Connect your wallet to view your portfolio</h2>
      <!--  <button @click="connectWallet" class="px-4 py-2 rounded-md overline bg-secondary bg-opacity-20 hover:bg-opacity-30 divide-gray-200 transition-all text-secondary text-white">Connect Wallet</button> -->
    </div>
    <div class="bg-gradient-to-t from-gray-900 to-transparent w-full h-full left-0 top-0 absolute"></div>
    <div v-for="index in 6" :key="index">
      <div class="bg-gray-700 bg-opacity-50 mb-4 rounded-md w-full h-20"></div>
    </div>
  </div>
</template>

<!--
<script>
import { Services } from "@/services";

export default {
  beforeMount: async function() {
    if (localStorage.getItem("walletTokenDict")) {
      await this.connectWallet();
    }
  },
  methods: {
    connectWallet: async function() {
      let web3 = await Services.getWeb3(this.$store.state.chain);
      this.$store.commit("connectWallet", web3);
    },
  },
  computed: {
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
  },
};
</script>
-->
