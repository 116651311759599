<template>
  <Listbox as="div">
    <div class="relative">
      <div class="inline-flex shadow-sm rounded-md">
        <div class="relative z-0 inline-flex shadow-sm rounded-md">
          <div
            @click="toggleSwap"
            v-wave
            class="text-sm cursor-pointer font-semibold font-heading relative inline-flex items-center bg-white dark:bg-secondary dark:hover:bg-opacity-30 dark:bg-opacity-20 hover:bg-opacity-90 pl-2 pr-3 border border-transparent rounded-l-md shadow-sm text-gray-800 dark:text-secondary"
          >
            <span>{{ $t("charts.components.topbar.swap") }}</span>
          </div>
          <ListboxButton
            class="relative inline-flex items-center bg-gray-200 dark:bg-secondary p-0.5 rounded-l-none border border-transparent rounded-r-md text-sm font-medium hover:bg-opacity-80 focus:outline-none focus:z-10 focus:ring-1 focus:ring-white"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-400 dark:text-secondary-xtra_dark" aria-hidden="true" />
          </ListboxButton>
        </div>
      </div>

      <transition v-if="this.$store.state.chain == 'bsc'" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="origin-top-right absolute z-20 right-0 mt-2 w-40 rounded-md shadow-lg overflow-hidden bg-gray-800 border border-gray-700">
          <ListboxOption as="template">
            <li class="cursor-pointer select-none relative">
              <div class="flex flex-col">
                <div class="flex-col flex justify-between">
                  <a target="_blank" href="https://app.bogged.finance/stoploss" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">Stop Loss</a>
                  <a target="_blank" href="https://app.bogged.finance/trade" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">Limit Order</a>
                </div>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
  },
  methods: {
    toggleSwap: function() {
      this.$store.commit("toggleSwap");
    },
  },
  computed: {
    swapIsOpen: function() {
      return this.$store.getters.swapOpen;
    },
  },
};
</script>
