<template>
  <div class="w-full h-full">
    <TabGroup  v-if="renderComponent" :defaultIndex="defaultTab">
      <div v-dragscroll style="height: 49px;" class="overflow-hidden block whitespace-nowrap">
        <TabList class="flex overflow p-2 space-x-2 border-b border-gray-100 dark:border-gray-700">
          <Tab v-for="tab in Object.keys(tabs)" as="template" :key="tab" v-slot="{ selected }">
            <button
              :class="['py-1.5 px-3 text-sm transition-colors duration-200  rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10', selected ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-900 hover:text-white']"
            >
              {{ tab }}
            </button>
          </Tab>
        </TabList>
      </div>

      <sentiment-bar />

      <TabPanels style="height: calc(100% - 32px)">
        <TabPanel class="h-full" v-for="(tabs, idx) in Object.values(tabs)" :key="idx">
          <div v-for="tab in tabs" v-bind:key="tab.id" class="relative h-full">
            <component v-bind:is="tab.content" class="tab"></component>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import tradeBook from "@/views/Chart/components/Tabs/tradeBook";
import News from "@/views/Chart/components/Tabs/News.vue";
import Info from "@/views/Chart/components/Tabs/Info.vue";
import Liquidity from "@/views/Chart/components/Tabs/Liquidity.vue";
import sentimentBar from "@/views/Chart/components/Tabs/sentimentBar.vue";
import userTrades from "@/views/Chart/components/Tabs/userTrades.vue";
import Holders from "@/views/Chart/components/Tabs/Holders.vue";
import { ref } from "vue";

export default {
  components: {
    News,
    tradeBook,
    Info,
    userTrades,
    Holders,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Liquidity,
    sentimentBar,
  },
  computed: {
    defaultTab: {
      get() {
        return JSON.parse(localStorage.getItem("defaultTab") || "0");
      },
    },
  },
  data() {
    return {
      renderComponent: true,
    };
  },
  methods: {
    force_rerender() {
      // Remove my-component from the DOM
      this.set_tabs()
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    set_tabs() {
      if (this.$store.state.chain == 'bsc') {
        this.tabs = {
          Tradebook: [
            {
              id: 1,
              content: "trade-book",
            },
          ],
          "Your Trades": [
            {
              id: 2,
              content: "user-trades",
            },
          ],
          Holders: [
            {
              id: 4,
              content: "holders",
            },
          ],
          Details: [
            {
              id: 5,
              content: "info",
            },
          ],
          Liquidity: [
            {
              id: 6,
              content: "liquidity",
            },
          ],
          News: [
            {
              id: 7,
              content: "news",
            },
          ],
        }

        
      } else {        
        this.tabs = {
          Tradebook: [
            {
              id: 1,
              content: "trade-book",
            },
          ],
          "Your Trades": [
            {
              id: 2,
              content: "user-trades",
            },
          ],
          Details: [
            {
              id: 5,
              content: "info",
            },
          ],
          Liquidity: [
            {
              id: 6,
              content: "liquidity",
            },
          ],
          News: [
            {
              id: 7,
              content: "news",
            },
          ],
        }
      }
    }
  },
  watch: {
    "$store.state.chain": function() {
      this.force_rerender()
    }
  },
  mounted() {
    this.force_rerender()
  },
  setup() {
    let tabs = ref({})
    
    return { tabs };
  },
};
</script>
