<template>
  <th scope="col" class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
    <div class="flex flex-row items-center whitespace-nowrap">
      {{ name }}
      <div v-if="sortBy" :class="sortType == sortBy ? 'text-gray-200' : 'text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
        <SortAscendingIcon v-if="sortType == sortBy && !isReverse" class="h-5 w-5" aria-hidden="true" />
        <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
      </div>
      <div v-if="tooltip" class="whitespace-normal">
        <InformationCircleIcon
          v-tippy="{
            content: tooltip,
            appendTo: 'parent',
            theme: 'bog',
            animation: 'shift-toward-subtle',
            arrow: false,
            maxWidth: '350px',
          }"
          class="h-5 w-5 text-gray-500 ml-2"
          aria-hidden="true"
        />
      </div>
    </div>
  </th>
</template>

<script>
import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon } from "@heroicons/vue/outline";

export default {
  props: ["sortType", "sortBy", "name", "isReverse", "tooltip"],
  components: {
    SortAscendingIcon,
    SortDescendingIcon,
    InformationCircleIcon,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
th:first-child {
  border-radius: 100px 0 0 100px;
}
th:last-child {
  border-radius: 0 100px 100px 0;
}
</style>
