export default {
  "charts.common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
  "charts.common.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tersalin!"])},
  "charts.common.copyaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin Alamat"])},
  "charts.common.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copotkan"])},
  "charts.common.yourwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dompet Anda"])},
  "charts.common.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dompet"])},
  "charts.common.sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor"])},
  "charts.common.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli"])},
  "charts.components.topbar.24hrvol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 24 Jam"])},
  "charts.components.topbar.liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Likuiditas"])},
  "charts.components.topbar.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalisasi Pasar"])},
  "charts.components.topbar.bal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "charts.components.topbar.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukar"])},
  "charts.components.topbar.trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Order"])},
  "charts.components.topbar.bscscan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSCScan"])},
  "charts.components.topbar.singleblocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok Individu"])},
  "charts.components.topbar.plottrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tampilkan Transaksi"])},
  "charts.components.infobar.info.valuelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu LP bernilai: "])},
  "charts.components.infobar.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi"])},
  "charts.components.infobar.usertrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Anda"])},
  "charts.components.infobar.tokeninfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Token"])},
  "charts.components.infobar.liqinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informasi Likuiditas"])},
  "charts.components.infobar.time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waktu"])},
  "charts.components.infobar.traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditransaksikan"])},
  "charts.components.infobar.tokenprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga Token"])},
  "charts.components.infobar.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai"])},
  "charts.components.infobar.dex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX"])},
  "charts.components.infobar.tradebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buku Transaksi"])},
  "charts.components.infobar.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berita"])},
  "charts.components.infobar.connecttoviewtrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan dompet Anda untuk melihat transaksi Anda"])},
  "charts.components.infobar.transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "charts.components.infobar.holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pemegang"])},
  "charts.components.infobar.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrak"])},
  "charts.components.sidebar.goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kembali ke Bogged.Finance"])},
  "charts.components.sidebar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dasbor"])},
  "charts.components.sidebar.chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik"])},
  "charts.components.sidebar.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portofolio"])},
  "charts.components.sidebar.tokenexplorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penjelajah Token"])},
  "charts.components.sidebar.multichart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banyak Charts"])},
  "charts.components.sidebar.othertools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat Lainnya"])},
  "charts.components.sidebar.madeby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibuat dengan ❤ oleh "])},
  "charts.components.sidebar.bogtools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogTools"])},
  "charts.components.sidebar.version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
  "charts.components.infobar.tokeninfo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik Harga Langsung"])},
  "charts.components.infobar.tokeninfo.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situs"])},
  "charts.components.infobar.tokeninfo.telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "charts.components.infobar.tokeninfo.twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "charts.components.infobar.tokeninfo.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "charts.components.infobar.tokeninfo.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak"])},
  "charts.components.infobar.tokeninfo.waterfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterfall"])},
  "charts.components.infobar.tokeninfo.xsupplyof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["memiliki pasokan"])},
  "charts.components.infobar.tokeninfo.xcircsupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dengan pasokan beredar sebesar"])},
  "charts.components.infobar.tokeninfo.liqback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["likuiditas merupakan"])},
  "charts.components.infobar.tokeninfo.ofthemcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dari kapitalisasi pasar."])},
  "charts.views.portfolio.clicktoconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan Dompet Anda untuk Melihat Portofolio"])},
  "charts.views.portfolio.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harga"])},
  "charts.views.portfolio.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
  "charts.views.portfolio.totalvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Total"])},
  "charts.views.portfolio.24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Jam"])},
  "charts.views.portfolio.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K.Pasar"])},
  "charts.views.portfolio.stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake"])},
  "charts.views.portfolio.viewchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Grafik"])},
  "charts.views.portfolio.hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembunyikan"])},
  "charts.views.portfolio.yourportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portofolio Anda"])},
  "charts.views.portfolio.portfoliooverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambaran Portfolio"])},
  "charts.views.portfolio.favorited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourited"])},
  "charts.components.infobar.tokeninfo.basicdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merupakan token"])},
  "charts.views.home.welcometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat Datang di BogCharts v3"])},
  "charts.views.home.welcometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda bisa melihat grafik untuk token apapun dengan likuiditas di PancakeSwap secara gratis dengan BogCharts. Ini termasuk informasi penting seperti likuiditas dan kapitalisasi pasar. Pembaruan dan peningkatan layanan terhadap BogCharts akan dirilis dalam waktu mendatang."])},
  "charts.views.home.latestnews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berita Terkini"])},
  "charts.views.home.latestnews.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap v2 kini hadir!"])},
  "charts.views.home.latestnews.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22 April 2021"])},
  "charts.views.home.latestnews.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tukarkan token PancakeSwap v1 dan v2 dengan BogSwap, dan transaksi Anda akan disalurkan ke DEX terbaik, tanpa biaya tambahan."])},
  "charts.views.home.promote.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promosikan Token Anda"])},
  "charts.views.home.promote.titlelong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promosikan Token Anda dengan Bogged.Finance"])},
  "charts.views.home.promote.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada berbagai alat dan opsi untuk mempromosikan token Anda dengan Bogged.Finance, gratis dan berbayar. Klik dibawah untuk mencari tahu lebih lanjut tentang menambahkan bot harga ke Telegram Anda, menambahkan logo dan informasi token Anda ke BogCharts, menjadi #trending di Bogged.Finance, dan mengiklan di Bogged.Finance"])},
  "charts.views.home.promote.learnmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelajari Lebih Lanjut"])},
  "charts.views.home.trending.trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang Tren"])},
  "charts.views.home.trending.promoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipromosikan"])},
  "charts.views.home.trending.viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paling Banyak Dilihat"])},
  "charts.views.chart.loadingchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat Grafik"])},
  "charts.views.chart.lowliqwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token yang sedang ditampilkan memiliki likuiditas rendah, artinya transaksi kecil dapat mendampak harga secara signifikan. Ini bisa saja normal untuk token yang baru diluncurkan."])},
  "charts.views.chart.lpchartwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda sedang mencoba menampilkan token LP. Token ini seharusnya tidak ditransaksikan, namun dipecahkan menjadi token masing-masing di bagian Likuiditas di PancakeSwap atau APESwap."])},
  "charts.topics.tools.stoploss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss"])},
  "charts.topics.tools.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penembak Jitu"])},
  "charts.topics.tools.staking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staking"])},
  "charts.topics.tools.telegrampricebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot Harga Telegram"])},
  "charts.topics.titles.thirdparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat Pihak Ketiga"])},
  "charts.topics.titles.promote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promosikan Token Anda"])},
  "charts.home.premium.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibutuhkan"])},
  "charts.home.premium.unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buka"])},
  "charts.home.premium.unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbuka"])},
  "charts.home.premium.locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terkunci"])},
  "charts.home.premium.connectwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan dompet Anda"])},
  "charts.home.connectwalletshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan Dompet"])},
  "charts.home.premium.connectwalletfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungkan dompet Anda untuk mengakses fitur terbuka."])},
  "charts.home.premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur Premium"])},
  "charts.home.premium.congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat! Anda telah membuka semua fitur Bogged.Finance"])},
  "charts.home.premium.nextunlockpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur selanjutnya dibuka pada"])},
  "charts.home.premium.nextunlockafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOG lagi"])},
  "charts.home.premium.holdstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegang atau Stake $BOG untuk membuka fitur lebih lanjut pada BogCharts dan Bogged.Finance"])},
  "charts.home.premium.porttrackbasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelacak Portofolio Sederhana"])},
  "charts.home.premium.porttrackpro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelacak Portofolio Profesional"])},
  "charts.home.premium.porttrackbasic.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lacak portofolio Anda selama rentang waktu dengan grafik nilai portfolio per hari."])},
  "charts.home.premium.porttrackpro.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitur Premium"])},
  "charts.home.premium.limitorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Order"])},
  "charts.home.premium.limitorders.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Order di Bogged.Finance untuk token apapun di PancakeSwap v1/v2 dan ApeSwap."])},
  "charts.home.premium.stoplosses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Losses"])},
  "charts.home.premium.stoplosses.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat pesanan Stop Loss di Bogged.Finance"])},
  "charts.home.premium.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOGSniper"])},
  "charts.home.premium.sniper.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snipe peluncuran token dengan BOGSniper di Bogged.Finance."])},
  "charts.home.premium.bogswapadv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogSwap Lanjutan"])},
  "charts.home.premium.bogswapadv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buatlah transaksi lebih cepat &amp; dan lebih efektif dengan fitur BogSwap Lanjutan."])},
  "charts.home.premium.bogswapadv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastikan pembelian Anda sukses dengan tidak melewati batas pembelian token tersebut."])},
  "charts.home.premium.bogswapadv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lewati Batas Transaksi pada setiap token di BSC."])},
  "charts.home.premium.trailingstop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing Stop Loss."])},
  "charts.home.premium.trailingstop.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan Trailing Stop Loss di Bogged.Finance."])}
}