<template>
  <div class="fade-in flex space-x-4 flex-shrink-0 items-center">
    <toggleFavorite v-bind:address="address" v-bind:tokenImageUrl="tokenImageUrl" v-bind:tokenName="tokenName" />
    <a :href="'/?c=' + tokenChain() + '&t=' + address" class="fade-in flex space-x-4 flex-shrink-0 items-center">
      <span v-if="tokenIndex">
        <span v-if="tokenIndex == 1" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-gold bg-opacity-40 font-semibold dark:text-gold-light text-gold-dark border-2 border-gold-dark">{{ tokenIndex }}</span>
        <span v-else-if="tokenIndex == 2" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-gray-200 bg-opacity-40 font-semibold dark:text-gray-100 text-gray-400 border-2 border-gray-400">{{ tokenIndex }}</span>
        <span v-else-if="tokenIndex == 3" class="text-sm font-heading h-7 w-7 rounded-md items-center flex place-content-center bg-bronze bg-opacity-40 font-semibold dark:text-bronze-light text-bronze-dark border-2 border-bronze-dark">{{
          tokenIndex
        }}</span>
        <span v-else class="text-2xs font-heading h-7 w-7 rounded-md items-center flex place-content-center from-gray-200 to-gray-300 bg-gradient-to-br dark:from-gray-800 dark:to-gray-700 font-semibold dark:text-gray-500">{{ tokenIndex }}</span>
      </span>
      <img class="w-8 h-8 rounded-full cursor-default bg-white" v-bind:src="tokenImageUrl" @error="altSymbol" />
      <div class="ml-4">
        
        <div class="text-sm font-medium font-heading text-gray-900 dark:text-white">
          {{ tokenName }}
        </div>
        <div v-html="price" class="text-sm text-gray-600 dark:text-gray-300 mt-1"></div>
      </div>
    </a>
  </div>
</template>

<script>
import toggleFavorite from "@/components/Favorite/toggleFavorite.vue";

export default {
  props: ["tokenImageUrl", "address", "tokenName", "tokenIndex", "price", "chain"],
  data() {
    return {};
  },
  components: {
    toggleFavorite,
  },
  methods: {
    tokenChain() {
      if (this.$props.chain) {
        return this.$props.chain
      } else {
        return this.$store.state.chain
      }
    },
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
