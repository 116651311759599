<template>
  <div class="flex relative pt-16 h-full">
    <div
      v-bind:class="{ hidden: !isOpen || !isMobile }"
      @click="toggleSidebar"
      style="backdrop-filter: blur(1.2px)"
      class="top-0 fixed z-20 w-full left-0 h-full bg-gray-900 bg-opacity-60"
    ></div>
    <div
      :class="[
        !isMobile && isOpen ? 'w-75' : 'w-16',
        isMobile && isOpen ? 'translate-x-0 w-75' : '',
        isMobile && !isOpen ? 'opacity-0 invisible' : '',
      ]"
      class="flex fixed justify-between border-r dark:border-gray-700 duration-200 overflow-hidden border-gray-200 flex-col z-40 inset-y-0 left-0 bg-white dark:bg-gray-800 xl:static inset-0 pb-4"
    >
      <nav class="select-none overflow-y-auto overflow-x-hidden pt-16 pb-4 xl:pt-0">
        <div class="md:hidden flex flex-row dark:bg-gray-900 bg-gray-100 dark:bg-opacity-60 py-3 justify-between px-2">
          <networkToggler />
          <button
            @click="openSettingsModal"
            type="button"
            class="flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white hover:bg-gray-50 hover:bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-gray-600"
          >
            <CogIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mx-2" v-on="isMobile ? { click: toggleSidebar } : {}">
          <router-link
            class="flex items-center duration-200 mt-2 sm:mt-4 p-3 rounded-lg"
            :class="[$route.name === 'Home' ? activeClass : inactiveClass]"
            to="/home"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex-shrink-0 fill-current" fill="none" viewBox="0 0 25 24">
              <path
                fill=""
                d="M18.5 21h-12a3 3 0 01-3-3V8.765a3 3 0 011.543-2.622l6-3.333a3.002 3.002 0 012.914 0l6 3.333A3 3 0 0121.5 8.765V18a3 3 0 01-3 3z"
                opacity=".35"
              />
              <path
                fill=""
                d="M15.5 21h-6v-6a2 2 0 012-2h2a2 2 0 012 2v6zM20.999 9.23c-.247 0-.496-.061-.727-.189l-6.839-3.8a1.909 1.909 0 00-1.864 0l-6.839 3.8a1.5 1.5 0 11-1.457-2.623l6.839-3.8a4.888 4.888 0 014.778 0l6.839 3.8a1.5 1.5 0 01-.73 2.812z"
              />
            </svg>
            <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap">{{
              $t("charts.components.sidebar.home")
            }}</span>
          </router-link>
        </div>

        <div class="mx-2" v-on="isMobile ? { click: toggleSidebar } : {}">
          <!-- <router-link class="flex items-center duration-200 mt-2 sm:mt-4 p-3 rounded-lg" :class="[$route.name === 'Chart' ? activeClass : inactiveClass]" to="/"> -->
          <router-link
            v-on:click="$store.dispatch('loadToken', $store.state.token)"
            :to="'/?c=' + $store.state.chain + '&t=' + $store.state.token"
            class="flex items-center duration-200 mt-2 sm:mt-4 p-3 rounded-lg"
            :class="[$route.name === 'Chart' ? activeClass : inactiveClass]"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex-shrink-0 fill-current" fill="none" viewBox="0 0 25 24">
              <path
                fill=""
                d="M6.5 21h-2a2 2 0 01-2-2v-3a2 2 0 012-2h2a2 2 0 012 2v3a2 2 0 01-2 2zM20.5 21h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2v6a2 2 0 01-2 2zM13.5 21h-2a2 2 0 01-2-2v-9a2 2 0 012-2h2a2 2 0 012 2v9a2 2 0 01-2 2z"
                opacity=".35"
              ></path>
              <path
                fill=""
                d="M5.52 9a1 1 0 01-.504-1.865l6.982-4.04c.235-.135.514-.168.772-.097l7 1.973a1 1 0 01-.543 1.925l-6.594-1.858L6.02 8.865a.995.995 0 01-.5.135z"
              ></path>
              <path
                fill=""
                d="M19.5 8a2 2 0 100-4 2 2 0 000 4zM12.5 6a2 2 0 100-4 2 2 0 000 4zM5.5 10a2 2 0 100-4 2 2 0 000 4z"
              ></path>
            </svg>

            <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap">{{
              $t("charts.components.sidebar.chart")
            }}</span>
          </router-link>
        </div>

        <div :class="!cryptocurrenciesDropdown ? 'h-12' : ''" class="mx-2 duration-100">
          <div
            class="flex items-center justify-between duration-200 mt-2 sm:mt-4 p-3 rounded-lg"
            :class="[$route.name === 'Cryptocurrencies' ? activeClass : inactiveClass]"
          >
            <router-link
              v-on="isMobile ? { click: toggleSidebar } : {}"
              to="/cryptocurrencies"
              class="flex flex-row items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex-shrink-0 fill-current" fill="none" viewBox="0 0 24 24">
                <path d="M18 21H6a3 3 0 01-3-3V6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3z" opacity=".35" />
                <path
                  d="M17 11h-6a1 1 0 000 2h6a1 1 0 000-2zM7 13a1 1 0 100-2 1 1 0 000 2zM17 7h-6a1 1 0 000 2h6a1 1 0 000-2zM7 9a1 1 0 100-2 1 1 0 000 2zM17 15h-6a1 1 0 000 2h6a1 1 0 000-2zM7 17a1 1 0 100-2 1 1 0 000 2z"
                />
              </svg>

              <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap"
                >Cryptocurrencies</span
              >
            </router-link>
            <svg
              @click="
                cryptocurrenciesDropdown = !cryptocurrenciesDropdown;
                walletDropdown = false;
              "
              xmlns="http://www.w3.org/2000/svg"
              :class="!cryptocurrenciesDropdown ? 'rotate-0' : '-rotate-180'"
              class="transform h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <div
            style="min-width: 271px"
            v-if="isOpen"
            :class="!cryptocurrenciesDropdown ? 'opacity-0 invisible h-0' : 'opacity-100'"
            class="rounded-2xl overflow-hidden transition-all duration-300 bg-gray-100 dark:bg-gray-900 mt-2"
          >
            <router-link
              v-on="isMobile ? { click: toggleSidebar } : {}"
              :class="[$route.name === 'Cryptocurrencies' ? 'dark:text-white text-gray-800' : 'dark:text-gray-400 text-gray-500']"
              class="flex cursor-pointer items-center duration-200 p-3 pl-7 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:bg-opacity-50 dark:hover:text-gray-100 hover:text-gray-900"
              to="/cryptocurrencies"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
                />
              </svg>
              <span :class="!cryptocurrenciesDropdown ? 'opacity-0' : 'opacity-100'" class="ml-2 overline duration-100"
                >Ranking</span
              >
            </router-link>
            <router-link
              v-on="isMobile ? { click: toggleSidebar } : {}"
              :class="[$route.name === 'Trending' ? 'dark:text-white text-gray-800' : 'dark:text-gray-400 text-gray-500']"
              class="flex cursor-pointer items-center duration-200 p-3 pl-7 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:bg-opacity-50 dark:hover:text-gray-100 hover:text-gray-900"
              to="/trending-tokens"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                  clip-rule="evenodd"
                />
              </svg>
              <span :class="!cryptocurrenciesDropdown ? 'opacity-0' : 'opacity-100'" class="ml-2 overline duration-100"
                >Trending</span
              >
            </router-link>
            <router-link
              v-on="isMobile ? { click: toggleSidebar } : {}"
              :class="[
                $route.name === 'Gainers and Losers' ? 'dark:text-white text-gray-800' : 'dark:text-gray-400 text-gray-500',
              ]"
              class="flex cursor-pointer items-center duration-200 p-3 pl-7 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:bg-opacity-50 dark:hover:text-gray-100 hover:text-gray-900"
              to="/gainers-losers"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                  clip-rule="evenodd"
                />
              </svg>
              <span :class="!cryptocurrenciesDropdown ? 'opacity-0' : 'opacity-100'" class="ml-2 overline duration-100"
                >Gainers & Losers</span
              >
            </router-link>
            <router-link
              v-on="isMobile ? { click: toggleSidebar } : {}"
              :class="[$route.name === 'Favorites' ? 'dark:text-white text-gray-800' : 'dark:text-gray-400 text-gray-500']"
              class="flex cursor-pointer items-center duration-200 p-3 pl-7 hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:bg-opacity-50 dark:hover:text-gray-100 hover:text-gray-900"
              to="/favorites"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <span :class="!cryptocurrenciesDropdown ? 'opacity-0' : 'opacity-100'" class="ml-2 overline duration-100"
                >Favorites</span
              >
            </router-link>
          </div>
        </div>

        <div v-if="true || $store.state.chain == 'bsc'">
          <div :class="!walletDropdown ? 'h-12' : ''" class="mx-2 duration-100">
            <div
              class="flex items-center justify-between duration-200 mt-2 sm:mt-4 p-3 rounded-lg"
              :class="[$route.name === 'Portfolio' ? activeClass : inactiveClass]"
            >
              <div v-on="isMobile ? { click: toggleSidebar } : {}">
                <router-link to="/portfolio" class="flex flex-row items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 flex-shrink-0 fill-current"
                    fill="none"
                    viewBox="0 0 25 24"
                  >
                    <path
                      opacity="0.35"
                      d="M18 21H6C4.343 21 3 19.657 3 18V6H18C19.657 6 21 7.343 21 9V18C21 19.657 19.657 21 18 21Z"
                    />
                    <path
                      d="M17.5 15C18.3284 15 19 14.3284 19 13.5C19 12.6716 18.3284 12 17.5 12C16.6716 12 16 12.6716 16 13.5C16 14.3284 16.6716 15 17.5 15Z"
                    />
                    <path d="M3 6C3 4.343 4.343 3 6 3H15C16.657 3 18 4.343 18 6H3Z" />
                  </svg>
                  <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap">{{
                    $t("charts.components.sidebar.portfolio")
                  }}</span>
                </router-link>
              </div>
              <svg
                v-if="isWalletConnected"
                @click="
                  walletDropdown = !walletDropdown;
                  cryptocurrenciesDropdown = false;
                "
                xmlns="http://www.w3.org/2000/svg"
                :class="!walletDropdown ? 'rotate-0' : '-rotate-180'"
                class="transform h-5 w-5 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <transition name="opacity">
              <div
                style="min-width: 271px"
                v-if="isOpen && isWalletConnected && walletDropdown"
                :class="!walletDropdown ? 'opacity-0 invisible' : 'opacity-100'"
                class="mt-4"
              >
                <miniPortfolio />
              </div>
            </transition>
          </div>
        </div>

        <div class="mx-2" v-on="isMobile ? { click: toggleSidebar } : {}">
          <router-link
            class="flex items-center duration-200 mt-2 sm:mt-4 p-3 rounded-lg"
            :class="[$route.name === 'Multichart' ? activeClass : inactiveClass]"
            to="/multichart"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex-shrink-0 fill-current" fill="none" viewBox="0 0 24 24">
              <path d="M9 11H5a2 2 0 01-2-2V5a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2z" />
              <path
                d="M19 11h-4a2 2 0 01-2-2V5a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2zM9 21H5a2 2 0 01-2-2v-4a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2z"
                opacity=".35"
              />
              <path d="M19 21h-4a2 2 0 01-2-2v-4a2 2 0 012-2h4a2 2 0 012 2v4a2 2 0 01-2 2z" />
            </svg>

            <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap">{{
              $t("charts.components.sidebar.multichart")
            }}</span>
          </router-link>
        </div>

        <div v-if="isOpen">
          <div class="ml-6 mt-10 text-gray-600 overline">Other</div>

          <div class="mx-2">
            <router-link
              class="flex items-center duration-200 mt-2 p-2 pl-0 rounded-lg"
              :class="[$route.name === 'Promote' ? activeClass : inactiveClass]"
              to="/promote"
            >
              <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap">{{
                $t("charts.topics.titles.promote")
              }}</span>
            </router-link>
          </div>

          <div class="mx-2">
            <router-link
              class="flex items-center duration-200 mt-2 p-2 pl-0 rounded-lg"
              :class="[$route.name === 'Tools' ? activeClass : inactiveClass]"
              to="/tools"
            >
              <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap"
                >Tools for Teams</span
              >
            </router-link>
          </div>

          <div class="mx-2">
            <router-link
              class="flex items-center duration-200 mt-2 p-2 pl-0 rounded-lg"
              :class="[$route.name === 'promoteTelegram' ? activeClass : inactiveClass]"
              to="/promotetelegram"
            >
              <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap"
                >Free Price Bot</span
              >
            </router-link>
          </div>

          <div class="mx-2">
            <a
              target="_blank"
              href="https://app.bogged.finance"
              class="flex items-center duration-200 mt-2 p-2 pl-0 rounded-lg dark:text-gray-400 dark:hover:bg-opacity-10 hover:bg-opacity-40 hover:bg-gray-100 dark:hover:text-gray-100 text-gray-500 dark:hover:bg-gray-300 hover:text-gray-900"
            >
              <span :class="!isOpen ? 'opacity-0' : 'opacity-100'" class="mx-4 overline duration-100 whitespace-nowrap"
                >Trading Tools</span
              >
            </a>
          </div>
        </div>
      </nav>
      <div
        v-if="isOpen"
        style="min-width: 300px"
        :class="!isOpen ? 'opacity-0' : 'opacity-100'"
        class="block duration-75 border-t border-gray-200 dark:border-gray-700 ease-in-out"
      >
        <div class="px-5 space-y-6 mt-6">
          <div class="flex flex-row items-center">
            <div class="flex flex-row justify-between w-full space-x-2">
              <div class="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-7 h-7 mr-2 fill-current dark:text-white text-gray-200"
                  fill="none"
                  viewBox="0 0 70 70"
                >
                  <g clip-path="url(#clip0)">
                    <path d="M34.62 69.24a34.62 34.62 0 100-69.24 34.62 34.62 0 000 69.24z" />
                    <path fill="#101722" d="M42.78 18H26.46l-17 16.66 17 16.66h16.32l17-16.66-17-16.66z" />
                    <path
                      fill="#fff"
                      d="M34.62 45.26a10.549 10.549 0 01-7.49-3.13A10.75 10.75 0 0124 34.57h5a5.66 5.66 0 001 3.12 5.55 5.55 0 005.72 2.39 5.64 5.64 0 002-10.19 5.49 5.49 0 00-3.09-.95v-5.07A10.57 10.57 0 0142.11 27a10.78 10.78 0 010 15.13 10.53 10.53 0 01-7.49 3.13z"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path fill="#fff" d="M0 0h70v70H0z" />
                    </clipPath>
                  </defs>
                </svg>

                <span
                  class="dark:text-gray-300 text-gray-700 text-sm cursor-pointer"
                  @click="$store.dispatch('loadToken', '0xB09FE1613fE03E7361319d2a43eDc17422f36B09')"
                  >${{ maxDigits($store.state.bogPrice, 2) }}</span
                >
              </div>
            </div>
            <div class="space-x-3 flex flex-row">
              <div class="flex flex-row items-center">
                <a href="https://t.me/boggedfinance" target="_blank"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-300 hover:opacity-70 dark:hover:opacity-100 dark:text-gray-400 dark:hover:text-white transition-all duration-300 fill-current h-7 w-7"
                    fill="none"
                    viewBox="0 0 48 48"
                  >
                    <path
                      d="M5.852 26.196l6.17 2.418a2.36 2.36 0 011.376 1.454l2.574 7.836a2.498 2.498 0 003.96 1.152l4.456-3.658a1.463 1.463 0 011.922.056l5.702 5.26c2.07 1.91 5.432.816 5.982-1.946l5.958-29.922c.372-1.87-1.494-3.396-3.254-2.66L5.79 20.786c-2.416 1.014-2.378 4.454.062 5.41z"
                      opacity=".35"
                    />
                    <path
                      d="M22.916 32.542l13.98-18.326c.656-.86-.422-1.964-1.296-1.33L12.934 29.29c.196.23.368.484.464.778l2.574 7.836a2.463 2.463 0 001.5 1.546c1.806.512 5.444-6.908 5.444-6.908z"
                    />
                  </svg>
                </a>
              </div>
              <div class="flex flex-row items-center">
                <a href="https://twitter.com/boggedfinance" target="_blank"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-300 hover:opacity-70 dark:hover:opacity-100 dark:text-gray-400 dark:hover:text-white transition-all duration-300 fill-current h-7 w-7"
                    fill="none"
                    viewBox="0 0 48 48"
                  >
                    <path d="M32 12a2 2 0 10.001 4.001A2 2 0 0032 12z" />
                    <path
                      d="M43.266 9.552c.626-.736-.16-1.82-1.052-1.446-1.49.624-3.12 1.182-4.276 1.41-.058.016-.106.034-.162.05a9.656 9.656 0 00-6.806-2.798c-5.352 0-9.692 4.34-9.692 9.692 0 .282-.024.802 0 1.076-6.052 0-10.892-2.656-14.62-6.36-.946-.94-2.554-.304-2.626 1.026-.022.366-.032.734-.032 1.096 0 3.018 2.358 5.982 6.032 7.818-.676.174-1.422.3-2.198.3-.71 0-1.696-.292-2.494-.602a.811.811 0 00-1.072.984c.952 3.34 4.778 5.592 8.232 6.286-.808.476-2.436.524-3.23.524-.234 0-.718-.044-1.24-.104-.844-.096-1.414.858-.914 1.544 1.694 2.328 4.904 3.618 7.99 3.672-2.092 1.64-5.532 2.556-8.964 2.954-1.212.142-1.476 1.8-.366 2.306 3.15 1.438 6.296 2.25 9.786 2.25 16.004 0 25.1-12.198 25.1-23.696 0-.186-.004-.572-.01-.962 0-.038.01-.076.01-.114 0-.058-.018-.114-.018-.172a26.146 26.146 0 00-.02-.708 17.69 17.69 0 003.252-3.04c.334-.402-.066-.99-.562-.828-1.072.35-2.19.596-3.342.734 1.018-.608 2.276-1.694 3.294-2.892z"
                      opacity=".35"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <themeToggler />
            <select
              class="rounded-md caption text-gray-600 dark:text-gray-200 py-1.5 pl-3 pr-8 border-none transition-colors duration-200 cursor-pointer bg-gray-100 dark:bg-gray-900"
              v-model="$i18n.locale"
              @change="langChange"
            >
              <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                {{ localeStrings[lang] }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeToggler from "@/components/Sidebar/themeToggler";
import networkToggler from "@/components/Header/networkToggler";
import { Services } from "@/services";
import miniPortfolio from "@/components/Sidebar/miniPortfolio.vue";
import { maxDigits } from "@/filters";
import { CogIcon } from "@heroicons/vue/solid";
import i18n from "@/i18n";

export default {
  components: { themeToggler, miniPortfolio, maxDigits, networkToggler, CogIcon },
  beforeMount() {
    this.$store.dispatch("initTheme");
  },

  data: function () {
    return {
      activeClass:
        "dark:bg-primary dark:bg-opacity-10 dark:text-primary border-gray-100 bg-opacity-20 text-primary-dark bg-primary",
      inactiveClass:
        "dark:text-gray-400 dark:hover:bg-opacity-10 hover:bg-opacity-40 hover:bg-gray-100 dark:hover:text-gray-100 text-gray-500 dark:hover:bg-gray-300 hover:text-gray-900",
      walletDropdown: true,
      cryptocurrenciesDropdown: false,
      windowWidth: window.innerWidth,
      langs: ["en", "cn", "es", "ru", "id", "nl", "it", "fr"],
      localeStrings: {
        en: "English",
        nl: "Nederlands",
        ru: "русский",
        ua: "Ukrainian",
        fr: "Français",
        de: "Deutsch",
        es: "Español",
        cn: "官话",
        id: "Indonesia",
        it: "Italiano",
      },
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    try {
      this.$i18n.locale = localStorage.getItem("lang") || "en";
    } catch (error) {
      console.error("Error setting locale, defaulting to en.");
    }
  },
  methods: {
    maxDigits,
    openSettingsModal: function () {
      this.$store.commit("openSettingsModal");
    },
    toggleSidebar: function () {
      this.$store.commit("toggleSidebar");
    },
    langChange: function () {
      localStorage.setItem("lang", this.$i18n.locale);
      console.log("Language Changed to! " + this.$i18n.locale);
    },
    showSidebar: function () {
      if (!this.isOpen) {
        this.$store.commit("toggleSidebar");
      }
    },
    connectWallet: async function () {
      let web3 = await Services.getWeb3(this.$store.state.chain);
      this.$store.commit("connectWallet", web3);
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 1280;
    },
    isOpen: function () {
      return this.$store.getters.sidebarOpen;
    },
    animateWallet: function () {
      // if there is no walletTokenDict saved, user has never used wallet feature before.
      // Animate at them to let them know about the feature
      return !localStorage.getItem("walletTokenDict");
    },
    walletReloading: function () {
      return this.$store.state.walletReloading;
    },
    walletFirstLoadComplete: function () {
      return this.$store.state.walletFirstLoadComplete;
    },
    isWalletConnected: function () {
      return this.$store.getters.walletConnected;
    },
    ...mapGetters({ theme: "getTheme" }),
  },
  watch: {
    theme(newTheme, oldTheme) {
      newTheme === "light"
        ? document.querySelector("html").classList.remove("dark")
        : document.querySelector("html").classList.add("dark");
    },
  },
};
</script>
