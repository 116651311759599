<template>
  <div class="w-full mt-8 justify-center shadow-smooth p-8 max-w-2xl text-center mx-auto rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-gray-900 dark:text-white">
    This feature is not yet supported on <span class="capitalize">{{ chainName.toUpperCase() }}</span>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {
    chainName() {
      return this.$store.state.chain;
    },
  },
};
</script>
