<template>
  <div class="container mx-auto my-8 px-4">
    <div class="flex flex-row justify-between">
      <div>
        <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">
          Honeypot test
        </h1>        
        <h3 class="text-white">
          For internal use only
        </h3>
      </div>		
    </div>
	 <input
        v-on:keyup.enter="capturePress($event)"     
        onfocus="this.select();"
        class="w-full text-white rounded-lg transition-colors dark:bg-opacity-40 focus:ring-0 dark:focus:border-gray-600 focus:border-gray-300 absolute z-40 border-gray-200 border h-11 shadow-inner text-gray-700 bg-gray-200 dark:border-gray-700 dark:hover:border-gray-600 dark:bg-gray-900 text-xs dark:text-secondary-light placeholder-gray-500 dark:placeholder-gray-400 py-3 pr-4 pl-10"
        type="text"
        placeholder="Enter a token address to test"
        @input="tokenChanged"
        :value="token"
           
        
        autocomplete="off"
      />
	
  </div>    
  <br>
  <div class="w-full text-white">
		{{ message }}
	</div>
  <div class="w-full text-white">
    <span v-if="test_result">Test result: {{test_result}}</span>
  </div>
  <div class="w-full text-white">
    <span v-if="fail_pct != null">Failures: {{Math.round(fail_pct*100)/100}} %</span>
  </div>
  <div class="w-full text-white">
    <table>
      <tr v-bind:key="item" v-for="(item, key) in results">
        <td>
          <div>{{key}} </div>
          <div>{{item}}</div>
        </td>
      </tr>
    </table>		
	</div>
  <br>
  <p  class="text-white"><b>Notes for interpreting this test:</b></p>
  <ul class="text-white">
    <li> - The test does a transfer simulation from 30 of the top 100 wallets, avoid the deployer wallet and liq pools.</li>
    <li> - Failure messages do not necessarily correlate with the real failure reason. Devs can make the fail reason say anything they want. This is often used for misdirection.</li>
    <li> - The test makes sure wallet balances are > 0, so any "exceeds balance" messages are fake.</li>
  </ul>
  <br>
</template>

<script>
import { Services } from "@/services";


export default {
	data() {
    return {
      token: this.$store.state.token,
      waitingForHoneypot: false,
		  message: null,
      results: [],
      fail_pct: null,
      test_result: null,
      addys_tested: [],
    };
  },
	mounted() {
    this.token = ''    
    this.$store.state.hptest=true
    this.$store.dispatch("loadToken", '');
    /*
    if (this.$store.state.chain == "bsc") {
      this.waitingForHoneypot = true;
      setTimeout(() => {
        this.waitForHoneypotVars(this.$store.state.token);
      }, 200);
    }*/
  },
  components: {

  },

  methods: {
  capturePress: function(event) {
    console.log(event)
    this.loadToken()
    

  },
  tokenChanged(e) {    
    this.token = e.target.value;
  },
	loadToken() {
      this.searching = false;
      this.message= null,
      this.results= [],
      this.fail_pct= null,
      this.test_result= null,
      this.addys_tested= [],
      this.token = this.token.trim();
      //router.push("/?c=" + this.$store.state.chain + "&t=" + this.token);
      this.$store.state.hptest=true
      this.$store.dispatch("loadToken", this.token);
    },
    waitForHoneypotVars(token) {
      if (token != this.$store.state.token) {
        return;
      } // token changed while we were waiting
      if (this.$store.state.chain == "bsc" && this.$store.state.currentExchange && this.$store.state.top100 && this.$store.state.tokenDeployer && this.$store.state.top100IsContract) {
        this.waitingForHoneypot = false;
        let wl = this.$store.state.whitelistData
        // whitelist info is obfuscated from api.
        // wl[0] is the token category (abbreviated): bogged-token-list, promoted, manual whitelist or alert
        // wl[1] is a flag (0/1) specifying whether the whitelist should be overridden (if 1, the honeypot test can run)
        if (wl && (wl[0]=='btl' || wl[0]=='p' || wl[0]=='mw') && !wl[1]) { 
          console.log('whitelist')
          console.log(wl)
          //return
        }
        if (!this.$store.state.localHoneypotTestStarted) {
          this.$store.state.localHoneypotTestStarted = true;
          this.message = "Test in progress..."
          let self = this
          Services.honeypotTest(this.$store, this.$store.state.token, 30).then(function(testResult) {
            if (!testResult) {
              self.message="Test did not complete"
            } else {
              self.message = "Completed"
              console.log(testResult)            
              self.results = testResult.transfer_results
              self.fail_pct = testResult.fail_pct
              self.test_result = testResult.test_result
              self.addys_tested = testResult.addys_tested
            }
          });
        }
      } else {
        //console.log('waiting for honeypot vars...')
        setTimeout(() => {
			    this.message = "Waiting for top100 & deployer info..."
          this.waitForHoneypotVars(this.$store.state.token);
        }, 200);
      }
    }
  },
  watch: {
	  "$store.state.token": function() {
      if (this.$store.state.chain == "bsc") {
        this.waitingForHoneypot = true;
        setTimeout(() => {
          this.waitForHoneypotVars(this.$store.state.token);
        }, 200);
      }
    },
  },
};
</script>
