<template>
  <div class="relative flex overflow-hidden dark:bg-gray-900 bg-background">
    <div class="flex-1 overflow-auto focus:outline-none">
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
        <!-- Page header -->
        <div class="dark:bg-gray-800 bg-white dark:bg-opacity-70 dark:shadow">
          <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div class="py-6 md:flex md:items-center md:justify-between">
              <div class="flex-1 min-w-0">
                <!-- Profile -->
                <div class="flex items-center">
                  <div class="hidden h-16 w-16 bg-secondary bg-opacity-20 rounded-full sm:flex items-center place-content-center">
                    <span class="fade-in text-lg text-secondary select-none font-heading font-bold"
                      ><span v-if="isWalletConnected">{{ address.substr(address.length - 2) }}</span
                      ><span v-else>-</span></span
                    >
                  </div>
                  <div>
                    <div class="flex items-center">
                      <div class="sm:hidden h-16 w-16 bg-secondary bg-opacity-20 rounded-full flex items-center place-content-center">
                        <span class="fade-in text-lg text-secondary select-none font-heading font-bold">{{ address.substr(address.length - 2) }}</span>
                      </div>
                      <h1 class="ml-3 text-2xl font-bold leading-7 dark:text-white text-gray-900 sm:leading-9 sm:truncate">
                        {{ greetingMessage }}
                      </h1>
                    </div>
                    <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dd v-if="isWalletConnected" class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                        <span class="h-4 w-4 mr-2 bg-success-bright bg-opacity-25 rounded-full flex items-center justify-center" aria-hidden="true">
                          <span class="h-2 w-2 bg-success-bright rounded-full"></span>
                        </span>
                        Connected
                      </dd>
                      <dd
                        v-tippy="{
                          content: 'address',
                          appendTo: 'parent',
                          theme: 'bog',
                          animation: 'shift-toward-subtle',
                          arrow: false,
                          maxWidth: 'none',
                        }"
                        class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6"
                      >
                        <UserCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {{ maxLength(address, 6) }}...{{ address.substr(address.length - 2) }}
                      </dd>
                      <dd class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <TerminalIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="pr-1">{{ $t("charts.components.sidebar.version") }}</span> <a class="hover:underline" target="_blank" href="https://docs.bogged.finance/bogged-finance/product-information/charts/changelog">{{ $store.getters.appVersion }}</a>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <a
                  target="_blank"
                  href="https://app.bogged.finance/swap?tokenIn=BNB&tokenOut=0xB09FE1613fE03E7361319d2a43eDc17422f36B09"
                  class="inline-flex button items-center px-4 py-2 text-sm font-medium rounded-md text-primary-dark dark:text-primary bg-primary bg-opacity-10 hover:bg-opacity-20 focus:outline-none focus:ring-1 focus:ring-primary"
                >
                  Buy BOG
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <featured-article />
          </div>
        </div>

        <div class="mt-8">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <article-list />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
const currentHour = new Date().getHours();

const greetingMessage =
  currentHour >= 4 && currentHour < 12 // after 4:00AM and before 12:00PM
    ? "Good morning."
    : currentHour >= 12 && currentHour <= 17 // after 12:00PM and before 6:00pm
    ? "Good afternoon."
    : currentHour > 17 || currentHour < 4 // after 5:59pm or before 4:00AM (to accommodate night owls)
    ? "Good evening." // if for some reason the calculation didn't work
    : "Welcome";

import { Dialog, DialogOverlay, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { BellIcon, MenuAlt1Icon, ScaleIcon, XIcon, CurrencyDollarIcon } from "@heroicons/vue/outline";
import { CashIcon, WifiIcon, ChevronDownIcon, ChevronRightIcon, UserCircleIcon, TerminalIcon, OfficeBuildingIcon, SearchIcon } from "@heroicons/vue/solid";
import { maxLength, maxDigits, bogintificPrice } from "@/filters";
import articleList from "@/components/articleList.vue";
import topCards from "@/views/Cryptocurrencies/components/topCards/topCards.vue";
import featuredArticle from "@/components/featuredArticle.vue";

export default {
  methods: {
    maxLength,
    maxDigits,
    bogintificPrice,
  },
  data() {
    return {
      greetingMessage: greetingMessage,
    };
  },
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    WifiIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
    UserCircleIcon,
    TerminalIcon,
    articleList,
    featuredArticle,
    topCards,
  },
  computed: {
    address: function() {
      return this.$store.getters.account || "";
    },
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
  },
};
</script>
