<template>
  <div v-if="articles.length">
    <div class="mt-16 bg-gradient-to-br from-purple-dark to-blue rounded-2xl" v-for="article in articles" v-bind:key="article.sys.id">
      <div class="flex lg:flex-row flex-col-reverse">
        <div class="lg:w-1/2 w-full flex place-content-center place-items-start flex-col pt-4 lg:pt-8 p-8 h-full">
          <div class="text-white mt-4 text-5xl font-semibold font-heading">{{ article.title }}</div>
          <div style="letter-spacing: -0.3px;" class="text-white max-w-sm mt-4 text-base font-medium">{{ article.description }}</div>
          <a href="https://app.bogged.finance/trade" target="_blank" class="bg-gray-900 transition-all duration-200 bg-opacity-50 hover:bg-opacity-70 text-white font-semibold font-heading inline-block px-6 py-3 mt-6 rounded-lg">Place Order</a>
        </div>
        <div class="lg:w-1/2 w-full flex place-content-center h-80 relative">
          <img class="absolute bottom-6 lg:bottom-12 flex object-contain md:object-cover h-full" v-bind:src="article.coverImage.url" />
        </div>
      </div>
    </div>
  </div>

  <div v-else class="flex space-x-10 mb-4 flex-row">
    <div class="w-full space-y-3" v-for="index in 1" :key="index">
      <div style="height: 240px;" class="bg-gray-200 dark:bg-gray-800 rounded-md w-full animate-pulse-disable skeleton-loader"></div>
      <div style="height: 12px;" class="bg-gray-200 dark:bg-gray-800 max-w-24 rounded-md w-full animate-pulse-disable skeleton-loader"></div>
      <div style="height: 24px;" class="bg-gray-200 dark:bg-gray-800 rounded-md w-full animate-pulse-disable skeleton-loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articles: [],
    };
  },

  async created() {
    this.articles = await this.getArticles();
  },
  methods: {
    getArticles: async () => {
      const query = `{
  featuredArticleCollection {
    items {
      sys {
        id
      }
      title
      coverImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      category
      description
    }
  }
}`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) => response.json());
        return response.data.featuredArticleCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
};
</script>

<style></style>
