<template>

  <not-available v-if="!onSupportedChain" />
  <div v-else class="container mx-auto my-8 px-4">
    <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">Find Your Next Investment</h1>
    <h3 class="text-gray-500 text-xl mb-10">
      Browse trending tokens on <span :class="$store.state.chain == 'bsc' ? 'uppercase' : 'capitalize'">{{ $store.state.chain.toUpperCase() }}</span> 🔥
    </h3>    
    <TabGroup>
      <TabList class="md:border-b border-gray-200 dark:border-gray-700 mb-4 mt-8 space-x-4 flex flex-row justify-between items-center">
        <div class="overflow-x-auto block whitespace-nowrap">
          <Tab v-slot="{ selected }" as="template">
            <button class="px-4 md:py-6 pb-3 pt-6 font-medium text-sm border-b-2 transition-colors duration-200" :class="[selected ? 'border-secondary text-secondary' : 'border-transparent text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 hover:border-gray-300 dark:hover:border-gray-600']">
              Trending
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button class="px-4 md:py-6 pb-3 pt-6 font-medium text-sm border-b-2 transition-colors duration-200" :class="[selected ? 'border-secondary text-secondary' : 'border-transparent text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 hover:border-gray-300 dark:hover:border-gray-600']">
              Most Viewed
            </button></Tab
          >
          <Tab v-slot="{ selected }" as="template">
            <button class="px-4 md:py-6 pb-3 pt-6 font-medium text-sm border-b-2 transition-colors duration-200" :class="[selected ? 'border-secondary text-secondary' : 'border-transparent text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 hover:border-gray-300 dark:hover:border-gray-600']">
              Promoted
            </button></Tab
          >
        </div>
        <!--  <select-time /> -->
      </TabList>
      <TabPanels>
        <TabPanel><trending /></TabPanel>
        <TabPanel><most-viewed /></TabPanel>
        <TabPanel><promoted /></TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import topCards from "./components/topCards/topCards.vue";
import Trending from "./components/Trending.vue";
import mostViewed from "./components/mostViewed.vue";
import Promoted from "./components/Promoted.vue";
import selectTime from "./components/selectTime.vue";
import newsMarquee from "./components/newsMarquee.vue";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";
import Popper from "vue3-popper";

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    bogintificPrice,
    maxDigits,
    Trending,
    mostViewed,
    Popper,
    selectTime,
    topCards,
    Promoted,
    newsMarquee,
    notAvailable,
  },
  computed: {
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("Trending");
    },
  },
  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
