<template>
  <not-available v-if="!onSupportedChain" />
  <iframe
    v-else
    allowtransparency="true"
    height="780"
    class="w-full"
    :src="embedSrc"
    v-resize="{ log: true }"
    width="100%"
    frameborder="0"
    id="myiframe"
  ></iframe>
</template>

<script>
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";

import { Component, Vue } from "vue-property-decorator";
//import iframeResize from 'iframe-resizer/js/iframeResizer';
import { iframeResizer, iframeResize } from "iframe-resizer";



export default {
  components: { notAvailable },
  data: function() {
    return {
      embedSrc: '',
    }
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize: function() {
      iframeResizer({ checkOrigin: false }, "#myiframe");
    },
  },
  computed: {
    src: function() {
      return this.embedSrc
    },
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("Swap");
    },
  },
  directives: {
   'resize': {
      bind: function(el, { value = {} }) {
        el.addEventListener('load', () => iframeResize(value, el))
      },
      unbind: function (el) {
        el.iFrameResizer.removeListeners();
      }
    }
  },
  watch: {
    "$store.state.token": function () {
      setTimeout(function() {        
        this.embedSrc = 'https://app.bogged.finance/' + this.$store.state.chain + '/swap?tokenIn=' + this.$store.state.chainTokenSymbol + '&tokenOut=' + this.$store.state.token + '&embed=1' + '&theme=' + this.$store.state.theme.theme
        console.log(this.embedSrc)
      }.bind(this), 500);      
    },
  },
  created() {
    //this.embedSrc = 'https://app.bogged.finance/' + this.$store.state.chain + '/swap?tokenIn=' + this.$store.state.chainTokenSymbol + '&tokenOut=' + this.$store.state.token + '&embed=1' + '&theme=' + this.$store.state.theme.theme
    //console.log(this.embedSrc)
    setTimeout(function() {        
      this.embedSrc = 'https://app.bogged.finance/' + this.$store.state.chain + '/swap?tokenIn=' + this.$store.state.chainTokenSymbol + '&tokenOut=' + this.$store.state.token + '&embed=1' + '&theme=' + this.$store.state.theme.theme
      console.log(this.embedSrc)
    }.bind(this), 500);      
  },
};
</script>
