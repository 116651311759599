<template>
  <swap-overlay />
  <not-available v-if="!onSupportedChain" />
  <div v-else style="height:max(100vh - 64px, 900px)">
    <div class="absolute z-10 right-8 bottom-8">
      <button
        v-tippy="{
          content: 'Add new chart',
          appendTo: 'parent',
          theme: 'bog-nowrap',
          animation: 'shift-toward-subtle',
          arrow: false,
          maxWidth: 'none',
          placement: 'left',
        }"
        class="text-secondary-dark flex items-center place-content-center bg-secondary hover:bg-opacity-70 transition-all duration-200 hover:text-secondary-light rounded-full h-12 w-12 font-heading text-2xl"
        @click="addItem"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
          />
        </svg>
      </button>
    </div>
    <div>
      <div class="w-full">
        <grid-layout
          v-model:layout.sync="layout"
          :col-num="layoutColNum"
          :row-height="10"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[0, 0]"
          :use-css-transforms="false"
          @layout-updated="layoutUpdatedEvent"
          @layout-ready="layoutReadyEvent"
        >
          <grid-item v-for="(item, idx) in layout" drag-allow-from=".draggable-handle" class="rounded-none overflow-hidden" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="idx">
            <div class="h-full relative flex flex-col">
              <div class="vue-resizable-handle z-20 absolute -bottom-8 -right-8 w-14 h-16 rotate-45 transform text-white">
                <div class="bg-gray-700 h-full w-full">
                  <div class="bottom-5 left-1 absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="flex flex-row items-center relative">
                <input
                  type="text"
                  v-model="item.token"
                  placeholder="Enter the token address"
                  @change="saveLocalStorage()"
                  class="flex caption text-secondary-light placeholder-gray-300 flex-1 h-10 transition-colors duration-200 border-0 focus:ring-0 bg-gray-900 dark:bg-opacity-50 dark:bg-gray-800"
                />

                <div class="absolute right-2 flex items-center space-x-2">
                  <button
                    class="text-gray-300 hover:text-white transition-colors duration-200"
                    @click="
                      openSwapOverlay();
                      $store.dispatch('loadToken', item.token);
                    "
                  >
                    Swap
                  </button>
                  <div class="draggable-handle text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 01-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 110-2h4a1 1 0 011 1v4a1 1 0 11-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 112 0v1.586l2.293-2.293a1 1 0 011.414 1.414L6.414 15H8a1 1 0 110 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h1.586l-2.293-2.293a1 1 0 011.414-1.414L15 13.586V12a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>

                  <button v-if="layout.length > 1" class="text-gray-400 opacity-50 hover:opacity-100 duration-200 transition-opacity" @click="removeItem(item.i)"><x-circle-icon class="h-6 w-6" aria-hidden="true" /></button>
                </div>
              </div>

              <iframe :src="'https://embed.bogged.finance/' + item.token + '?bogchart=1'" style="width:100%; height:100%;" class="bg-gray-800 bg-opacity-40" scrolling="no"></iframe>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </div>
  </div>
</template>

<script>
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";
import swapOverlay from "@/components/Swap/swapOverlay";
import { XCircleIcon } from "@heroicons/vue/solid";

export default {
  data: function() {
    return {
      counter: 0,
      layout: JSON.parse(localStorage.getItem("layout")) || [{ x: 0, y: 0, w: 6, h: 50, i: "0", token: "" }],
      layoutMap: [],
      layoutColNum: 12,
      itemW: 6,
      itemH: 50,
    };
  },
  methods: {
    saveLocalStorage: function() {
      localStorage.setItem("layout", JSON.stringify(this.layout));
    },
    openSwapOverlay: function() {
      this.$store.commit("openSwapOverlay");
    },
    addItem: function() {
      var itemW = parseInt(this.itemW, 10);
      var itemH = parseInt(this.itemH, 10);
      var addItem = {
        x: 0,
        y: this.layoutMap.length,
        w: itemW,
        h: itemH,
        i: this.layout[this.layout.length - 1] ? parseInt(this.layout[this.layout.length - 1].i, 10) + 1 : 0,
      };
      if (this.layoutMap.length) {
        let loop = 0;
        for (let r = 0, rLen = this.layoutMap.length; r < rLen; r++) {
          for (let c = 0; c <= this.layoutColNum - itemW; c++) {
            loop++;
            let res = this.regionalTest(c, r, itemW, rLen > r + itemH ? itemH : rLen - r);
            loop += res.loopCounter;
            if (res.result) {
              addItem = {
                x: c,
                y: r,
                w: itemW,
                h: itemH,
                i: parseInt(this.layout[this.layout.length - 1].i, 10) + 1,
              };
              c = this.layoutColNum + 1;
              r = rLen + 1;
            } else {
              c = res.offsetX;
            }
          }
        }
        console.log("loop" + loop);
      }
      for (let itemR = 0; itemR < itemH; itemR++) {
        for (let itemC = 0; itemC < itemW; itemC++) {
          if (!this.layoutMap[addItem.y + itemR]) {
            this.layoutMap[addItem.y + itemR] = new Array(this.layoutColNum);
            for (let i = 0; i < this.layoutColNum; i++) {
              this.layoutMap[addItem.y + itemR][i] = 0;
            }
          }
          this.layoutMap[addItem.y + itemR][addItem.x + itemC] = 1;
        }
      }
      this.layout.push(addItem);
    },
    genereatePlaneArr: function(data) {
      var map = [];
      if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          var one = data[i];
          for (var r = one.y; r < one.y + one.h; r++) {
            for (var c = one.x; c < one.x + one.w; c++) {
              if (!map[r]) {
                map[r] = new Array(this.layoutColNum);
                for (let i = 0; i < this.layoutColNum; i++) {
                  map[r][i] = 0;
                }
              }
              map[r][c] = 1;
            }
          }
        }
      }
      return map;
    },
    regionalTest: function(x, y, w, h) {
      let offsetX = 0,
        offsetY = 0,
        res = true,
        loopCounter = 0;
      for (let r = 0; r < h; r++) {
        for (let c = 0; c < w; c++) {
          loopCounter++;
          let point = this.layoutMap[y + r] ? this.layoutMap[y + r][x + c] : 0;
          if (point === 1) {
            res = false;
            offsetX = offsetX > x + c ? offsetX : x + c;
            c = offsetX - x;
            offsetY = offsetY > y + r ? offsetY : y + r;
            r = offsetY - y;
          }
        }
      }
      return {
        result: res,
        offsetX: offsetX,
        offsetY: offsetY,
        loopCounter,
      };
    },
    layoutReadyEvent: function(newLayout) {
      console.log("4Ready");
      this.layoutMap = this.genereatePlaneArr(this.layout);
    },
    layoutUpdatedEvent: function(newLayout) {
      console.log("Updated");
      this.layoutMap = this.genereatePlaneArr(this.layout);
    },
    rnd: function(m, n) {
      return (Math.random() * (m - n + 1) + n) | 0;
    },
    removeItem: function(val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout.splice(index, 1);
      localStorage.setItem("layout", JSON.stringify(this.layout));
    },
  },
  computed: {
    swapOverlayIsOpen: function() {
      return this.$store.getters.swapOverlayOpen;
    },
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("Multichart");
    },
  },
  components: {
    XCircleIcon,
    swapOverlay,
    notAvailable,
  },
};
</script>

<style>
.vue-resizable-handle {
  background: none !important;
}

.vue-grid-item.vue-grid-placeholder {
  background: #1bc870 !important;
}
</style>
