<template>
  <span class="relative z-0 inline-flex mb-4 shadow-sm rounded-md">
    <button
      @click="
        sortType = 'launchDate';
        isReverse = !isReverse;
      "
      type="button"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-100 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 focus:z-10 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-600"
    >
      Launch Date
      <div :class="sortType == 'launchDate' ? 'dark:text-gray-200 text-gray-700' : 'text-gray-300 dark:text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
        <SortAscendingIcon v-if="sortType == 'launchDate' && !isReverse" class="h-4 w-4" aria-hidden="true" />
        <SortDescendingIcon v-else class="h-4 w-4" aria-hidden="true" />
      </div>
    </button>

    <button
      @click="
        sortType = 'riskRating';
        isReverse = !isReverse;
      "
      type="button"
      class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-100 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 focus:z-10 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-600"
    >
      Risk Rating
      <div :class="sortType == 'riskRating' ? 'dark:text-gray-200 text-gray-700' : 'text-gray-300 dark:text-gray-500'" class="ml-2 inline-block group-hover:text-gray-300 duration-200 transition-colors">
        <SortAscendingIcon v-if="sortType == 'riskRating' && !isReverse" class="h-4 w-4" aria-hidden="true" />
        <SortDescendingIcon v-else class="h-4 w-4" aria-hidden="true" />
      </div>
    </button>
  </span>

  <div v-if="launches.length" class="flex flex-wrap overflow-visible">
    <div class="font-heading fade-in pr-4 pb-4 text-sm flex 2xl:w-1/4 xl:w-1/3 md:w-1/2 w-full justify-between" v-for="launch in sortTable" v-bind:key="launch.sys.id">
      <div class="dark:bg-gray-800 bg-white border rounded-lg border-gray-200 dark:border-gray-700 w-full p-4">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center">
            <div class="relative">
              <div :class="dateTime(launch.launchDate) < dateTime() ? 'bg-secondary' : 'bg-error-bright'" class="rounded-full h-2 w-2"></div>
              <div v-if="dateTime(launch.launchDate) < dateTime()" class="rounded-full h-2 w-2 absolute left-0 top-0 animate-ping bg-secondary"></div>
            </div>
            <span
              class="overline ml-2"
              v-html="dateTime(launch.launchDate) < dateTime() ? 'Launched ' + timeToLaunch(launch.launchDate) : 'Launching ' + timeToLaunch(launch.launchDate)"
              :class="dateTime(launch.launchDate) < dateTime() ? 'text-secondary' : 'dark:text-white text-gray-900'"
            >
            </span>
          </div>
          <socials-dropdown />
        </div>

        <div class="text-gray-900 dark:text-white">{{ launch.firstPublishedAt }}</div>

        <img class="w-16 h-16 rounded-full overflow-x-hidden my-4" v-bind:src="launch.logo.url" alt="" />
        <h4 class="font-heading text-lg text-gray-900 dark:text-white">{{ launch.name }}</h4>
        <div class="mt-2">
          <div class="flex justify-between items-center">
            <span class="dark:text-gray-400 text-gray-500 overline">KYC</span>
            <span :class="launch.kyc ? 'text-secondary' : 'text-error-bright'" class="overline">{{ launch.kyc ? "Yes" : "No" }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="dark:text-gray-400 text-gray-500 overline">Audit</span>
            <span :class="launch.audit ? 'text-secondary' : 'text-error-bright'" class="overline">{{ launch.audit ? "Yes" : "No" }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="dark:text-gray-400 text-gray-500 overline">Risk</span>
            <span :class="launch.riskRating < 2.49 ? 'text-secondary' : 'text-error-bright'" class="overline">{{ launch.riskRating }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="dark:text-gray-400 text-gray-500 overline">Launch</span>
            <span class="text-white caption">{{ dateTimeFormat(launch.launchDate) }}</span>
          </div>
        </div>
        <p class="caption mt-4 text-gray-700 dark:text-gray-200">{{ launch.description }}</p>
        <p class="text-2xs leading-snug mt-2 text-gray-400">Always do your own research. Bogged Finance is not partnered with {{ launch.name }}.</p>
        <div class="mt-6 flex flex-row space-x-2">
          <router-link
            v-if="launch.contract && dateTime(launch.launchDate) < dateTime()"
            class="dark:bg-gray-700 bg-gray-200 hover:bg-opacity-75 border dark:text-gray-100 text-gray-700 border-gray-300 dark:border-gray-600 px-3 py-1 rounded-md"
            v-on:click="$store.dispatch('loadToken', launch.contract)"
            :to="'/' + launch.contract"
            >View Chart</router-link
          >
          <a v-if="launch.contract" target="_blank" class="dark:bg-gray-700 bg-gray-200 hover:bg-opacity-75 border dark:text-gray-100 text-gray-700 border-gray-300 dark:border-gray-600 px-3 py-1 rounded-md" :href="'https://'+ $store.state.blockExplorer + '/address/' + launch.contract + '#code'">View Contract</a>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="flex mt-24 items-center flex-col place-content-center text-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 dark:text-gray-700 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h4 class="dark:text-gray-700 text-gray-300 mt-4">
        No upcoming launches
      </h4>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import socialsDropdown from "./socialsDropdown.vue";
import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/vue/solid";

export default {
  components: {
    socialsDropdown,
    SortAscendingIcon,
    SortDescendingIcon,
  },
  data() {
    return {
      launches: [],
      sortType: "",
      isReverse: false,
      maxLaunchDate: moment()
        .subtract(7, "days")
        .format(),
    };
  },
  async created() {
    this.launches = await this.getlaunches();
  },
  computed: {
    currentDate: function() {
      return moment();
    },
    sortTable: function() {
      var app = this;
      if (this.maxLaunchDate)
        this.launches = this.launches.filter((item) => {
          return item.launchDate >= this.maxLaunchDate;
        });
      this.launches.sort(function(a, b) {
        if (a[app.sortType] < b[app.sortType]) {
          if (app.isReverse) return 1;
          else return -1;
        } else {
          if (app.isReverse) return -1;
          else return 1;
        }
      });
      return this.launches;
    },
  },
  methods: {
    dateTimeFormat(value) {
      return moment(value).format("MMMM Do YYYY, h:mm a");
    },
    dateTime(value) {
      return moment(value);
    },
    timeToLaunch(value) {
      return moment(value).fromNow();
    },
    getlaunches: async () => {
      const query = `{
  upcomingLaunchCollection {
    items {
      sys {
        id
        firstPublishedAt
      }
      name
      logo {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      kyc
      audit
      riskRating
  	  description
      launchDate
      contract
    }
  }
}`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) => response.json());
        return response.data.upcomingLaunchCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
};
</script>

<style></style>
