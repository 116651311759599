<template>
  <div
    v-if="$store.state.showHolders && $store.state.chain == 'bsc'"
    class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center space-x-2 md:space-x-4"
  >
    <div id="canvas-holder">
      <canvas v-bind:class="{ 'fade-opacity': chartReady, '': !chartReady }" width="40" height="40" id="chart-area" />
    </div>

    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        >Holders<br />Distribution
        <div class="whitespace-normal">
          <InformationCircleIcon
            v-tippy="{
              placement: 'bottom',
              content:
                '<span>Top holders distribution chart</span><br><br><table>' +
                legendHtml +
                '</table><br><span>Segments are a percentage of circulating supply. Burned tokens are not displayed.</span>',
              allowHTML: true,
              appendTo: 'parent',
              theme: 'bog',
              animation: 'shift-toward-subtle',
              arrow: false,
              maxWidth: '350px',
            }"
            class="h-4 w-4 text-gray-500 ml-1"
            aria-hidden="true"
          />
        </div>
      </span>
      <div id="textfade" v-if="$store.state.holders">
        <h4 class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg group">
          <a
            v-if="$store.state.holdersHitCap"
            :href="'https://' + $store.state.blockExplorer + '/token/' + $store.state.token"
            target="blank"
            class="flex flex-row items-center"
            >100K+
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 ml-1 group-hover:text-gray-400 duration-200 transition-all text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </a>
        </h4>
      </div>
      <div
        v-else
        class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable"
      ></div>
      <!--<n-skeleton v-else class="w-full h-4" :sharp="false" size="medium" />-->
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import Chart from "chart.js/auto";
import { ZERO_ADDRESS, ONE_ADDRESS, DEAD_ADDRESS, sleep } from "@/constants";

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";
    //table.style.zIndex = 1000
    table.style.width = "140px";

    tooltipEl.appendChild(table);
    tooltipEl.style.zIndex = 1000;
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  tooltipEl.style.zIndex = 1000;

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;
      tr.style.width = "140px";

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      th.style.width = "140px";
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      //span.style.borderColor = colors.borderColor;
      span.style.borderColor = colors.backgroundColor;
      span.style.borderWidth = "4px";
      span.style.marginRight = "10px";
      //span.style.height = '10px';
      //span.style.width = '10px';
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.width = "140px";

      const text = document.createTextNode(body + "%");

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.fontSize = "12px";
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
  tooltipEl.style.zIndex = 1000;
};

export default {
  data() {
    return {
      myopacity: 1,
      opacityDirection: -1,
      token: this.$store.state.token,
      pieSegments: [],
      series: [],
      chartObj: null,
      chartOpts: null,
      chartReady: false,
      legendHtml: "",
      computingPie: false,
      chartOptsBlank: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: [],
              borderWidth: 0,
            },
          ],
        },
        options: {
          interaction: { mode: "index" },
          onHover: function (e) {
            const points = this.getElementsAtEventForMode(e, "index", { axis: "x", intersect: true }, false);

            if (points.length) e.native.target.style.cursor = "default";
            else e.native.target.style.cursor = "default";
          },
          responsive: true,
          maintainAspectRatio: false,
          cutout: "72%",
          borderRadius: "24",
          animation: {
            duration: 0
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            },
          },

          title: {
            display: false,
          },
        },
      },
    };
  },
  mounted: function () {},
  unmounted: function () {
    if (this.chartObj) {
      //console.log("unmounted destroying chart");
      this.destroyPie();
    }
  },
  methods: {
    destroyPie: function () {
      if (this.chartObj) {
        //console.log("destroying chart");
        try {
          this.chartObj.stop();
        } catch (e) {
          console.log(e);
        }
        try {
          this.chartObj.destroy();
        } catch (e) {
          console.log(e);
        }
      }
    },
    computePie: function (token) {
      try {
        console.log("computePie fired");
        if (token != this.$store.state.token) {
          console.log("token changed while computing pie");
          this.computingPie = false;
          return;
        }
        this.computingPie = true;
        var ctx = document.getElementById("chart-area");
        if (ctx == null || ctx == undefined) {
          console.log("waiting for pie context");
          setTimeout(() => {
            this.computePie(token);
          }, 100);
          return;
        }

        let segments = [];
        this.legendHtml = "";

        let colors = [];
        let labels = [];
        let colorIndex = 0;
        let sumPcts = 0;
        let pools = [];
        let reserves = [];
        let poolAmt = 0;
        for (let pool of this.$store.state.liquidityData) {
          pools.push(pool.contract.toLowerCase());
          reserves.push(pool.token.pooled);
          poolAmt += pool.token.pooled;
        }

        console.log(reserves);
        console.log(pools);
        console.log(this.$store.state.liquidityData);

        let legendSquareHtml =
          '<span style="background: #65C8FF; border-color: #65C8FF; border-width: 4px; margin-right: 10px; display: inline-block; border-radius: 2px; box-shadow: 0px 0px 6px 0px #65C8FF" />';
        if (poolAmt) {
          let segPct = Math.round(((100.0 * poolAmt) / this.$store.state.tokenCirculatingSupply) * 100) / 100;
          sumPcts += segPct;
          //segments.push({value: segPct, color: 'gold'})
          segments.push(segPct);
          colors.push("#65C8FF");
          labels.push("Liquidity");
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Liquidity:</td><td>" + segPct.toString() + "%</td></tr>";
        } else {
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Liquidity:</td><td>0%</td></tr>";
        }

        let top10Seg = 0;
        let top11To100Seg = 0;
        let contractSeg = 0;
        let n = 0;
        //let i = 0;
        //for (let th of this.$store.state.top100) {
        for (let i = 0; i < this.$store.state.top100.length; i += 1) {
          let th = this.$store.state.top100[i];
          let dead = [ZERO_ADDRESS, ONE_ADDRESS, DEAD_ADDRESS];
          if (dead.includes(th.address.toLowerCase())) {
            // don't include dead tokens in the pie chart
            continue;
          }
          if (pools.includes(th.address.toLowerCase())) {
            continue;
          }
          let segPct = Math.round(((100.0 * th.balance) / this.$store.state.tokenCirculatingSupply) * 100) / 100;
          if (
            this.$store.state.top100IsContract &&
            this.$store.state.top100IsContract.length == this.$store.state.top100.length &&
            this.$store.state.top100IsContract[i]
          ) {
            contractSeg += segPct;
          } else {
            n += 1;
            if (n <= 10) {
              top10Seg += segPct;
            } else {
              top11To100Seg += segPct;
            }
          }
          sumPcts += segPct;
        }
        legendSquareHtml =
          '<span style="background: #7645D9; border-color: #7645D9; border-width: 4px; margin-right: 10px; display: inline-block; border-radius: 2px; box-shadow: 0px 0px 6px 0px #DD7BFF" />';
        if (contractSeg) {
          contractSeg = Math.round(contractSeg * 100) / 100;
          segments.push(contractSeg);
          colors.push("#7645D9");
          labels.push("Contracts");
          this.legendHtml +=
            "<tr><td>" + legendSquareHtml + "</td><td>Contracts:</td><td>" + contractSeg.toString() + "%</td></tr>";
        } else {
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Contracts:</td><td>0%</td></tr>";
        }
        legendSquareHtml =
          '<span style="background: #FF6A6A; border-color: #FF6A6A; border-width: 4px; margin-right: 10px; display: inline-block; border-radius: 2px; box-shadow: 0px 0px 6px 0px #FF6A6A" />';
        if (top10Seg) {
          top10Seg = Math.round(top10Seg * 100) / 100;
          segments.push(top10Seg);
          colors.push("#FF6A6A");
          labels.push("Top 10");
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Top 10:</td><td>" + top10Seg.toString() + "%</td></tr>";
        } else {
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Top 10:</td><td>0%</td></tr>";
        }
        legendSquareHtml =
          '<span style="background: #FFDE6A; border-color: #FFDE6A; border-width: 4px; margin-right: 10px; display: inline-block; border-radius: 2px; box-shadow: 0px 0px 6px 0px #FFDE6A" />';
        if (top11To100Seg) {
          top11To100Seg = Math.round(top11To100Seg * 100) / 100;
          segments.push(top11To100Seg);
          colors.push("#FFDE6A");
          labels.push("Top 11-100");
          this.legendHtml +=
            "<tr><td>" + legendSquareHtml + "</td><td>Top 11-100:</td><td>" + top11To100Seg.toString() + "%</td></tr>";
        } else {
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Top 11-100:</td><td>0%</td></tr>";
        }
        if (sumPcts > 100.0) {
          for (let s of segments) {
            s /= sumPcts / 100.0;
          }
        }
        legendSquareHtml =
          '<span style="background: #61738E; border-color: #61738E; border-width: 4px; margin-right: 10px; display: inline-block; border-radius: 2px; box-shadow: 0px 0px 6px 0px #61738E" />';
        if (sumPcts < 100.0) {
          //segments.push({value: 100.0 - sumPcts, color: 'gray'})
          colors.push("#61738E");
          labels.push("Top 101+");
          let segPct = Math.round((100.0 - sumPcts) * 100) / 100;
          segments.push(segPct);
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Top 101+:</td><td>" + segPct.toString() + "%</td></tr>";
        } else {
          this.legendHtml += "<tr><td>" + legendSquareHtml + "</td><td>Top 101+:</td><td>0%</td></tr>";
        }

        this.series = segments;

        this.chartOpts = this.chartOptsBlank;
        this.chartOpts.data.labels = labels;
        this.chartOpts.data.datasets[0].backgroundColor = colors;
        this.chartOpts.data.datasets[0].data = segments;

        var ctx = document.getElementById("chart-area");
        if (ctx != null && ctx != undefined) {
          console.log("creating new chart");
          this.chartObj = new Chart(ctx.getContext("2d"), this.chartOpts);
        } else {
          console.log("!! chart area missing");
        }

        this.chartReady = true;
        console.log('CHART READY')
        
        this.computingPie = false;
      } catch (e) {
        console.log("exception in computePie");
        console.log(e);
        this.computingPie = false;
      }
    },
    readyForPie: function () {
      if (
        !this.computingPie &&
        this.$store.state.tokenCirculatingSupply &&
        this.$store.state.top100 &&
        this.$store.state.top100IsContract &&
        this.$store.state.liquidityData
      ) {
        //this.destroyPie();
        setTimeout(() => {
          this.computePie(this.$store.state.token);
        }, 0);
      }
    },
  },

  watch: {
    "$store.state.token": function () {
      this.token = this.$store.state.token;
      if (this.chartObj) {
        //console.log("token change destroying chart");
        this.destroyPie();
      }
    },
    "$store.state.chartLoading": function () {
      if (this.$store.state.chartLoading) {
        this.chartReady = false;
      }
    },
    "$store.state.tokenCirculatingSupply": function () {
      this.readyForPie();
    },
    "$store.state.top100": function () {
      this.readyForPie();
    },
    "$store.state.liquidityData": function () {
      this.readyForPie();
    },
    "$store.state.showHolders": function () {
      if (
        !this.computingPie &&
        this.$store.state.showHolders &&
        this.$store.state.chain == "bsc" &&
        this.$store.state.tokenCirculatingSupply &&
        this.$store.state.top100 &&
        this.$store.state.top100IsContract &&
        this.$store.state.liquidityData
      ) {
        // give vue some time to render the holders card html
        // this is hacky but meh
        this.destroyPie();
        setTimeout(() => {
          this.computePie(this.$store.state.token);
        }, 0);
      }
    },
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
