<template>
  <div class="flex w-full flex-col font-mono">
    <h4 class="dark:text-error-bright text-error font-semibold">Top Losers</h4>
    <tableContainer>
      <tableHeader v-if="sortTable">
        <headerCell :name="'Position'" />
        <headerCell :name="'24h Change'" />
        <headerCell :name="'Volume'" />
        <headerCell :name="'Trust score'" :tooltip="'Trust score is calculated from a number of factors including: token age, liquidity, associated rugpulls, source code analysis, holder distribution and more'" />
      </tableHeader>

      <tableBody v-if="sortTable.length">
        <Row v-for="item in sortTable.slice(0, 30)" :key="item">
          <dataCell :type="'token'" :tokenImageUrl="item.tokenImageUrl" :address="item.address" :tokenName="item.tokenName" :price="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" />
          <dataCell :type="'percentage'" :value="item.change_pct_24h" />
          <dataCell :type="'text'" :value="bogintificPrice(item.volume_usd_24h, $store.state.useTruncatedBogintific)" />
          <dataCell :type="'trustScore'" :value="item.trustscore" />
        </Row>
      </tableBody>

      <div v-else class="w-full space-y-4">
        <div v-for="index in 8" :key="index">
          <div class="bg-gray-200 dark:bg-gray-800 rounded-md w-full h-20 animate-pulse-disable skeleton-loader"></div>
        </div>
      </div>
    </tableContainer>
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import dataCell from "@/components/Table/dataCell";
import Row from "@/components/Table/Row";
import tableBody from "@/components/Table/tableBody";
import tableHeader from "@/components/Table/tableHeader";
import headerCell from "@/components/Table/headerCell";
import tableContainer from "@/components/Table/tableContainer";

export default {
  components: {
    dataCell,
    Row,
    tableBody,
    tableHeader,
    headerCell,
    tableContainer,
  },
  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
  computed: {
    sortTable() {
      return _.chain(this.$store.state.topMcapTokens)
        .sortBy("change_pct_24h")
        .partition("change_pct_24h")
        .flatten()
        .value();
    },
  },
};
</script>
