<template>
  <div v-if="trustscore >= 80" v-html="trustscore" class="text-sm bg-secondary bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-secondary"></div>
  <div v-else-if="trustscore > 50" v-html="trustscore" class="text-sm bg-alert bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-alert-dark dark:text-alert-bright"></div>
  <div v-else-if="trustscore <= 50 && trustscore != null" v-html="trustscore" class="text-sm bg-error-bright bg-opacity-20 dark:bg-error dark:bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-error"></div>
  <div v-else-if="trustscore == null" class="text-sm bg-gray-700 bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-gray-500">N/A</div>
</template>

<script>
export default {
  props: ["trustscore"],
};
</script>
