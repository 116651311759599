<template>
  <not-available v-if="!onSupportedChain" />
  <div v-else-if="show_report && analytics_ready" class="mx-auto px-4 mb-8">
    <div class="flex flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-8 text-gray-900 dark:text-white">
      <div class="dark:bg-gray-50 dark:bg-opacity-5 bg-gray-900 bg-opacity-5 rounded-2xl p-4 lg:p-6 w-full">
        <div>
          <div class="flex flex-row items-center">
            <img v-if="$store.state.tokenImage" :src="$store.state.tokenImage" @error="altSymbol" class="w-12 h-12 mr-4 rounded-full cursor-default" />
            <span class="text-3xl font-heading font-semibold">{{ $store.state.tokenName }}</span>
          </div>
          <div class="text-gray-500 text-xs font-semibold mt-4">
            Contract report generated
            {{ general_report.report_generated_general[1] }}
          </div>
        </div>
        <div class="dark:bg-gray-900 bg-white rounded-lg border dark:border-gray-800 border-gray-200 mt-6">
          <div class="flex flex-wrap flex-row justify-between">
            <span class="flex flex-col space-y-2.5 min-w-40 m-6">
              <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
                >Trust Score
                <div class="whitespace-normal">
                  <InformationCircleIcon
                    v-tippy="{
                      content: 'Trust score is calculated from a number of factors including: token age, liquidity, associated rugpulls, source code analysis, holder distribution and more',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="h-4 w-4 text-gray-500 ml-1"
                    aria-hidden="true"
                  /></div
              ></span>
              <div class="text-4xl font-bold">
                <div v-if="$store.state.trustscore >= 80" class="inline-block rounded-md text-secondary">{{ $store.state.trustscore }}</div>
                <div v-else-if="$store.state.trustscore > 50" v-html="$store.state.trustscore" class="inline-block rounded-md text-alert-bright"></div>
                <div v-else-if="$store.state.trustscore <= 50 && $store.state.trustscore != null" v-html="$store.state.trustscore" class="inline-block rounded-md text-error-bright"></div>
                <div v-else-if="$store.state.trustscore == null" class="inline-block rounded-md text-gray-500">N/A</div>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div v-if="$store.state.trustscore >= 80" :style="{ width: $store.state.trustscore + '%' }" class="bg-secondary h-full"></div>
                <div v-else-if="$store.state.trustscore > 50" :style="{ width: $store.state.trustscore + '%' }" class="bg-alert-bright h-full"></div>
                <div v-else-if="$store.state.trustscore <= 50 && $store.state.trustscore != null" :style="{ width: $store.state.trustscore + '%' }" class="bg-error-bright h-full"></div>
                <div v-else-if="$store.state.trustscore == null" class="w-0 h-full"></div>
              </div>
            </span>
            <span v-if="$store.state.socialScore >= 50" class="flex flex-col space-y-2.5 min-w-40 m-6">
              <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
                >Social Score
                <div class="whitespace-normal">
                  <InformationCircleIcon
                    v-tippy="{
                      content: 'Social score represents this token’s hits on search engines & social media',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="h-4 w-4 text-gray-500 ml-1"
                    aria-hidden="true"
                  /></div
              ></span>
              <div class="text-4xl font-bold">
                <div v-if="$store.state.socialScore >= 80" class="inline-block rounded-md text-secondary">{{ $store.state.socialScore }}</div>
                <div v-else-if="$store.state.socialScore > 50" v-html="$store.state.socialScore" class="inline-block rounded-md text-alert-bright"></div>
                <div v-else-if="$store.state.socialScore <= 50 && $store.state.socialScore != null" v-html="$store.state.socialScore" class="inline-block rounded-md text-error-bright"></div>
                <div v-else-if="$store.state.socialScore == null" class="inline-block rounded-md text-gray-500">N/A</div>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div v-if="$store.state.socialScore >= 80" :style="{ width: $store.state.socialScore + '%' }" class="bg-secondary h-full"></div>
                <div v-else-if="$store.state.socialScore > 50" :style="{ width: $store.state.socialScore + '%' }" class="bg-alert-bright h-full"></div>
                <div v-else-if="$store.state.socialScore <= 50 && $store.state.socialScore != null" :style="{ width: $store.state.socialScore + '%' }" class="bg-error-bright h-full"></div>
                <div v-else-if="$store.state.socialScore == null" class="w-0 h-full"></div>
              </div>
            </span>
            <span v-if="general_report.weighted_n_functions_more_common_in_rugs != null" class="flex flex-col space-y-2.5 min-w-40 m-6">
              <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
                >Suspicious Functions
                <div class="whitespace-normal">
                  <InformationCircleIcon
                    v-tippy="{
                      content: 'The number of functions found in the sourcecode that are more commonly found in other rugpulls.',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="h-4 w-4 text-gray-500 ml-1"
                    aria-hidden="true"
                  /></div
              ></span>
              <div class="text-4xl font-bold">
                {{ general_report.weighted_n_functions_more_common_in_rugs[1] }}
              </div>
            </span>
            <span v-if="general_report.n_functions_unique_to_rugs != null" class="flex flex-col space-y-2.5 min-w-40 m-6">
              <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
                >Rug Functions
                <div class="whitespace-normal">
                  <InformationCircleIcon
                    v-tippy="{
                      content: 'The number of functions found in the sourcecode that have only been found in other rugpulls.',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="h-4 w-4 text-gray-500 ml-1"
                    aria-hidden="true"
                  /></div
              ></span>
              <div class="text-4xl font-bold">
                {{ general_report.n_functions_unique_to_rugs[1] }}
              </div>
            </span>
          </div>
        </div>
        <div class="mt-3 rounded-lg dark:bg-gray-900 bg-white border border-gray-200 dark:border-transparent p-6">
          <div class="mb-6 flex flex-col lg:flex-row lg:space-y-0 space-y-2 justify-between">
            <span class="text-sm font-heading font-medium text-secondary">Liquidity</span>
            <div v-if="liquidity_report.report_generated_liq" class="text-gray-500 text-xs font-semibold">
              Liquidity report generated
              {{ liquidity_report.report_generated_liq[1] }}
            </div>
          </div>
          <div class="space-y-4">
            <div v-if="liquidity_report.liq_usd" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Amount</span>
                <span v-html="bogintificPrice(liquidity_report.liq_usd[1], this.$store.state.useTruncatedBogintific)" class="dark:text-white text-gray-900 text-xs font-bold"></span>
              </div>
              <div v-if="liquidity_report.liq_usd_max" class="flex flex-row place-self-end dark:text-gray-300 text-gray-600 text-2xs space-x-1">
                <span>Maximum recorded:</span>
                <span v-if="liquidity_report.liq_usd >= liquidity_report.liq_usd_max[1]" v-html="bogintificPrice(liquidity_report.liq_usd[1], this.$store.state.useTruncatedBogintific)"></span>
                <span v-else v-html="bogintificPrice(liquidity_report.liq_usd_max[1], this.$store.state.useTruncatedBogintific)"></span>
              </div>
            </div>
            <div v-if="liquidity_report.supply" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Supply</span>
                <span v-html="bogintificValue(liquidity_report.supply[1], this.$store.state.useTruncatedBogintific)" class="dark:text-white text-gray-900 text-xs font-bold"></span>
              </div>
              <div class="w-full flex flex-col">
                <div v-if="liquidity_report.supply[1] != liquidity_report.supply_effective[1]" class="flex flex-row place-self-end dark:text-gray-300 text-gray-600 text-2xs space-x-1">
                  <span>Circulating:</span>
                  <span v-html="bogintificValue(liquidity_report.supply_effective[1], this.$store.state.useTruncatedBogintific)"></span>
                </div>
                <div v-if="liquidity_report.supply_earliest && liquidity_report.supply[1] != liquidity_report.supply_earliest[1]" class="flex flex-row place-self-end dark:text-gray-300 text-gray-600 text-2xs space-x-1">
                  <span>Earliest recorded:</span>
                  <span v-html="bogintificValue(liquidity_report.supply_earliest[1], this.$store.state.useTruncatedBogintific)"></span>
                </div>
              </div>
            </div>
            <div v-if="liquidity_report.liq_pct_of_supply" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Liquidity % of supply</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.liq_pct_of_supply[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.liq_pct_of_supply[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="liquidity_report.burned_pct_of_supply" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Burned % of supply</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.burned_pct_of_supply[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.burned_pct_of_supply[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="liquidity_report.pct_orig_supply_minted" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">% of original supply minted</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.pct_orig_supply_minted[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.pct_orig_supply_minted[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="liquidity_report.creator_sold_pct_of_supply" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Creator sold % of supply</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.creator_sold_pct_of_supply[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.creator_sold_pct_of_supply[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="liquidity_report.top_holder_pct_of_supply_effective" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">Top holder % of supply</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.top_holder_pct_of_supply_effective[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.top_holder_pct_of_supply_effective[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="!over_max_cakelp_txns" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">CAKE-LP redeemed</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.cakelp_redeemed_pct[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.cakelp_redeemed_pct[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="!over_max_cakelp_txns" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">CAKE-LP burned</span>
                <span class="dark:text-white text-gray-900 text-xs font-bold">{{ liquidity_report.cakelp_pct_burned[1] }}</span>
              </div>
              <div class="h-1 overflow-hidden w-full dark:bg-gray-800 bg-gray-100 rounded-sm">
                <div :style="{ width: liquidity_report.cakelp_pct_burned[1] }" class="bg-blue-bright h-full rounded-sm overflow-hidden w-full transition-all duration-1000"></div>
              </div>
            </div>
            <div v-if="general_report.parsed_urls[1] != ''" class="flex flex-col space-y-2">
              <div class="flex flex-row justify-between">
                <span class="dark:text-gray-300 text-gray-600 text-xs font-medium">URLs found in sourcecode</span>
                <div class="text-2xs" v-html="general_report.parsed_urls[1]"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="space-x-2 mt-4">
          <button
            @click="tabMode = 0"
            :class="[
              'py-1.5 px-3 text-sm transition-colors duration-200 rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10',
              tabMode == 0 ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-gray-900 dark:hover:text-white',
            ]"
          >
            Matches
          </button>
          <button
            @click="tabMode = 1"
            :class="[
              'py-1.5 px-3 text-sm transition-colors duration-200 rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10',
              tabMode == 1 ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-gray-900 dark:hover:text-white',
            ]"
          >
            Associated
          </button>
          <button
            @click="tabMode = 2"
            :class="[
              'py-1.5 px-3 text-sm transition-colors duration-200 rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10',
              tabMode == 2 ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-gray-900 dark:hover:text-white',
            ]"
          >
            Clones
          </button>
        </div>

        <div class="mt-3 rounded-lg dark:bg-gray-900 bg-white border border-gray-200 dark:border-transparent p-6">
          <div class="text-xs mb-3">
            <span :class="tabMode === 0 ? '' : 'opacity-0 hidden'">Tokens that match the source code of {{ $store.state.tokenName }}.</span>
            <span :class="tabMode === 1 ? '' : 'opacity-0 hidden'">Tokens that were associated with this token in a deep blockchain search</span>
            <span :class="tabMode === 2 ? '' : 'opacity-0 hidden'">Clones (i.e. near-exact source copies of other tokens) associated with this token. These are generally skipped for processing by the reporting system.</span>
          </div>
          <div class="overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-800 dark:bg-opacity-20 border dark:border-gray-800 pr-4 py-4">
            <div class="h-80 overflow-y-auto">
              <div :class="tabMode === 0 ? '' : 'opacity-0 hidden'" class="h-full px-4 flex flex-col text-xs">
                <div v-if="code_matches != ''">
                  <div v-html="code_matches"></div>
                  <template v-if="n_code_matches > 10">
                    <div class="hideable_content">
                      <div v-html="code_matches_overflow"></div>
                    </div>
                    <button type="button" class="w-full mt-3 hideable_button text-sm bg-gray-700 hover:bg-opacity-30 bg-opacity-20 px-2 py-1 rounded-md">Show more</button>
                  </template>
                </div>
                <div class="w-full h-full flex items-center place-content-center" v-else>
                  <div class="text-center flex flex-col items-center">
                    <img class="mb-4 invert hue-rotate-180 filter" src="@/assets/emptystate/emptybox.svg" />
                    <span class="text-xs font-semibold dark:text-gray-500 text-gray-300">No source code matches found</span>
                  </div>
                </div>
              </div>
              <div :class="tabMode === 1 ? '' : 'opacity-0 hidden'" class="h-full px-4 flex flex-col text-xs">
                <div v-if="forensics_report.associated_notrugs[0] || forensics_report.associated_rugs[1]">
                  <div v-if="forensics_report.associated_rugs[1]" v-html="forensics_report.associated_rugs[1]"></div>
                  <div v-if="forensics_report.associated_notrugs[0]" v-html="forensics_report.associated_notrugs[1]"></div>
                </div>
                <div class="w-full h-full flex items-center place-content-center" v-else>
                  <div class="text-center flex flex-col items-center">
                    <img class="mb-4 invert hue-rotate-180 filter" src="@/assets/emptystate/emptybox.svg" />
                    <span class="text-xs font-semibold dark:text-gray-500 text-gray-300">No associated tokens found</span>
                  </div>
                </div>
              </div>
              <div :class="tabMode === 2 ? '' : 'opacity-0 hidden'" class="h-full px-4 flex flex-col text-xs">
                <div v-if="forensics_report.associated_clones[1]">
                  <div v-html="forensics_report.associated_clones[1]"></div>
                </div>
                <div class="w-full h-full flex items-center place-content-center" v-else>
                  <div class="text-center flex flex-col items-center">
                    <img class="mb-4 invert hue-rotate-180 filter" src="@/assets/emptystate/emptybox.svg" />
                    <span class="text-xs font-semibold dark:text-gray-500 text-gray-300">No clones found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:max-w-md w-full">
        <div class="overline">Overview</div>
        <div class="mt-4 dark:text-blue-bright text-purple flex flex-col space-y-2">
          <div class="p-6 relative rounded-lg overflow-hidden">
            <div class="bg-gradient-to-tl opacity-20 from-purple to-blue-bright w-full h-full absolute inset-0"></div>
            <div class="relative flex items-center justify-between">
              <span class="text-sm font-semibold">Age</span>
              <span class="lg:text-xl text-base font-medium">{{ general_report.contract_created[1] }}</span>
            </div>
          </div>
          <div class="p-6 relative rounded-lg overflow-hidden">
            <div class="bg-gradient-to-tl opacity-20 from-purple to-blue-bright w-full h-full absolute inset-0"></div>
            <div class="relative flex items-center justify-between">
              <span class="text-sm font-semibold">Holders</span>
              <span class="lg:text-xl text-base font-medium">
                <h4 v-if="$store.state.holders" class="group">
                  <a v-if="$store.state.holdersHitCap" :href="'https://' + $store.state.blockExplorer + '/token/' + $store.state.token" target="blank" class="flex flex-row items-center"
                    >100K+
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1 group-hover:text-gray-400 duration-200 transition-all text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  </a>
                  <span v-else>{{ $store.state.holders }}</span>
                </h4></span
              >
            </div>
          </div>
          <div class="p-6 relative rounded-lg overflow-hidden">
            <div class="bg-gradient-to-tl opacity-20 from-purple to-blue-bright w-full h-full absolute inset-0"></div>
            <div class="relative flex items-center justify-between">
              <span class="text-sm font-semibold">Creator</span>
              <span class="lg:text-xl text-base font-medium">
                <a :href="'https://' + $store.state.blockExplorer + '/address/' + general_report.contract_creator[1]" target="blank" class="group flex flex-row items-center">
                  {{ maxLength(general_report.contract_creator[1], 2) }}...{{ general_report.contract_creator[1].substr(general_report.contract_creator[1].length - 4) }}
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1 group-hover:text-gray-400 duration-200 transition-all text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="mt-8 overline flex flex-row items-center space-x-2">
          <span>Issues</span>
          <span class="dark:bg-gray-800 bg-gray-200 dark:text-gray-400 text-gray-500 rounded-md px-2 py-0.5">{{ warn_metrics.length }}</span>
        </div>

        <div class="mt-4 flex flex-col space-y-2" v-if="warn_metrics.length">
          <div v-bind:style="vars[2]" class="flex flex-col justify-between py-2 px-3 rounded-md" v-for="vars in warn_metrics" :key="vars">
            <div class="flex flex-row justify-between space-x-2 w-full">
              <span class="text-sm font-semibold font-heading">{{ vars[0] }}</span>
              <span class="text-sm font-semibold font-heading">{{ vars[1] }}</span>
            </div>
            <span class="dark:text-white text-gray-900 text-opacity-70 text-2xs mt-2">{{ vars[3] }}</span>
          </div>
        </div>
        <div class="mt-4 flex flex-col items-center place-content-center w-full bg-gray-50 bg-opacity-5 p-6 rounded-lg" v-else>
          <img class="invert hue-rotate-180 filter" src="@/assets/emptystate/taskdone.svg" />
          <span class="text-xs font-semibold dark:text-gray-500 text-gray-300">No issues found</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="no_data" class="fixed top-0 w-screen h-screen z-0">
    <div class="w-full h-full flex flex-col items-center place-content-center">
      <div class="max-w-xl text-center px-4">
        <span class="text-lg font-heading text-gray-900 dark:text-white">No analytics are available for {{ $store.state.tokenName }}</span>
        <span class="text-xs mt-4 block text-gray-600 dark:text-gray-400"
          >This is usually because the contract code was an exact clone of a large number of other tokens, so processing was skipped. Skipped tokens will be processed if daily volume exceeds $10k.</span
        >
      </div>
    </div>
  </div>
  <div v-else class="fixed top-0 w-screen h-screen z-0">
    <div class="w-full h-full flex flex-col items-center place-content-center">
      <mini-spinner></mini-spinner>
      <span class="text-xs mt-6 block text-gray-600">Loading Analytics for {{ $store.state.tokenName }}</span>
    </div>
  </div>
</template>

<script>
function local_time_from_unix_timestamp(timestamp_seconds) {
  var a = new Date(timestamp_seconds * 1000);
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  //var sec = a.getSeconds();
  var time_str = date + " " + month + " " + year + " " + hour + ":" + min;
  return time_str;
}
function timestamp_to_age(timestamp_seconds) {
  var now_ts = Math.floor(Date.now() / 1000);
  var diff = now_ts - timestamp_seconds;
  if (diff < 60) {
    return Math.round(diff).toString() + " seconds";
  } else if (diff < 60 * 60) {
    return Math.round(diff / 60).toString() + " minutes";
  } else if (diff < 60 * 60 * 24) {
    return Math.round(diff / 60 / 60).toString() + " hours";
  } else {
    return Math.round(diff / 60 / 60 / 24).toString() + " days";
  }
}
function get_formatted_value(value, meta, chart_token) {
  if (meta["display_format"] == null) {
    return null;
  }
  if (meta["display_format"] == "text") {
    if (value == null || value == "") {
      value = "";
    }
    return value;
  }
  if (meta["display_format"] == "datetime_and_age") {
    if (value == null || value == 0) {
      return null;
    }
    return local_time_from_unix_timestamp(value) + " (" + timestamp_to_age(value) + ")";
  }
  if (meta["display_format"] == "int") {
    return value;
  }
  var round_to = null;
  var rounded = null;
  var retval = null;
  var addylist;
  var addy;
  var tokenname;
  var rugstr;
  var rugclass;
  var i;
  if (meta["display_format"].includes("double")) {
    if (value == null) {
      return null;
    }
    // format is "double.x" where x is number of decimals to round to
    round_to = parseInt(meta["display_format"].split(".")[1]);
    return Math.round(value * 10 ** round_to) / 10 ** round_to;
  }
  if (meta["display_format"].includes("percent")) {
    if (value == null) {
      return null;
    }
    // format is "double.x" where x is number of decimals to round to
    round_to = parseInt(meta["display_format"].split(".")[1]);
    rounded = Math.round(value * 10 ** round_to) / 10 ** round_to;
    return rounded.toString() + "%";
  }
  if (meta["display_format"] == "usd") {
    if (value == null) {
      return null;
    }
    rounded = Math.round(value * 10 ** 2) / 10 ** 2;
    return rounded.toFixed(2);
  }
  if (meta["display_format"] == "yes_no") {
    if (value == null) {
      return null;
    }
    if (value == 0) {
      return "No";
    } else if (value == 1) {
      return "Yes";
    } else {
      return null;
    }
  }
  if (meta["display_format"] == "bogchart_link") {
    if (value == null) {
      return "";
    }
    return '<a href="https://charts.bogged.finance/?token=' + value + '">' + value + "</a>";
  }
  if (meta["display_format"] == "bscscan_address_link") {
    if (value == null) {
      return "";
    }
    return value;
  }
  if (meta["display_format"] == "bscscan_token_link") {
    if (value == null) {
      return "";
    }
    return '<a href="https://bscscan.com/token/' + value + '">[Bscscan]</a>';
  }
  if (meta["display_format"] == "address_holders_contract_chart") {
    if (value == null) {
      return "";
    }
    var chartlink = '<a href="https://charts.bogged.finance/?token=' + value + '">Chart</a>';
    var holderslink = '<a href="https://bscscan.com/token/' + value + '#balances">Holders</a>';
    var contractlink = '<a href="https://bscscan.com/address/' + value + '#code">Contract</a>';
    return value + " " + holderslink + " " + contractlink + " " + chartlink;
  }
  if (meta["display_format"] == "list_address_name_isrug") {
    if (value == null) {
      return "";
    }
    retval = "";
    if (value.length > 0) {
      addylist = value.split("\n");

      for (i = 0; i < addylist.length; i++) {
        addylist[i] = addylist[i].split("\t");
        addy = addylist[i][0];
        if (addy && chart_token && addy.toLowerCase() == chart_token.toLowerCase()) {
          continue
        }
        rugstr = "";
        rugclass = "hidden";
        if (addylist[i][2] == "rug") {
          rugstr = "Rugged";
          rugclass = "ml-2 py-px px-1 rounded-sm bg-error-bright bg-opacity-20 text-error-bright text-2xs mr-4";
        }
        if (addylist[i][2] == "clone") {
          tokenname = addy;
          rugclass = "hidden";
        }
        if (addylist[i][1]) {
          tokenname = addylist[i][1];
        }
        retval +=
          '<div class="flex flex-col lg:flex-row lg:items-center py-2 lg:py-1 lg:space-y-0 space-y-1">' +
          '<div class="lg:w-full lg:h-5"><a href="' +
          "/?c=" +
          "bsc" +
          "&t=" +
          addy +
          '">' +
          "</a>" +
          tokenname +
          '<span class="' +
          rugclass +
          '">' +
          rugstr +
          "</span>" +
          "</div>" +
          "<div>" +
          "</div>" +
          '<a target="_blank" class="text-gray-500" href="https://bscscan.com/token/' +
          addy +
          '">BscScan</a>' +
          "</div>";
      }
    }
    return retval;
  }
  if (meta["display_format"] == "list_address_name_symbol_sim_isrug") {
    if (value == null) {
      return "";
    }
    retval = "";
    if (value.length > 0) {
      addylist = value.split("\n");
      if (addylist.length > 0) {
        retval += '<div class="flex flex-col">';
        //retval += '<thead><div><div>Address</div><div>Token</div><div>Match %</div><div>Is rugpull</div></div></thead>'
        for (i = 0; i < addylist.length; i++) {
          addylist[i] = addylist[i].split("\t");
          addy = addylist[i][0];
          if (addy && chart_token && addy.toLowerCase() == chart_token.toLowerCase()) {
            continue
          }
          rugstr = "";
          rugclass = "hidden";
          if (addylist[i][4] == "rug") {
            rugstr = "Rugged";
            rugclass = "ml-2 py-px px-1 rounded-sm bg-error-bright bg-opacity-20 text-error-bright text-2xs mr-4";
          }
          var simstr = (Math.round(addylist[i][3] * 1000) / 10).toString() + "%";
          retval +=
            '<div class="flex flex-col lg:flex-row lg:items-center py-2 lg:py-1 lg:space-y-0 space-y-1">' +
            '<div class="lg:w-full lg:h-5"><a href="' +
            "/?c=" +
            "bsc" +
            "&t=" +
            addy +
            '">' +
            addylist[i][1] +
            "</a>" +
            " (" +
            addylist[i][2] +
            ")" +
            '<span class="' +
            rugclass +
            '">' +
            rugstr +
            "</span>" +
            "</div>" +
            "<div>" +
            simstr +
            "</div>" +
            '<a target="_blank" class="text-gray-500 lg:ml-4" href="https://bscscan.com/token/' +
            addy +
            '">BscScan</a>' +
            "</div>";
        }
        retval += "</div>";
      }
    }
    return retval;
  }
  if (meta["display_format"] == "url_list") {
    if (value == null) {
      return "";
    }
    retval = "";
    if (value.length > 0) {
      addylist = value.split("\n");
      if (addylist.length > 0) {
        for (i = 0; i < addylist.length; i++) {
          retval += '<div><a href="' + addylist[i] + '">' + addylist[i] + "</a></div>";
        }
      }
    }
    return retval;
  }
}
function get_style(value, meta) {
  if (meta["display_format"] == null) {
    return null;
  }
  if (meta["comparison"] == null) {
    return null;
  }
  if (value == null) {
    return null;
  }
  if (meta["display_format"] == "text") {
    if (meta["comparison"] == "eq") {
      if (meta["red_cutoff"] != null) {
        if (value == meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value == meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value == meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "ne") {
      if (meta["red_cutoff"] != null) {
        if (value != meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value != meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value != meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    }
  } else if (meta["display_format"] == "yes_no") {
    if (meta["comparison"] == "eq") {
      if (meta["red_cutoff"] != null) {
        if (value == meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value == meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value == meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "ne") {
      if (meta["red_cutoff"] != null) {
        if (value != meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value != meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value != meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    }
  } else if (meta["display_format"] == "int" || meta["display_format"].includes("double") || meta["display_format"].includes("percent") || meta["display_format"] == "usd") {
    // NOTE:
    if (meta["comparison"] == "eq") {
      if (meta["red_cutoff"] != null) {
        if (value == meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value == meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value == meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "ne") {
      if (meta["red_cutoff"] != null) {
        if (value != meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value != meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value != meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "gt") {
      if (meta["red_cutoff"] != null) {
        if (value > meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value > meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value > meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "gte") {
      if (meta["red_cutoff"] != null) {
        if (value >= meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value >= meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value >= meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "lt") {
      if (meta["red_cutoff"] != null) {
        if (value < meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value < meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value < meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    } else if (meta["comparison"] == "lte") {
      if (meta["red_cutoff"] != null) {
        if (value <= meta["red_cutoff"]) {
          return "red";
        }
      }
      if (meta["orange_cutoff"] != null) {
        if (value <= meta["orange_cutoff"]) {
          return "orange";
        }
      }
      if (meta["yellow_cutoff"] != null) {
        if (value <= meta["yellow_cutoff"]) {
          return "yellow";
        }
      }
    }
  }
  return null;
}

//import { percent, numberfloor, number } from "@/filters";
import miniSpinner from "@/components/miniSpinner.vue";
import { maxLength, bogintificPrice, bogintificValue } from "@/filters";
import { Services } from "@/services";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";
import { InformationCircleIcon } from "@heroicons/vue/outline";


export default {
  components: { notAvailable, InformationCircleIcon, miniSpinner },
  data() {
    return {
      tabMode: 0,
      analytics_ready: false,
      analytics_data: null,
      general_report: {},
      liquidity_report: {},
      liquidity_providers: [],
      forensics_report: {},
      code_matches: "",
      code_matches_overflow: "",
      code_match_type: "",
      n_code_matches: 0,
      reports_meta: [],
      warn_metrics: [],
      show_warnings: false,
      no_issues: false,
      token_address: "",
      token_name: "",
      token_symbol: "",
      quote_symbol: "",
      show_report: false,
      show_liq_report: false,
      show_clone: false,
      clone_token: [],
      clone_match_url: "",
      show_error: false,
      error_str: "",
      rugscore_str: "",
      rugcircle_attr: "",
      matches_score: null,
      over_max_cakelp_txns: false,
      coll: null,
      no_data: false,
    };
  },
  computed: {
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("Analytics");
    },
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
  },
  methods: {
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    maxLength,
    bogintificPrice,
    bogintificValue,
    process_analytics(token) {
      //let token = this.$store.state.token
      if (token != this.$store.state.token) {
        return;
      }
      if (!this.$store.state.top100 || !this.$store.state.tokenSupply || !this.$store.state.tokenCirculatingSupply || !this.$store.state.tokenLiquidity) {
        console.log(this.$store.state.top100)
        console.log(this.$store.state.tokenSupply)
        console.log(this.$store.state.tokenCirculatingSupply)
        console.log(this.$store.state.tokenLiquidity)
        if (this.$store.state.token != token) {
          console.log('token changed while we were loading analytics')
          return
        }
        setTimeout(() => {
          this.process_analytics(token);
        }, 400);
        return;
      }

      if (!this.$store.state.tokenAnalytics) {
        Services.get_token_analytics(this.$store.state.token, this.$store).then((result) => {
          this.format_analytics(token);
        });
      } else {        
        this.format_analytics(token);
      }
    },
    format_analytics(token) {
      this.analytics_ready = false
      if (this.$store.state.tokenAnalytics) {
        console.log(this.$store.state.tokenAnalytics);
        if (this.$store.state.tokenAnalytics == "NO_DATA") {
          this.no_data = true;
          return;
        }
        let data = this.$store.state.tokenAnalytics;
        console.log(data);
        this.reports_meta = {};
        for (let this_meta of data.meta) {
          this.reports_meta[this_meta["column_name"]] = this_meta;
        }
        console.log(this.reports_meta);

        var base_token_symbol_str = "Base token";
        var quote_token_symbol_str = "Quote token";
        var value = null;
        var meta = null;
        var style = null;
        var style_text = null;
        var key = null;
        var formatted_value = null;
        var red_metrics = [];
        var orange_metrics = [];
        var yellow_metrics = [];
        var rugscore = 0;

        if (data.general) {
          this.show_report = true;
          for (key in data.general) {
            if (Object.prototype.hasOwnProperty.call(data.general, key)) {
              value = data.general[key];
              if (key == "report_generated") {
                key = "report_generated_general";
              }
              meta = this.reports_meta[key];
              if (!meta) {
                continue;
              }
              if (key == "symbol") {
                base_token_symbol_str = value.toUpperCase();
                this.token_symbol = value.toUpperCase();
              } else if (key == "address") {
                this.token_address = value;
              } else if (key == "name") {
                this.token_name = value;
              }

              if (base_token_symbol_str.length > 18) {
                base_token_symbol_str = base_token_symbol_str.substring(0, 18);
              }

              if (meta.display_name != null) {
                formatted_value = get_formatted_value(value, meta, token);
                style = get_style(value, meta);
                style_text = "";
                if (style == "red") {
                  style_text = "background-color: rgba(255, 106, 106, .2); color: #FF6A6A;";
                  red_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.red_score != null) {
                    rugscore += parseFloat(meta.red_score);
                  }
                } else if (style == "orange") {
                  style_text = "background-color: rgba(255, 139, 70, 0.2); color: #FF8B46;";
                  orange_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.orange_score != null) {
                    rugscore += parseFloat(meta.orange_score);
                  }
                } else if (style == "yellow") {
                  style_text = "background-color: rgba(255, 222, 106, .2); color: #ffaf4c;";
                  yellow_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.yellow_score != null) {
                    rugscore += parseFloat(meta.yellow_score);
                  }
                }
                this.general_report[key] = [meta.display_name, formatted_value, style_text];
              }
            }
          }
          console.log(this.general_report);
        }

        if (data.bytecode_matches || data.source_matches) {
          if (data.source_matches) {
            console.log(data.source_matches);
            this.code_match_type = "Sourcecode Matches";
            value = data.source_matches.source_matches;
            if (value != null) {
              this.n_code_matches = value.split("\n").length;
            }
            this.reports_meta["matches_score"]["display_name"] = this.reports_meta["matches_score"]["display_name"].replace("__CODETYPE__", "Sourcecode");
          } else {
            this.code_match_type = "Bytecode Matches";
            value = data.bytecode_matches.bytecode_matches;
            if (value != null) {
              this.n_code_matches = value.split("\n").length;
            }
            this.reports_meta["matches_score"]["display_name"] = this.reports_meta["matches_score"]["display_name"].replace("__CODETYPE__", "Bytecode");
          }
          meta = this.reports_meta["code_matches"];

          if (this.n_code_matches > 10) {
            var code_matches_arr = value.split("\n");
            this.code_matches = get_formatted_value(code_matches_arr.slice(0, 10).join("\n"), meta);
            this.code_matches_overflow = get_formatted_value(code_matches_arr.slice(10).join("\n"), meta);
          } else {
            this.code_matches = get_formatted_value(value, meta, token);
          }

          // calculate code matches contribution to rugscore (which we use to determine if it reaches a warning threshold)
          var matches_scaling_factor = 1.0
          var matches_score = 0
          if (data.general.is_safemoon_clone == 1) { matches_scaling_factor = 0.3; }          
          if (data.source_matches && data.source_matches.source_matches) {										
            var value = data.source_matches.source_matches
            
            var addy
            var sim
            if (value.length > 0) {
              var addylist = value.split('\n')
              if (addylist.length > 0) {
                for (let i = 0; i < addylist.length; i++) {
                  addylist[i] = addylist[i].split('\t')
                  addy = addylist[i][0]
                  sim = addylist[i][3]
                  if (addylist[i][4] == 'rug') {
                    matches_score += ((sim*8-7)**3 + 0.01) * 0.3 * matches_scaling_factor
                  }
                }													
              }
            }
            if (matches_score > 0.65) { matches_score = 0.65; }
            if (data.general.is_safemoon_clone == 1 && matches_score > 0.3) { matches_score = 0.3; }
            //this.code_matches.matches_score = matches_score
          }
          else if (data.bytecode_matches && data.bytecode_matches.bytecode_matches) {
            //code_matches = bytecode_matches
            var value = data.bytecode_matches.bytecode_matches
            //var matches_score = 0
            var addy
            var sim
            if (value.length > 0) {
              var addylist = value.split('\n')
              if (addylist.length > 0) {
                for (let i = 0; i < addylist.length; i++) {
                  addylist[i] = addylist[i].split('\t')
                  addy = addylist[i][0]
                  sim = addylist[i][3]
                  if (addylist[i][4] == 'rug') {
                    matches_score += ((sim*5-4)**3 + 0.01) * 0.3 * matches_scaling_factor
                  }
                }													
              }
            }
            if (matches_score > 0.65) { matches_score = 0.65; }
            if (data.general.is_safemoon_clone == 1 && matches_score > 0.3) { matches_score = 0.3; }
            //this.code_matches.matches_score = matches_score
          }
          value = matches_score
          meta = this.reports_meta['matches_score']

          // FIX THIS!
          //value = null;
          //meta = null;

          if (meta && meta.display_name != null) {
            formatted_value = get_formatted_value(value, meta, token);
            style = get_style(value, meta);
            style_text = "";
            if (style == "red") {
              style_text = "background-color: rgba(255, 106, 106, .2); color: #FF6A6A;";
              red_metrics.push([meta.display_name, "(See matches below)", style_text, meta.hover_description]);
              if (meta.red_score != null) {
                //rugscore += parseFloat(meta.red_score);
              }
            } else if (style == "orange") {
              style_text = "background-color: rgba(255, 139, 70, 0.2); color: #FF8B46;";
              orange_metrics.push([meta.display_name, "(See matches below)", style_text, meta.hover_description]);
              if (meta.orange_score != null) {
                //rugscore += parseFloat(meta.orange_score);
              }
            } else if (style == "yellow") {
              style_text = "background-color: rgba(255, 222, 106, .2); color: #ffaf4c;";
              yellow_metrics.push([meta.display_name, "(See matches below)", style_text, meta.hover_description]);
              if (meta.yellow_score != null) {
                //rugscore += parseFloat(meta.yellow_score);
              }
            }

            //this.general_report.push([meta.display_name, formatted_value, style_text])
          }
          //console.log(this.code_matches)
        } else {
          this.code_match_type = "Sourcecode Matches";
        }

        if (data.liq) {
          this.show_liq_report = true;

          if (true) {
            data.liq.liq_usd = this.$store.state.tokenLiquidity;
            data.liq.supply = this.$store.state.tokenSupply;
            data.liq.supply_effective = this.$store.state.tokenCirculatingSupply;
            data.liq.liq_pct_of_supply = 100.0 * (data.liq.liq_usd / this.$store.state.tokenPrice / data.liq.supply_effective);
            data.liq.burned_pct_of_supply = 100.0 * ((data.liq.supply - data.liq.supply_effective) / data.liq.supply);
            if (data.liq.supply && data.liq.supply_earliest && data.liq.supply > data.liq.supply_earliest) {
              data.liq.pct_orig_supply_minted = 100.0 * ((data.liq.supply - data.liq.supply_earliest) / data.liq.supply);
            } else {
              data.liq.pct_orig_supply_minted = 0;
            }

            
            if (this.$store.state.top100 && this.$store.state.top100.length > 0) {
              for (let i = 0; i < this.$store.state.top100.length; i += 1) {
                let holder = this.$store.state.top100[i];
                let is_contract = this.$store.state.top100IsContract[i];
                if (holder.address == "0x000000000000000000000000000000000000dead" || holder.address == "0x0000000000000000000000000000000000000000" || holder.address == "0x0000000000000000000000000000000000000001") {
                  continue;
                }
                if (is_contract || (this.$store.state.liquidityPools && this.$store.state.liquidityPools.includes(holder.address))) {
                  continue;
                }
                data.liq.top_holder_pct_of_supply_effective = 100.0 * (holder.balance / data.liq.supply_effective);
                break;
              }
              if (data.liq.top_holder_pct_of_supply_effective == undefined) {
                data.liq.top_holder_pct_of_supply_effective = 0;
              }
            } else {
              console.log('ANALYTICS ERROR: top100 not populated')
              console.log(this.$store.state.top100)
              data.liq.top_holder_pct_of_supply_effective = null
            }
            console.log(data.liq);
            console.log('data.liq.top_holder_pct_of_supply_effective',data.liq.top_holder_pct_of_supply_effective)
            for (key in data.liq) {
              let nullcheck_fields = ['cakelp_pct_burned', 'cakelp_redeemed_pct', 'creator_sold_pct_of_liq', 'creator_sold_pct_of_supply']
              if (nullcheck_fields.includes(key) && data.liq[key] == null) { data.liq[key] = 0; }
              if (data.liq[key] != null && data.liq[key] != undefined && data.liq[key] != NaN) {
                if (key == "liq_usd_max" && data.liq_usd > data.liq_usd_max) {
                  data.liq_usd_max = data.liq_usd;
                }
                value = data.liq[key];
                if (key == "report_generated") {
                  key = "report_generated_liq";
                }
                meta = this.reports_meta[key];
                if (key == "quote_symbol") {
                  quote_token_symbol_str = value.toUpperCase();
                  this.quote_symbol = quote_token_symbol_str;
                }
                if (quote_token_symbol_str.length > 18) {
                  quote_token_symbol_str = quote_token_symbol_str.substring(0, 18);
                }
                if (key == "remaining_pct_quote") {
                  if (value == 0 && response.data.liquidity_report["liq_provider_tots_quote_in"] == 0) {
                    value = null;
                  }
                }
                if (key == "over_max_cakelp_txns") {
                  if (value == 1) {
                    this.over_max_cakelp_txns = true;
                  }
                }

                if (meta.display_name != null) {
                  //meta.display_name = meta.display_name.replace('__BASESYMBOL__',base_token_symbol_str).replace('__QUOTESYMBOL__',quote_token_symbol_str)
                  formatted_value = get_formatted_value(value, meta, token);
                  style = get_style(value, meta);
                  style_text = "";
                  if (style == "red") {
                    style_text = "background-color: rgba(255, 106, 106, .2); color: #FF6A6A;";
                    red_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                    if (meta.red_score != null) {
                      rugscore += parseFloat(meta.red_score);
                    }
                  } else if (style == "orange") {
                    style_text = "background-color: rgba(255, 139, 70, 0.2); color: #FF8B46;";
                    orange_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                    if (meta.orange_score != null) {
                      rugscore += parseFloat(meta.orange_score);
                    }
                  } else if (style == "yellow") {
                    style_text = "background-color: rgba(255, 222, 106, .2); color: #ffaf4c;";
                    yellow_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                    if (meta.yellow_score != null) {
                      rugscore += parseFloat(meta.yellow_score);
                    }
                  }

                  //this.liquidity_report.push([meta.display_name, formatted_value, style_text])
                  this.liquidity_report[key] = [meta.display_name, formatted_value, style_text];
                }
              }
            }            
          }
        }
        console.log(this.liquidity_report)
        //if (Object.prototype.hasOwnProperty.call(response.data, 'liquidity_providers'))  { this.liquidity_providers = response.data.liquidity_providers; }
        if (data.forensics) {
          //this.forensics_report = response.data.forensics_report;
          for (key in data.forensics) {
            if (data.forensics[key] != null && data.forensics[key] != undefined) {
              if (key == "report_generated") {
                key = "report_generated_forensics";
              }
              value = data.forensics[key];
              meta = this.reports_meta[key];

              if (meta.display_name != null) {
                formatted_value = get_formatted_value(value, meta, token);
                style = get_style(value, meta);
                style_text = "";
                if (style == "red") {
                  style_text = "background-color: rgba(255, 106, 106, .2); color: #FF6A6A;";
                  red_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.red_score != null) {
                    rugscore += parseFloat(meta.red_score);
                  }
                } else if (style == "orange") {
                  style_text = "background-color: rgba(255, 139, 70, 0.2); color: #FF8B46;";
                  orange_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.orange_score != null) {
                    rugscore += parseFloat(meta.orange_score);
                  }
                } else if (style == "yellow") {
                  style_text = "background-color: rgba(255, 222, 106, .2); color: #ffaf4c;";
                  yellow_metrics.push([meta.display_name, formatted_value, style_text, meta.hover_description]);
                  if (meta.yellow_score != null) {
                    rugscore += parseFloat(meta.yellow_score);
                  }
                }

                //this.forensics_report.push([meta.display_name, formatted_value, style_text])
                this.forensics_report[key] = [meta.display_name, formatted_value, style_text];
              }
            }
          }
        }
        console.log(this.forensics_report)

        this.warn_metrics = red_metrics.concat(orange_metrics).concat(yellow_metrics);
        if (this.warn_metrics.length > 0) {
          this.show_warnings = true;
        } else {
          this.no_issues = true;
        }

        if (!this.show_report && !this.show_clone) {
          this.show_error = true;
          this.error_str = "Token not found.";
        }

        console.log('!! Analytics ready !!')
        console.log(this.liquidity_report)

        this.analytics_ready = true;
      }
    },
  },
  mounted() {
    console.log("analytics tab mounted");
    if (!this.analytics_ready) {
      this.process_analytics(this.$store.state.token);
    }
  },
  watch: {
    "$store.state.token": function() {
      this.$store.state.tokenAnalytics = null;
      this.analytics_ready = false;
      this.analytics_data = null;
      this.general_report = {};
      this.liquidity_report = {};
      this.liquidity_providers = [];
      this.forensics_report = {};
      this.code_matches = "";
      this.code_matches_overflow = "";
      this.code_match_type = "";
      this.n_code_matches = 0;
      this.reports_meta = [];
      this.warn_metrics = [];
      this.show_warnings = false;
      this.no_issues = false;
      this.token_address = "";
      this.token_name = "";
      this.token_symbol = "";
      this.quote_symbol = "";
      this.show_report = false;
      this.show_liq_report = false;
      this.show_clone = false;
      this.clone_token = [];
      this.clone_match_url = "";
      this.show_error = false;
      this.error_str = "";
      this.rugscore_str = "";
      this.rugcircle_attr = "";
      this.matches_score = null;
      this.over_max_cakelp_txns = false;
      this.coll = null;
      this.no_data = false;


      setTimeout(() => {
        this.process_analytics(this.$store.state.token);
      }, 500);

    },
  },
  updated() {
    var coll = document.getElementsByClassName("hideable_button");
    var i;
    //console.log(JSON.parse(JSON.stringify(coll)))
    //console.log(coll.length)
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.previousElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
          this.innerText = "Show more";
        } else {
          content.style.display = "block";
          this.innerText = "Show less";
        }
      });
    }
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.2);
  width: 4px;
  height: 10px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  border: solid 3px transparent;
  box-shadow: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: none;
  box-shadow: inset 0 0 10px 10px #1bc870;
  border-radius: 20px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
</style>
