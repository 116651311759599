<template>
  <div>
    
    <div v-if="this.$store.state.masterToken == this.$store.state.chainToken" class="relative">
      <div class="inline-flex shadow-sm overflow-hidden rounded-md">
        <div v-wave class="button py-1 relative inline-flex items-center bg-white dark:bg-gray-800 hover:bg-opacity-90 pl-2 pr-2 border border-transparent shadow-sm text-gray-800 dark:text-gray-200">
          <div>
            <div
              class="flex justify-between items-center cursor-pointer"
              @click="
                togglePricePair();
                animate();
              "
            >
              <span class="text-xs font-medium"
                ><div v-if="activatePair === true">{{ this.$store.state.chainTokenSymbol }}</div>
                <div v-if="!activatePair === true">USD</div></span
              >

              <svg :class="{ 'animate-spin': animated }" xmlns="http://www.w3.org/2000/svg" class="ml-1 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activatePair: this.$store.state.isTokenPair,
      animated: false,
    };
  },
  methods: {
    togglePricePair: function() {
      this.$store.commit("togglePricePair");
      this.activatePair = !this.activatePair;
    },
    animate: function() {
      var self = this;
      self.animated = true;
      setTimeout(function() {
        self.animated = false;
      }, 500);
    },
  },
};
</script>
