<template>
  <tableContainer>
    <tableHeader v-if="$store.state.favoritedTokens.length">
      <headerCell :name="'Token'" />
      <headerCell :name="'Mcap'" />
      <headerCell :name="'Chain'" />
    </tableHeader>

    <tableBody v-if="$store.state.favoritedTokens.length">
      <Row v-for="item in $store.state.favoritedTokens" :key="item">
        <dataCell :type="'token'" :tokenImageUrl="item.tokenImageUrl" :address="item.tokenAddress" :tokenName="item.tokenName" :price="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" />
        <dataCell :type="'text'" :value="bogintificPrice(item.mcap, $store.state.useTruncatedBogintific)" />
        <dataCell :type="'chain'" :chain="item.tokenChain" />
      </Row>
    </tableBody>

    <div v-else class="w-full relative h-96 p-4 bg-gray-800 rounded-b-lg overflow-hidden rounded-lg">
      <div class="absolute left-0 space-y-6 z-10 top-0 text-center flex flex-col place-content-center items-center w-full h-full">
        <h2 class="font-semibold text-white">You haven't added any favorites</h2>
        <router-link to="/cryptocurrencies" class="px-4 py-2 rounded-md overline bg-blue-bright bg-opacity-20 hover:bg-opacity-30 divide-gray-200 transition-all text-blue-bright text-white">Find Favorites</router-link>
      </div>
      <div class="bg-gradient-to-t from-gray-900 to-transparent w-full h-full left-0 top-0 absolute"></div>
      <div v-for="index in 6" :key="index">
        <div class="bg-gray-700 bg-opacity-50 mb-4 rounded-md w-full h-20"></div>
      </div>
    </div>
  </tableContainer>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import dataCell from "@/components/Table/dataCell";
import Row from "@/components/Table/Row";
import tableBody from "@/components/Table/tableBody";
import tableHeader from "@/components/Table/tableHeader";
import headerCell from "@/components/Table/headerCell";
import tableContainer from "@/components/Table/tableContainer";

export default {
  data: function() {
    return {
      sortType: "",
      isReverse: false,
    };
  },
  components: {
    dataCell,
    Row,
    tableBody,
    tableHeader,
    headerCell,
    tableContainer,
  },
  methods: {
    bogintificPrice,
    maxDigits,
  },
};
</script>
