<template>
  <div
    class="relative overflow-hidden w-full bg-gray-800 rounded-full border border-gray-700"
  >
    <div class="marquee-content">
      <div class="marquee-items">
        <a class="mx-4" v-for="item in news" :key="item.date">
          <div class="flex caption space-x-2 whitespace-nowrap flex-row">
            <span class="text-gray-400"> {{ item.date }} </span>
            <span class="text-white"> {{ item.title }}</span>
          </div>
        </a>
      </div>
      <div class="marquee-items">
        <a class="mx-4" v-for="item in news" :key="item.date">
          <div class="flex caption space-x-2 whitespace-nowrap flex-row">
            <span class="text-gray-400"> {{ item.date }} </span>
            <span class="text-white"> {{ item.title }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowSmDownIcon } from "@heroicons/vue/solid";

const news = [
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" },
  { date: "Aug 20th", title: "BOG Becomes World Currency" }
  // More news...
];

export default {
  setup() {
    return {
      news
    };
  },
  methods: {
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    }
  },
  components: {
    ArrowSmDownIcon
  }
};
</script>

<style style="text/css" scoped>
* {
  box-sizing: border-box;
}

.marquee-content {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
}

.marquee-items {
  --move-initial: 0%;
  --move-final: -200%;
  display: flex;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: 60s linear 0s infinite normal none marquee;
  animation-play-state: running;
  position: absolute;
  left: 100%;
  padding-right: 148px;
}

.marquee-item {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 4px;
  border-radius: 8px;
  white-space: nowrap;
}

.marquee-items:nth-child(2) {
  animation-delay: 30s;
}

.marquee-content:hover > .marquee-items {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
</style>
