import { Services } from "@/services"
import {  
  quoteTokens, 
  USD_TOKENS,
  FACTORY_ABI,
} from "./constants"

class _PairsLib {
  async loadLargestExchangeContract(context, token) {
    console.log('loadLargestExchangeContract')
    if (context.state.token != token) {
        return null
    }
    try{
        context.state.loadLargestExchangeContractTries++
        if (context.state.chain == 'csc') {
            let pools = await Services.getAllLiquidityPoolsBogAPI(context.state.token, context.state.chain)
            if (pools) {
                let largest = pools[0]
                console.log("Using factory for charting: " + largest.factory)
                context.state.masterToken = largest.token0.toLowerCase() === context.state.token.toLowerCase() ? Web3.utils.toChecksumAddress(largest.token1) : Web3.utils.toChecksumAddress(largest.token0);
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
                console.log('master token: '+context.state.masterToken)
                context.state.tokenPairContract = Web3.utils.toChecksumAddress(largest.lp_address);
                console.log('csc tokenPairContract', context.state.tokenPairContract)
                context.state.currentExchange = largest.factory.toLowerCase();
                if (context.state.token.toLowerCase() == largest.token0.toLowerCase()) {
                    context.state.tokenPairIndex = 0
                } else {
                    context.state.tokenPairIndex = 1
                }
            } else {
                console.log('no pools for',context.state.token)
                context.state.tokenPairContract = null
                context.state.currentExchange = null
                return
            }

            // now get pool for quote token
            pools = await Services.getAllLiquidityPoolsBogAPI(context.state.masterToken, context.state.chain)
            if (pools) {
                let largest
                for (let pool of pools) {
                    if (Web3.utils.toChecksumAddress(pool.quote_token) in quoteTokens[context.state.chain]) {
                        largest = pool
                        break
                    }
                }
                if (!largest) {
                    console.log('no pools for quote token',context.state.token)
                    context.state.masterPairContract = null
                    return
                }                                                
                context.state.masterPairContract = Web3.utils.toChecksumAddress(largest.lp_address);
                console.log('csc masterPairContract', context.state.masterPairContract)
                if (context.state.masterToken.toLowerCase() == largest.token0.toLowerCase()) {
                    context.state.masterPairIndex = 0
                } else {
                    context.state.masterPairIndex = 1
                }
            } else {
                console.log('no pools for quote token',context.state.token)
                context.state.masterPairContract = null
                return
            }
        }
        else {
            let pools = await Services.getAllLiquidityPools(context, context.state.web3s[context.state.chain], context.state.token, context.state.chain)                                    
            let largest = Services.getLargestPool(context.state.token, pools)
            if(largest === null){
                context.state.tokenPairContract = null
                context.state.currentExchange = null
                setTimeout(()=>{
                  this.loadLargestExchangeContract(context, token)
                }, 3000);                
                console.log('no pools try',context.state.loadLargestExchangeContractTries)
                return
            }
            console.log("Using factory for charting: " + largest.factory)
            context.state.masterToken = largest.token0.adr.toLowerCase() === context.state.token.toLowerCase() ? largest.token1.adr : largest.token0.adr;
            /*
            if (context.state.masterToken.toLowerCase() != CHAIN_TOKENS[context.state.chain].toLowerCase() && USD_TOKENS.includes(context.state.masterToken.toLowerCase)) {
                context.state.pricePair = 'USD';
                context.state.masterTokenPrice = 1.0
            }
            */
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
            console.log('master token: '+context.state.masterToken)
            context.state.tokenPairContract = largest.adr;
            context.state.currentExchange = largest.factory.toLowerCase();
        }
        
        if (context.state.loadLargestExchangeContractTries > 1) {
            // we found a liq pool after some retries (probably a token launch)
            context.state.singleBlock = true
            context.dispatch("loadToken", context.state.token)                    
        }
    } catch (e) {
        console.log(e)
        console.log("Failed to load largest exchange contract. Rechecking")
        setTimeout(context.dispatch("loadToken", context.state.token), 3500);
    }
  }
  async loadLargestPoolForExchange(context) { // gets & sets the largest liq pool for the currently selected exchange (from all available token pairs)

    try{
        if (context.state.chain == 'csc') {
            let pools = await Services.getAllLiquidityPoolsBogAPI(context.state.token, context.state.chain)
            if (pools) {
                let largest
                for (let pool of pools) {
                    if (pool.factory == context.state.currentExchange.toLowerCase()) {
                        largest = pool
                        break
                    }
                }
                if (!largest) {
                    console.log('no pools for',context.state.token)
                    context.state.tokenPairContract = null
                    context.state.currentExchange = null
                    return
                }
                
                //let largest = pools[0]
                console.log("Using factory for charting: " + largest.factory)
                context.state.masterToken = largest.token0.toLowerCase() === context.state.token.toLowerCase() ? Web3.utils.toChecksumAddress(largest.token1) : Web3.utils.toChecksumAddress(largest.token0);
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
                console.log('master token: '+context.state.masterToken)
                context.state.tokenPairContract = Web3.utils.toChecksumAddress(largest.lp_address);
                console.log('csc tokenPairContract', context.state.tokenPairContract)
                context.state.currentExchange = largest.factory.toLowerCase();
                console.log(context.state.token.toLowerCase(), largest.token0.toLowerCase())
                if (context.state.token.toLowerCase() == largest.token0.toLowerCase()) {
                    context.state.tokenPairIndex = 0
                } else {
                    context.state.tokenPairIndex = 1
                }
            } else {
                console.log('no pools for',context.state.token)
                context.state.tokenPairContract = null
                context.state.currentExchange = null
                return
            }

            // now get pool for quote token
            pools = await Services.getAllLiquidityPoolsBogAPI(context.state.masterToken, context.state.chain)
            if (pools) {
                let largest
                for (let pool of pools) {
                    if (Web3.utils.toChecksumAddress(pool.quote_token) in quoteTokens[context.state.chain]) {
                        largest = pool
                        break
                    }
                }
                if (!largest) {
                    console.log('no pools for quote token',context.state.token)
                    context.state.masterPairContract = null
                    return
                }
                context.state.masterPairContract = Web3.utils.toChecksumAddress(largest.lp_address);
                console.log('csc masterPairContract', context.state.masterPairContract)
                if (context.state.masterToken.toLowerCase() == largest.token0.toLowerCase()) {
                    context.state.masterPairIndex = 0
                } else {
                    context.state.masterPairIndex = 1
                }
            } else {
                console.log('no pools for quote token',context.state.token)
                context.state.masterPairContract = null
                return
            }
        }
        else {
            let pools = await Services.getLiquidityPoolsForExchange(context, context.state.web3s[context.state.chain], context.state.token, context.state.chain, context.state.currentExchange)
            let largest = Services.getLargestPool(context.state.token, pools)
            console.log(largest)
            if(largest === null){ return null; }
            console.log("Using factory for charting: " + largest.factory)
            context.state.masterToken = largest.token0.adr.toLowerCase() === context.state.token.toLowerCase() ? largest.token1.adr : largest.token0.adr;
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
            console.log('master token: '+context.state.masterToken)
            context.state.tokenPairContract = largest.adr;
            context.state.currentExchange = largest.factory.toLowerCase();
        }
    } catch (e) {
        console.log(e)
        console.log("Failed to load largest pool for exchange. Rechecking")
        setTimeout(context.dispatch("loadToken", context.state.token), 3500);
    }
  }
  async setPoolInfo(context) { // gets & sets pool info for the currently selected pool (only needed on coinex)
    try{
        if (context.state.chain == 'csc') {
            let pools = await Services.getAllLiquidityPoolsBogAPI(context.state.token, context.state.chain)
            if (pools) {
                let current_pool
                for (let pool of pools) {
                    if (pool.lp_address == context.state.tokenPairContract.toLowerCase()) {
                        current_pool = pool
                        break
                    }
                }
                if (!current_pool) {
                    console.log('pool not found for',context.state.token)
                    context.state.tokenPairContract = null
                    context.state.currentExchange = null
                    return
                }
                
                //let largest = pools[0]
                console.log("Using factory for charting: " + current_pool.factory)
                context.state.masterToken = current_pool.token0.toLowerCase() === context.state.token.toLowerCase() ? Web3.utils.toChecksumAddress(current_pool.token1) : Web3.utils.toChecksumAddress(current_pool.token0);
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
                console.log('master token: '+context.state.masterToken)
                context.state.tokenPairContract = Web3.utils.toChecksumAddress(current_pool.lp_address);
                console.log('csc tokenPairContract', context.state.tokenPairContract)
                context.state.currentExchange = current_pool.factory.toLowerCase();
                console.log(context.state.token.toLowerCase(), current_pool.token0.toLowerCase())
                if (context.state.token.toLowerCase() == current_pool.token0.toLowerCase()) {
                    context.state.tokenPairIndex = 0
                } else {
                    context.state.tokenPairIndex = 1
                }
            } else {
                console.log('no pools for',context.state.token)
                context.state.tokenPairContract = null
                context.state.currentExchange = null
                return
            }

            // now get pool for quote token
            pools = await Services.getAllLiquidityPoolsBogAPI(context.state.masterToken, context.state.chain)
            if (pools) {
                let largest
                for (let pool of pools) {
                    //if (Web3.utils.toChecksumAddress(pool.quote_token) in quoteTokens[context.state.chain]) {
                    if (Web3.utils.toChecksumAddress(pool.quote_token) == USD_TOKENS[context.state.chain]) {
                        largest = pool
                        break
                    }
                }
                if (!largest) {
                    console.log('no pools for quote token',context.state.token)
                    context.state.masterPairContract = null
                    return
                }
                context.state.masterPairContract = Web3.utils.toChecksumAddress(largest.lp_address);
                console.log('csc masterPairContract', context.state.masterPairContract)
                if (context.state.masterToken.toLowerCase() == largest.token0.toLowerCase()) {
                    context.state.masterPairIndex = 0
                } else {
                    context.state.masterPairIndex = 1
                }
            } else {
                console.log('no pools for quote token',context.state.token)
                context.state.masterPairContract = null
                return
            }
        }
        else {
            return
        }
    } catch (e) {
        console.log(e)
        console.log("Failed to set token info")
    }
  }
  async loadExchangeContracts(context) {
    for (let [factoryAddress, factory] of Object.entries(context.state.exchangeTable[context.state.chain])) {
        let factoryContract = factory.contract
        if (!factoryContract) {
            factoryContract = await new context.state.web3s[context.state.chain].eth.Contract(FACTORY_ABI, factoryAddress)
            context.state.exchangeTable[context.state.chain][factoryAddress].contract = factoryContract
        }
    }
  }
  
}

export const PairsLib = new _PairsLib();
