import { createWebHistory, createRouter } from "vue-router";
import Chart from "@/views/Chart/Chart.vue";
import Home from "@/views/Home/Home.vue";
import Promote from "./views/Promote.vue";
import promoteAds from "./views/promoteAds.vue";
import promoteList from "./views/promoteList.vue";
import promoteTelegram from "./views/promoteTelegram.vue";
import Portfolio from "@/views/Portfolio/Portfolio.vue";
import Cryptocurrencies from "@/views/Cryptocurrencies/Cryptocurrencies.vue";
import hpTest from "@/views/hpTest.vue";
import walletTrackingPage from "@/views/walletTrackingPage.vue";
import gainersLosers from "@/views/Cryptocurrencies/gainersLosers.vue";
import upcomingLaunches from "@/views/Cryptocurrencies/upcomingLaunches/upcomingLaunches.vue";
import Trending from "@/views/Cryptocurrencies/Trending.vue";
import Favorites from "@/views/Cryptocurrencies/Favorites.vue";
import Multichart from "@/views/Multichart/Multichart.vue";
import thirdParty from "./views/thirdParty.vue";
import promoteBogSwap from "./views/promoteBogSwap.vue";
import Tools from "./views/Tools.vue";
import store from "./store";

const routes = [
  {
    path: "/",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/cryptocurrencies",
    name: "Cryptocurrencies",
    component: Cryptocurrencies,
  },
  {
    path: "/hptest",
    name: "hptest",
    component: hpTest, // honeypot test (unlisted)
  },
  {
    path: "/wallet-tracker",
    name: "wallet-tracker",
    component: walletTrackingPage, 
  },
  {
    path: "/gainers-losers",
    name: "Gainers and Losers",
    component: gainersLosers,
  },
  {
    path: "/trending-tokens",
    name: "Trending",
    component: Trending,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/promote",
    name: "Promote",
    component: Promote,
  },
  {
    path: "/promotelist",
    name: "promoteList",
    component: promoteList,
  },
  {
    path: "/promoteads",
    name: "promoteAds",
    component: promoteAds,
  },
  {
    path: "/tools",
    name: "Tools",
    component: Tools,
  },
  {
    path: "/promotetelegram",
    name: "promoteTelegram",
    component: promoteTelegram,
  },
  {
    path: "/promotebogswap",
    name: "promoteBogSwap",
    component: promoteBogSwap,
  },
  {
    path: "/multichart",
    name: "Multichart",
    component: Multichart,
  },
  {
    path: "/thirdparty",
    name: "thirdParty",
    component: thirdParty,
  },
  {
    path: "/:token",
    name: "chart",
    component: Chart,
    params: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  if (!store.state.initialized) {
    store.commit("storeInitialization", store.dispatch("initialize"));
  }
  next();
});

export default router;
