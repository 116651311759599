<template>
  <button
    v-tippy="{
      content: tippyText,
      appendTo: 'parent',
      theme: 'bog',
      animation: 'shift-toward-subtle',
      arrow: false,
      maxWidth: 'none'
    }"
    class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer"
    @click="toggle()"
  >
    <favoriteIcon
      class="toggle-favorite__icon"
      :class="iconClasses"
      @animationend="onIconAnimationEnds"
    />
  </button>
</template>

<script>
import favoriteIcon from "./favoriteIcon";
import { Services } from "@/services";
const Web3 = require("web3");
export default {
  name: "toggleFavorite",
  components: {
    favoriteIcon
  },
  props: ["address", "tokenImageUrl", "tokenName"],
  data() {
    return {
      animating: false
      // favorited: undefined,
      // dynamic: false
    };
  },
  mounted() {},
  computed: {
    dynamic() {
      return this.$props.address == undefined;
    },
    favorited() {
      return this.$store.state.favoritedTokens
        .map((x) => x.tokenAddress)
        .includes(this.fixedAddress);
    },
    iconClasses() {
      return {
        "toggle-favorite__icon--favorited": this.favorited,
        "toggle-favorite__icon--animate": this.animating
      };
    },
    tippyText() {
      if (this.favorited) {
        return "Remove from favorites";
      } else {
        return "Add to favorites";
      }
    },
    fixedAddress() {
      let fixed = this.address;
      if (this.address?.startsWith("0x") && !this.address?.includes("stake")) {
        fixed = Web3.utils.toChecksumAddress(this.address);
      }
      return this.dynamic ? this.$store.state.token : fixed;
    },
    fixedImageUrl() {
      return this.dynamic ? this.$store.state.tokenImage : this.tokenImageUrl;
    },
    fixedTokenName() {
      return this.dynamic ? this.$store.state.tokenName : this.tokenName;
    }
  },
  methods: {
    toggle() {
      // Only animate on favoriting.
      if (!this.favorited) {
        this.animating = true;

        this.$store.state.favoritedTokens.push({
          tokenAddress: this.fixedAddress,
          tokenImageUrl: this.fixedImageUrl,
          tokenName: this.fixedTokenName,
          tokenChain: this.$store.state.chain
        });
        Services.incrementTokenLikeCount(this.fixedAddress);
      } else {
        this.$store.state.favoritedTokens = this.$store.state.favoritedTokens.filter(
          (x) => x.tokenAddress != this.fixedAddress
        );
      }

      localStorage.setItem(
        "favoriteTokens",
        JSON.stringify(this.$store.state.favoritedTokens)
      );

      if (this.dynamic) {
        this.$store.state.tokenFavorited = this.$store.state.favoritedTokens
          .map((x) => x.tokenAddress)
          .includes(this.$store.state.token);
      }
    },
    onIconAnimationEnds() {
      this.animating = false;
    }
  }
  // watch: {
  //   "$store.state.favoritedTokens": {
  //     handler: function() {
  //       let tokenAddress = this.dynamic
  //         ? this.$store.state.token
  //         : this.address;
  //       this.favorited = this.$store.state.favoritedTokens
  //         .map((x) => x.tokenAddress)
  //         .includes(tokenAddress);
  //     },
  //     deep: true
  //   }
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
@keyframes favorite-icon-animation {
  0% {
    opacity: 1;
    transform: scale(0.1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tokenSearchPopout .toggle-favorite,
.vue-tabpanel .toggle-favorite {
  background: transparent !important;
}

.toggle-favorite {
  font-size: 18px;
  position: relative;
}
.toggle-favorite__icon {
  height: 1em;
  width: 1em;
  transition: fill-opacity 0.2s, stroke 0.2s;
  fill: #a0adc2;
  fill-opacity: 0;
  stroke: #a0adc2;
}
.dark .toggle-favorite__icon--favorited {
  fill-opacity: 1;
  stroke: #fdc113;
  fill: #fdc113;
}
.toggle-favorite__icon--favorited {
  fill-opacity: 1;
  stroke: #4c5c75;
  fill: #4c5c75;
}
.toggle-favorite__icon--animate {
  opacity: 0;
  transform: scale(0);
  animation-duration: 0.48s;
  animation-delay: 0.32s;
  animation-name: favorite-icon-animation;
}
.toggle-favorite__particles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 3em;
  height: 3em;
}
</style>
