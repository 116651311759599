<template>
  <div class="inline-flex flex-row bg-gray-800 bg-opacity-50 border border-gray-700 border-opacity-70 py-3 px-6 rounded-full items-center space-x-2 place-self-start">
    <div class="text-gray-300">Fear and Greed Index</div>
    <div v-if="index.value >= 70" v-html="index.value" class="font-bold inline-block rounded-md text-secondary"></div>
    <div v-else-if="index.value > 40" v-html="index.value" class="font-bold inline-block rounded-md text-alert-bright"></div>
    <div v-else-if="index.value <= 40 && index.value" v-html="index.value" class="font-bold inline-block rounded-md text-error-bright"></div>
    <div v-else-if="!index.value" class=" font-bold inline-block rounded-md text-gray-500">N/A</div>
    <div class="text-gray-500">({{ index.value_classification }})</div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      index: [],
    };
  },
  mounted() {
    fetch("https://api.alternative.me/fng/").then((response) => (this.index = response.data.data[0]));
  },
};
</script>
