<template>
  <div v-if="$store.state.showRatio && this.$store.state.chain == 'bsc'" class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4">
    <span class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        ><span class="max-w-10 overflow-ellipsis truncate">{{ $store.state.tokenSymbol }}</span
        >&nbsp;per&nbsp;<span class="max-w-10 overflow-ellipsis truncate">{{ $store.state.masterTokenSymbol }}</span>
      </span>
      <div id="textfade" v-if="$store.state.tokenMarketCap">
        <h4 v-html="bogintific((1 / $store.state.tokenMasterPairPrice).toFixed(3))" class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg"></h4>
      </div>
      <div v-else class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable skeleton-loader"></div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
