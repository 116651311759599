<template>
  <div v-if="$store.state.showMasterTokenPrice" class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4">
    <span class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" /></svg
    ></span>
    <span class="flex flex-col">
      <span class="text-sm w-full block font-heading dark:text-gray-300 text-gray-500 whitespace-nowrap">{{ $store.state.chainTokenSymbol }} Price</span>
      <div id="textfade" v-if="$store.state.chainTokenPrice">
        <h4 v-html="maxDigits($store.state.chainTokenPrice, 2)" class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg group"></h4>
      </div>
      <div v-else class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md w-full h-4 md:h-5 2xl:h-6 animate-pulse-disable skeleton-loader"></div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
