export default {
  ethereum: {
    name: "Ethereum",
    explorerName: "EtherScan",
    info: {
      chainId: "0x1", // A 0x-prefixed hexadecimal string
      chainName: "Ethereum Mainnet",
      nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
      blockExplorerUrls: ["https://etherscan.io/"],
    },
    enabled: false,
    supports: [],
  },
  bsc: {
    name: "Binance Smart Chain",
    explorerName: "BscScan",
    info: {
      chainId: "0x38", // A 0x-prefixed hexadecimal string
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
      blockExplorerUrls: ["https://bscscan.com/"],
    },
    enabled: true,
    supports: ["Trending", "gainerLosers", "Portfolio", "upcomingLaunches", "Swap", "Multichart", "Analytics"],
  },
  polygon: {
    name: "Polygon (Matic)",
    explorerName: "PolygonScan",
    info: {
      chainId: "0x89", // A 0x-prefixed hexadecimal string
      chainName: "Polygon",
      nativeCurrency: {
        name: "Matic",
        symbol: "MATIC", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://rpc-mainnet.matic.quiknode.pro/"],
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
    enabled: true,
    supports: ["Trending", "gainerLosers", "Portfolio", "Swap"],
  },
  avax: {
    name: "Avalanche",
    explorerName: "Snowtrace",
    info: {
      chainId: "0xa86a", // A 0x-prefixed hexadecimal string
      chainName: "Avalanche",
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
      //rpcUrls: ["hhttps://speedy-nodes-nyc.moralis.io/b080b30ddec5b854a3546097/avalanche/mainnet"],      
      blockExplorerUrls: ["https://snowtrace.io/"],
    },
    enabled: true,
    supports: ["Trending", "gainerLosers", "Portfolio", "Swap"],
  },
  ftm: {
    name: "Fantom",
    explorerName: "FTMScan",
    info: {
      chainId: "0xfa", // A 0x-prefixed hexadecimal string
      chainName: "Fantom",
      nativeCurrency: {
        name: "FTM",
        symbol: "FTM", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ftm.tools/"],
      //rpcUrls: ["hhttps://speedy-nodes-nyc.moralis.io/b080b30ddec5b854a3546097/avalanche/mainnet"],      
      blockExplorerUrls: ["https://ftmscan.com/"],
    },
    enabled: true,
    supports: ["Trending", "gainerLosers", "Portfolio", "Swap"],
  },
  csc: {
    name: "Coinex Smart Chain",
    explorerName: "Coinex.net",
    info: {
      chainId: "0x34", // A 0x-prefixed hexadecimal string
      chainName: "Coinex Smart Chain",
      nativeCurrency: {
        name: "CoinEx Token",
        symbol: "CET", // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ["https://rpc.coinex.net"],
      //rpcUrls: ["hhttps://speedy-nodes-nyc.moralis.io/b080b30ddec5b854a3546097/avalanche/mainnet"],      
      blockExplorerUrls: ["https://coinex.net/"],
    },
    enabled: true,
    supports: ["Trending", "gainerLosers"],
  },
};
