export default {
  "charts.common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "charts.common.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado!"])},
  "charts.common.copyaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar dirección"])},
  "charts.common.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
  "charts.common.yourwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cartera"])},
  "charts.common.sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrocinador"])},
  "charts.common.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
  "charts.components.topbar.24hrvol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen diario"])},
  "charts.components.topbar.liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidez"])},
  "charts.components.topbar.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalización de mercado"])},
  "charts.components.topbar.bal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "charts.components.topbar.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
  "charts.components.topbar.trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden limitada"])},
  "charts.components.topbar.bscscan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSCScan"])},
  "charts.components.topbar.singleblocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloques sueltos"])},
  "charts.components.infobar.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambios"])},
  "charts.components.infobar.tokeninfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el token"])},
  "charts.components.infobar.liqinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre la liquidez"])},
  "charts.components.infobar.time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
  "charts.components.infobar.traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiado"])},
  "charts.components.infobar.tokenprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio del token"])},
  "charts.components.infobar.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
  "charts.components.infobar.dex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX"])},
  "charts.components.infobar.transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias"])},
  "charts.components.infobar.holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietarios"])},
  "charts.components.infobar.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrato"])},
  "charts.components.sidebar.goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Bogged.Finance"])},
  "charts.components.sidebar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "charts.components.sidebar.chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico"])},
  "charts.components.sidebar.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
  "charts.components.sidebar.tokenexplorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador de tokens"])},
  "charts.components.sidebar.othertools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras herramientas"])},
  "charts.components.sidebar.madeby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho con ❤ por "])},
  "charts.components.sidebar.bogtools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bogtools"])},
  "charts.components.sidebar.version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión"])},
  "charts.components.infobar.tokeninfo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico del precio en vivo"])},
  "charts.components.infobar.tokeninfo.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página web"])},
  "charts.components.infobar.tokeninfo.telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "charts.components.infobar.tokeninfo.twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "charts.components.infobar.tokeninfo.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "charts.components.infobar.tokeninfo.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrato"])},
  "charts.components.infobar.tokeninfo.waterfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterfall"])},
  "charts.components.infobar.tokeninfo.xsupplyof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiene un total de"])},
  "charts.components.infobar.tokeninfo.xcircsupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con una cantidad en circulación de"])},
  "charts.components.infobar.tokeninfo.liqback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liquidez de [insert token name] respalda el"])},
  "charts.components.infobar.tokeninfo.ofthemcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la capitalización de mercado."])},
  "charts.views.portfolio.clicktoconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecta tu cartera para verla"])},
  "charts.views.portfolio.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
  "charts.views.portfolio.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "charts.views.portfolio.totalvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor total"])},
  "charts.views.portfolio.24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
  "charts.views.portfolio.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalización"])},
  "charts.views.portfolio.stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participar"])},
  "charts.views.portfolio.viewchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver el gráfico"])},
  "charts.views.portfolio.hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar"])},
  "charts.views.portfolio.yourportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cartera"])},
  "charts.views.portfolio.portfoliooverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de tu cartera"])},
  "charts.components.infobar.tokeninfo.basicdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es un token en"])},
  "charts.views.home.welcometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a BogCharts v3"])},
  "charts.views.home.welcometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes ver el gráfico de cualquier token con liquidez en PancakeSwap sin coste alguno con los gráficos de Bogged Finance. Esto incluye información vital como liquidez y capitalización de mercado. Durante las próximas semanas y meses se irán implementando actualizaciónes y mejoras para la plataforma."])},
  "charts.views.home.latestnews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas noticias"])},
  "charts.views.home.latestnews.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El intercambiador v2 ha llegado!"])},
  "charts.views.home.latestnews.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22 de abril 2021"])},
  "charts.views.home.latestnews.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambia cualquier token de PancakeSwap v1 y v2 con Bogged.Finance, y tu orden se procesará automáticamente a través del mejor DEX, sin coste adicional."])},
  "charts.views.home.promote.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promueve tu token"])},
  "charts.views.home.promote.titlelong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promueve tu token con Bogged.Finance"])},
  "charts.views.home.promote.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existe una variedad de recursos y opciones para promover tu token con Bogged.Finance, tanto gratuitos como de pago. Haz clic abajo para averiguar cómo añadir un robot que dé el precio en tu Telegram, añadir tu logo e información a nuestras gráficas, estar en #tendencia en Bogged, y conseguir anuncios en Bogged.Finance."])},
  "charts.views.home.promote.learnmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprender más"])},
  "charts.views.home.trending.trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tendencia"])},
  "charts.views.home.trending.promoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promocionado"])},
  "charts.views.home.trending.viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más vistos"])}
}