<template>
  <not-available v-if="false && $store.state.chain != 'bsc'" />  
  
    <div class="flex flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-8 text-gray-900 dark:text-white place-content-center overflow-x-auto ">
      <div style="min-width:850px" class="overflow-y-hidden dark:bg-gray-50 dark:bg-opacity-5 bg-gray-900 bg-opacity-5 rounded-2xl p-4 lg:p-6 ">
        <div class="flex flex-row items-center">           
          <span class="text-3xl font-heading font-semibold">Live feed</span>
        </div>
        <p v-if="this.$store.state.chain == 'bsc'" >
          Many of these tokens are likely to be scams. Proceed with caution! 🐉
        </p>
        <div class="flex flex-row pt-4">
          <div class="flex">
            Filter: &nbsp;
          </div>
          <div v-if="this.$store.state.chain == 'bsc'" class="flex">
            <button
              v-tippy="{
                content: 'Filter clone tokens that have not been scanned for trustscore / rugpull / honeypot',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_clones()"
            >Clones &nbsp;
            <span v-if="filter_clones">🔵</span>
            <span v-else>◯</span>
              
            </button>
          </div>
          &nbsp;
          <div v-if="this.$store.state.chain == 'bsc'" class="flex">
            <button
              v-tippy="{
                content: 'Filter tokens that have been flagged as rug pulls',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_rugpulls()"
            >Rugpulls &nbsp;
            <span v-if="filter_rugpulls">🔵</span>
            <span v-else>◯</span>
              
            </button>
          </div>
          &nbsp;
          <!--
          <div class="flex">
            <button
              v-tippy="{
                content: 'Filter honeypots',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_honeypots()"
            >Honeypots &nbsp;
            <span v-if="filter_honeypots">🔵</span>
            <span v-else>◯</span>
              
            </button>
          </div>
          &nbsp;
          -->
          <div class="flex">
            <button
              v-tippy="{
                content: 'Minimum liquidity of $2000',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_low_liq()"
            >Low liquidity &nbsp;
            <span v-if="filter_low_liq">🔵</span>
            <span v-else>◯</span>
              
            </button>
          </div>
          &nbsp;
          <div class="flex">
            <button
              v-tippy="{
                content: 'Minimum volume of $1000',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_low_vol()"
            >Low volume &nbsp;
            <span v-if="filter_low_vol">🔵</span>
            <span v-else>◯</span>
              
            </button>
          </div>
          &nbsp;
          <div v-if="this.$store.state.chain == 'bsc'" class="flex">
            <button
              v-tippy="{
                content: 'Minimum trustscore of 50',
                appendTo: 'parent',
                theme: 'bog',
                animation: 'shift-toward-subtle',
                arrow: false,
                maxWidth: 'none'
              }"
              class="toggle-favorite focus:outline-none border border-gray-400 dark:border-gray-700 hover:bg-gray-200 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 lg:py-1 px-1 py-px hover:text-white flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer text-sm"
              @click="toggle_filter_low_trustscore()"
              >Low trustscore &nbsp;
              <span v-if="filter_low_trustscore">🔵</span>
              <span v-else>◯</span>              
            </button>
          </div>
        </div>
        
        <div v-if="loading">
          <br>
          <mini-spinner></mini-spinner>
        </div>
        <div v-else class="text-gray-700 dark:text-gray-200 text-xs  mt-4">     
          <table class="text-md font-mono ">
            <tr class="font-semibold">
              <td class="pr-4"></td>
              <td class="pr-4"></td>
              <td class="pr-4">Name</td>
              <td class="pr-4">Liquidity</td>
              <td v-if="this.$store.state.chain == 'csc'" class="pr-4">7d Volume</td>
              <td v-else class="pr-4">12 hr Volume</td>
              <td class="pr-4">Liquidity added</td>
              <td class="pr-4">Deployer</td>
              <td v-if="this.$store.state.chain == 'bsc'">Trustscore</td>                
            </tr>
                
            <tr v-for="tok in tokens" v-bind:key="tok.address">
              <td class="pt-3 ">                  
                <a class="nowrap text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-white" :href="'?c='+$store.state.chain+'&t='+tok.address+'&page=chart'">Chart</a>&nbsp;                  
              </td>
              <td class="pt-3 ">                  
                <a v-if="tok.trustscore != undefined" class="nowrap text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-white" :href="'?c='+$store.state.chain+'&t='+tok.address+'&page=analytics'">Analytics</a>&nbsp;
              </td>
              <td class="items-center pt-3">                                    
                {{tok.name.substring(0,30)}}
              </td>
              <td class="pt-3 ">
                ${{ Math.round(tok.liq_usd * 100) / 100 }}
              </td>
              <td class="pt-3 ">
                ${{ Math.round(tok.vol_2h * 100) / 100 }}
              </td>
              <td class="pt-3 ">
                {{ timestamp_to_age(tok.liq_added) }}
              </td>
              <td class="pt-3 ">
                <a
                  class="flex flex-row items-center space-x-2"
                  rel="noopener noreferrer"
                  target="_blank"
                  :href="'https://' + $store.state.blockExplorer + '/address/' + tok.creator"
                >{{tok.creator.substring(0,6)}}</a>
              </td>
              <td class="pt-3 ">
                <div style="margin-top: -1.6rem !important; margin-bottom: -1.6rem !important;" v-if="tok.trustscore != undefined">
                  <dataCell :type="'trustScore'" :value="tok.trustscore" />
                </div>
                <div v-if="tok.clone_of != undefined">
                  <td>
                    <a :href="'?c='+$store.state.chain+'&t='+tok.clone_of+'&page=analytics'">
                      
                      Clone of {{ tok.clone_of.substring(0,8) }}
                    </a>
                  </td>
                </div>
              </td>
              <td class="pt-3 ">
                <div v-if="tok.is_rugpull == 1">Rugpull</div>
                <!--<div v-if="tok.transfer_sim_result == 'FAIL'">Honeypot</div>-->
              </td>
              
            </tr>
          </table>
        </div>
      </div>
      
    </div>  
</template>

<script>




import miniSpinner from "@/components/miniSpinner.vue";
import { maxLength, bogintificPrice, bogintificValue } from "@/filters";
import { Services } from "@/services";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";
import { CHAIN_MAP } from "@/constants"
import { InformationCircleIcon } from "@heroicons/vue/outline";
import dataCell from "@/components/Table/dataCell";

export default {
  components: { notAvailable, InformationCircleIcon, miniSpinner, dataCell },
  data() {
    return {
      tokens: [],
      timeout_id: null,
      filter_clones: null,
      filter_rugpulls: null,
      filter_honeypots: null,
      filter_low_liq: null,
      filter_low_vol: null,
      filter_low_trustscore: null,
      loading: false,
      most_recent_fetch_ts: 0,
    };
  },
  computed: {
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("NewTokensFeed");
    },
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
    
  },
  methods: {
    toggle_filter_clones() {
      this.filter_clones = !this.filter_clones
      localStorage.analytics_filter_clones = this.filter_clones
      this.get_new_tokens(this.$store.state.chain, true)
    },
    toggle_filter_rugpulls() {
      this.filter_rugpulls = !this.filter_rugpulls
      localStorage.analytics_filter_rugpulls = this.filter_rugpulls
      this.get_new_tokens(this.$store.state.chain, true)
    },
    toggle_filter_honeypots() {
      this.filter_honeypots = !this.filter_honeypots
      localStorage.analytics_filter_honeypots = this.filter_honeypots
      this.get_new_tokens(this.$store.state.chain, true)
    },
    toggle_filter_low_liq() {
      this.filter_low_liq = !this.filter_low_liq
      localStorage.analytics_filter_low_liq = this.filter_low_liq
      this.get_new_tokens(this.$store.state.chain, true)
    },
    toggle_filter_low_vol() {
      this.filter_low_vol = !this.filter_low_vol
      localStorage.analytics_filter_low_vol = this.filter_low_vol
      this.get_new_tokens(this.$store.state.chain, true)
      
    },
    toggle_filter_low_trustscore() {
      this.filter_low_trustscore = !this.filter_low_trustscore
      localStorage.analytics_filter_low_trustscore = this.filter_low_trustscore
      this.get_new_tokens(this.$store.state.chain, true)
    },
    timestamp_to_age(timestamp_seconds) {
      var now_ts = Math.floor(Date.now() / 1000);
      var diff = now_ts - timestamp_seconds;
      if (diff < 59.5) {
        let n = Math.round(diff)
        if (n == 1) { return n.toString() + " sec" }
        else { return n.toString() + " secs" }
      } else if (diff < 60 * 59.5) {
        let n = Math.round(diff / 60)
        if (n == 1) { return n.toString() + " min" }
        else { return n.toString() + " mins" }
      } else if (diff < 60 * 60 * 23.5) {
        let n = Math.round(diff / 60 / 60)
        if (n == 1) { return n.toString() + " hr" }
        else { return n.toString() + " hrs" }
      } else {
        let n = Math.round(diff / 60 / 60 / 24)
        if (n == 1) { return n.toString() + " day" }
        else { return n.toString() + " days" }        
      }
    },
    get_new_tokens(chain, show_loading_anim=false) {
      if (this.timeout_id) {
        clearTimeout(this.timeout_id)
      }
      console.log('get_new_tokens fired')
      if (show_loading_anim) {
        this.loading=true
      }
      let fetch_ts = Date.now()
      this.most_recent_fetch_ts = fetch_ts
      try {
        Services.getTokenFeedBogApi(chain, this.filter_clones, this.filter_rugpulls, this.filter_honeypots, this.filter_low_liq, this.filter_low_vol, this.filter_low_trustscore).then((res) => {
          if (this.$store.state.chain != chain) { return; }
          if (fetch_ts == this.most_recent_fetch_ts) {
            for (let t of res.data) {
              t.liq_usd *= 2.0 // api returns just the quote token value component of liq
            }
            this.tokens = res.data
            this.loading=false
            //console.log('oooooooooooooooooooooo')
            //console.log('NEW TOKENS FEED RESULT')
            //console.log(res.data)
            //console.log('oooooooooooooooooooooo')
            this.timeout_id = setTimeout(() => {
              this.get_new_tokens(chain);
            }, 30*1000);
          }
          
        })
        

      } catch (e) {
        console.log('!! new token feed exception')
        console.log(e)
      }
    }
  },
  mounted() {
    this.loading=true
    if (localStorage.analytics_filter_clones == undefined) {
      localStorage.analytics_filter_clones = true
      console.log('setting clones')
    }
    if (localStorage.analytics_filter_rugpulls == undefined) {
      localStorage.analytics_filter_rugpulls = true
    }
    if (localStorage.analytics_filter_honeypots == undefined) {
      localStorage.analytics_filter_honeypots = true
    }
    if (localStorage.analytics_filter_low_liq == undefined) {
      localStorage.analytics_filter_low_liq = true
    }
    if (localStorage.analytics_filter_low_vol == undefined) {
      localStorage.analytics_filter_low_vol = true
    }
    if (localStorage.analytics_filter_low_trustscore == undefined) {
      localStorage.analytics_filter_low_trustscore = true
    }

    this.filter_clones = localStorage.analytics_filter_clones == 'true'
    this.filter_rugpulls = localStorage.analytics_filter_rugpulls == 'true'
    this.filter_honeypots = localStorage.analytics_filter_honeypots == 'true'
    this.filter_low_liq = localStorage.analytics_filter_low_liq == 'true'
    this.filter_low_vol = localStorage.analytics_filter_low_vol == 'true'
    this.filter_low_trustscore = localStorage.analytics_filter_low_trustscore == 'true'
    console.log('set filter_clones = ',this.filter_clones)
    if (this.timeout_id) {
      clearTimeout(this.timeout_id)
    }
    this.get_new_tokens(this.$store.state.chain)
  },
  unmounted() {
    if (this.timeout_id != null) {
      console.log('!! clearing timeout id !!')
      if (this.timeout_id) {
        clearTimeout(this.timeout_id)
      }
    }    
  },
  watch: {
    "$store.state.chain": function() {
      console.log('!! clearing timeout id !!')
      if (this.timeout_id) {
        clearTimeout(this.timeout_id)
      }
      this.tokens = []
      this.get_new_tokens(this.$store.state.chain)
    },
  },  
};
</script>

