<template>
  <div class="p-2" id="mobileChart">
    <div id="chartWrapper" class="flex flex-col w-full overflow-hidden">
      <div class="flex flex-col fade-in space-y-1.5">
        <marquee />
        <topbar />
        <actions-bar />
      </div>

      <div :class="tabMode === 0 ? '' : 'opacity-0 hidden'" class="fade-in flex overflow-hidden h-full lg:flex-row flex-col space-y-4 lg:space-y-0 fade-in">
        <div class="flex relative" id="bogChart">
          <div v-if="$store.state.chartLoading" style="backdrop-filter: blur(4px);" class="top-0 absolute flex items-center place-content-center flex-col z-20 w-full left-0 h-full bg-white dark:bg-gray-900">
            <mini-spinner></mini-spinner>
            <div class="text-center md:max-w-md sm:max-w-sm max-w-50">
              <span class="text-xs mt-10 block text-gray-600">{{ text }}</span>
            </div>
          </div>
          <div id="tv_chart_container" style="flex: 1 1 0%;"></div>
        </div>
        <toggles />
        <div class="h-full w-full rounded-lg bg-gray-50 border-gray-200 dark:bg-gray-800 border dark:border-gray-700 overflow-hidden">
          <token-tabs></token-tabs>
        </div>
      </div>

      <div :class="tabMode === 1 ? '' : 'opacity-0 hidden'" id="mobileSwap" class="fade-in dark:bg-gray-800 bg-white overflow-y-auto lg:pb-4 lg:px-4 border-t dark:border-gray-700">
        <swap></swap>
      </div>

      <div class="fixed left-0 bottom-0 z-20 w-full bg-gray-800 border-t border-b border-gray-700">
        <div class="flex flex-row h-full">
          <div :class="[tabMode === 0 ? activeModeClasses : inactiveModeClasses]" @click="tabMode = 0" class="py-6 px-3 w-1/2 h-full items-center place-content-center flex flex-row text-primary">
            <transition name="fade">
              <svg xmlns="http://www.w3.org/2000/svg" v-if="tabMode != 0" class="w-6 h-6 flex-shrink-0 fill-current mr-2 absolute" fill="none" viewBox="0 0 25 24">
                <path
                  fill=""
                  d="M6.5 21h-2a2 2 0 01-2-2v-3a2 2 0 012-2h2a2 2 0 012 2v3a2 2 0 01-2 2zM20.5 21h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2v6a2 2 0 01-2 2zM13.5 21h-2a2 2 0 01-2-2v-9a2 2 0 012-2h2a2 2 0 012 2v9a2 2 0 01-2 2z"
                  opacity=".35"
                ></path>
                <path fill="" d="M5.52 9a1 1 0 01-.504-1.865l6.982-4.04c.235-.135.514-.168.772-.097l7 1.973a1 1 0 01-.543 1.925l-6.594-1.858L6.02 8.865a.995.995 0 01-.5.135z"></path>
                <path fill="" d="M19.5 8a2 2 0 100-4 2 2 0 000 4zM12.5 6a2 2 0 100-4 2 2 0 000 4zM5.5 10a2 2 0 100-4 2 2 0 000 4z"></path>
              </svg>
            </transition>
            <transition name="fade">
              <span v-if="tabMode === 0" class="overline text-2xs absolute">{{ $t("charts.components.sidebar.chart") }}</span>
            </transition>
          </div>

          <div :class="[tabMode === 1 ? activeModeClasses : inactiveModeClasses]" @click="tabMode = 1" class="py-6 px-3 w-1/2 h-full items-center place-content-center flex flex-row text-primary">
            <transition name="fade"
              ><svg xmlns="http://www.w3.org/2000/svg" v-if="tabMode != 1" class="w-6 h-6 flex-shrink-0 fill-current mr-2 absolute" fill="none" viewBox="0 0 25 24">
                <path d="M21.414 11.586l-6.572-6.572 1.507-1.507c.545-.545.162-1.477-.609-1.481L10.26 2A1.255 1.255 0 009 3.26l.026 5.48c.004.77.936 1.154 1.481.609l1.507-1.507 6.572 6.572a2 2 0 102.828-2.828z" opacity=".35" />
                <path d="M2.586 12.414l6.572 6.572-1.507 1.507c-.545.545-.161 1.477.609 1.481l5.48.026A1.255 1.255 0 0015 20.74l-.026-5.48c-.004-.771-.936-1.154-1.481-.609l-1.507 1.507-6.572-6.572a2 2 0 10-2.828 2.828z" />
              </svg>
            </transition>
            <transition name="fade">
              <span v-if="tabMode === 1" class="overline text-2xs absolute">{{ $t("charts.components.topbar.swap") }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swap from "@/components/Swap/swapForm.vue";
import tradeIndicator from "@/views/Chart/components/Tabs/sentimentBar.vue";
import Topbar from "@/views/Chart/components/Topbar/Topbar";
import bogChart from "@/views/Chart/components/bogChart";
import Marquee from "@/components/Marquee.vue";
import actionsBar from "../components/actionsBar/actionsBar.vue";
import tokenTabs from "@/views/Chart/components/Tabs/tokenTabs";
import Toggles from "../components/actionsBar/Toggles.vue";

export default {
  components: { Swap, tradeIndicator, Topbar, bogChart, tokenTabs, Marquee, actionsBar, Toggles },
  data() {
    return {
      activeModeClasses: "bg-opacity-60 bg-gray-900 text-primary cursor-pointer focus:outline-none",
      inactiveModeClasses: "dark:text-gray-400 shadow-inner hover:text-white cursor-pointer focus:outline-none",

      tabMode: 0,
    };
  },
};
</script>
