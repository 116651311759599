<template>
  <page-layout>
    <transition @before-enter="scrollTop" mode="out-in" appear>
      <router-view />
    </transition>
  </page-layout>
</template>

<script>
import pageLayout from "@/components/pageLayout";
export default {
  components: { pageLayout },
  methods: {
    scrollTop() {
      document.getElementById("app").scrollIntoView();
    },
  },
};
</script>
