<template>
  <!--
  <div v-if="!$store.state.disableAd" class="flex w-full place-content-center">
    <iframe class="lg:block hidden" src="//ad.a-ads.com/1701147?size=970x90" scrolling="no" style="width:970px; height:90px; border:0px; padding:0; overflow:hidden" allowtransparency="true"></iframe>
    <iframe class="lg:hidden" src="//ad.a-ads.com/1701157?size=320x50" scrolling="no" style="width:320px; height:50px; border:0px; padding:0; overflow:hidden" allowtransparency="true"></iframe>
  </div>
  -->
  <div class="container mx-auto my-8 px-4">
    <div class="flex flex-row justify-between">
      <div>
        <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">
          Top Cryptocurrencies on <span :class="$store.state.chain == 'bsc' ? 'uppercase' : 'capitalize'">{{ $store.state.chain.toUpperCase() }}</span>
        </h1>
        <h3 class="text-gray-500 text-xl mb-10">View today's best cryptocurrencies</h3>
      </div>
      <div class="lg:block hidden"> 
        <iframe data-aa="1701157" src="//ad.a-ads.com/1701157?size=320x50" style="width:320px; height:50px; border:0px; padding:0; overflow:hidden; background-color: transparent;"></iframe>
      </div>
    </div>
    <article-list class="mb-10" />
    <top-cards />
    <all-tokens class="mt-8" />
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import allTokens from "./components/allTokens.vue";
import topCards from "./components/topCards/topCards.vue";
import Marquee from "@/components/Marquee.vue";
import articleList from "@/components/articleList.vue";
import fearGreedIndex from "@/components/fearGreedIndex.vue";
import Popper from "vue3-popper";

export default {
  components: {
    bogintificPrice,
    maxDigits,
    Popper,
    topCards,
    allTokens,
    articleList,
    Marquee,
    fearGreedIndex,
  },

  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
