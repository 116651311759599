<template>
  <div class="w-full relative subPageHeightAdjust">
    <transition appear name="fade">
      <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full absolute">
        <div class="w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
          <div class="w-full">
            <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
              <div class="px-6 py-8 bg-white dark:bg-transparent">
                <h4 class="mb-2 text-gray-800 dark:text-primary">Embed BogSwap in your website for free</h4>
                <h6 class="leading-relaxed text-gray-700 dark:text-gray-100">
                  It's quick and simple.
                </h6>
              </div>
              <div class="flex flex-wrap">
                <span class="leading-relaxed text-gray-700 dark:text-gray-100 px-6 py-8">
                  1. Copy and paste the below code into your website.<br />
                  2. Remove the existing contract address (0x18b426813731C144108c6D7FAf5EdE71a258fD9A)<br />
                  3. Replace it with your contract address, in a checksum'd format.
                </span>

                <code class="block whitespace-pre overflow-auto ">
                  &lt;iframe src=&quot;https://app.bogged.finance/swap?tokenIn=BNB&amp;tokenOut=0x18b426813731C144108c6D7FAf5EdE71a258fD9A&amp;embed=1&quot; height=&quot;780px&quot; width=&quot;100%&quot; &gt;&lt;/iframe&gt;
                </code>
                <span class="leading-relaxed text-gray-700 dark:text-gray-100 px-6 py-8">
                  Advanced options:<br />
                  <b>Autoset Slippage:</b> You can add <code>&amp;slippage=5</code> to, for example, autoset the slippage to 5%.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
