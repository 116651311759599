<template>
  <div class="w-full relative subPageHeightAdjust">
    <transition appear name="fade">
      <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full absolute">
        <div class="lg:w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
          <router-link
            to="/promote"
            class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:hover:text-gray-100 dark:border-gray-700 dark:bg-gray-800 text-gray-300 transition-colors duration-200 h-auto p-4 text-center cursor-pointer button"
            >Back to Promotion</router-link
          >
          <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
            <div class="px-6 py-8 bg-white dark:bg-transparent">
              <h4 class="mb-2 text-gray-800 dark:text-primary">Add your token to the promoted token list</h4>
              <h6 class="leading-relaxed text-gray-700 dark:text-gray-100 mb-6">
                Get your token in front of millions of monthly visitors.
              </h6>
              <ul class="list-disc text-sm ml-4 space-y-2 text-gray-700 dark:text-gray-300">
                <li>Your token will go through an approval process to ensure it is not a honeypot or scam.</li>
                <li>Your token will go to the top of the promoted token list.</li>
                <li>As other tokens are promoted, you'll start to travel down the list.</li>
                <li>You can resubmit your token to bring it back to the top at any time.</li>
                <li>The latest promoted tokens are displayed on the Main chart, and on the token explorer.</li>
                <li>We reserve the right to remove rugpulls from the list.</li>
                <li><b>Once you submit your token, please let us know in the <a href="https://t.me/bogfortokenteams">Telegram Group</a>.</b></li>
              </ul>
            </div>
            <div>
              <div class="flex flex-row items-center mb-3 px-6 content-center	">
                <h6 class="mr-2 font-semibold dark:text-white text-primary-dark content-center	" style="text-align:center">Promote your token now by submitting it below.</h6>
              </div>

              <input
                class="text-gray-800 shadow-inner dark:text-white overline placeholder-gray-400 dark:placeholder-secondary  dark:bg-secondary dark:bg-opacity-20 dark:border-secondary border focus:ring-0 focus:ring-gray-300 focus:border-gray-200 border-transparent w-full h-14 bg-gray-200"
                type="text"
                placeholder="Enter your Token Address..."
                v-model="token"
              />
              <button type="button" class="py-5 w-full transition-opacity hover:opacity-70 bg-secondary text-white focus:outline-none" @click="connectWallet" v-if="isWalletConnected || $store.state.chain != 'bsc'">
                Connect Wallet on BSC
              </button>
              <button type="button" class="py-5 w-full transition-opacity hover:opacity-70 bg-secondary text-white focus:outline-none" @click="promote" v-else>
                Pay 12 BNB
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {Services} from "@/services";

export default {
  data() {
    return {
      token: "",
    }
  },
  methods: {
    async connectWallet() {
      try {
        let web3 = await Services.getWeb3(this.$store.state.chain);
        await this.$store.commit("connectWallet", web3);
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    promote: async function() {
      let accounts = await this.$store.state.wallet.eth.getAccounts();
      let contract = await Services.getPromotedContract(this.$store.state.wallet);
      await contract.methods.requestPromotion(this.$store.state.web3.utils.toChecksumAddress(this.token)).send({
        from: accounts[0],
        value: this.$store.state.web3.utils.toWei("12", "ether"),
      });
    }
  },
  getters: {
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
  }
}
</script>