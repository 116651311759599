<template >
  <TabGroup v-if="renderComponent" :defaultIndex="$store.state.defaultTabIndex" >
    <TabList class="px-1 md:px-4 space-x-0 md:space-x-0 py-3 z-10 relative">
      <Tab v-for="tab in Object.keys(tabs)" as="template" :key="tab" v-slot="{ selected }">
        <button @click="setPage(tab)" :class="['py-1.5 px-2 text-sm transition-colors duration-200 rounded-lg group focus:outline-none focus:ring-1 ring-gray-500 ring-opacity-10', selected ? 'bg-secondary bg-opacity-20 text-secondary' : 'text-gray-400 hover:text-gray-900 dark:hover:text-white']">
          {{ tab }}
          <span class="px-1 py-0.5 text-2xs uppercase font-semibold rounded-md bg-blue-bright text-blue-bright bg-opacity-20" v-if="false && tab == 'Trending'">New</span>
        </button>
      </Tab>
    </TabList>

    <TabPanels>
      <TabPanel v-for="(tabs, idx) in Object.values(tabs)" :key="idx">
        <div v-for="tab in tabs" v-bind:key="tab.id">
          <component v-bind:is="tab.content"></component>
        </div>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import chartPage from "./components/chartPage.vue";
import analyticsPage from "./components/analyticsPage.vue";
import Trending from "../Cryptocurrencies/Trending.vue";
import newTokensPage from "./components/newTokensPage.vue";
import { ref } from "vue";
import { Services } from "@/services";

export default {
  components: { TabGroup, TabList, Tab, TabPanels, TabPanel, chartPage, analyticsPage, Trending, newTokensPage },
  data() {
    return {
      renderComponent: true,
    };
  },
  methods: {
    force_rerender() {
      // Remove my-component from the DOM
      this.set_tabs()
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    set_tabs() {
      console.log('>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<')
      console.log(this.$store.state.chain)
      console.log('>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<')
      if (this.$store.state.chain == 'bsc') {
        this.tabs = {
          Chart: [
            {
              id: 0,
              content: "chartPage",
              page: "chart"
            },
          ],
          Analytics: [
            {
              id: 1,
              content: "analyticsPage",
              page: "analytics"
            },
          ],
          Trending: [
            {
              id: 2,
              content: "Trending",
              page: "trending"
            },
          ],
        }
      } else {
        this.tabs ={
          Chart: [
            {
              id: 0,
              content: "chartPage",
              page: "chart"
            },
          ],
          Trending: [
            {
              id: 2,
              content: "Trending",
              page: "trending"
            },
          ],
        }
      }
    },
    setPage(tab) {
      //this.setTabs()
      this.$store.state.defaultTabIndex = this.tabs[tab][0].id
      this.$store.state.page = this.tabs[tab][0].page
      Services.updateURL(this.$store)
    }
  },
  watch: {
    "$store.state.chain": function() {
      this.force_rerender()
    },
    "$store.state.rerenderTabs": function() {
      console.log('__________________')
        console.log('__________________')
        console.log('rerender tabs fired')
        console.log('__________________')
        console.log('__________________')
      if (this.$store.state.rerenderTabs) {
        this.$store.state.rerenderTabs = false
        this.force_rerender()
      }
    }
  },
  mounted() {
    this.force_rerender()
  },
  setup() {
    let tabs = ref({
      Chart: [
            {
              id: 0,
              content: "chartPage",
              page: "chart"
            },
          ],
          Analytics: [
            {
              id: 1,
              content: "analyticsPage",
              page: "analytics"
            },
          ],
    });
    

     

    return { tabs };
  },
};
</script>
