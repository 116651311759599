<template>
  <div
    class="py-2 px-3 my-2 mx-2 dark:bg-gray-800 bg-gray-200 rounded-xl text-xs"
    v-bind:class="{
      'text-gray-500': isArbitrage,
      'text-blue-bright dark:text-blue-bright': txType === 'Add Liq' || txType === 'Remove Liq',
      'dark:text-secondary text-secondary-dark hover:bg-secondary-dark dark:hover:bg-secondary dark:hover:text-secondary hover:bg-opacity-70 dark:hover:bg-opacity-20 he-bought':
        !isSell && !isArbitrage && txType != 'Add Liq' && txType != 'Remove Liq',
      'dark:text-error-bright text-error hover:bg-error dark:hover:bg-error dark:hover:text-error-bright hover:bg-opacity-70 dark:hover:bg-opacity-20 he-sold':
        isSell && !isArbitrage && txType != 'Add Liq' && txType != 'Remove Liq',
    }"
  >
    <div class="flex flex-row justify-between w-full items-center mb-1.5">
      <div class="space-x-1 flex flex-row flex-nowrap">
        <a
          class="nowrap text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-white"
          target="_blank"
          :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash"
        >
          View
        </a>
        <span
          class="nowrap text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300"
          >{{ shortFromNow }}</span
        >
        <span
          style="max-width: 80px"
          class="nowrap text-2xs py-0.5 px-1.5 overflow-hidden overflow-ellipsis block rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300"
        >
          {{ pool }}</span
        >
      </div>

      <div class="ml-2 flex flex-row space-x-1">
        <div
          v-if="!isSell"
          :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
        <div
          v-else
          :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
        <div
          v-if="!isSell"
          :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
        <div
          v-else
          :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
        <div
          v-if="!isSell"
          :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
        <div
          v-else
          :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'"
          class="w-4 h-1.5 rounded-sm"
        ></div>
      </div>
    </div>

    <div class="flex flex-row justify-between w-full items-center">
      <div v-if="!isArbitrage">
        <span v-if="!isSell && !isArbitrage" class="dark:text-gray-400 text-gray-600">
          <span v-if="txType === 'Add Liq'">Added</span>
          <span v-else-if="!isSell">Bought</span>
        </span>
        <span v-if="isSell && !isArbitrage" class="dark:text-gray-400 text-gray-600">
          <span v-if="txType === 'Remove Liq'">Removed</span>
          <span v-else-if="isSell">Sold</span>
        </span>
        {{ bogintific(roundToNSigFigs(tokenAmount, 2)) }}
        <span class="dark:text-gray-400 text-gray-600">for</span>
        {{ priceMinDigits(roundToNSigFigs(value, 2), 2) }}
        <span v-if="txType != 'Add Liq' && txType != 'Remove Liq'" class="dark:text-gray-400 text-gray-600">(${{ maxDigits(roundToNSigFigs(value / tokenAmount, 2), 2) }} ea.)</span>
        <span v-else class="dark:text-gray-400 text-gray-600">(${{ maxDigits(roundToNSigFigs(value / tokenAmount / 2.0, 2), 2) }} ea.)</span>
      </div>
      <div class="flex flex-row whitespace-nowrap" v-if="walletAddress && !isArbitrage">
        <span class="text-gray-500" v-if="walletAddress && walletAddress == $store.state.tokenDeployer">
          <Tooltip
            v-if="$store.state.top100ByAddress[walletAddress]"
            placement="left"
            :tooltip="
              'Deployer (holds ' +
              Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) / 100 +
              '% of supply)'
            "
          >
            <template v-slot:custom>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-purple-bright mr-2 fill-current" viewBox="0 0 512 512">
                <path
                  d="M200 376l-49.23-16.41c-7.289-2.434-7.289-12.75 0-15.18L200 328l16.41-49.23c2.434-7.289 12.75-7.289 15.18 0L248 328l49.23 16.41c7.289 2.434 7.289 12.75 0 15.18L248 376L240 416H448l-86.38-201.6C355.4 200 354.8 183.8 359.8 168.9L416 0L228.4 107.3C204.8 120.8 185.1 141.4 175 166.4L64 416h144L200 376zM231.2 172.4L256 160l12.42-24.84c1.477-2.949 5.68-2.949 7.156 0L288 160l24.84 12.42c2.949 1.477 2.949 5.68 0 7.156L288 192l-12.42 24.84c-1.477 2.949-5.68 2.949-7.156 0L256 192L231.2 179.6C228.2 178.1 228.2 173.9 231.2 172.4zM496 448h-480C7.164 448 0 455.2 0 464C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-48C512 455.2 504.8 448 496 448z"
                />
              </svg>
            </template>
          </Tooltip>
          <Tooltip v-else placement="left" :tooltip="'Deployer'">
            <template v-slot:custom>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-purple-bright mr-2 fill-current" viewBox="0 0 512 512">
                <path
                  d="M200 376l-49.23-16.41c-7.289-2.434-7.289-12.75 0-15.18L200 328l16.41-49.23c2.434-7.289 12.75-7.289 15.18 0L248 328l49.23 16.41c7.289 2.434 7.289 12.75 0 15.18L248 376L240 416H448l-86.38-201.6C355.4 200 354.8 183.8 359.8 168.9L416 0L228.4 107.3C204.8 120.8 185.1 141.4 175 166.4L64 416h144L200 376zM231.2 172.4L256 160l12.42-24.84c1.477-2.949 5.68-2.949 7.156 0L288 160l24.84 12.42c2.949 1.477 2.949 5.68 0 7.156L288 192l-12.42 24.84c-1.477 2.949-5.68 2.949-7.156 0L256 192L231.2 179.6C228.2 178.1 228.2 173.9 231.2 172.4zM496 448h-480C7.164 448 0 455.2 0 464C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-48C512 455.2 504.8 448 496 448z"
                />
              </svg>
            </template>
          </Tooltip>
        </span>
        <span
          v-else-if="
            walletAddress &&
            $store.state.top100ByAddress[walletAddress] &&
            Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) / 100 >
              0.15
          "
        >
          <Tooltip
            placement="left"
            :tooltip="
              'Top 100 Wallet ' +
              Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) / 100 +
              '% of supply'
            "
          >
            <template v-slot:custom>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-blue-bright mr-2 fill-current" viewBox="0 0 640 512">
                <path
                  d="M480 128c-91.09 0-176.7 35.47-241.1 99.87l-85.66 82.69c-6.312 6.094-14.62 9.437-23.41 9.437C111.2 320 96 304.8 96 286.2c0-31.56 12.28-61.2 34.58-83.52l9.811-9.811l56.75 13.61C207.6 208.6 218.4 205.3 226 197.7l57.28-57.28c9.016-9.016 4.332-24.48-8.221-26.96L191.3 96.7L174.5 12.9C172 .3524 156.6-4.333 147.6 4.682L90.28 61.96C82.68 69.56 79.41 80.43 81.53 90.86l13.61 56.74L49.13 193.6C17.44 225.3 0 267.4 0 312.3c0 92.5 75.25 167.7 167.8 167.7H544c52.94 0 96-43.06 96-95.1V288C640 199.8 568.2 128 480 128zM384 312c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24s24 10.74 24 24C408 301.3 397.3 312 384 312z"
                />
              </svg>
            </template>
          </Tooltip>
        </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          :href="'https://' + $store.state.blockExplorer + '/token/' + $store.state.token + '?a=' + walletAddress"
        >
          <span>
            <img
              style="display:inline-block; whitespace:nowrap; position: relative; top: -2px;"              
              :src="'https://cdn.bogged.finance/blockies/'+walletAddress.substring(walletAddress.length - 4).toLowerCase()+'.png'"
              :id="'blockie_' + walletAddress"
            />
          </span>
        </a>
      </div>

      <div v-if="isArbitrage">ARB</div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "@boggedfinance/bogged-components";
import { maxDigits, priceMinDigits, bogintific, roundToNSigFigs } from "@/filters";
import VueBlockie from "./VueBlockie";
export default {
  props: [
    "token",
    "tokenAmount",
    "isSell",
    "value",
    "id",
    "pool",
    "txHash",
    "isArbitrage",
    "arbPools",
    "shortFromNow",
    "walletAddress",
    "txType",
  ],
  setup() {},
  data: function () {
    return {
      // fromNow: "",
      intervalID: null,
      cellStyle: "px-4 py-2 whitespace-nowrap text-sm font-medium",
    };
  },
  methods: {
    maxDigits,
    priceMinDigits,
    bogintific,
    roundToNSigFigs,
  },
  components: {
    VueBlockie,
    Tooltip,
  },
  async created() {
    // moment.relativeTimeThreshold('ss', 3);  // enable this if you want to show "5 seconds ago" etc
    // window.moment = moment;
    // let self = this; // to pass to setInterval
    // // set initial fromNow
    // let mo = moment(self.$props.date).fromNow();
    // self.$data.fromNow = mo;
    // // update it periodically
    // this.$data.intervalID = setInterval(function() {
    //   let mo = moment(self.$props.date).fromNow();
    //   self.$data.fromNow = mo;
    // }, 5000);
  },
};
</script>
