<template>
  <div class="w-full relative subPageHeightAdjust">
    <transition appear name="fade">
      <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full absolute">
        <div class="lg:w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
          <router-link
            to="/tools"
            class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:hover:text-gray-100 dark:border-gray-700 dark:bg-gray-800 text-gray-300 transition-colors duration-200 h-auto p-4 text-center cursor-pointer button"
            >View Our Tools for Token Teams</router-link
          >
          <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
            <div class="px-6 py-8 bg-white dark:bg-transparent">
              <h4 class="mb-2 text-gray-800 dark:text-primary">Promote Your Token with Bogged.Finance</h4>
              <h6 class="leading-relaxed text-gray-700 dark:text-gray-100">
                There are a variety of ways both free and paid in which you can promote your token with the power of Bogged.Finance. Get the most for your project from Bogged.Finance
              </h6>
            </div>
            <div class="flex flex-wrap">
              <a href="https://a-ads.com/campaigns/new?selected_site_id=529945&selected_source_type=site&partner=1701147" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                <div class="flex flex-row items-center mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">BogCharts Ads</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">Varies</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Hover over the icon at the top right of any ad on Charts.Bogged.Finance to place an order for that ad spot. It's quick and easy!
                </p>
              </a>

              <router-link to="/promotelist" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                <div class="flex flex-row items-center flex-wrap mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Promoted Token List</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">12 BNB</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Instantly add your token directly to the promoted token list and get seen by tens of thousands of users daily!
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </router-link>

              <a href="#" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                <div class="flex flex-row items-center flex-wrap mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Organic Methods of Promotion</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">FREE</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Everytime your users trade your token on BogSwap, view your token on BogCharts and "Love" your token on BogCharts, it helps you show up in the Trending and Most Viewed tokens lists to hundreds of thousands of viewers per day. So
                  just by encouraging your users to use BogCharts, you can get more exposure!
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
