<template>
  <div
    v-bind:class="{
      animateArb: isArbitrage && shortFromNow == 'Now' && $store.state.performanceMode == false && !$store.state.lowSpecMachine,
      animateBuy:
        !isSell && !isArbitrage && shortFromNow == 'Now' && $store.state.performanceMode == false && !$store.state.lowSpecMachine,
      animateSell:
        isSell && !isArbitrage && shortFromNow == 'Now' && $store.state.performanceMode == false && !$store.state.lowSpecMachine,
    }"
  >    
    <div
      :id="'row_' + id"
      class="tradebookRow font-mono text-xs py-0.5 font-light items-center flex flex-row"
      v-bind:class="{
        '': shortFromNow == 'Now' && $store.state.performanceMode == false && !$store.state.lowSpecMachine,
        'text-gray-500': isArbitrage,
        'text-blue-bright dark:text-blue-bright': txType == 'Add Liq' || txType == 'Remove Liq',
        'dark:text-secondary text-secondary-dark hover:bg-secondary-dark dark:hover:bg-secondary dark:hover:text-secondary hover:bg-opacity-70 dark:hover:bg-opacity-20 he-bought':
          !isSell && !isArbitrage && txType != 'Add Liq' && txType != 'Remove Liq',
        'dark:text-error-bright text-error hover:bg-error dark:hover:bg-error dark:hover:text-error-bright hover:bg-opacity-70 dark:hover:bg-opacity-20 he-sold':
          isSell && !isArbitrage && txType != 'Add Liq' && txType != 'Remove Liq',
      }"
    >
      <div hidden id="txHash">{{txHash}}</div>
      <div hidden id="walletAddress">{{walletAddress}}</div>
      
      <div class="max-w-10xl mx-auto flex flex-row w-full">
        
        <div class="tradeTime flex items-center p-2">
          <div class="md:hidden flex flex-row space-x-1 mr-2">
            <div
              v-if="!isSell"
              :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
              class="w-1 h-4 rounded-sm"
            ></div>
            <div
              v-else
              :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
              class="w-1 h-4 rounded-sm"
            ></div>
            <div
              v-if="!isSell"
              :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
              class="w-1 h-4 rounded-sm"
            ></div>
            <div
              v-else
              :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
              class="w-1 h-4 rounded-sm"
            ></div>
            <div
              v-if="!isSell"
              :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
              class="w-1 h-4 rounded-sm"
            ></div>
            <div
              v-else
              :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
              class="w-1 h-4 rounded-sm"
            ></div>
          </div>
          <a
            class="flex flex-row items-center space-x-2"
            rel="noopener noreferrer"
            target="_blank"
            :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash"
          >
            
            <svg
              width="12"
              height="12"
              class="md:inline-block hidden fill-current"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                v-if="!isSell"
                d="M5.168 2.9455L2.168 7.4455C2.0565 7.6125 2 7.806 2 8C2 8.162 2.039 8.324 2.1185 8.472C2.2925 8.797 2.631 9 3 9L9 9C9.369 9 9.7075 8.797 9.8815 8.472C9.961 8.324 10 8.162 10 8C10 7.806 9.9435 7.6125 9.832 7.445L6.832 2.945C6.6465 2.667 6.3345 2.5 6 2.5C5.6655 2.5 5.3535 2.667 5.168 2.9455Z"
              />
              <path
                v-if="isSell"
                d="M6.832 9.0545L9.832 4.5545C9.9435 4.3875 10 4.194 10 4C10 3.838 9.961 3.676 9.8815 3.528C9.7075 3.203 9.369 3 9 3L3 3C2.631 3 2.2925 3.203 2.1185 3.528C2.039 3.676 2 3.838 2 4C2 4.194 2.0565 4.3875 2.168 4.555L5.168 9.055C5.3535 9.333 5.6655 9.5 6 9.5C6.3345 9.5 6.6465 9.333 6.832 9.0545Z"
              />
            </svg>
            
            <div class="flex flex-col">
              {{ shortFromNow }}
            </div>
          </a>
        </div>
        
        <div class="tradeValue flex items-center p-2">
          <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
            <span class="flex flex-row items-center md:space-x-4">
              <div class="md:flex hidden flex-row space-x-1">
                <div
                  v-if="!isSell"
                  :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
                  class="w-1 h-4 rounded-sm"
                ></div>
                <div
                  v-else
                  :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
                  class="w-1 h-4 rounded-sm"
                ></div>
                <div
                  v-if="!isSell"
                  :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
                  class="w-1 h-4 rounded-sm"
                ></div>
                <div
                  v-else
                  :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
                  class="w-1 h-4 rounded-sm"
                ></div>
                <div
                  v-if="!isSell"
                  :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-secondary bg-secondary-dark'"
                  class="w-1 h-4 rounded-sm"
                ></div>
                <div
                  v-else
                  :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-200' : 'dark:bg-error-bright bg-error'"
                  class="w-1 h-4 rounded-sm"
                ></div>
              </div>

              <div class="flex flex-col">
                {{ priceMinDigits(roundToNSigFigs(Math.round(value * 1000000) / 1000000, 2), 2) }}
                
              </div>
            </span>
          </a>
        </div>
        
        <div class="tradeTraded flex items-center p-2">
          <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
            <span class="lg:flex hidden" v-if="!isArbitrage">
              <span v-html="bogintific(roundToNSigFigs(tokenAmount, 2), true, true)"></span>&nbsp;<span
                class="dark:text-gray-500 text-2xs text-gray-400"
                >{{ token }}</span
              >
            </span>

            <span class="flex lg:hidden" v-if="!isArbitrage">
              <span v-html="bogintific(roundToNSigFigs(tokenAmount, 2), true, true)"></span>&nbsp;@&nbsp;
              <span v-if="txType != 'Add Liq' && txType != 'Remove Liq'" v-html="bogintificPrice(value / tokenAmount, $store.state.useTruncatedBogintific)"></span>
              <span v-else v-html="bogintificPrice(value / tokenAmount / 2.0, $store.state.useTruncatedBogintific)"></span>
            </span>

            <span v-else></span>
          </a>
        </div>
        <div class="tradePrice hidden lg:flex items-center p-2">
          <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
            <span v-if="!isArbitrage && txType != 'Add Liq' && txType != 'Remove Liq'" v-html="bogintificPrice(value / tokenAmount, $store.state.useTruncatedBogintific)"></span>
            <span v-else-if="!isArbitrage" v-html="bogintificPrice(value / tokenAmount / 2.0, $store.state.useTruncatedBogintific)"></span>
            <span v-else> </span>
          </a>
        </div>
        
        <div class="tradeType lg:flex hidden items-center p-2">
          <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
            {{ txType }}
          </a>
        </div>
        
        <div class="tradeExchange flex items-center p-2">
          <span v-if="!isArbitrage">
            <a rel="noopener noreferrer" target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
              {{ pool }}
            </a>
          </span>
        </div>
        
        <div class="walletAddress dark:text-gray-300 text-gray-600 justify-end flex items-center p-2">
          <span v-if="walletAddress && walletAddress == $store.state.tokenDeployer">
            <span class="tooltip">

              <Tooltip
                v-if="$store.state.top100ByAddress[walletAddress]"
                placement="left"
                :tooltip="
                  'Deployer (holds ' +
                  Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) /
                    100 +
                  '% of supply)'
                "
              >
                <template v-slot:custom>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 mr-2 text-purple-bright fill-current"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M200 376l-49.23-16.41c-7.289-2.434-7.289-12.75 0-15.18L200 328l16.41-49.23c2.434-7.289 12.75-7.289 15.18 0L248 328l49.23 16.41c7.289 2.434 7.289 12.75 0 15.18L248 376L240 416H448l-86.38-201.6C355.4 200 354.8 183.8 359.8 168.9L416 0L228.4 107.3C204.8 120.8 185.1 141.4 175 166.4L64 416h144L200 376zM231.2 172.4L256 160l12.42-24.84c1.477-2.949 5.68-2.949 7.156 0L288 160l24.84 12.42c2.949 1.477 2.949 5.68 0 7.156L288 192l-12.42 24.84c-1.477 2.949-5.68 2.949-7.156 0L256 192L231.2 179.6C228.2 178.1 228.2 173.9 231.2 172.4zM496 448h-480C7.164 448 0 455.2 0 464C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-48C512 455.2 504.8 448 496 448z"
                    />
                  </svg>
                </template>
              </Tooltip>
              <Tooltip v-else placement="left" :tooltip="'Deployer'">
                <template v-slot:custom>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-4 h-4 mr-2 text-purple-bright fill-current"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M200 376l-49.23-16.41c-7.289-2.434-7.289-12.75 0-15.18L200 328l16.41-49.23c2.434-7.289 12.75-7.289 15.18 0L248 328l49.23 16.41c7.289 2.434 7.289 12.75 0 15.18L248 376L240 416H448l-86.38-201.6C355.4 200 354.8 183.8 359.8 168.9L416 0L228.4 107.3C204.8 120.8 185.1 141.4 175 166.4L64 416h144L200 376zM231.2 172.4L256 160l12.42-24.84c1.477-2.949 5.68-2.949 7.156 0L288 160l24.84 12.42c2.949 1.477 2.949 5.68 0 7.156L288 192l-12.42 24.84c-1.477 2.949-5.68 2.949-7.156 0L256 192L231.2 179.6C228.2 178.1 228.2 173.9 231.2 172.4zM496 448h-480C7.164 448 0 455.2 0 464C0 490.5 21.49 512 48 512h416c26.51 0 48-21.49 48-48C512 455.2 504.8 448 496 448z"
                    />
                  </svg>
                </template>
              </Tooltip>
            </span>
          </span>
          <span
            v-else-if="
              walletAddress &&
              $store.state.top100ByAddress[walletAddress] &&
              Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) / 100 >
                0.15
            "
          >
            <Tooltip
              placement="left"
              :tooltip="
                'Wallet holds ' +
                Math.round((100 * 100 * $store.state.top100ByAddress[walletAddress]) / $store.state.tokenCirculatingSupply) /
                  100 +
                '% of supply'
              "
            >
              <template v-slot:custom>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2 text-blue-bright fill-current" viewBox="0 0 640 512">
                  <path
                    d="M480 128c-91.09 0-176.7 35.47-241.1 99.87l-85.66 82.69c-6.312 6.094-14.62 9.437-23.41 9.437C111.2 320 96 304.8 96 286.2c0-31.56 12.28-61.2 34.58-83.52l9.811-9.811l56.75 13.61C207.6 208.6 218.4 205.3 226 197.7l57.28-57.28c9.016-9.016 4.332-24.48-8.221-26.96L191.3 96.7L174.5 12.9C172 .3524 156.6-4.333 147.6 4.682L90.28 61.96C82.68 69.56 79.41 80.43 81.53 90.86l13.61 56.74L49.13 193.6C17.44 225.3 0 267.4 0 312.3c0 92.5 75.25 167.7 167.8 167.7H544c52.94 0 96-43.06 96-95.1V288C640 199.8 568.2 128 480 128zM384 312c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24s24 10.74 24 24C408 301.3 397.3 312 384 312z"
                  />
                </svg>
              </template>
            </Tooltip>
          </span>
          <span v-if="walletAddress">
            <a
              rel="noopener noreferrer"
              target="_blank"
              :href="'https://' + $store.state.blockExplorer + '/token/' + $store.state.token + '?a=' + walletAddress"
            >
              <span class="flex place-content-start"
                ><img                  
                  class="inline-block mr-2 fade-opacity"
                  :src="'https://cdn.bogged.finance/blockies/'+walletAddress.substring(walletAddress.length - 4).toLowerCase()+'.png'"
                  :id="'blockie_' + walletAddress"
                />
                {{ walletAddress.substring(0, 6) + ".." + walletAddress.substring(walletAddress.length - 4) }}
              </span>
            </a>
          </span>
        </div>
        
      </div>
      
      
    </div>
  </div>
</template>
<script>
// import moment from "moment";
import { Tooltip } from "@boggedfinance/bogged-components";
import { maxDigits, priceMinDigits, bogintific, bogintificPrice, roundToNSigFigs, get16Colour } from "@/filters";
import { Services } from "@/services";


export default {
  props: [
    "token",
    "tokenAmount",
    "isSell",
    "value",
    "id",
    "pool",
    "txHash",
    "isArbitrage",
    "arbPools",
    "shortFromNow",
    "walletAddress",
    "txType",
    "blockieColour1",
    "blockieColour2",
  ],
  setup() {},
  data: function () {
    return {
      // fromNow: "",
      intervalID: null,
      cellStyle: "px-4 py-2 whitespace-nowrap text-sm font-medium",
    };
  },
  methods: {
    maxDigits,
    priceMinDigits,
    bogintific,
    bogintificPrice,
    roundToNSigFigs,
    onIntersection(entries, opts) {
      for (let row of entries) {
        //for (let [key,val] of Object.entries(row.target)) {
        //  console.log(key,val)
        //}
        //console.log(typeof(row.target))
        //console.log(row.target)
        let els = row.target.getElementsByTagName('div')
        let wallet,txHash
        for (let el of els) {
          if (el.id == 'txHash') {
            txHash = el.textContent
          } else if (el.id == 'walletAddress') {
            wallet = el.textContent
          }
          if (wallet != null && txHash != null) { break; }
        }        
        if (!wallet) {
          this.$store.state.txhashesMissingWallet.push(txHash)
        }
        //console.log(this.$store.state.txhashesMissingWallet)
        //console.log(this.$store.state.txhashesMissingWalletComputing)
        
        if (!this.$store.state.txhashesMissingWalletComputing) {          
          this.$store.state.txhashesMissingWalletComputing = true
          while (this.$store.state.txhashesMissingWallet.length > 0) {
            try {
              let txhash = this.$store.state.txhashesMissingWallet.pop()
              if (txhash in this.$store.state.transactionsByHash && this.$store.state.transactionsByHash[txhash].length > 0 && !this.$store.state.transactionsByHash[txhash][0].walletAddress) {                
                Services.getWalletFromTxhash(this.$store, txhash).then((res) => {                  
                  if (res) {
                    // txns without a wallet address will always be swaps
                    //console.log(txhash)
                    let walletAddress = res
                    let txns = this.$store.state.transactionsByHash[txhash]
                    for (let tx of txns) {
                        tx.walletAddress = walletAddress
                        tx.txType = 'Swap'
                    }
                  }
                })
              }
              //console.log(receipt)
              //console.log(txhash)
            } catch (e) {
              //console.log(e)
              break
            }
          }
          this.$store.state.txhashesMissingWalletComputing = false
        }
        
        

        
        //console.log(els)
        //console.log(els.textContent)
      }
      //console.log('onIntersection fired')
    },
  },
  components: {
    Tooltip,
  },
  setup(props) {
    const addLiquidity = props.txType == "Add Liq";
    const removeLiquidity = props.txType == "Remove Liq";
    return { addLiquidity, removeLiquidity };
  },
  mounted() {
    // define an observer instance
    
    var observer = new IntersectionObserver(this.onIntersection, {
      root: document.getElementById("row_"+this.id).parentElement.parentElement.parentElement.parentElement.parentElement,
      threshold: .01 // percentage of target's visible area. Triggers "onIntersection"
    })

    
    
    // Use the observer to observe an element
    //observer.observe( document.getElementById('blockie_'+this.walletAddress))
    observer.observe( document.getElementById('row_'+this.id))
  },
  async created() {
    // moment.relativeTimeThreshold('ss', 3);  // enable this if you want to show "5 seconds ago" etc
    // window.moment = moment;
    // let self = this; // to pass to setInterval
    // // set initial fromNow
    // let mo = moment(self.$props.date).fromNow();
    // self.$data.fromNow = mo;
    // // update it periodically
    // this.$data.intervalID = setInterval(function() {
    //   let mo = moment(self.$props.date).fromNow();
    //   self.$data.fromNow = mo;
    // }, 5000);
  },
};
</script>

<style scoped>
.fade-in-inner {
  animation: 0.4s ease 0s normal forwards 1 fadeIn;
}
@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  33% {
    opacity: 0.6;
  }
  66% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
}

.animateBuy {
  will-change: transform; /* To reduce CPU usage */
  animation: 0.3s ease 0s normal forwards 1 fadeIn;
}


.animateSell {
  will-change: transform; /* To reduce CPU usage */
  animation: 0.3s ease 0s normal forwards 1 fadeIn;
}


.animateArb {
  will-change: transform; /* To reduce CPU usage */
  animation: 0.3s ease 0s normal forwards 1 fadeIn;
}

</style>
