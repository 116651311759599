import { Services } from "@/services"
import {  
  quoteTokens,
  CHAIN_TOKENS,
  USD_TOKEN_NAMES,
  defaultFactories,
} from "./constants"

class _PricesLib {  
  async loadMasterTokenPrice(context) {
    try{ 
        if (context.state.masterToken.toLowerCase() == CHAIN_TOKENS[context.state.chain].toLowerCase()) {
            context.state.masterTokenPrice = await Services.getChainTokenSpotPrice(context);
            context.state.chainTokenPrice = context.state.masterTokenPrice                    
        }
        else {
            context.state.chainTokenPrice = await Services.getChainTokenSpotPrice(context)
            if (context.state.masterToken in quoteTokens[context.state.chain] && USD_TOKEN_NAMES.includes(quoteTokens[context.state.chain][context.state.masterToken].name)) {
                context.state.masterTokenPrice = 1.0
            } else {
                console.log('master token:',context.state.masterToken)
                context.state.masterTokenPrice = (await Services.getTokenSpotPrice(context, context.state.masterToken, context.state.web3s[context.state.chain], context.state.chain, defaultFactories[context.state.chain].slice(0,5), null)).usdPrice
                console.log('master token price:', context.state.masterTokenPrice)
            }
            
        }
        if (context.state.chain == 'bsc') {
            context.state.bnbPrice = context.state.chainTokenPrice                    
        } else {
            let price = await Services.getTokenSpotPrice(context, CHAIN_TOKENS['bsc'], context.state.web3s['bsc'], 'bsc', defaultFactories['bsc'], null)
            if (price.usdPrice != null) {
                context.state.bnbPrice = price.usdPrice;
            }
        }
        context.state.bogPrice = (await Services.getTokenSpotPrice(context, "0xB09FE1613fE03E7361319d2a43eDc17422f36B09", context.state.web3s['bsc'], 'bsc', defaultFactories['bsc'], null)).bnbPrice * context.state.bnbPrice
    } catch (e) {
        console.log(e)
        return 0;
    }
  }



  async loadTokenPrice(context) {
    try {       
        
        //If BNB
        if (context.state.token == context.state.chainToken) { 
            let price = await Services.getChainTokenSpotPrice(context);
            if (price != null) {
                context.state.tokenPrice = price
                context.state.autoUpdateArray.push(price);
            }
            context.state.tokenMasterPairPrice = 1;
        }
        else{
            if (context.state.token && context.state.currentExchange) {
                let thisToken = context.state.token
                var priceObj = await Services.getTokenSpotPrice(context, context.state.token, context.state.web3s[context.state.chain], context.state.chain, [context.state.currentExchange], [context.state.masterToken]);
                
                if (thisToken != context.state.token) { return } // token changed while we were fetching price
                if(context.state.pricePair == 'BNB') {
                    if (priceObj.bnbPrice != null) {
                        context.state.tokenPrice = priceObj.usdPrice;
                        context.state.tokenMasterPairPrice = priceObj.bnbPrice;
                        context.state.autoUpdateArray.push(priceObj.bnbPrice);
                    }
                } else {
                    if (priceObj.usdPrice != null) {
                        context.state.tokenPrice = priceObj.usdPrice;
                        context.state.tokenMasterPairPrice = priceObj.bnbPrice;
                        context.state.autoUpdateArray.push(priceObj.usdPrice);
                    }
                }
                
            }
        }
        

        context.state.tokenMarketCap = (context.state.tokenSupply - context.state.tokenBurnt) * context.state.tokenPrice
        context.state.tokenLiqBackingPct = context.state.tokenLiquidity / context.state.tokenMarketCap

        document.title = context.state.tokenSymbol + " $" + ((value)=>{
            let stringedValue = parseFloat(value).toFixed(20).toString();
            let truncated = "";
            let pastLeading = false;
            let counter = 0;

            for (let i = 0; i < stringedValue.length; i++) {
                truncated += stringedValue[i];

                if (!pastLeading) {
                    if (stringedValue[i] != '0' && stringedValue[i] != '.') {
                        pastLeading = true;
                    }
                } else {
                    counter++;
                    if (counter == 5) {
                        break;
                    }
                }
            }
            return truncated;})(context.state.tokenPrice) + " Price Chart  (" + context.state.tokenName + ") - BOGCharts";
            document.querySelector('meta[name="description"]')?.setAttribute("content","View accurate "+context.state.tokenName+" ("+context.state.tokenSymbol+") charts for free with BOGCharts. View $"+context.state.tokenSymbol+" Marketcap, Price and Liquidity information with Bogged.Finance.");                    
        
        } catch (e) {
            console.log(e)
            document.title = context.state.tokenSymbol + " $0 - Unlaunched token on BOGCharts";
            return 0;
        }
    }
  
}

export const PricesLib = new _PricesLib();
