<template>
  <Listbox as="div">
    <div class="relative z-0 inline-flex dark:shadow-sm rounded-md divide-x divide-secondary-dark">
      <div class="relative">
        <ListboxButton
          v-if="this.$store.state.network == '0x38'"
          class="cursor-pointer relative w-full bg-primary bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
        >
          <div class="flex items-center">
            <bsc-logo class="h-5 w-5" />
            <span class="ml-3 text-primary-dark dark:text-white">BSC</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-primary-dark dark:text-primary-light" aria-hidden="true" />
          </span>
        </ListboxButton>

        <ListboxButton
          v-if="this.$store.state.network == '0x89'"
          class="cursor-pointer relative w-full bg-purple-bright bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-purple-bright focus:border-purple-bright sm:text-sm"
        >
          <div class="flex items-center">
            <polygon-logo class="h-5 w-5" />

            <span class="ml-3 text-purple dark:text-white">Polygon</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-purple-light dark:text-white" aria-hidden="true" />
          </span>
        </ListboxButton>

        <!--
        <ListboxButton
          v-if="this.$store.state.network == '0xa86a'"
          class="cursor-pointer relative w-full bg-red-bright bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-red-bright focus:border-red-bright sm:text-sm"
        >
          <div class="flex items-center">
            <avax-logo class="h-5 w-5" />

            <span class="ml-3 text-red dark:text-white">Avalanche</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-red-light dark:text-white" aria-hidden="true" />
          </span>
        </ListboxButton>

        <ListboxButton
          v-if="this.$store.state.network == '0xfa'"
          class="cursor-pointer relative w-full bg-blue-bright bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-blue-bright focus:border-blue-bright sm:text-sm"
        >
          <div class="flex items-center">
            <ftm-logo class="h-5 w-5" />

            <span class="ml-3 text-blue dark:text-white">Fantom</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-blue-light dark:text-white" aria-hidden="true" />
          </span>
        </ListboxButton>
        -->

        <ListboxButton
          v-if="this.$store.state.network == '0x1'"
          class="cursor-pointer relative w-full bg-blue-bright bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-blue-bright focus:border-blue-bright sm:text-sm"
        >
          <div class="flex items-center">
            <eth-logo class="h-5 w-5" />
            <span class="ml-3 text-blue dark:text-white">Ethereum</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-blue dark:text-blue-light" aria-hidden="true" />
          </span>
        </ListboxButton>

        <ListboxButton
          v-if="this.$store.state.network == '0x34'"
          class="cursor-pointer relative w-full bg-blue-bright bg-opacity-20 hover:bg-opacity-30 rounded-md dark:shadow-sm pl-3 pr-8 py-2 text-left focus:outline-none focus:ring-1 focus:ring-blue-bright focus:border-blue-bright sm:text-sm"
        >
          <div class="flex items-center">
            <csc-logo class="h-5 w-5" />
            <span class="ml-3 text-blue dark:text-white">CSC</span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon class="h-5 w-5 text-blue dark:text-blue-light" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="left-0 absolute mt-2 w-40 rounded-md shadow-lg bg-gray-800 ring-1 ring-gray-700 ring-opacity-100 focus:outline-none">
            <ListboxOption as="template">
              <li class="cursor-pointer select-none relative text-sm">
                <div class="flex flex-col">
                  <div class="flex-col flex justify-between">
                    <div @click="$store.dispatch('switchChain', {chain:'bsc', token_to_load:null})" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      BSC
                    </div>
                    <!--
                    <div @click="$store.dispatch('switchChain', {chain:'avax', token_to_load:null})" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      Avalanche
                    </div>
                    <div @click="$store.dispatch('switchChain', {chain:'ftm', token_to_load:null})" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      Fantom
                    </div>
                    -->
                    <div @click="$store.dispatch('switchChain', {chain:'polygon', token_to_load:null})" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      Polygon
                    </div>
                    <div @click="$store.dispatch('switchChain', {chain:'csc', token_to_load:null})" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      CSC
                    </div>
                    <!--
                    <div @click="$store.dispatch('switchChain', 'eth')" class="block cursor-pointer py-4 px-4 text-sm text-gray-100 hover:bg-gray-700">
                      Ethereum
                    </div>
                    -->
                  </div>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { SelectorIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import ethLogo from "@/assets/networks/ethLogo.vue";
import bscLogo from "@/assets/networks/bscLogo.vue";
import polygonLogo from "@/assets/networks/polygonLogo.vue";
import cscLogo from "@/assets/networks/cscLogo.vue";
import ftmLogo from "@/assets/networks/ftmLogo.vue";
import avaxLogo from "@/assets/networks/avaxLogo.vue";

export default {
  data() {
    return {
      networkMode: this.$store.state.network,
    };
  },
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    SelectorIcon,
    ethLogo,
    bscLogo,
    polygonLogo,
    cscLogo,
    avaxLogo,
    ftmLogo,
  },
  methods: {
    toggleSwap: function() {
      this.$store.commit("toggleSwap");
    },
  },
  computed: {
    swapIsOpen: function() {
      return this.$store.getters.swapOpen;
    },
  },
};
</script>
