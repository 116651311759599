<template>
  <tr class="my-4">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  props: [],
};
</script>
