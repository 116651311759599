<template>
  <price-alert-overlay />
  <div :class="isMobile ? 'pb-14' : 'pb-0'" class="flex-1 min-w-0 flex flex-row">
    <div :class="this.$store.state.enableFullWidth ? '' : 'container'" class="mx-auto w-full min-w-0">
      <div class="flex w-full place-content-center lg:mb-1">
       <a href="https://octavia.one/?ref=bogged" target="_blank">
        <img
          class="lg:block hidden"
          data-aa="1701147"
          src="/octavia/salelive.jpg" 
          style="width: 970px; height: 90px; border: 0px; padding: 0; overflow: hidden"
          allowtransparency="true"
        />
        <img
          class="lg:hidden"
          data-aa="1701147"
          src="/octavia/salelivemob.png" 
          scrolling="no"
          style="width: 100%; max-height: 60px; border: 0px; padding: 0; overflow: hidden"
          allowtransparency="true"
        /></a>
      </div>
      <div id="chartWrapper" class="flex flex-col w-full space-y-4 p-2 md:p-4 pt-0 md:pt-0 overflow-hidden">
        <div class="flex flex-col space-y-2 md:space-y-3">
          <notification />
          <marquee :key="updateMarquee" />
          <topbar />
          <actions-bar />
        </div>
        <div :class="tabMode === 0 || !isMobile ? '' : 'opacity-0 hidden'" class="flex relative" id="bogChart">
          <div
            v-if="$store.state.chartLoading"
            style="backdrop-filter: blur(4px)"
            class="top-0 absolute flex items-center place-content-center flex-col z-20 w-full left-0 h-full bg-white dark:bg-gray-900"
          >
            <mini-spinner></mini-spinner>
            <div class="text-center md:max-w-md sm:max-w-sm max-w-50">
              <span
                v-if="!$store.state.currentExchange && $store.state.loadLargestExchangeContractTries > 1"
                class="text-xs mt-10 block text-gray-600"
                >Waiting for liquidity...</span
              >
              <span v-else class="text-xs mt-10 block text-gray-600">{{ text }}</span>
            </div>
          </div>
          <div id="tv_chart_container" style="flex: 1 1 0%"></div>
        </div>

        <bog-chart :key="updateChart" />
        <toggles :class="tabMode === 0 || !isMobile ? '' : 'opacity-0 hidden'" class="lg:hidden block" />
        <div
          :class="tabMode === 1 || !isMobile ? '' : 'opacity-0 hidden'"
          id="chartBottom"
          class="flex overflow-hidden h-full lg:flex-row flex-col space-y-4 lg:space-y-0"
        >
          <div
            class="h-full w-full rounded-lg bg-gray-50 border-gray-200 dark:bg-gray-800 border dark:border-gray-700 overflow-hidden"
          >
            <token-tabs></token-tabs>
          </div>
        </div>

        <div v-if="tabMode === 2 && isMobile" :class="tabMode === 2 && isMobile ? '' : 'opacity-0 hidden'" class="fade-in">
          <div class="w-full h-full rounded-lg">
            <div class="rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden h-195 bg-white dark:bg-gray-800">
              <Swap />
            </div>
          </div>
        </div>

        <div v-if="isMobile" class="fixed left-0 bottom-0 z-20 w-full bg-gray-800 border-t border-b border-gray-700">
          <div class="flex flex-row h-full">
            <div
              :class="[tabMode === 0 ? 'text-success-bright' : 'text-gray-500']"
              @click="tabMode = 0"
              class="py-6 cursor-pointer px-3 w-1/2 h-full items-center place-content-center flex flex-row"
            >
              <transition name="fade">
                <svg
                  class="w-6 h-6 flex-shrink-0 fill-current mr-2 absolute"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                >
                  <path
                    d="M 19.5 4 C 18.119 4 17 5.119 17 6.5 L 17 17.5 C 17 18.881 18.119 20 19.5 20 C 20.881 20 22 18.881 22 17.5 L 22 6.5 C 22 5.119 20.881 4 19.5 4 z M 12.5 8 C 11.119 8 10 9.119 10 10.5 L 10 17.5 C 10 18.881 11.119 20 12.5 20 C 13.881 20 15 18.881 15 17.5 L 15 10.5 C 15 9.119 13.881 8 12.5 8 z M 5.5 12 C 4.119 12 3 13.119 3 14.5 L 3 17.5 C 3 18.881 4.119 20 5.5 20 C 6.881 20 8 18.881 8 17.5 L 8 14.5 C 8 13.119 6.881 12 5.5 12 z"
                  />
                </svg>
              </transition>
            </div>

            <div
              :class="[tabMode === 1 ? 'text-success-bright' : 'text-gray-500']"
              @click="tabMode = 1"
              class="py-6 cursor-pointer px-3 w-1/2 h-full items-center place-content-center flex flex-row text-secondary"
            >
              <transition name="fade">
                <svg
                  class="w-6 h-6 flex-shrink-0 fill-current mr-2 absolute"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                >
                  <path
                    d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 7.0097656 21 C 7.5567656 21 8 20.556766 8 20.009766 L 8 19.990234 C 8 19.443234 7.5567656 19 7.0097656 19 L 5 19 L 5 8 L 19 8 L 19 13.392578 C 19 13.832578 19.286891 14.232797 19.712891 14.341797 C 19.719891 14.343797 19.725422 14.346656 19.732422 14.347656 C 20.371422 14.512656 21 14.058437 21 13.398438 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 7.5 11 C 7.224 11 7 11.224 7 11.5 L 7 12.5 C 7 12.776 7.224 13 7.5 13 L 8.5 13 C 8.776 13 9 12.776 9 12.5 L 9 11.5 C 9 11.224 8.776 11 8.5 11 L 7.5 11 z M 11.5 11 C 11.224 11 11 11.224 11 11.5 L 11 12.5 C 11 12.776 11.224 13 11.5 13 L 16.5 13 C 16.776 13 17 12.776 17 12.5 L 17 11.5 C 17 11.224 16.776 11 16.5 11 L 11.5 11 z M 7.5 15 C 7.224 15 7 15.224 7 15.5 L 7 16.5 C 7 16.776 7.224 17 7.5 17 L 8.5 17 C 8.776 17 9 16.776 9 16.5 L 9 15.5 C 9 15.224 8.776 15 8.5 15 L 7.5 15 z M 17 16 C 12.854 16 10.782094 18.565891 10.121094 19.587891 C 9.9590937 19.838891 9.9590937 20.161109 10.121094 20.412109 C 10.782094 21.434109 12.854 24 17 24 C 21.146 24 23.217906 21.434109 23.878906 20.412109 C 24.040906 20.161109 24.040906 19.838891 23.878906 19.587891 C 23.217906 18.565891 21.146 16 17 16 z M 17 18 C 18.104 18 19 18.896 19 20 C 19 21.104 18.104 22 17 22 C 15.896 22 15 21.104 15 20 C 15 18.896 15.896 18 17 18 z"
                  />
                </svg>
              </transition>
            </div>

            <div
              :class="[tabMode === 2 ? 'text-success-bright' : 'text-gray-500']"
              @click="tabMode = 2"
              class="py-6 cursor-pointer px-3 w-1/2 h-full items-center place-content-center flex flex-row text-secondary"
            >
              <transition name="fade">
                <svg
                  class="w-6 h-6 flex-shrink-0 fill-current mr-2 absolute"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                >
                  <path
                    d="M 8.9824219 2 C 8.4404219 2 8 2.4404219 8 2.9824219 L 8 7.3730469 C 8 7.9270469 8.6685469 8.2045 9.0605469 7.8125 L 10.730469 6.1445312 L 19.292969 14.707031 A 1.0001 1.0001 0 1 0 20.707031 13.292969 L 12.144531 4.7304688 L 13.818359 3.0546875 C 14.207359 2.6656875 13.931859 2 13.380859 2 L 8.9824219 2 z M 3.9902344 8.9902344 A 1.0001 1.0001 0 0 0 3.2929688 10.707031 L 11.855469 19.269531 L 10.1875 20.939453 C 9.7965 21.331453 10.072953 22 10.626953 22 L 15.017578 22 C 15.559578 22 16 21.559578 16 21.017578 L 16 16.619141 C 16 16.068141 15.333359 15.792641 14.943359 16.181641 L 13.269531 17.855469 L 4.7070312 9.2929688 A 1.0001 1.0001 0 0 0 3.9902344 8.9902344 z"
                  />
                </svg>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="max-w-sm 2xl:max-w-md w-full ml-0 mt-0 m-4"
      v-if="!isMobile && swapIsOpen"
      :class="swapIsOpen ? 'fade-in' : 'absolute opacity-0 left-0'"
    >
      <div class="flex flex-col h-full space-y-4">
        <div class="rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden h-195 bg-white dark:bg-gray-800">
          <Swap />
        </div>
        <div class="coinzilla" data-zone="C-129621c9e27780bf107"></div>
        <!--
        <div class="p-6 relative rounded-lg overflow-hidden">
          
          
          <div class="bg-gradient-to-tl opacity-20 from-purple to-blue-bright w-full h-full absolute inset-0"></div>
          <div class="relative">
            
            
            <h5 class="text-2xl pb-6 font-semibold text-gray-700 dark:text-white">Learn more about our tools on BogAcademy</h5>
            <a
              class="button inline-block rounded-md py-3 px-4 transition-all duration-200 dark:text-purple-light dark:hover:bg-opacity-30 dark:hover:text-purple-bright bg-purple-light text-white hover:bg-opacity-60 bg-opacity-80 dark:bg-opacity-20"
              target="_blank"
              href="https://www.bogged.finance/academy"
              >Learn More</a
            

          </div>
          
        </div>        
        >-->
      </div>
    </div>
  </div>
</template>

<script>
import miniSpinner from "@/components/miniSpinner.vue";
import mobileChart from "./mobileChart";
import Notification from "./Notification";
import Swap from "@/components/Swap/swapForm.vue";
import Topbar from "./Topbar/Topbar";
import Toggles from "./actionsBar/Toggles.vue";
import bogChart from "./bogChart";
import tokenTabs from "./Tabs/tokenTabs";
import Marquee from "@/components/Marquee.vue";
import actionsBar from "./actionsBar/actionsBar.vue";
import swapOverlay from "@/components/Swap/swapOverlay";
import priceAlertOverlay from "./priceAlert/priceAlertOverlay.vue";
import { XIcon } from "@heroicons/vue/outline";
import { ref } from "vue";

export default {
  components: {
    XIcon,
    mobileChart,
    Swap,
    Topbar,
    bogChart,
    tokenTabs,
    Marquee,
    actionsBar,
    swapOverlay,
    priceAlertOverlay,
    Toggles,
    Notification,
    miniSpinner,
  },
  data() {
    return {

      randomImageUrl1: '',
      randomImageUrl2: '',
      windowWidth: window.innerWidth,
      updateChart: 0,
      updateMarquee: 0,
      tabMode: 0,
      coinzillaElement: null,
      randomText: [
        "Like the price action you see? Remember to use BOG's limit orders to buy/sell!",
        "Think before you act, never share your private key with anyone.",
        "Buy $BOG to Unlock Premium Features.",
        "Anyone can write a roadmap, only competent teams can deliver.",
        "Did you know that BOGSwap automatically gets you the best price by routing your trade through multiple DEX's?",
        "Did you know that tokens with low liquidity are more volatile & likely to dump than tokens with higher liquidity?",
        "Bogged.Finance exists to give you the best trading data to help you make better trading decisions.",
        "Always research tokens before purchasing them, FOMO is the easiest way to lose money.",
        "Remember you can stake both BOG LP and Solo Stake your BOG tokens for rewards!",
        "If you hold more than $1000 in crypto, buy a hardware wallet. It's worth it.",
        "“The market can stay irrational longer than you can stay solvent”\n— John Maynard Keynes",
        "“Be fearful when others are greedy, and greedy when others are fearful.”\n— Warren Buffet",
        "“An investment in knowledge pays the best interest.”\n— Benjamin Franklin",
        "“In investing, what is comfortable is rarely profitable.”\n— Robert Arnott",
        "“How many millionaires do you know who have become wealthy by investing in savings accounts? I rest my case.”\n— Robert G. Allen",
        "“The biggest risk of all is not taking one.”\n— Mellody Hobson",
        "“Know what you own, and know why you own it.”\n— Peter Lynch",
        "“Financial peace isn't the acquisition of stuff. It's learning to live on less than you make, so you can give money back and have money to invest.” — Dave Ramsey",
        "“Learn from the experiences of others, it's cheaper”\n— John Bogle",
        "“When everything is going against you, remember, the airplane takes off against the wind, not with it.”\n— Henry Ford",
        "“Set your course by the stars, not by the lights of every passing ship”\n— Omar Bradley",
        "“Wealth consists not in having great possessions, but in having few wants.”\n— Epictetus",
        "“Money, if it does not bring you happiness, will at least help you be miserable in comfort.”\n— Helen Gurley Brown",
        "“The rich rule over the poor, and the borrower is servant to the lender.”\n— Proverbs ",
        "“You must spend money to make money.”\n— Plautus",
        "“A wise person should have money in their head, but not in their heart.”\n— Jonathan Swift",
        "“Money [..] is a fiction created by the state. Money becomes paper and everybody convinces everybody else that the little scraps are worth something because the State says so.”\n— Carl Jung",
        "“The only strategy that is guaranteed to fail is not taking risks.”\n— Mark Zuckerberg",
        "“If you can't hold, you won't be rich.”\n— CZ",
        "“Not Financial Advice.”\n— CZ",
        "“FUD. Pain for some, an opportunity for others.”\n— CZ",
        "“Paper money is going away.”\n— Elon Musk",
        "“The most important quality for an investor is temperament, not intellect.”\n— Warren Buffett",
        "“Derivatives are financial weapons of mass destruction.”\n— Warren Buffett",
        "“If I’d only followed CNBC’s advice, I’d have a million dollars today. Provided I’d started with a hundred million dollars.”\n— Jon Stewart",
        "“Unless you can watch your stock holding decline by 50% without becoming panic stricken, you should not be in the stock market.”\n— Warren Buffett",
        "“More money has been lost trying to anticipate and protect from corrections than actually in them.”\n— Peter Lynch",
        "“Tell me where I’m going to die so that I won’t go there.”\n— Сharlie Munger",
        "“If you don’t stay with your winners, you are not going to be able to pay for the losers.”\n— Jack D. Schwager",
        "“Everyone has the brainpower to make money [..] Not everyone has the stomach.”\n— Peter Lynch",
        "“It's how you deal with failure that determines how you achieve success.”\n— David Feherty",
        "“Annual income £20, annual expenditure £19.60, result happiness. Annual income £20, annual expenditure £20.6, result misery.”\n— Charles Dickens",
        "“A nickel ain't worth a dime anymore.”\n— Yogi Berra",
        "“Formal education will make you a living; self-education will make you a fortune.”\n— Jim Rohn",
        "“Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.”\n— Ayn Rand",
        "“He who loses money, loses much; He who loses a friend, loses much more; He who loses faith, loses all.”\n— Eleanor Roosevelt",
        "“Buy when everyone else is selling and hold until everyone else is buying. That’s not just a catchy slogan. It’s the very essence of successful investing.”\n— J. Paul Getty",
        "“I made my money the old-fashioned way. I was very nice to a wealthy relative right before he died.”\n— Malcolm Forbes",
        "“Money is a terrible master but an excellent servant.”\n— P.T. Barnum",
        "“Never spend your money before you have it.”\n— Thomas Jefferson",
        "“No wealth can ever make a bad man at peace with himself.”\n— Plato",
        "“If you chase two rabbits, you will lose them both.”\n— Native American saying",
        "“If you speak the truth, have a foot in the stirrup.”\n— Turkish Proverb",
        "“The man who moves a mountain begins by carrying away small stones.”\n— Confucius",
        "“You can't direct the wind, but you can adjust your sails.”\n— Unknown",
        "“Everything is worth what its purchaser will pay for it.”\n— Publius Syrius",
        "“Banking establishments are more dangerous than standing armies.”\n— Thomas Jefferson",
        "“Compound interest is the most powerful force in the universe.”\n— Albert Einstein",
        "“There is no wealth like knowledge, no poverty like ignorance.”\n— Ali ibn Abi-Talib",
        "“Happiness: a good bank account, a good cook and a good digestion.”\n— Jean-Jacques Rousseau",
      ],
    };
  },
  computed: {
    text: function () {
      return this.randomText[Math.trunc(Math.random() * 57)]; //57 = number of quotes
    },
    isMobile() {
      /*
      if (this.$store.state.swapIsOpen) {
        if (this.windowWidth <= 382) {
          return 1
        } else {
          return this.windowWidth - 382 <= 650;
        }        
      } else {
        return this.windowWidth <= 650;
      }*/
      return this.windowWidth <= 1023;
    },
    swapIsOpen: function () {
      return this.$store.getters.swapOpen;
    },
  },
  created() {


  },
  mounted() {
    if (this.$store.getters.swapOpen) {
      this.loadCoinzillaJs();
    }

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (
        //(this.$store.state.screenWidth >= 1024 && this.windowWidth < 1024) ||
        //(this.$store.state.screenWidth < 1024 && this.windowWidth >= 1024) ||
        (this.$store.state.screenWidth >= 768 && this.windowWidth < 768) ||
        (this.$store.state.screenWidth < 768 && this.windowWidth >= 768)
      ) {
        this.$store.state.resized = true
        this.$store.state.forceDisplay = false // fix for chart being empty after resizing
        console.log("____ resize fired ___");
      }

      this.$store.state.screenWidth = this.windowWidth;
    });
  },
  watch: {
    "$store.state.theme.theme": function () {
      this.updateChart += 1;
    },
    "$store.state.chain": function () {
      this.updateMarquee += 1;
    },
    "$store.getters.swapOpen": function () {
      if (this.$store.getters.swapOpen) {
        this.loadCoinzillaJs();
      } else {
        this.unloadCoinzillaJs();
      }
    },
  },
  methods: {

    loadCoinzillaJs: function () {
      console.log("!!>>>>>> loading coinzilla js");
      this.coinzillaElement = document.createElement("script");
      this.coinzillaElement.setAttribute("src", "https://coinzillatag.com/lib/display.js");
      this.coinzillaElement.async = true;
      document.head.appendChild(this.coinzillaElement);

      window.coinzilla_display = window.coinzilla_display || [];
      var c_display_preferences = {};
      c_display_preferences.zone = "129621c9e27780bf107";
      c_display_preferences.width = "320";
      c_display_preferences.height = "100";
      coinzilla_display.push(c_display_preferences);
    },
    unloadCoinzillaJs: function () {
      if (this.coinzillaElement) {
        this.coinzillaElement.remove();
        this.coinzillaElement = null;
      }
    },
    toggleSwap: function () {
      this.$store.commit("toggleSwap");
    },
    saveChartLayout: function () {
      this.$store.state.triggerChartSave = true;
    },
    loadChartLayout: function () {
      this.$store.state.triggerChartLoad = true;
    },
    resetChartLayout: function () {
      this.$store.state.triggerChartReset = true;
    },
  },
};
</script>
