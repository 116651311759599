<template>
  <div class="container mx-auto my-8 px-4">
    <div class="flex flex justify-between">
      <div>
        <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">
          Wallet Tracker
        </h1>                
      </div>		
    </div>
	 
	
  </div>    
  <br>
  <div class="flex">
    <div style="flex: 0 0 70%;" class="text-white">
      <div v-if="results.length">      
        <div v-bind:key="item" v-for="item in results">
          <div class="flex" v-if="item.t0_lp_change > 0">
            <div class="p-2">{{ timeAgo(item.timestamp*1000) }}</div>
            <div class="p-2">Swap&nbsp;&nbsp;</div>
            <div class="p-2">{{ Math.abs(round2(item.t0_lp_change)) }}</div>
            <div class="p-2">{{ item.t0_symbol.symbol }}</div>
            <div class="p-2">=></div>
            <div class="p-2">{{ Math.abs(round2(item.t1_lp_change)) }}</div>
            <div class="p-2">{{ item.t1_symbol.symbol }}</div>
            <div class="p-2">${{ item.usd_value_t0 }}</div>
          </div>
          <div class="flex" v-else>
            <div class="p-2">{{ timeAgo(item.timestamp*1000) }}</div>
            <div class="p-2">Swap&nbsp;&nbsp;</div>
            <div class="p-2">{{ Math.abs(round2(item.t1_lp_change)) }}</div>
            <div class="p-2">{{ item.t1_symbol.symbol }}</div>
            <div class="p-2">=></div>
            <div class="p-2">{{ Math.abs(round2(item.t0_lp_change)) }}</div>
            <div class="p-2">{{ item.t0_symbol.symbol }}</div>
            <div class="p-2">${{ round2(item.usd_value_t1) }}</div>
          </div>
        </div>
      </div>		
      <div v-else>
        No results
      </div>
    </div>
    <div style="min-width:400px">
      <input id="wallet_input" 
        v-on:keyup.enter="capturePress($event)"     
        onfocus="this.select();"        
        type="text"
        placeholder="Enter a wallet address"
        @input="walletChanged"
        
        class="text-black"
           
        
        autocomplete="off"
      /> <button class="text-white" type="button" @click="addWallet()">Track</button>
      <div id="wallets_fieldset" class="radio-toolbar">
        <div v-for="w in wallets" v-bind:key="w">
          <div v-if="w == wallet">
            <input checked @click="setWallet" class="text-white" name="wallet_radio" :value="w" :id="w" type="radio" /><label :for="w" class="text-white">{{w}}</label>
          </div>
          <div v-else>
            <input @click="setWallet" class="text-white" name="wallet_radio" :value="w" :id="w" type="radio" /><label :for="w" class="text-white">{{w}}</label>
          </div>
        </div>
      </div>

    </div>
	</div>
</template>

<style>
  .radio-toolbar {
    margin: 3px;
  }

  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .radio-toolbar label {
      display: inline-block;
      background-color: rgb(14, 14, 31);
      padding: 3px 5px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid #444;
      border-radius: 4px;
  }

  .radio-toolbar label:hover {
    background-color: rgb(42, 58, 128);
  }

  .radio-toolbar input[type="radio"]:focus + label {
      /*border: 2px dashed #444;*/
  }

  .radio-toolbar input[type="radio"]:checked + label {
      background-color: rgb(168, 18, 88);
      border-color: rgb(16, 17, 36);
  }
</style>

<script>
import { Services } from "@/services";
import { CHAIN_MAP } from "@/constants";



export default {
	data() {
    return {      
      results: [],      
      wallet: null,
      wallets: [],
      walletSelectedId: 0
    };
  },
  components: {
    //Services
  },
	mounted() {
    this.chain = this.$store.state.chain
    
    let trackedWallets = localStorage.getItem('trackedWallets')
    if (!trackedWallets) {
      localStorage.setItem("trackedWallets", JSON.stringify({bsc: [], polygon: [], avax: [], ftm: [], csc: []}))
      trackedWallets = {bsc: [], polygon: [], avax: [], ftm: [], csc: []}
    } else {
      trackedWallets = JSON.parse(trackedWallets)
    }
    this.trackedWallets = trackedWallets
    this.wallets = this.trackedWallets[this.chain]

    this.$store.state.walletTrackingPage=true    
    //this.getWalletTxns()
  },

  methods: {
    round2(n) {
      return Math.round(n*100)/100
    },
    addWallet(wallet) {
      if (!wallet) {
        wallet = document.getElementById('wallet_input').value
      }
      document.getElementById('wallet_input').value = ''
      if (!wallet) {
        console.log('!! empty wallet field')
        return
      }
      if (!this.isAddress(wallet)) {
        console.log('invalid address',wallet)
        alert('Invalid address')
        return
      }
      if (!(this.wallets.includes(wallet))) {
        wallet = this.$store.state.web3.utils.toChecksumAddress(wallet)
        let el = document.getElementById('wallets_fieldset')
        let html = ''
        this.wallets.unshift(wallet)
        this.trackedWallets[this.chain] = this.wallets
        localStorage.setItem("trackedWallets", JSON.stringify(this.trackedWallets))
        /*
        for (let w of this.wallets) {
          if (w == wallet) {
            html += '<input @click="setWallet" class="text-white" checked name="wallet_radio" value="'+w+'" id="'+w+'" type="radio" /><label for="'+w+'" class="text-white">'+w+'</label>'
          } else {
            html += '<input @click="setWallet" class="text-white" name="wallet_radio" value="'+w+'" id="'+w+'" type="radio" /><label for="'+w+'" class="text-white">'+w+'</label>'
          }
        }
        el.innerHTML = html
        */
        clearTimeout(this.timeout_id)
        this.getWalletTxns(wallet)
      }
    },
    walletChanged(e) {    
      this.wallet = e.target.value;
    },
    setWallet(event) {
      this.wallet = event.target.value
      event.target.checked=true
      console.log(event.target.checked)
      clearTimeout(this.timeout_id)
      this.getWalletTxns(this.wallet)
    },
    capturePress: function(event) {
      console.log(event)
      if (this.wallet) {
        
        this.results = []        
        this.addWallet(this.wallet)
        
      }
      

    },
    timeAgo(date) {
      return Services.timeAgo(date)
    },
    loadToken() {

    },
    isAddress(wallet) {
      try {
        let test = this.$store.state.web3.utils.toChecksumAddress(wallet)
        if (test.length == 42) {
          return true
        } else {
          console.log(length,'test.length')
        }
      } catch (e) {
        console.log(e)
      }
      console.log('invalid address',wallet)
      return false
    },
    getWalletTxns(wallet) {
      if (wallet && this.isAddress(wallet)) {
        //let url = 'http://15.235.43.32:6969/wallet_txns?api_key=aa3bA52CDOEMDsosVLAEsw&chain='+CHAIN_MAP[this.chain]+'&wallet='+wallet

        fetch(url).then((res) => {                  
          if (res) {
            res.json().then((data) => {
              console.log(data)
              this.results = data
              this.timeout_id = setTimeout(() => {
                this.getWalletTxns(wallet);
              }, 30*1000);
            })
            
          }
        })
      }
      /*
      } else {
        //console.log('waiting for honeypot vars...')
        setTimeout(() => {
          this.message = "Waiting for top100 & deployer info..."
          this.waitForHoneypotVars(this.$store.state.token);
        }, 200);
      }
      */
    }
  },
  watch: {
	  "$store.state.chain": function() {
      this.chain = this.$store.state.chain
    },
  },
};
</script>
