<template>
  <button
    v-tippy="{
      content: 'Add to MetaMask',
      appendTo: 'parent',
      theme: 'bog',
      animation: 'shift-toward-subtle',
      arrow: false,
      maxWidth: 'none',
    }"
    v-wave
    class="focus:outline-none border hover:bg-gray-200 border-gray-400 dark:border-gray-700 dark:bg-gray-800 dark:bg-opacity-0 dark:hover:bg-opacity-90 p-1 text-gray-400 hover:text-gray-900 dark:hover:text-success-bright flex flex-row items-center transition-colors duration-300 rounded-md cursor-pointer"
    @click="addmm"
  >
    <meta-mask-icon />
    <span class="pl-1">+</span>
  </button>
</template>
<script>
import metaMaskIcon from "./metaMaskIcon.vue";

export default {
  methods: {
    addmm: function() {
      this.$store.dispatch("addtomm");
    },
  },

  components: {
    metaMaskIcon,
  },
};
</script>

<style></style>
