<script>
import { widget } from "@/charting_library";
import { Services } from "@/services";
import { NEW_BOG_TOKEN_ADDR, CHAIN_TOKENS, quoteTokens } from "@/constants";

export default {
  data() {
    return {
      token: this.$store.state.token,
      waitingForHoneypot: false,

      //skip: true,
      tvWidget: null,
    };
  },
  mounted() {
    if (this.$store.state.chain == "bsc") {
      this.waitingForHoneypot = true;
      setTimeout(() => {
        this.waitForHoneypotVars(this.$store.state.token);
      }, 200);
    }
    
  },
  created() {
    console.log("bogChart vue created fired");
    if (!this.tvWidget && this.$store.state.dataFeed) {
      console.log('llll datafeed exists')
      this.makeChart()
      return

    }
    if (this.$store.state.chartSkip) {
      console.log("chart skip");
      this.$store.state.chartSkip = false;
      return;
    }
    if (!this.tvWidget && this.$store.state.resized) {
      console.log("bogChart vue reloading chart (resized)");
      console.log(this.$store.state.token);
      //this.tvWidget = null
      //this.dataFeed = null
      this.$store.commit("reloadChart");
      return;
      //this.makeChart();
    }
    if (!this.tvWidget) {
      console.log("bogChart vue reloading chart");
      this.$store.commit("reloadChart");
      //this.makeChart();
      return;
    }
  },
  methods: {
    makeChart() {
      console.log('make chart')
      setTimeout(() => {
        var disabled = ["header_symbol_search", "header_compare", "symbol_info", "display_market_status", "save_chart_properties_to_local_storage"];

        if (this.$store.state.singleBlock) {
          disabled = ["header_symbol_search", "header_resolutions", "header_compare", "symbol_info", "display_market_status", "save_chart_properties_to_local_storage"];
        }

        var curTheme = this.$store.state.theme.theme;
        var embed = this.$store.state.embed;

        if (this.$store.state.theme.theme == "dark") {
          var overrides = {
            "paneProperties.backgroundType": "solid",
            "paneProperties.background": "#101722",
            "paneProperties.vertGridProperties.color": "#1E2735",
            "paneProperties.horzGridProperties.color": "#1E2735",
            "scalesProperties.textColor": "#D3F2DA",
            "scalesProperties.lineColor": "#313D50",
            "scalesProperties.backgroundColor": "#313D50",
            "scalesProperties.showCurrency": "true",
            "timeScale.rightOffset": 5,
            //"mainSeriesProperties.style": 8, // heikin-ashii
          };
        } else {
          var overrides = {
            "paneProperties.backgroundType": "solid",
            "paneProperties.background": "#ffffff",
            "paneProperties.vertGridProperties.color": "#E8EEF6",
            "paneProperties.horzGridProperties.color": "#E8EEF6",
            "scalesProperties.textColor": "#7F8FA9",
            "scalesProperties.lineColor": "#F3F6FB",
            "scalesProperties.backgroundColor": "#F3F6FB",
            "scalesProperties.showCurrency": "true",
            "timeScale.rightOffset": 5,
            //"mainSeriesProperties.style": 8, // heikin-ashii
          };
        }

        console.log("Creating tv widget");
        this.$store.state.chartLoading = true;

        if (this.tvWidget !== null) {
          this.tvWidget.remove();
          this.tvWidget = null;
        }

        let saved_data = null;
        if (this.$store.state.chartLayouts.length > 0 && this.$store.state.chartLayouts[0]) {
          saved_data = this.$store.state.chartLayouts[0];
          if (saved_data) {
            /*
            if (this.$store.state.interval == '1D') {
              //saved_data.charts[0].timeScale.m_barSpacing = 40
              //saved_data.charts[0].timeScale.m_barSpacing = 15
              saved_data.charts[0].timeScale.m_barSpacing = 4
            } else {
              saved_data.charts[0].timeScale.m_barSpacing = 4
            }
            */
            if (window.innerWidth < 768) {
              saved_data.charts[0].timeScale.m_barSpacing = 1
            } else {
              saved_data.charts[0].timeScale.m_barSpacing = 4
            }
          }
        }
        let tvWidget;
        if (window.innerWidth < 768) {
          tvWidget = new widget({
            symbol: this.$store.state.token, // default symbol
            interval: this.$store.state.interval, // default interval of 15 for mobile
            fullscreen: false, // displays the chart in the fullscreen mode
            autosize: true,
            disabled_features: ["header_compare", "symbol_info", "display_market_status", "edit_buttons_in_legend", "context_menus", "control_bar", "border_around_the_chart", "save_chart_properties_to_local_storage", "vert_touch_drag_scroll"],
            container: "tv_chart_container",
            datafeed: this.dataFeed,
            library_path: "/charting_library/",
            custom_css_url: "/charting_library/chart.css",
            theme: curTheme == "dark" || embed == "1" ? "Dark" : "Light",
            locale: "en",
            loading_screen: {
              backgroundColor: curTheme == "dark" || embed == "1" ? "#101722" : "#ffffff",
            },
            enabled_features: ["hide_left_toolbar_by_default", "use_localstorage_for_settings"],
            toolbar_bg: curTheme == "dark" || embed == "1" ? "#101722" : "#ffffff",
            overrides: overrides,
            saved_data: saved_data,
          });
          //if (this.$store.state.chartLayouts.length > 0 && this.$store.state.chartLayouts[0]) {
          //  console.log(this.tvWidget)
          //  this.tvWidget.load(this.$store.state.chartLayouts[0])
          //}
        } else {
          tvWidget = new widget({
            symbol: this.$store.state.token, // default symbol
            interval: this.$store.state.interval, // default interval of 15 min for full chart
            fullscreen: false, // displays the chart in the fullscreen mode
            autosize: true,
            disabled_features: disabled,
            container: "tv_chart_container",
            datafeed: this.dataFeed,
            library_path: "/charting_library/",
            custom_css_url: "/charting_library/chart.css",
            theme: curTheme == "dark" || embed == "1" ? "Dark" : "Light",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locale: "en",
            loading_screen: {
              backgroundColor: curTheme == "dark" || embed == "1" ? "#101722" : "#ffffff",
            },
            enabled_features: ["hide_left_toolbar_by_default", "use_localstorage_for_settings"],
            toolbar_bg: curTheme == "dark" || embed == "1" ? "#101722" : "#ffffff",
            debug: false,
            overrides: overrides,
            saved_data: saved_data,
          });
        }
        var self = this;
        tvWidget.onChartReady(() => {
          tvWidget.applyOverrides(overrides);
          tvWidget.subscribe("onAutoSaveNeeded", function(e) {
            self.chartAutosave();
          });
          tvWidget.headerReady().then(() => {
            const button = tvWidget.createButton();
            button.setAttribute("title", "Reset chart to default settings");
            button.classList.add("apply-common-tooltip");            
            button.addEventListener("click", () =>
              tvWidget.showConfirmDialog({
                title: "Confirm",
                body: "Reset all chart settings to defaults?",
                callback: (result) => {
                  if (result) {
                    self.chartReset();
                  }
                },
              })
            );
            button.innerHTML = "Reset";
            console.log('>>>>>>>>>>>>>>>>>>')
            console.log('chart ready')
            console.log('>>>>>>>>>>>>>>>>>>')
          });
        });
        this.tvWidget = tvWidget;
        //this.tvWidget.onChartReady(this.chartReady());
      }, 1);
    },

    waitForHoneypotVars(token) {
      if (token != this.$store.state.token) {
        return;
      } // token changed while we were waiting
      if (this.$store.state.chain == "bsc" && this.$store.state.currentExchange && this.$store.state.top100 && this.$store.state.tokenDeployer && this.$store.state.top100IsContract) {
        this.waitingForHoneypot = false;
        let wl = this.$store.state.whitelistData;
        // whitelist info is obfuscated coming from api.
        // wl[0] is the token category (abbreviated): bogged-token-list, promoted, manual whitelist or alert
        // wl[1] is a flag (0/1) specifying whether the whitelist should be overridden (if 1, the honeypot test can run)
        if (wl && (wl[0] == "btl" || wl[0] == "p" || wl[0] == "mw") && !wl[1]) {
          console.log("whitelist");
          console.log(wl);
          return;
        }
        if (!this.$store.state.localHoneypotTestStarted) {
          // decide if we should run the honeypot test based on previous results
          let hp_res = this.$store.state.honeypotResults;
          let run_test = false;
          let prev_result = null;
          if (!hp_res[token]) {
            run_test = true;
          } else {
            let n_tests = hp_res[token]["tested"].length;
            if (!n_tests) {
              run_test = true; // should never hit this code
            } else {
              let ms_since_last_test = Date.now() - hp_res[token]["tested"][n_tests - 1];
              prev_result = hp_res[token]["results"][n_tests - 1];
              if ((hp_res[token]["results"].includes("FAIL") || prev_result == "DNC") && ms_since_last_test > 1000 * 60 * 5) {
                run_test = true;
              } else if (token in this.$store.state.topMcapTokensObj && ms_since_last_test > 120 * 60 * 1000) {
                run_test = true;
              } else if (ms_since_last_test > n_tests * 5 * 60 * 1000) {
                run_test = true;
              }
            }
          }
          if (token == NEW_BOG_TOKEN_ADDR || token == CHAIN_TOKENS[this.$store.state.chain] || token in quoteTokens[this.$store.state.chain]) {
            run_test = false;
          }
          if (run_test) {
            this.$store.state.localHoneypotTestStarted = true;
            Services.honeypotTest(this.$store, this.$store.state.token, 15);
            //console.log('______________________')
            //console.log('running honeypot test')
            //console.log(this.$store.state.honeypotResults)
            //console.log('______________________')
          } else {
            if (prev_result == "FAIL") {
              this.$store.state.localHoneypotTestResults = { test_result: "FAIL" };
              this.$store.state.honeypot = true;
            }
            //console.log('______________________')
            //console.log('not running honeypot test')
            //console.log(this.$store.state.honeypotResults)
            //console.log('______________________')
          }
        }
      } else {
        //console.log('waiting for honeypot vars...')
        setTimeout(() => {
          this.waitForHoneypotVars(this.$store.state.token);
        }, 200);
      }
    },
    chartAutosave() {
      console.log("Autosave!");
      this.tvWidget.save(this.saveCallback);
    },
    chartReset() {
      this.$store.state.chartLayouts = [];
      localStorage.setItem("chartLayouts", JSON.stringify([]));
      this.$store.commit("reloadChart");
    },
    saveCallback(saveData) {
      saveData.charts[0].timeScale.m_barSpacing = 5; // don't save the zoom level
      saveData.charts[0].timeScale.m_rightOffset = 5;
      saveData.charts[0].panes[0].rightAxisesState[0].state.m_isAutoScale = true;
      this.$store.state.chartLayouts = [];
      this.$store.state.chartLayouts[0] = saveData;
      let saveSize = (JSON.stringify(this.$store.state.chartLayouts).length * 2) / 1024;
      if (saveSize > 4500) {
        //alert('Error: Chart layout contains too much data. Try removing any drawings.')
        console.log("Autosave Error: Chart layout contains too much data for localstorage!");
      } else {
        localStorage.setItem("chartLayouts", JSON.stringify(this.$store.state.chartLayouts));
        console.log("_____________________");
        console.log("chart layout saved!");
        console.log(saveSize.toString() + " kb used");
        console.log(saveData);
        console.log("_____________________");
        //alert('Chart layout saved!')
      }
      this.$store.state.triggerChartSave = null;
    },
  },
  computed: {
    dataFeed() {
      return this.$store.state.dataFeed;
    },
    fullScreen() {
      return this.$store.state.bogEmbed;
    },
  },
  watch: {
    "$store.state.dataFeed": function() {
      console.log("bogChart vue dataFeed watcher fired");
      //if (this.$store.state.chartSkip) {
      //  this.$store.state.chartSkip = false;
      //  return;
      //}
      if (this.$store.state.resized) {
        console.log("resized");
        this.makeChart();
        this.$store.state.resized = false;
        return;
      }

      let start = Date.now()
      this.makeChart();
      console.log('>>>>>>>>>>>>>>>>>>')
      console.log('make chart in',Date.now()-start,'ms')
      console.log('>>>>>>>>>>>>>>>>>>')
    },
    "$store.state.resized": function() {
      if (this.$store.state.resized) {
        console.log("resized watcher fired");
        //this.setOptionsAfterResize();
        this.makeChart();
        this.$store.state.resized = false;
      }
    },
    "$store.state.token": function() {
      if (this.$store.state.chain == "bsc") {
        this.waitingForHoneypot = true;
        setTimeout(() => {
          this.waitForHoneypotVars(this.$store.state.token);
        }, 200);
      }
    },
    "$store.state.theme.theme": function() {
      var curTheme = this.$store.state.theme.theme,
        newTheme = curTheme == "dark" ? "Dark" : "Light";

      this.tvWidget.changeTheme(newTheme);
    },
    "$store.state.triggerChartSave": function() {
      if (this.$store.state.triggerChartSave && this.tvWidget !== null) {
        this.tvWidget.save(this.saveCallback);
      }
    },
    "$store.state.triggerChartLoad": function() {
      if (this.$store.state.triggerChartLoad && this.tvWidget !== null && this.$store.state.chartLayouts[0]) {
        this.tvWidget.load(this.$store.state.chartLayouts[0]);
        console.log("_____________________");
        console.log("chart layout loaded!");
        console.log("_____________________");
        this.$store.state.triggerChartLoad = null;
      }
    },
    "$store.state.triggerChartReset": function() {
      if (this.$store.state.triggerChartReset && this.tvWidget !== null) {
        this.$store.state.chartLayouts = [];
        //this.tvWidget.load({})
        this.tvWidget.activeChart().executeActionById("chartReset");
        //this.tvWidget.load()
        this.$store.commit("reloadChart");
        console.log("_____________________");
        console.log("chart reset!");
        console.log("_____________________");
        this.$store.state.triggerChartReset = null;
      }
    },
  },
  destroyed() {
    if (this.tvWidget !== null) {
      console.log("bogChart destructor");
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
};
</script>

<style></style>
