<template>
  <not-available v-if="!onSupportedChain" />
  <div v-else>
    <swap-overlay />
    <div class="container mx-auto my-8 px-4">
      <h2 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">Your Portfolio</h2>
      <h3 class="text-gray-500 text-xl mb-10">View your assets</h3>
      <div v-if="isWalletConnected" class="mb-4 flex md:flex-row flex-col md:space-y-0 space-y-3 md:space-x-6">
        <div v-bind:key="item" v-for="item in orderedChangeDesc.slice(0, 1)">
          <span class="dark:text-gray-400 text-gray-900 overline">Best performer</span>
          <div class="text-white lg:text-base text-sm whitespace-nowrap">
            <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.percentChange >= 0, 'dark:text-error-bright text-error': item.percentChange < 0 }" class="fade-in flex flex-row items-center">
              <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.percentChange >= 0 }" class="h-5 w-5" aria-hidden="true" />
              {{ item.percentChange ? item.percentChange.toFixed(2) + "%" : "-" }}
              {{ item.currency.symbol }}
            </div>
          </div>
        </div>
        <div v-bind:key="item" v-for="item in orderedChangeAsc.slice(0, 1)">
          <span class="dark:text-gray-400 text-gray-900 overline">Worst performer</span>
          <div class="text-white lg:text-base text-sm whitespace-nowrap">
            <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.percentChange >= 0, 'dark:text-error-bright text-error': item.percentChange < 0 }" class="fade-in flex flex-row items-center">
              <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.percentChange >= 0 }" class="h-5 w-5" aria-hidden="true" />
              {{ item.percentChange ? item.percentChange.toFixed(2) + "%" : "-" }}
              {{ item.currency.symbol }}
            </div>
          </div>
        </div>
        <div>
          <span class="dark:text-gray-400 text-gray-900 overline whitespace-nowrap">Total tokens</span>
          <div class="dark:text-white text-gray-600 lg:text-base text-sm whitespace-nowrap">
            {{ tokenlist.length }}
          </div>
        </div>
        <div v-if="this.$store.state.portfolioTokensHidden.length != 0">
          <span class="dark:text-gray-400 text-gray-900 overline whitespace-nowrap">Hidden tokens</span>
          <div class="dark:text-white text-gray-600 lg:text-base text-sm whitespace-nowrap">
            {{ this.$store.state.portfolioTokensHidden.length }}
          </div>
        </div>
      </div>
      <div :class="isWalletConnected ? 'block' : 'hidden'" class="container w-full mx-auto md:pb-4">
        <div class="flex flex-col w-full">
          <div class="flex lg:flex-row flex-col w-full lg:space-x-4 lg:space-y-0 space-y-4 mb-4">
            <div class="apexChart lg:w-7/12 w-full relative shadow-smooth pb-8 rounded-lg border dark:bg-gray-800 dark:border-gray-700 bg-gray-800">
              <div v-if="donutLoading" style="backdrop-filter: blur(4px);" class="top-0 absolute flex items-center place-content-center flex-col z-20 rounded-lg w-full left-0 h-full bg-gray-900 bg-opacity-70">
                <mini-spinner></mini-spinner>
                <div class="animate-pulse-disable mt-8 text-center">
                  <span class="text-secondary overline block">Loading Portfolio</span>
                  <span class="text-2xs mt-1 block text-white text-opacity-50 ">This might take a couple seconds</span>
                </div>
              </div>
              <div>
                <div class="p-4 flex flex-row items-center justify-between space-x-2">
                  <span class="overline select-none dark:text-gray-400 dark:bg-gray-900 bg-gray-900 flex flex-row items-center text-gray-200 py-1 px-3 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 13a3 3 0 110-6 3 3 0 010 6z" opacity=".35" />
                      <path d="M13.123 2.062A1.007 1.007 0 0012 3.057v5.947A3.005 3.005 0 0115 12h5.938c.6 0 1.067-.527 1-1.123a10.004 10.004 0 00-8.815-8.815z" />
                    </svg>

                    {{ $t("charts.views.portfolio.portfoliooverview") }}
                  </span>
                  <button v-if="nVisibleHoneypots > 0" class="text-white text-xs px-3 py-1.5 bg-opacity-0 hover:bg-opacity-10 duration-200 transition-all bg-gray-50 rounded-full" @click="hideHoneypots">
                    Hide all warning flagged tokens
                  </button>
                </div>
                <div style="height: 380px;" class="p-1 flex flex-col place-content-center">
                  <div id="apexDonut"></div>
                </div>
              </div>
            </div>
            <div v-if="false"  class="lg:w-5/12 w-full relative shadow-smooth rounded-lg border dark:bg-gray-800 dark:border-gray-700 bg-gray-800">
              <div class="p-4">
                <div class="mb-4 flex flex-row justify-between items-center">
                  <span class="overline select-none dark:text-gray-400 dark:bg-gray-900 bg-gray-900 flex flex-row items-center text-gray-200 py-1 px-3 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 fill-current mr-2" fill="none" viewBox="0 0 25 24">
                      <path opacity="0.35" d="M18 21H6C4.343 21 3 19.657 3 18V6H18C19.657 6 21 7.343 21 9V18C21 19.657 19.657 21 18 21Z" />
                      <path d="M17.5 15C18.3284 15 19 14.3284 19 13.5C19 12.6716 18.3284 12 17.5 12C16.6716 12 16 12.6716 16 13.5C16 14.3284 16.6716 15 17.5 15Z" />
                      <path d="M3 6C3 4.343 4.343 3 6 3H15C16.657 3 18 4.343 18 6H3Z" />
                    </svg>
                    My wallets
                  </span>
                  <button
                    v-tippy="{
                      content: 'Multi-wallet feature coming soon',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="text-secondary text-xs px-3 py-1.5 bg-opacity-0 hover:bg-opacity-10 duration-200 transition-all bg-secondary rounded-full"
                  >
                    Add New
                  </button>
                </div>

                <div class="flex flex-col space-y-2">
                  <div class="bg-gray-900 cursor-pointer justify-between w-full flex items-center bg-opacity-50 py-3 px-6 rounded-full text-white">
                    <div class="flex flex-row items-center space-x-2">
                      <span class="overline">Primary Wallet</span>
                      <div class="bg-secondary bg-opacity-20 text-2xs font-semibold text-secondary py-0.5 px-2 rounded-full">Selected</div>
                    </div>
                    <span class="text-xs font-mono">{{ maxLength(address, 2) }}...{{ address.substr(address.length - 4) }}</span>
                  </div>
                  <div
                    v-tippy="{
                      content: 'Multi-wallet feature coming soon',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="bg-gray-900 opacity-50 hover:bg-opacity-80 duration-200 transition-all cursor-pointer justify-between w-full flex items-center bg-opacity-50 py-3 px-6 rounded-full text-white"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <span class="overline text-gray-400">Secondary Wallet</span>
                    </div>
                    <span class="text-xs font-mono">0x...B091</span>
                  </div>
                  <div
                    v-tippy="{
                      content: 'Multi-wallet feature coming soon',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="bg-gray-900 opacity-50 hover:bg-opacity-80 duration-200 transition-all cursor-pointer justify-between w-full flex items-center bg-opacity-50 py-3 px-6 rounded-full text-white"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <span class="overline text-gray-400">Igors Wallet</span>
                    </div>
                    <span class="text-xs font-mono">0x...B092</span>
                  </div>
                  <div
                    v-tippy="{
                      content: 'Multi-wallet feature coming soon',
                      appendTo: 'parent',
                      theme: 'bog',
                      animation: 'shift-toward-subtle',
                      arrow: false,
                      maxWidth: '350px',
                    }"
                    class="bg-gray-900 opacity-50 hover:bg-opacity-80 duration-200 transition-all cursor-pointer justify-between w-full flex items-center bg-opacity-50 py-3 px-6 rounded-full text-white"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <span class="overline text-gray-400">This is where I keep my boggies</span>
                    </div>
                    <span class="text-xs font-mono">0x...B093</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col font-mono">
            <div class="overflow-x-auto">
              <div class="py-2 align-middle inline-block min-w-full">
                <table class="min-w-full">
                  <thead class="dark:bg-gray-800 bg-white overflow-hidden">
                    <tr>
                      <th
                        @click="
                          sortType = 'price';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer rounded-l-full px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          Token
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'price' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>
                      <th
                        @click="
                          sortType = 'balance';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          {{ $t("charts.views.portfolio.amount") }}
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'balance' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>
                      <th
                        @click="
                          sortType = 'value';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          {{ $t("charts.views.portfolio.totalvalue") }}
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'value' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>
                      <th
                        @click="
                          sortType = 'percentChange';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          {{ $t("charts.views.portfolio.24h") }}
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'percentChange' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>

                      <th
                        @click="
                          sortType = 'mcap';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          {{ $t("charts.views.portfolio.mcap") }}
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'mcap' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>

                      <th
                        @click="
                          sortType = 'trustscore';
                          isReverse = !isReverse;
                        "
                        scope="col"
                        class="group cursor-pointer px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        <div class="flex flex-row items-center">
                          Trust score
                          <div class="ml-2 inline-block group-hover:text-white text-gray-500 duration-200 transition-colors">
                            <SortAscendingIcon v-if="sortType == 'mcap' && !isReverse" class="h-5 w-5" aria-hidden="true" />
                            <SortDescendingIcon v-else class="h-5 w-5" aria-hidden="true" />
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="group cursor-pointer rounded-r-full px-6 py-3 text-xs font-medium text-gray-400 uppercase tracking-wider text-right">
                        <div v-if="isWalletConnected && this.$store.state.portfolioTokensHidden.length != 0" class="overflow-hidden">
                          <button @click="showHiddenTokens">
                            Restore hidden
                          </button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!forceReload" class="divide-y divide-gray-200 dark:divide-gray-800">
                    <tr v-for="(item, i) in sortTable" :key="'item' + i">
                      <td class="px-6 py-6 flex flex-row space-x-4 whitespace-nowrap">
                        <button
                          v-if="!item.isStake"
                          class="text-gray-300 hover:text-secondary duration-200 transition-colors w-12"
                          @click="
                            openSwapOverlay();
                            this.$store.dispatch('loadToken', item.currency.address);
                          "
                        >
                          Swap
                        </button>
                        <div v-else class="w-12"></div>

                        <router-link v-on:click="this.$store.dispatch('loadToken', item.currency.address)" :to="'/?c=' + this.$store.state.chain + '&t=' + item.currency.address" v-bind:key="'tokenlist-pfo-' + i" class="flex space-x-4 items-center">
                          <img class="w-9 h-9 rounded-full cursor-default bg-white" :src="item.currency.tokenImage" @error="altSymbol" />

                          <div class="ml-4">
                            <div class="text-sm font-medium text-gray-900 dark:text-white">
                              {{ item.currency.symbol }}
                            </div>
                            <div v-html="bogintificPrice(item.price, this.$store.state.useTruncatedBogintific)" class="text-sm text-gray-600 dark:text-gray-300 mt-1"></div>
                          </div>
                        </router-link>
                      </td>
                      <td class="px-6 py-6 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        <div v-html="bogintificValue(item.balance, this.$store.state.useTruncatedBogintific)" class="text-sm mt-2"></div>
                      </td>
                      <td v-html="bogintificPrice(item.value, this.$store.state.useTruncatedBogintific)" class="px-6 py-6 whitespace-nowrap text-sm text-gray-900 dark:text-white"></td>
                      <td class="px-6 py-6 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        <div v-bind:class="item.percentChange >= 0 || item.percentChange == undefined ? 'dark:text-secondary text-secondary-dark' : 'dark:text-error-bright text-error'" class="fade-in flex flex-row items-center text-xs">
                          <span class="flex flex-row items-center" v-if="item.percentChange != undefined">
                            <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.percentChange >= 0 }" class="h-4 w-4" aria-hidden="true" />
                            <span class="text-sm">{{ item.percentChange.toFixed(2) }}%</span>
                          </span>
                          <span v-else>
                            <span class="text-sm">N/A</span>
                          </span>
                        </div>
                      </td>
                      <td v-html="item.mcap ? bogintificPrice(item.mcap, this.$store.state.useTruncatedBogintific) : ''" class="px-6 py-6 whitespace-nowrap text-sm text-gray-900 dark:text-white"></td>
                      <td class="px-6 py-6 whitespace-nowrap text-sm text-white">
                        <div class="flex flex-row items-center">
                          <div v-if="item.trustscore >= 0.8" v-html="Math.round(item.trustscore * 100.0).toString()" class="text-sm bg-secondary bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-secondary"></div>
                          <div v-else-if="item.trustscore > 0.5" v-html="Math.round(item.trustscore * 100.0).toString()" class="text-sm bg-alert bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-alert-dark dark:text-alert-bright"></div>
                          <div
                            v-else-if="item.trustscore <= 0.5 && item.trustscore != null"
                            v-html="Math.round(item.trustscore * 100.0).toString()"
                            class="text-sm bg-error-bright bg-opacity-20 dark:bg-error dark:bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-error"
                          ></div>
                          <div v-else-if="item.trustscore == null" class="text-sm bg-gray-700 bg-opacity-20 px-2 py-0.5 inline-block rounded-md text-gray-500">N/A</div>
                          <div class="ml-2 space-x-1 flex flex-row items-center overline text-error-bright" v-if="item.honeypot">
                            Sell warning
                            <InformationCircleIcon
                              v-tippy="{
                                content: 'Tests indicate that you may not be able to sell this token.',
                                appendTo: 'parent',
                                theme: 'bog',
                                animation: 'shift-toward-subtle',
                                arrow: false,
                                maxWidth: '350px',
                              }"
                              class="h-4 w-4 text-error-bright ml-1"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </td>

                      <td class="px-6 py-6 whitespace-nowrap text-sm text-right">
                        <button @click="hideToken(item)" class="dark:text-gray-500 text-gray-300 hover:text-gray-400">
                          {{ $t("charts.views.portfolio.hide") }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isWalletConnected">
        <not-connected />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Services } from "@/services";
import ApexCharts from "apexcharts";
import miniSpinner from "@/components/miniSpinner.vue";
import swapOverlay from "@/components/Swap/swapOverlay";
import { bogintificPrice, bogintificValue } from "@/filters";
import { ArrowSmDownIcon, SortAscendingIcon, SortDescendingIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon } from "@heroicons/vue/outline";
import notAvailable from "@/components/notAvailable";
import notConnected from "./notConnected.vue";
import chains from "@/chains";
import { maxLength } from "@/filters";
import trustScore from "@/views/Cryptocurrencies/components/trustScore.vue";

export default {
  components: { miniSpinner, swapOverlay, InformationCircleIcon, ArrowSmDownIcon, SortAscendingIcon, SortDescendingIcon, notAvailable, notConnected, trustScore },
  created: async function() {
    this.reloadChart();
  },
  mounted: async function() {
    // apex donut
    this.donutChart = new ApexCharts(document.querySelector("#apexDonut"), {
      colors: ["#54478c", "#2c699a", "#048ba8", "#0db39e", "#16db93", "#83e377", "#efea5a", "#f1c453", "#f29e4c"],
      chart: {
        height: "100%",
        type: "donut",
        redrawOnWindowResize: true,
        dropShadow: {
          enabled: true,
          top: 4,
          left: 0,
          blur: 10,
          color: "#101722",
          opacity: 0.2,
        },
      },
      plotOptions: {
        pie: {
          startAngle: 90,
          endAngle: -270,
          donut: {
            size: "75%",
            background: "transparent",
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "20px",
                fontFamily: "Jost, sans-serif",
                fontWeight: 500,
                color: "#FFFFFF",
                offsetY: 0,
                formatter: function(val) {
                  return "$" + parseFloat(val).toLocaleString();
                },
              },
              total: {
                show: true,
                showAlways: false,
                label: "Total Balance",
                fontSize: "14px",
                fontFamily: "Jost, sans-serif",
                fontWeight: 700,
                color: "#1BC870",
                formatter: function(w) {
                  return (
                    "$" +
                    w.globals.seriesTotals
                      .reduce((a, b) => {
                        return a + b;
                      }, 0)
                      .toLocaleString()
                  );
                },
              },
            },
          },
        },
        dataLabels: {
          offset: 0,
        },
      },
      xaxis: {
        min: 1,
        max: 1,
      },
      yaxis: {
        min: 1,
        max: 1,
      },
      stroke: {
        width: 0,
      },

      series: [],
      labels: [],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontFamily: "Jost, sans-serif",
          fontWeight: 500,
          color: "#1BC870",
        },
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        fillSeriesColor: true,
      },
      legend: {
        show: true,
        // formatter: function(val, opts) {
        //   return val + " - " + opts.w.globals.series[opts.seriesIndex]
        // },
        position: "right",
        horizontalAlign: "left",
        fontSize: "11px",
        labels: {
          colors: ["#CCD7E9"],
          fontFamily: "Jost, sans-serif",
          useSeriesColors: false,
        },
        markers: {
          radius: 2,
          width: 8,
          height: 16,
          offsetX: -2,
          offsetY: 4,
        },
        itemMargin: {
          horizontal: 8,
          vertical: 6,
        },
      },
    });
    let donutPromise = this.donutChart.render();

    await donutPromise;
    this.reloadChart();
    this.donutLoading = false;
  },
  data: function() {
    return {
      sortType: "value",
      isReverse: true,
      forceReload: false,
      donutLoading: true,
    };
  },
  methods: {
    maxLength,
    bogintificPrice,
    bogintificValue,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    openSwapOverlay: function() {
      this.$store.commit("openSwapOverlay");
    },
    connectWallet: async function() {
      let web3 = await Services.getWeb3(this.$store.state.chain);
      this.$store.commit("connectWallet", web3);
    },
    toggleSwapOverlay: function() {
      this.$store.commit("toggleSwapOverlay");
    },
    showHiddenTokens: async function() {
      this.$store.state.portfolioTokensHidden = [];
      localStorage.setItem("hiddenTokens", []);
      this.reloadChart();
    },
    hideToken: async function(token) {
      this.$store.state.portfolioTokensHidden.push(token.currency.address);
      localStorage.setItem("hiddenTokens", JSON.stringify(this.$store.state.portfolioTokensHidden));
      this.reloadChart();
    },
    hideHoneypots: async function() {
      for (let token of this.$store.getters.accountWalletTokens) {
        if (token.honeypot && !this.$store.state.portfolioTokensHidden.includes(token.currency.address)) {
          this.$store.state.portfolioTokensHidden.push(token.currency.address);
        }
      }
      localStorage.setItem("hiddenTokens", JSON.stringify(this.$store.state.portfolioTokensHidden));
      this.reloadChart();
    },
    reloadChart: async function() {
      if (this.donutChart) {
        let labels = this.tokenLabelsForChart;
        let series = this.tokenListForChart;
        console.log(labels);
        console.log(series);
        this.donutChart.updateOptions({
          labels: this.tokenLabelsForChart,
          series: this.tokenListForChart,
        });
      }
    },
  },
  computed: {
    wallets: function() {
      if (this.$store.state.walletTokens[this.$store.state.chain]) {
        let walletAdrs = [];
        for (let walletAdr in this.$store.state.walletTokens[this.$store.state.chain]) {
          if (walletAdr == this.$store.state.wallet) {
            walletAdrs.unshift(walletAdr);
          } else {
            walletAdrs.push(walletAdr);
          }
        }
        return walletAdrs;
      }
      return [];
    },
    nVisibleHoneypots: function() {
      if (!this.$store.getters.accountWalletTokens) {
        return 0;
      }
      let n = 0;
      for (let token of this.$store.getters.accountWalletTokens) {
        if (token.honeypot && !this.$store.state.portfolioTokensHidden.includes(token.currency.address)) {
          n += 1;
        }
      }
      return n;
    },
    onSupportedChain() {
      return chains[this.$store.state.chain].supports.includes("Portfolio");
    },
    address: function() {
      return this.$store.getters.account || "";
    },
    sortTable: function() {
      var app = this;
      this.tokenlist.sort(function(a, b) {
        if (a[app.sortType] < b[app.sortType]) {
          if (app.isReverse) return 1;
          else return -1;
        } else {
          if (app.isReverse) return -1;
          else return 1;
        }
      });
      return this.tokenlist;
    },
    orderedChangeDesc() {
      return _.chain(this.tokenlist)
        .sortBy("percentChange")
        .reverse()
        .partition("percentChange")
        .flatten()
        .value();
    },
    orderedChangeAsc() {
      return _.chain(this.tokenlist)
        .sortBy("percentChange")
        .partition("percentChange")
        .flatten()
        .value();
    },
    animateWallet: function() {
      // if there is no walletTokenDict saved, user has never used wallet feature before.
      // Animate at them to let them know about the feature
      return !localStorage.getItem("walletTokenDict");
    },
    swapOverlayIsOpen: function() {
      return this.$store.getters.swapOverlayOpen;
    },
    walletReloading: function() {
      return this.$store.state.walletReloading;
    },
    walletValue: function() {
      if (this.$store.getters.accountWalletTokens == null) {
        return 0;
      }
      let totalValue = 0;
      let filteredTokens = this.$store.getters.accountWalletTokens.filter((t) => {
        return t && t.balance && t.value && !this.$store.state.portfolioTokensHidden.includes(t.currency.address);
      });
      for (let token of filteredTokens) {
        totalValue += token.value;
      }
      return totalValue;
    },
    tokenlist: function() {
      return this.$store.getters.accountWalletTokens == null
        ? []
        : this.$store.getters.accountWalletTokens
            .filter((t) => {
              return t && t.balance && t.value && !this.$store.state.portfolioTokensHidden.includes(t.currency.address);
            })
            .sort((a, b) => {
              let stakeMismatch = a.isStake != b.isStake;
              if (stakeMismatch) {
                return b.isStake ? 1 : -1;
              }
              return b.value - a.value;
            });
    },
    tokenLabelsForChart: function() {
      //if (this.$store.getters.accountWalletTokens == null) return [];
      if (!this.$store.getters.accountWalletTokens) return [];
      let totalValue = 0;
      for (let token of this.$store.getters.accountWalletTokens) {
        if (token.value && !this.$store.state.portfolioTokensHidden.includes(token.currency.address)) {
          totalValue += token.value;
        }
      }
      let labels = this.$store.getters.accountWalletTokens
        .filter((t) => {
          return t && t.value && !this.$store.state.portfolioTokensHidden.includes(t.currency.address) && (100.0 * t.value) / totalValue > this.$store.state.portfolioCutoffPercent;
        })
        .sort((a, b) => {
          let stakeMismatch = a.isStake != b.isStake;
          if (stakeMismatch) {
            return b.isStake ? 1 : -1;
          }
          return b.value - a.value;
        })
        .map((x) => x.currency.name);
      labels.push("Other");
      return labels;
    },
    tokenListForChart: function() {
      //if (this.$store.getters.accountWalletTokens == null) return [];
      if (!this.$store.getters.accountWalletTokens) return [];
      let totalBalance = 0;
      for (let token of this.$store.getters.accountWalletTokens) {
        if (token.value && !this.$store.state.portfolioTokensHidden.includes(token.currency.address)) {
          totalBalance += token.value;
        }
      }
      let values = this.$store.getters.accountWalletTokens
        .filter((t) => {
          return t && t.value && t.balance && !this.$store.state.portfolioTokensHidden.includes(t.currency.address) && (100.0 * t.value) / totalBalance > this.$store.state.portfolioCutoffPercent;
        })
        .sort((a, b) => {
          let stakeMismatch = a.isStake != b.isStake;
          if (stakeMismatch) {
            return b.isStake ? 1 : -1;
          }
          return b.value - a.value;
        })
        .map((x) => x.value);
      let othersTotal = this.$store.getters.accountWalletTokens
        .filter((t) => {
          return t && t.value && t.balance && (100.0 * t.value) / totalBalance <= this.$store.state.portfolioCutoffPercent && !this.$store.state.portfolioTokensHidden.includes(t.currency.address);
        })
        .reduce((a, b) => {
          return a + b.value;
        }, 0);
      values.push(othersTotal);
      return values;
    },
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
    ...mapGetters({ theme: "getTheme" }),
  },
  watch: {
    "$store.state.chain": function() {
      console.log("portfolio chain watcher fired");
      //this.reloadChart();
    },
    "$store.state.walletReloading": function() {
      if (!this.$store.state.walletReloading && this.$store.getters.accountWalletTokens) {
        console.log("walletReloading watcher fired");
        this.reloadChart();
        let found = document.getElementsByClassName("entry-counter");
        if (found.length != this.tokenlist.length) {
          this.forceReload = true;
          this.$nextTick().then((res) => {
            this.forceReload = false;
          });
        }
      }
    },
    "$store.getters.accountWalletTokens": function() {
      console.log("portfolio accountWalletTokens watcher fired");
      console.log(this.$store.state.chain);
      console.log(this.$store.getters.accountWalletTokens);
      this.reloadChart();
      // TODO: this is such a shit hack
      // figure out why tf our vue reactivity isn't working here and fix that instead
      // to reproduce: comment out the next 7ish lines, disconnect your wallet via bogcharts ui, reload, and
      // reconnect wallet while watching portfolio page. portfolio will stop updating after the first two entries
      let found = document.getElementsByClassName("entry-counter");
      if (found.length != this.tokenlist.length) {
        this.forceReload = true;
        this.$nextTick().then((res) => {
          this.forceReload = false;
        });
      }
    },
  },
};
</script>

<style></style>
