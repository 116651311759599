<template>

  <div class="container mx-auto my-8 px-4">
    <h1 class="dark:text-white text-gray-900 font-medium text-3xl mb-1">Your Favorite Tokens</h1>
    <h3 class="text-gray-500 text-xl mb-10">Star a token and it will appear here</h3>
    <favorited class="mt-8" />
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import Favorited from "./components/Favorited.vue";
import topCards from "./components/topCards/topCards.vue";
import newsMarquee from "./components/newsMarquee.vue";
import Popper from "vue3-popper";

export default {
  components: {
    bogintificPrice,
    maxDigits,
    Popper,
    topCards,
    newsMarquee,
    Favorited,
  },

  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
