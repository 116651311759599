<template>
  <div
    v-tippy="{
      content: 'Buys: ' + bogintificPrice(minPrecision($store.state.transactionsNetBuy, 0)) + ' / ' + 'Sells: ' + bogintificPrice(minPrecision($store.state.transactionsNetSell, 0)) + ' in the past ' + chainlookback + ' minutes.',
      appendTo: 'parent',
      theme: 'bog',
      animation: 'shift-toward-subtle',
      arrow: false,
      maxWidth: 'none',
      placement: 'top',
    }"
    class="h-4 w-full flex flex-row relative"
  >
    <span :style="tradeBuyIndicatorStyle" class="bg-secondary z-20 relative w-1/2 transition-all duration-300 overline flex items-center text-secondary-xtra_dark justify-end">
      <img style="bottom: 15px;" class="absolute fade-in z-20 right-0 w-5" v-if="buyPressure" src="@/assets/images/greenfire.png" />
      <span class="md:text-sm text-2xs z-10 font-semibold px-1 pr-2" v-html="bogintificPrice(minPrecision($store.state.transactionsNetBuy, 0))"></span>
      <div class="bar-overlay-buy"></div>
    </span>

    <span :style="tradeSellIndicatorStyle" class="bg-error-bright relative w-1/2 transition-all duration-300 overline flex items-center text-error-dark justify-start">
      <img style="bottom: 15px;" class="absolute fade-in z-20 left-0 w-5" v-if="sellPressure" src="@/assets/images/redfire.png" />
      <span class="md:text-sm text-2xs z-10 font-semibold px-1 pl-2" v-html="bogintificPrice(minPrecision($store.state.transactionsNetSell, 0))"></span>
      <div class="bar-overlay-sell"></div>
    </span>
  </div>
</template>

<script>
import { bogintificPrice, minPrecision } from "@/filters";
export default {
  methods: {
    bogintificPrice,
    minPrecision,
  },
  computed: {
    chainlookback: function() {
      if (this.$store.state.chain == "polygon") {
        return 100;
      } else {
        return 100;
      }
    },
    buyPressure: function() {
      let buyRatio = (this.$store.state.transactionsNetBuy / this.$store.state.tokenVolume) * 100;
      if (this.$store.state.tokenVolume) {
        // If recent buys are 10% or more of the 24hr vol
        return buyRatio >= 10;
      }
    },
    sellPressure: function() {
      let sellRatio = (this.$store.state.transactionsNetSell / this.$store.state.tokenVolume) * 100;
      if (this.$store.state.tokenVolume) {
        // If recent sells are 10% or more of the 24hr vol
        return sellRatio >= 10;
      }
    },
    tradeBuyIndicatorStyle: function() {
      let percent = this.$store.state.transactionsNetBuy / (this.$store.state.transactionsNetBuy + this.$store.state.transactionsNetSell);
      return "width: " + (100 * percent).toFixed(2) + "%;";
    },
    tradeSellIndicatorStyle: function() {
      let percent = this.$store.state.transactionsNetSell / (this.$store.state.transactionsNetBuy + this.$store.state.transactionsNetSell);
      return "width: " + (100 * percent).toFixed(2) + "%;";
    },
  },
};
</script>
