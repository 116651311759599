<template>
  <Switch
    id="themeToggler"
    v-model="enabled"
    @click="toggleTheme()"
    class="bg-gray-200 relative inline-flex flex-shrink-0 h-8 w-15 border-2 border-transparent rounded-md cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-1 focus:ring-gray-400 dark:focus:ring-gray-500"
  >
    <span id="iconsWrapper" class="translate-x-7 bg-white pointer-events-none relative inline-block h-7 w-7 rounded-md shadow transform ring-0 transition ease-in-out duration-200">
      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current" viewBox="0 0 20 20" fill="currentColor">
          <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
        </svg>
      </span>
      <span class="opacity-100 ease-in duration-200 text-primary absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </span>
  </Switch>
</template>

<script>
import { ref } from "vue";
import { Switch } from "@headlessui/vue";
export default {
  components: {
    Switch,
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("toggleTheme");
      const css = document.createElement("style");
      css.appendChild(
        document.createTextNode(
          `*:not(#themeToggler *) {
       -webkit-transition: none !important;
       -moz-transition: none !important;
       -o-transition: none !important;
       -ms-transition: none !important;
       transition: none !important;
    }`
        )
      );
      document.head.appendChild(css);
      setTimeout(function() {
        const _ = window.getComputedStyle(css).opacity;
        document.head.removeChild(css);
      }, 500);
    },
  },
  setup() {
    const enabled = ref(false);
    return {
      enabled,
    };
  },
};
</script>

<style scoped>
.dark #themeToggler {
  background-color: rgba(16, 23, 34);
}
.dark #iconsWrapper {
  --tw-translate-x: 0px;
  background-color: rgba(76, 92, 117);
}
.dark #iconsWrapper > span:nth-child(1) {
  opacity: 1;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  color: rgba(160, 173, 194);
}
.dark #iconsWrapper > span:nth-child(2) {
  opacity: 0;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
</style>
