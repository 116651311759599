<template>
  <button
    @click="openPriceAlertOverlay"
    v-wave
    class="dark:text-gray-400 text-gray-600 focus:outline-none border hover:bg-gray-200 dark:bg-gray-800 border-gray-300 dark:border-gray-700 dark:bg-opacity-0 dark:hover:bg-opacity-90 inline-flex select-none items-center md:py-1 pr-1 pl-1 md:pl-2 py-px border-transparent rounded text-xs font-medium"
  >
    <span class="md:block hidden">Alert</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:ml-1.5" viewBox="0 0 20 20" fill="currentColor">
      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
    </svg>
  </button>
</template>
<script>

import { Services } from "@/services";

export default {
  methods: {
    openPriceAlertOverlay: function() {
      this.$store.commit("openPriceAlertOverlay");
    },
    async connectWallet() {
        try {
        let web3 = await Services.getWeb3(this.$store.state.chain);
        await this.$store.commit("connectWallet", web3);
      } catch (error) {
        console.error(error);
      } 
    }
  }
}
</script>

<style></style>
