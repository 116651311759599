export default {
  "charts.common.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
  "charts.common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "charts.common.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié!"])},
  "charts.common.copyaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier Adresse"])},
  "charts.common.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "charts.common.sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor"])},
  "charts.common.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
  "charts.common.yourwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Portefeuille"])},
  "charts.components.infobar.connecttoviewtrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez connecter votre portefeuille pour voir vos transactions"])},
  "charts.components.infobar.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
  "charts.components.infobar.dex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX"])},
  "charts.components.infobar.holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détenteurs"])},
  "charts.components.infobar.info.valuelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un LP vaut: "])},
  "charts.components.infobar.liqinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info sur la Liquidité"])},
  "charts.components.infobar.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles"])},
  "charts.components.infobar.time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
  "charts.components.infobar.tokeninfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info sur le Jeton"])},
  "charts.components.infobar.tokeninfo.basicdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est un jeton sur"])},
  "charts.components.infobar.tokeninfo.contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
  "charts.components.infobar.tokeninfo.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "charts.components.infobar.tokeninfo.liqback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["représente"])},
  "charts.components.infobar.tokeninfo.liqbackpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liquidité de"])},
  "charts.components.infobar.tokeninfo.ofthemcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la capitalisation du marché."])},
  "charts.components.infobar.tokeninfo.telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram"])},
  "charts.components.infobar.tokeninfo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique en temps réel"])},
  "charts.components.infobar.tokeninfo.twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "charts.components.infobar.tokeninfo.waterfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterfall"])},
  "charts.components.infobar.tokeninfo.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Web"])},
  "charts.components.infobar.tokeninfo.xcircsupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec une offre en circulation de"])},
  "charts.components.infobar.tokeninfo.xsupplyof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a une offre maximale de"])},
  "charts.components.infobar.tokenprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du Jeton"])},
  "charts.components.infobar.tradebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "charts.components.infobar.traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
  "charts.components.infobar.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "charts.components.infobar.transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts"])},
  "charts.components.infobar.usertrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos Transactions"])},
  "charts.components.infobar.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
  "charts.components.sidebar.bogtools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogTools"])},
  "charts.components.sidebar.chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique"])},
  "charts.components.sidebar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "charts.components.sidebar.goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à Bogged.Finance"])},
  "charts.components.sidebar.madeby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fait avec ❤ par"])},
  "charts.components.sidebar.multichart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Graphiques"])},
  "charts.components.sidebar.othertools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres Outils"])},
  "charts.components.sidebar.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
  "charts.components.sidebar.tokenexplorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorateur de Jetons"])},
  "charts.components.sidebar.version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "charts.components.topbar.24hrvol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume 24h"])},
  "charts.components.topbar.bal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Solde"])},
  "charts.components.topbar.bscscan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSCScan"])},
  "charts.components.topbar.liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidité"])},
  "charts.components.topbar.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalisation du marché"])},
  "charts.components.topbar.plottrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Transactions"])},
  "charts.components.topbar.singleblocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloc par bloc"])},
  "charts.components.topbar.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
  "charts.components.topbar.trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Order"])},
  "charts.home.connectwalletshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter Portefeuille"])},
  "charts.home.premium.bogswapadv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogSwap Avancé"])},
  "charts.home.premium.bogswapadv1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trader plus rapidement et efficacement avec les fonctionnalités avancées de BogSwap."])},
  "charts.home.premium.bogswapadv2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie le bon déroulement de votre échange en s'assurant de ne pas dépasser les limites de transactions du jeton."])},
  "charts.home.premium.bogswapadv3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contournez les limites de transaction sur tous les jetons de la BSC."])},
  "charts.home.premium.congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations! Vous avez débloqué toutes les fonctionnalités de Bogged.Finance"])},
  "charts.home.premium.connectwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre portefeuille"])},
  "charts.home.premium.connectwalletfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez votre portefeuille pour voir vos fonctions débloquées."])},
  "charts.home.premium.holdstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détenez ou Staker des $BOG pour débloquer des fonctions avancées sur BogCharts et Bogged.Finance."])},
  "charts.home.premium.limitorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Orders"])},
  "charts.home.premium.limitorders.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit Orders sur Bogged.Finance pour n'importe quels jetons présents sur PancakeSwap v1/v2 et ApeSwap."])},
  "charts.home.premium.locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])},
  "charts.home.premium.nextunlockafter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOG ou plus"])},
  "charts.home.premium.nextunlockpre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine fonctionnalité débloquée à"])},
  "charts.home.premium.porttrackbasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi du Portfolio Basique"])},
  "charts.home.premium.porttrackbasic.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez votre portfolio sur le temps, avec un graphique journalier de sa valeur."])},
  "charts.home.premium.porttrackpro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi du Portfolio Pro"])},
  "charts.home.premium.porttrackpro.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalitées Premium"])},
  "charts.home.premium.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
  "charts.home.premium.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BogSniper"])},
  "charts.home.premium.sniper.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniper les lancements de jeton avec BogSniper sur Bogged.Finance"])},
  "charts.home.premium.stoplosses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Losses"])},
  "charts.home.premium.stoplosses.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placez des ordres Stop Loss sur Bogged.Finance"])},
  "charts.home.premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalitées Premium"])},
  "charts.home.premium.trailingstop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailing Stop Losses."])},
  "charts.home.premium.trailingstop.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active les Trailing Stop Losses sur Bogged.Finance."])},
  "charts.home.premium.unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer"])},
  "charts.home.premium.unlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloqué"])},
  "charts.topics.titles.promote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promouvoir Votre Jeton"])},
  "charts.topics.titles.thirdparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils Tiers"])},
  "charts.topics.tools.sniper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sniper"])},
  "charts.topics.tools.staking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staking"])},
  "charts.topics.tools.stoploss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss"])},
  "charts.topics.tools.telegrampricebot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot de Prix Telegram"])},
  "charts.views.chart.loadingchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique en cours de chargement"])},
  "charts.views.chart.lowliqwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeton que vous regardez a une très faible liquidité, cela signifie que de petites transactions peuvent impacter significativement le prix de celui-ci. Cela peut être normal pour les jetons lancés très récemment."])},
  "charts.views.chart.lpchartwarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous essayez de regarder le graphique d'un Jeton LP (Ces jetons ne doivent pas être échangés, mais utilisés dans l'interface de liquidité de PancakeSwap ou ApeSwap.)"])},
  "charts.views.home.latestnews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières Nouvelles"])},
  "charts.views.home.latestnews.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22 Avril 2021"])},
  "charts.views.home.latestnews.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échangez n'importe quel jeton de PancakeSwap v1 et v2 avec Bogged.Finance et votre commande sera automatiquement acheminée via le meilleur DEX, sans frais supplémentaires."])},
  "charts.views.home.latestnews.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap v2 est arrivé!"])},
  "charts.views.home.promote.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe une variété d'outils et d'options pour promouvoir votre jeton avec Bogged.Finance, à la fois gratuit et payant. Cliquez ci-dessous pour en savoir plus sur l'ajout d'un bot de prix à votre Telegram, l'ajout de votre logo et des informations à nos graphiques, soyez en #tendance sur Bogged et l'achat d'annonces sur Bogged.Finance"])},
  "charts.views.home.promote.learnmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En apprendre plus"])},
  "charts.views.home.promote.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites la promotion de votre Jeton"])},
  "charts.views.home.promote.titlelong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites la promotion de votre Jeton avec Bogged.Finance"])},
  "charts.views.home.trending.promoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promus"])},
  "charts.views.home.trending.trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendances"])},
  "charts.views.home.trending.viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plus regardés"])},
  "charts.views.home.welcometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez voir gratuitement les graphiques de n'importe quel jeton qui a de la liquidité sur PancakeSwap avec Bogged Finance's Charts. Cela inclut des informations vitales telles que la liquidité et la capitalisation de marché. Des mises à jour et améliorations pour les graphiques continueront d'être déployées dans les semaines et mois à venir."])},
  "charts.views.home.welcometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur BogCharts v3"])},
  "charts.views.portfolio.24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
  "charts.views.portfolio.amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "charts.views.portfolio.clicktoconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez Votre Portefeuille pour Afficher le Portfolio"])},
  "charts.views.portfolio.favorited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
  "charts.views.portfolio.hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher jeton"])},
  "charts.views.portfolio.mcap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CBV"])},
  "charts.views.portfolio.portfoliooverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du Portfolio "])},
  "charts.views.portfolio.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "charts.views.portfolio.stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake"])},
  "charts.views.portfolio.totalvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Totale"])},
  "charts.views.portfolio.viewchart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher Graphique"])},
  "charts.views.portfolio.yourportfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Portfolio"])}
}