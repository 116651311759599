<template>
  <div class="relative">
    <div class="flex md:flex-row flex-wrap items-center lg:items-center justify-between">
      <div class="relative min-w-40 overflow-hidden">
        <div v-dragscroll class="overflow-hidden grabbable pr-4 flex w-full">
          <token-tags class="flex flex-grow-0" />
        </div>
        <div class="h-5 absolute bottom-0 right-0 w-4 bg-gradient-to-l from-background dark:from-gray-900 to-transparent"></div>
      </div>
      <div class="flex flex-row flex-nowrap flex-shrink-0 lg:items-center space-x-2 mr-2">
        <toggles class="lg:inline-flex hidden" />
        <set-price-alert
          v-tippy="{
            content: 'Set price alert',
            appendTo: 'parent',
            theme: 'bog',
            animation: 'shift-toward-subtle',
            arrow: false,
            maxWidth: 'none',
          }"
        />
        <toggleFavorite />
        <add-meta-mask class="md:inline-flex hidden" />
        <price-pair-select class="z-20" />
        <swap-select class="lg:block hidden" />
      </div>
    </div>
  </div>
</template>

<script>
import tokenTags from "@/views/Chart/components/actionsBar/tokenTags.vue";
import toggleFavorite from "@/components/Favorite/toggleFavorite";
import setPriceAlert from "@/views/Chart/components/actionsBar/setPriceAlert.vue";
import addMetaMask from "./addMetaMask.vue";
import swapSelect from "./swapSelect.vue";
import pricePairSelect from "./pricePairSelect.vue";
import Toggles from "./Toggles.vue";
import { Services } from "@/services";
export default {
  components: {
    tokenTags,
    toggleFavorite,
    setPriceAlert,
    addMetaMask,
    swapSelect,
    pricePairSelect,
    Toggles,
  },
  computed: {
    isSafari() {
      return Services.isSafari();
    },
  },
};
</script>
