<template>
  <div v-if="$store.state.tokenSymbol == 'Cake-LP' || $store.state.tokenSymbol == 'APE-LP'" class="rounded-lg bg-error-bright flex flex-row items-center space-x-3 overline bg-opacity-20 px-2 py-1 relative" role="alert">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-none text-error-bright " fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <span class="block caption sm:inline text-gray-900 dark:text-error-light">{{ $t("charts.views.chart.lpchartwarning") }} </span
    ><InformationCircleIcon
      v-tippy="{
        content: '<span>Bots add liquidity for LP Tokens in an attempt to trick users into selling them. This almost always is a bad deal for the holder..</span>',
        allowHTML: true,
        appendTo: 'parent',
        theme: 'bog',
        animation: 'shift-toward-subtle',
        arrow: false,
        maxWidth: '350px',
      }"
      class="h-4 w-4 text-gray-600 dark:text-white ml-1"
      aria-hidden="true"
    />
  </div>
  <div v-if="$store.state.honeypot" class="rounded-lg bg-error-bright flex flex-row items-center space-x-3 overline bg-opacity-20 px-2 py-1 relative" role="alert">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-none text-error-bright " fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <span class="block caption sm:inline text-gray-900 dark:text-error-light">Warning! Tests indicate that you may not be able to sell the "{{ $store.state.tokenSymbol }}" token.</span>
    <div class="whitespace-normal">
      <InformationCircleIcon
        v-tippy="{
          content:
            '<span><b>Reasons why a token might display this warning:</b></span><ul><li>- The token is unlaunched and not yet open for trading</li><li>- Tokens are locked in a vesting schedule (check their website)</li><li>- The token has anti-whale or anti-bot measures that prevent wallets from selling</li><li>- The token is a honeypot scam or airdrop scam</li></ul><br><span><em>Do your own research and be careful!</em><br /><br /><i>Is this your token? <a href=\'https://docs.bogged.finance/bogged-finance/token-teams/bogcharts/bogcharts-honeypot-detection-faq\'>Find more information here.</a></i></span>',
          allowHTML: true,
          appendTo: 'parent',
          theme: 'bog',
          animation: 'shift-toward-subtle',
          arrow: false,
          maxWidth: '350px',
          interactive: true,
        }"
        class="h-4 w-4 text-gray-600 dark:text-white ml-1"
        aria-hidden="true"
      />
    </div>
  </div>
  <div v-if="$store.state.tokenAlert" class="rounded-lg bg-error-bright flex flex-row items-center space-x-3 overline bg-opacity-20 px-2 py-1 relative" role="alert">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-none text-error-bright " fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <span class="block caption sm:inline text-gray-900 dark:text-error-light">
      {{ $store.state.tokenAlert }} <a v-if="$store.state.tokenAlertURL" :href="$store.state.tokenAlertURL" target="_Blank" class="underline">{{ $store.state.tokenAlertURLDesc }} &raquo;</a>
    </span>
    <div class="whitespace-normal">
      <InformationCircleIcon
        v-tippy="{
          content: '<span>This is a custom alert added to this token manually by the Bogged Finance team.</span>',
          allowHTML: true,
          appendTo: 'parent',
          theme: 'bog',
          animation: 'shift-toward-subtle',
          arrow: false,
          maxWidth: '350px',
        }"
        class="h-4 w-4 text-gray-600 dark:text-white ml-1"
        aria-hidden="true"
      />
    </div>
  </div>
  <div v-if="$store.state.alertText" :class="'rounded-lg ' + alertBGColorCss + ' flex flex-row items-center space-x-3 overline bg-opacity-20 px-2 py-1 relative'" role="alert">
    <svg xmlns="http://www.w3.org/2000/svg" :class="'h-6 w-6 flex-none ' + alertSvgColorCss" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
    <span :class="'block caption sm:inline ' + alertTextColorCss"> {{ $store.state.alertText }} </span>
    <div class="whitespace-normal">
      <InformationCircleIcon
        v-tippy="{
          content: '<span>This is a custom alert added to this token manually by the Bogged Finance team.</span>',
          allowHTML: true,
          appendTo: 'parent',
          theme: 'bog',
          animation: 'shift-toward-subtle',
          arrow: false,
          maxWidth: '350px',
        }"
        class="h-4 w-4 text-gray-600 dark:text-white ml-1"
        aria-hidden="true"
      />
    </div>
  </div>
  <div v-if="$store.state.tokenLiquidity > 4 && (($store.state.chain == 'csc' && $store.state.tokenLiquidity < 1000) || ($store.state.chain != 'csc' && $store.state.tokenLiquidity < 15000))" class="rounded-lg bg-alert-bright flex flex-row items-center space-x-3 overline bg-opacity-20 px-2 py-1 relative" role="alert">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-none text-alert dark:text-alert-bright" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
      />
    </svg>
    <span class="block caption sm:inline text-gray-900 dark:text-alert-light">{{ $t("charts.views.chart.lowliqwarning") }}</span>
    <div class="whitespace-normal">
      <InformationCircleIcon
        v-tippy="{
          content:
            '<span><b>Reasons why a token might display this warning:</b></span><ul><li>- The token has recently launched and hasn\'nt gained enough trades to have significant liquidity.</li><li>- The token is abandoned or dead.</li><li>- The token does not have liquidity on DEX\'s supported by Bogged Finance</li><li>- The token is a honeypot scam or airdrop scam</li></ul><br><span><em>Do your own research and be careful!</em></span>',
          allowHTML: true,
          appendTo: 'parent',
          theme: 'bog',
          animation: 'shift-toward-subtle',
          arrow: false,
          maxWidth: '350px',
        }"
        class="h-4 w-4 text-gray-600 dark:text-white ml-1"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  methods: {},
  computed: {
    alertSvgColorCss() {
      let alertCss = "text-error-bright";
      let alertType = this.$store.state.alertType;
      if (alertType) {
        if (alertType == "RED") {
          alertCss = "text-error-bright";
        } else if (alertType == "YELLOW") {
          alertCss = "text-alert-bright";
        } else if (alertType == "INFORMATIONAL") {
          alertCss = "text-gray-300";
        }
      }
      return alertCss;
    },
    alertTextColorCss() {
      let alertCss = "text-gray-900 dark:text-error-light";
      let alertType = this.$store.state.alertType;
      if (alertType) {
        if (alertType == "RED") {
          alertCss = "text-gray-900 dark:text-error-light";
        } else if (alertType == "YELLOW") {
          alertCss = "text-gray-900 dark:text-alert-light";
        } else if (alertType == "INFORMATIONAL") {
          alertCss = "text-gray-900 dark:text-gray-100";
        }
      }
      return alertCss;
    },
    alertBGColorCss() {
      let alertCss = "bg-error-bright";
      let alertType = this.$store.state.alertType;
      if (alertType) {
        if (alertType == "RED") {
          alertCss = "bg-error-bright";
        } else if (alertType == "YELLOW") {
          alertCss = "bg-alert-bright";
        } else if (alertType == "INFORMATIONAL") {
          alertCss = "bg-gray-300";
        }
      }
      return alertCss;
    },
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
