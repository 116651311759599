<template>
  <td :style="type == 'graph' ? 'min-w-40' : ''" class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
    <Text :value="value" v-if="type == 'text'" />
    <Percentage :value="value" v-else-if="type == 'percentage'" />
    <trustScore :value="value" v-else-if="type == 'trustScore'" />
    <Graph :value="value" v-else-if="type == 'graph'" />
    <Token :chain="chain" :tokenImageUrl="tokenImageUrl" :address="address" :tokenName="tokenName" :tokenIndex="tokenIndex" :price="price" v-else-if="type == 'token'" />
    <Chain :chain="chain" v-if="type == 'chain'" />
  </td>
</template>

<script>
import Text from "./Cells/Text.vue";
import Percentage from "./Cells/Percentage.vue";
import trustScore from "./Cells/trustScore.vue";
import Graph from "./Cells/Graph.vue";
import Token from "./Cells/Token.vue";
import Chain from "./Cells/Chain.vue";
export default {
  props: ["type", "value", "tokenImageUrl", "address", "tokenName", "tokenIndex", "price", "chain"],
  components: {
    Text,
    Percentage,
    trustScore,
    Graph,
    Token,
    Chain,
  },
  data() {
    return {
      embed: false,
    };
  },
};
</script>
