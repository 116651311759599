import { Services } from "@/services"
import {  
  sleep,  
  quoteTokens,
  exchangeTable,  
} from "./constants"
import moment from "moment";
import { get16Colour } from "@/filters";

class _TradebookLib {
  async getTransactions(context, tokenSymbol) {
		if (context.state.getTransactionsRunning) {
			 console.log('getTransactions is already running!')
			 return
		}
		context.state.getTransactionsRunning = true
    let rpc_errors
		try {                
      let baseDecimals = await context.dispatch('getDecimals', context.state.masterToken)

      let allNewTransactions = []

      let w3 = context.state.web3s[context.state.chain]                
      let pairToken = context.state.masterToken            

      
      var firstLoad = false
      while (!context.state.currentExchange) {
        await sleep(50)
      }
      if (context.state.currentExchange.toLowerCase() in context.state.exchangesWithLiquidity && context.state.exchangesWithLiquidity[context.state.currentExchange.toLowerCase()].bogApiSupported) {

        // get txn list that was bundled with ohlcv data
        if (context.state.transactionListBogApi != '__PROCESSED__') {
          // first load & txn list hasn't come in yet, so wait for it
          firstLoad = true
          console.log('firstLoad')
          
          
          while (context.state.transactionListBogApi == null) {
              await sleep(50)                        
              if (context.state.tokenSymbol != tokenSymbol) {
                context.state.getTransactionsRunning = false
                return
              }
          }
          var txn_list = context.state.transactionListBogApi                    
          context.state.transactionListBogApi = '__PROCESSED__' // we only wanna do this once
          allNewTransactions = await this.processBogApiTxns(context, txn_list)

          var max_timestamp = Date.now() / 1000
          if (txn_list.length > 0) {
              max_timestamp = txn_list[txn_list.length-1].timestamp
          }
          context.state.bogApiMaxTxnTimestamp = max_timestamp

          let arbResult = this.getArbTxns(allNewTransactions)
          let arbTransactions = arbResult[0]
          let arbTransactionHashes = arbResult[1]
          // remove arb transaction halves
          allNewTransactions = allNewTransactions.filter(tx => !arbTransactionHashes.includes(tx.txHash))
          // TODO: re-add newly assembled arb tx
          allNewTransactions = allNewTransactions.concat(arbTransactions)

          //console.log('arbTransactions')
          //console.log(arbTransactions)
          //console.log('allNewTransactions')
          //console.log(allNewTransactions)

          allNewTransactions = allNewTransactions.sort((tx1, tx2) => { return tx1.date -  tx2.date })
          
          
          // unshift all into relevant data structures
          context.state.firstTxListDraw = true
          context.state.transactions = allNewTransactions.reverse()
          //for (let transaction of allNewTransactions) {                        
          //    context.state.transactions.unshift(transaction);
              
          //}

          console.log('_________________________')
          console.log('txns done')
          console.log(Date.now()-context.state.exstart)
          console.log('_________________________')

          let sumBuys = 0
          let sumSells = 0
          if (context.state.transactions) {
              for (let tx of context.state.transactions) {
                if (Date.now() - tx.date <= 100*60*1000) {
                  if (tx.isSell) {
                      sumSells += tx.value
                  } else {
                      sumBuys += tx.value
                  }
                }
              }
          }
          context.state.transactionsNetSell = sumSells
          context.state.transactionsNetBuy = sumBuys                        
          context.state.currentVolumeAmt += sumSells+sumBuys

          allNewTransactions = []

          var bn = await w3.eth.getBlockNumber()
          var blockFrom = Math.round(bn - (Date.now()/1000 - max_timestamp) / 3.0) // this is hacky and inaccurate

          for (let [factoryAddress, factory] of Object.entries(context.state.exchangeTable[context.state.chain])) {
              context.state.lastTransactionBlock[factory.address] = blockFrom
          }

          
        }
      }
        
      
      let txn_indexes_by_hash = {} // indexed by txhash for fast lookups
      let i=0
      for (let txn of context.state.transactions) {
        let txhash = txn.txHash.toLowerCase()
        if (!txn_indexes_by_hash[txhash]) { txn_indexes_by_hash[txhash] = [] }
        txn_indexes_by_hash[txhash].push(i)
        i += 1
      }
      // get recent txns from bog api so we can populate wallet_address field
      if (!firstLoad && context.state.getTransactionsIndex % 2 == 0) {
        //console.log('getting recent txns...')
        try {                        
          let start = Math.round(Math.round((context.state.bogApiMaxTxnTimestamp-60)/10)*10)                        
          let res = await Services.getRecentTxnsBogApi(context.state.chain, context.state.token, start)
          //console.log('___________________')
          //console.log('recent txns:')
          //console.log(res)
          //console.log('___________________')
          if (res && res.data && res.data.length > 0) {
              
              let bogRecentTxns = await this.processBogApiTxns(context, res.data)
              context.state.bogApiMaxTxnTimestamp = res.data[res.data.length-1].timestamp
              let walletAddressesUpdated = 0
              for (let transaction of bogRecentTxns) {
                if (txn_indexes_by_hash[transaction.txHash]) {
                  for (let txIndex of txn_indexes_by_hash[transaction.txHash]) {
                      //console.log(txIndex)                                        
                      if (!context.state.transactions[txIndex].walletAddress) {
                        walletAddressesUpdated += 1
                        context.state.transactions[txIndex].walletAddress = transaction.walletAddress
                        context.state.transactions[txIndex].blockieColour1 = transaction.blockieColour1
                        context.state.transactions[txIndex].blockieColour2 = transaction.blockieColour2
                      }
                      if (!context.state.transactions[txIndex].txType) {
                        context.state.transactions[txIndex].txType = transaction.txType
                      }
                  }
                } else {
                  //console.log('added txn from bog api',transaction.pool)
                  allNewTransactions.push(transaction)
                  context.state.tokenVolume += transaction.value
                  context.state.currentVolumeAmt += transaction.value
                }
              }
              //console.log(walletAddressesUpdated,'wallet addresses updated')
          }
        } catch (e) {                    
          console.log(e)
        }                    
      }
      let new_txns_by_hash = {} // indexed by txhash for fast lookups                
      for (let txn of allNewTransactions) {
        new_txns_by_hash[txn.txHash.toLowerCase()] = true
      }
      
      
      
      while (!context.state.liquidityData) {
        await sleep(50)                        
        console.log('.')
        if (context.state.tokenSymbol != tokenSymbol) {
          context.state.getTransactionsRunning = false
          return
        }
      }
      let s1 = Date.now()
      context.state.getTransactionsIndex += 1

      //if (true) {
        // needed later to calculate timestamps for new blocks
      let currentBlockNumber;
      //if (context.state.estimateTxTimes) {
      currentBlockNumber = await w3.eth.getBlockNumber()
      let now_ts = Date.now()
      //}
      let n_pools_checked = 0
      let poolsToCheck = []
      for (let pool of context.state.liquidityData) {
        poolsToCheck.push(pool)
        if (poolsToCheck.length == 3) { break; }
      }
      
    
      let blocks = {}
      if (context.state.chain == 'csc') {
        poolsToCheck = []
      }

      
      try {
        for (let pool of poolsToCheck) {
        //for (let pool of []) {
          // factories will skip update ticks depending on their update rate
          
          let factoryAdr = pool.factory.toLowerCase()
          if (!(factoryAdr in exchangeTable[context.state.chain]) || pool.quoteToken.address != context.state.masterToken) {
            //console.log(9.1)
            continue
          }                        
          let txListLimit = 500;
          let factory = exchangeTable[context.state.chain][factoryAdr]
          //let thisUpdateRate = factory.txnListUpdateRate
          let thisUpdateRate = 1
          if (context.state.token in quoteTokens[context.state.chain]) {
            // reduce update rate by half for quote tokens as they're very high volume
            thisUpdateRate += 1
          }
          /*
          if (pool.pooled_usd < 30) { thisUpdateRate += 20 }
          else if (pool.pooled_usd < 150) { thisUpdateRate += 10 }
          else if (pool.pooled_usd < 500) { thisUpdateRate += 5 }
          else if (pool.pooled_usd < 2000) { thisUpdateRate += 2 }
          */
          if (context.state.getTransactionsIndex % thisUpdateRate != 0) {
            //console.log(factory.txnListUpdateRate)
            //console.log(pool)
            //console.log(9.2)
            //console.log('skipping')
            continue
          }
          //console.log('checking pool',pool.contract)
          n_pools_checked += 1

          let lpContract = pool.contractObj
          let quoteIdxIs0 = pool.token0 == context.state.token
          var window_size = 450
          
          // check for mint events (indicating liq add)
          if (context.state.lastTransactionBlockMint[factory.address] == undefined) {                        
            context.state.lastTransactionBlockMint[factory.address] = currentBlockNumber - window_size
          }
          if (currentBlockNumber - context.state.lastTransactionBlockMint[factory.address] > window_size)
          {
            context.state.lastTransactionBlockMint[factory.address] = currentBlockNumber - window_size
          }
          //console.log(context.state.lastTransactionBlockMint[factory.address])
          //console.log('getting mint events from',context.state.lastTransactionBlockMint[factory.address],'to',currentBlockNumber,'#blocks',currentBlockNumber - context.state.lastTransactionBlockMint[factory.address])
          let mintEvents = await lpContract.getPastEvents('Mint', {fromBlock: context.state.lastTransactionBlockMint[factory.address]})                    
          //console.log('mint events:')
          //console.log(mintEvents)
          if (context.state.chain == 'bsc') { await sleep(1000) } // added this because started getting limit exceeded errors
          let lastTXIndex = mintEvents.findIndex(tx => tx.transactionHash == context.state.lastTransactionBlockMint[factory.address])
          if (lastTXIndex != -1) {
                while(lastTXIndex-- >= 0) {
                  mintEvents.shift()
            }
          }
          if (mintEvents.length > txListLimit) {
            mintEvents = mintEvents.slice(mintEvents.length - txListLimit, mintEvents.length)
          }

          // check for burn events (indicating liq remove)
          if (context.state.lastTransactionBlockBurn[factory.address] == undefined) {                        
            context.state.lastTransactionBlockBurn[factory.address] = currentBlockNumber - window_size
          }
          if (currentBlockNumber - context.state.lastTransactionBlockBurn[factory.address] > window_size)
          {
            context.state.lastTransactionBlockBurn[factory.address] = currentBlockNumber - window_size
          }
          //console.log(context.state.lastTransactionBlockBurn[factory.address])
          //console.log('getting burn events from',context.state.lastTransactionBlockBurn[factory.address],'to',currentBlockNumber,'#blocks',currentBlockNumber - context.state.lastTransactionBlockBurn[factory.address])
          let burnEvents = await lpContract.getPastEvents('Burn', {fromBlock: context.state.lastTransactionBlockBurn[factory.address]})          
          //console.log('burn events:')
          //console.log(burnEvents)
          if (context.state.chain == 'bsc') { await sleep(1000) } // added this because started getting limit exceeded errors
          lastTXIndex = burnEvents.findIndex(tx => tx.transactionHash == context.state.lastTransactionBlockBurn[factory.address])
          if (lastTXIndex != -1) {
                while(lastTXIndex-- >= 0) {
                  burnEvents.shift()
            }
          }
          if (burnEvents.length > txListLimit) {
            burnEvents = burnEvents.slice(burnEvents.length - txListLimit, burnEvents.length)
          }
          

          if (context.state.lastTransactionBlock[factory.address] == undefined) {                        
            context.state.lastTransactionBlock[factory.address] = currentBlockNumber - window_size
          }
          if (currentBlockNumber - context.state.lastTransactionBlock[factory.address] > window_size)
          {
            context.state.lastTransactionBlock[factory.address] = currentBlockNumber - window_size
          }
          //console.log(context.state.lastTransactionBlock[factory.address])
          //console.log('getting swap events from',context.state.lastTransactionBlock[factory.address],'to',currentBlockNumber,'#blocks',currentBlockNumber - context.state.lastTransactionBlock[factory.address])
          let events = await lpContract.getPastEvents('Swap', {fromBlock: context.state.lastTransactionBlock[factory.address]})
          //console.log('swap events:')
          //console.log(events)
          if (context.state.chain == 'bsc') { await sleep(1000) } // added this because started getting limit exceeded errors
          // since we probably backed up a little bit, there will be duplicate transactions
          // remove them, they are already on the page
          lastTXIndex = events.findIndex(tx => tx.transactionHash == context.state.lastTransactionID[factory.address])
          if (lastTXIndex != -1) {
                while(lastTXIndex-- >= 0) {
                events.shift()
            }
          }
          // trim in case 1000 blocks ago returned way too many tx's
          if (events.length > txListLimit) {
            events = events.slice(events.length - txListLimit, events.length)
          }

          let thisIterTxnsByHash = {} // contains txhashes of swap events for latest check
        
          if (events.length) {
            //console.log('!! events !!')
            //console.log(events)
            context.state.lastTransactionBlock[factory.address] = events[events.length-1].blockNumber - 10
            context.state.lastTransactionID[factory.address] = events[events.length - 1].transactionHash
            for (let event of events) {
                let txhash = event.transactionHash
                if (new_txns_by_hash[txhash.toLowerCase()] || txn_indexes_by_hash[txhash.toLowerCase()]) {
                  //console.log('skipping txn:')
                  //console.log(new_txns_by_hash[txhash.toLowerCase()])
                  //console.log(txn_indexes_by_hash[txhash.toLowerCase()])
                  continue
                }
                thisIterTxnsByHash[txhash.toLowerCase()] = true
                let quoteAmountOut = parseInt(quoteIdxIs0 ? event.returnValues.amount0Out : event.returnValues.amount1Out) / (10 ** parseInt(context.state.tokenDecimals));
                let quoteAmountIn = parseInt(quoteIdxIs0 ? event.returnValues.amount0In : event.returnValues.amount1In)/ (10 ** parseInt(context.state.tokenDecimals));
                let baseAmountOut = parseInt(quoteIdxIs0 ? event.returnValues.amount1Out : event.returnValues.amount0Out) / (10 ** parseInt(baseDecimals));
                let baseAmountIn = parseInt(quoteIdxIs0 ? event.returnValues.amount1In : event.returnValues.amount0In) / (10 ** parseInt(baseDecimals)); // unused, left for reference / symmetry
                let isSell = quoteAmountOut == "0"
                var txValue = isSell ? context.state.masterTokenPrice * baseAmountOut : context.state.masterTokenPrice * baseAmountIn;
                
                let txDate = new Date()
                if (context.state.estimateTxTimes) {
                  txDate = this.estimateTxTime(context, currentBlockNumber, event.blockNumber, now_ts)
                }
                
                let newTX = {
                  swapID: event.id,
                  isArbitrage: false,
                  arbPools: '',
                  isSell: isSell,
                  blockNumber: event.blockNumber,
                  id: event.transactionHash + factory.name + event.id,
                  txHash: event.transactionHash,
                  value: txValue,
                  token: context.state.tokenSymbol,
                  tokenAmount: isSell ? quoteAmountIn : quoteAmountOut,
                  pool: factory.name,
                  date: txDate,
                  txType: 'Swap',
                  quoteToken: pool.quoteToken.address,
                }
                allNewTransactions.push(newTX);
                context.state.currentVolumeAmt += newTX.value;
                context.state.tokenVolume += newTX.value
                //if (!context.state.transactions.find(tx => tx.id == newTX.id)) {
                  

                  //if(newTX.isSell){
                  //    context.state.transactionsNetSell = context.state.transactionsNetSell + newTX.value;
                  //}else{
                  //    context.state.transactionsNetBuy = context.state.transactionsNetBuy + newTX.value;
                  //}
                //}
            }

            let txhashes_seen = {}
            for (let event of mintEvents) {
                // mint events by the lp indicate this may be a liq add event
                let txhash = event.transactionHash
                if (txhash in txhashes_seen) { continue }
                txhashes_seen[txhash] = true
                if (thisIterTxnsByHash[txhash.toLowerCase()] || new_txns_by_hash[txhash.toLowerCase()] || txn_indexes_by_hash[txhash.toLowerCase()]) {                                                                
                  continue
                }
                try {
                  let res = await this.getWalletAndDecodedInput(context, txhash)
                  if (res) {
                    console.log('!! mint event:')
                    console.log(res)
                    let walletAddress = res[0]
                    let functionName = res[1]
                    let txn = res[2]
                    let decodedInput = res[3]
                    if (functionName && functionName.toLowerCase().includes('addliquidity')) {
                        //let usdValue = BigInt(txn.value) / Math.power(10,18) * context.state.chainTokenPrice
                        console.log(functionName)
                        console.log(txn)
                        //console.log(decodedInput)
                        let receipt = await context.state.web3s[context.state.chain].eth.getTransactionReceipt(txhash)
                        console.log(receipt)
                        let decodedLogs = await this.decodeTransferLogs(receipt)
                        console.log(decodedLogs)
                        let liq_added_usd = null
                        let tokenAmount = null
                        for (let log of decodedLogs) {
                          if (log.decoded) {
                            if (log.decoded.to.toLowerCase() == pool.contract.toLowerCase() && log.address.toLowerCase() == context.state.token.toLowerCase()) {
                                tokenAmount = Number(BigInt(log.decoded.value) * 100000000n / BigInt(Math.pow(10, context.state.tokenDecimals))) / 100000000
                                liq_added_usd = 2.0 * tokenAmount * context.state.tokenPrice
                                console.log('$',liq_added_usd, 'added')
                                break
                            }
                          }
                        }

                        let txDate
                        if (event.blockNumber in blocks) {
                          txDate = blocks[event.blockNumber].timestamp * 1000
                        } else {
                          let block
                          try {
                            block = await context.state.web3s[context.state.chain].eth.getBlock(event.blockNumber)
                          } catch (e) { console.log(e) }
                          console.log('fetched block:')
                          console.log(block)
                          if (block && block.timestamp) {
                            blocks[event.blockNumber] = block
                            txDate = block.timestamp * 1000
                          } else {
                            txDate = this.estimateTxTime(context, currentBlockNumber, event.blockNumber, now_ts-1)
                          }
                        }                                        
                        //let txDate = new Date()
                        //if (context.state.estimateTxTimes) {
                        //    txDate = Services.estimateTxTime(context, currentBlockNumber, event.blockNumber, now_ts-1)
                        //}
                        if (liq_added_usd) {
                          let newTX = {
                            swapID: event.id,
                            isArbitrage: false,
                            arbPools: '',
                            isSell: false,
                            blockNumber: event.blockNumber,
                            id: event.transactionHash + factory.name + event.id,
                            txHash: event.transactionHash,
                            value: liq_added_usd,
                            token: context.state.tokenSymbol,
                            tokenAmount: tokenAmount,
                            pool: factory.name,
                            date: txDate,
                            txType: 'Add Liq',
                            walletAddress: walletAddress,
                            quoteToken: pool.quoteToken.address,
                          }
                          allNewTransactions.push(newTX);
                          context.state.currentVolumeAmt += newTX.value
                          if (context.state.liquidityUpdated && txDate > context.state.liquidityUpdated) {
                            context.state.tokenLiquidity += liq_added_usd
                          }
                        }
                    } else {
                        console.log('!! unknown mint event')
                        console.log(functionName)
                        console.log(txn)
                    }
                  }
                } catch (e) {
                  console.log(e)
                }
            }

            txhashes_seen = {}
            for (let event of burnEvents) {
                // mint events by the lp indicate this may be a liq add event
                let txhash = event.transactionHash
                if (txhash in txhashes_seen) { continue }
                txhashes_seen[txhash] = true
                if (thisIterTxnsByHash[txhash.toLowerCase()] || new_txns_by_hash[txhash.toLowerCase()] || txn_indexes_by_hash[txhash.toLowerCase()]) {                                                                
                  continue
                }
                try {
                  let res = await this.getWalletAndDecodedInput(context, txhash)
                  if (res) {
                    console.log('!! burn event:')
                    console.log(res)
                    let walletAddress = res[0]
                    let functionName = res[1]
                    let txn = res[2]
                    let decodedInput = res[3]
                    if (functionName && functionName.toLowerCase().includes('removeliquidity')) {
                        //let usdValue = BigInt(txn.value) / Math.power(10,18) * context.state.chainTokenPrice
                        console.log(functionName)
                        console.log(txn)
                        //console.log(decodedInput)
                        let receipt = await context.state.web3s[context.state.chain].eth.getTransactionReceipt(txhash)
                        console.log(receipt)
                        let decodedLogs = await this.decodeTransferLogs(receipt)
                        console.log(decodedLogs)
                        let liq_removed_usd = null
                        let tokenAmount = null
                        for (let log of decodedLogs) {
                          if (log.decoded) {
                            if (log.decoded.from.toLowerCase() == pool.contract.toLowerCase() && log.address.toLowerCase() == context.state.token.toLowerCase()) {
                                tokenAmount = Number(BigInt(log.decoded.value) * 100000000n / BigInt(Math.pow(10, context.state.tokenDecimals))) / 100000000
                                liq_removed_usd = 2.0 * tokenAmount * context.state.tokenPrice
                                console.log('$',liq_removed_usd,'removed')
                                break
                            }
                          }
                        }
                        let txDate
                        if (event.blockNumber in blocks) {
                          txDate = blocks[event.blockNumber].timestamp * 1000
                        } else {
                          let block
                          try {
                            block = await context.state.web3s[context.state.chain].eth.getBlock(event.blockNumber)
                          } catch (e) { console.log(e) }
                          console.log('fetched block:')
                          console.log(block)
                          if (block && block.timestamp) {
                            blocks[event.blockNumber] = block
                            txDate = block.timestamp * 1000
                          } else {
                            txDate = this.estimateTxTime(context, currentBlockNumber, event.blockNumber, now_ts-1)
                          }
                        }
                        //let txDate = new Date()
                        //if (context.state.estimateTxTimes) {
                        //    txDate = Services.estimateTxTime(context, currentBlockNumber, event.blockNumber, now_ts-1) // offset liq events by a small amount to make sure they're shown earlier than swaps in same block
                        //}
                        if (liq_removed_usd) {
                          let newTX = {
                            swapID: event.id,
                            isArbitrage: false,
                            arbPools: '',
                            isSell: true,
                            blockNumber: event.blockNumber,
                            id: event.transactionHash + factory.name + event.id,
                            txHash: event.transactionHash,
                            value: liq_removed_usd,
                            token: context.state.tokenSymbol,
                            tokenAmount: tokenAmount,
                            pool: factory.name,
                            date: txDate,
                            txType: 'Remove Liq',
                            walletAddress: walletAddress,
                            quoteToken: pool.quoteToken.address,
                          }
                          allNewTransactions.push(newTX);
                          context.state.currentVolumeAmt += newTX.value
                          if (context.state.liquidityUpdated && txDate > context.state.liquidityUpdated) {
                            context.state.tokenLiquidity -= liq_removed_usd
                          }
                        }
                    } else {
                        console.log('!! unknown burn event')
                        console.log(functionName)
                        console.log(txn)
                    }
                  }
                } catch (e) {
                  if (e.toString().includes('too many requests') || e.toString().includes('Rate limit exceeded')) {
                    context.state.lastRpcError = Date.now()
                    context.state.rpcError = e.toString()
                  }
                  console.log(e)
                }
            }
          }
        }
      } catch (e) {
        rpc_errors = e
      }
        
      
      //console.log('txns computed in',Date.now()-s1)

      
      let arbResult = this.getArbTxns(allNewTransactions)
      let arbTransactions = arbResult[0]
      let arbTransactionHashes = arbResult[1]
      // remove arb transaction halves
      allNewTransactions = allNewTransactions.filter(tx => !arbTransactionHashes.includes(tx.txHash))
      // TODO: re-add newly assembled arb tx
      allNewTransactions = allNewTransactions.concat(arbTransactions)

      
  
      // since we are combining lists, sort by date
      //allNewTransactions = allNewTransactions.sort((tx1, tx2) => { return tx1.blockNumber -  tx2.blockNumber })
      let allTxns = context.state.transactions.concat(allNewTransactions)
      allTxns = allTxns.sort((tx1, tx2) => { return tx2.date -  tx1.date })
      allTxns = allTxns.slice(0,1000)

      //console.log(allNewTransactions.length)
      

      if (context.state.tokenSymbol != tokenSymbol) {
        context.state.getTransactionsRunning = false
        return
      }

      context.state.transactions = allTxns

      let txnsByHash = {} // indexed by txhash for fast lookups                    
      for (let txn of allTxns) {
        let txhash = txn.txHash.toLowerCase()
        if (!txnsByHash[txhash]) { txnsByHash[txhash] = [] }
        txnsByHash[txhash].push(txn)                        
      }
      context.state.transactionsByHash = txnsByHash

      // sum the buys & sells within last 100 min window
      let sumBuys = 0
      let sumSells = 0
      if (context.state.transactions) {
        for (let tx of context.state.transactions) {
          if (Date.now() - tx.date <= 100*60*1000)
          if (tx.isSell) {
            sumSells += tx.value
          } else {
            sumBuys += tx.value
          }
        }
      }
      context.state.transactionsNetSell = sumSells
      context.state.transactionsNetBuy = sumBuys

      // we only want to estimate date for the first batch, afterwards always take current time
      context.state.estimateTxTimes = false
		
		

		} catch (err) {
			 console.log('!! error in getTransactions')
			 console.log(err)                		 
		}
    if (rpc_errors) {
      console.log('!! error in getTransactions')
			 console.log(rpc_errors)                
			 if (rpc_errors.toString().includes('too many requests') || rpc_errors.toString().includes('limit exceeded')) {
				  context.state.lastRpcError = Date.now()
				  context.state.rpcError = rpc_errors.toString()
				  // back off for a bit
				  console.log('waiting for 30s before checking txns')
				  await sleep(30000)
			 }	

    }

		context.state.getTransactionsRunning = false
  } // getTransactions


  async processBogApiTxns(store, txn_list) {
    console.log(txn_list)
    var swapID = 0
    var allNewTransactions = []
    for (let tx of txn_list) {                        
        swapID += 1
        let this_factory_name
        if (tx.exchange_name) {
            this_factory_name = tx.exchange_name
        } else {
            this_factory_name = 'Unknown'
        }
        var base_lp_change, usd_value, price_usd, quoteToken     
        if (tx.t0 == store.state.token.toLowerCase()) {
            quoteToken = store.state.web3.utils.toChecksumAddress(tx.t1)
            base_lp_change = tx.t0_lp_change
            usd_value = tx.usd_value_t0
            price_usd = tx.price_usd_t0                
        } else {
            quoteToken = store.state.web3.utils.toChecksumAddress(tx.t0)
            base_lp_change = tx.t1_lp_change
            usd_value = tx.usd_value_t1
            price_usd = tx.price_usd_t1                
        }
        
        var isSell = base_lp_change > 0

        let tx_type = 'Swap'
        let chain = store.state.chain
        if ((chain == 'bsc' && tx.router_id == 13) || (chain == 'matic' && tx.router_id == 1532) || (chain == 'avax' && tx.router_id == 4441) || (chain == 'fantom' && tx.router_id == 2060))
        {
            tx_type = 'BogSwap'
        }
        else if (tx.fn_name) {
            if (tx.fn_name.toLowerCase().includes('addliquidity')) {                    
                tx_type = 'Add Liq'
                // volume is set to 0 in txn scanner db for liq events, so need to reconstruct
                usd_value = 2.0 * Math.abs(base_lp_change) * price_usd
                isSell = false
            }
            else if (tx.fn_name.toLowerCase().includes('removeliquidity')) {
                tx_type = 'Remove Liq'
                // volume is set to 0 in txn scanner db for liq events, so need to reconstruct
                usd_value = 2.0 * Math.abs(base_lp_change) * price_usd
                isSell = true
            }
        }

        if (usd_value <= 0) { continue }
        
        let newTX = {
            swapID: swapID,
            isArbitrage: false,
            arbPools: '',
            isSell: isSell,
            blockNumber: null,
            id: tx.txhash + this_factory_name + swapID.toString(),
            txHash: tx.txhash,
            value: usd_value,
            token: store.state.tokenSymbol,
            tokenAmount: Math.abs(base_lp_change),
            pool: this_factory_name,
            date: moment.unix(tx.timestamp).toDate(),
            walletAddress: tx.wallet_address,
            txType: tx_type,
            blockieColour1: get16Colour(tx.wallet_address[41]),
            blockieColour2: get16Colour(tx.wallet_address[40]),
            quoteToken: quoteToken,
        }
        allNewTransactions.push(newTX);
    }
    

    allNewTransactions = allNewTransactions.sort((tx1, tx2) => { return tx1.date -  tx2.date })
    return allNewTransactions
  }

  

  getArbTxns(allNewTransactions) {
    let arbTransactions = []
    let arbTransactionHashes = []
    // condense any arbitration into a single row
    let condensedTxns = {} // indexed by txhash
    for (let tx of allNewTransactions) {
        if (!(tx.txHash in condensedTxns)) {
            condensedTxns[tx.txHash] = []
        }
        condensedTxns[tx.txHash].push(tx)
    }
    for (let [txHash, sameHashTransactions] of Object.entries(condensedTxns)) {
        //let sameHashTransactions = allNewTransactions.filter(x => x.txHash == tx.txHash)
        if (sameHashTransactions.length > 1) {
            let sameHashPools = new Set(sameHashTransactions.map(x => x.pool))
            // Only combine ARB transactions, i.e. transactions that cross multiple pools
            // TODO: If we want to combine swapAndLiquify transactions into a single row, remove the following if statement
            if (sameHashPools.size > 1) {
                arbTransactionHashes.push(txHash)
                // create a new arb tx with the sum of the two halves
                // start with a workable object. we will overwrite values to make it correct
                let newArbTx = {...sameHashTransactions[0]}; // shallow copy
                if (sameHashTransactions[1].value > newArbTx.value) {
                    newArbTx = {...sameHashTransactions[1]} // shallow copy
                }
                let netValue = 0;
                let netTokenExchanged = 0;
                for (let tx of sameHashTransactions) {
                    netValue += (tx.isSell ? -1 * tx.value : tx.value)
                    netTokenExchanged += (tx.isSell ? -1 * tx.tokenAmount : tx.tokenAmount)
                }
                // only mark tx's that span multiple POOLS as arbs
                // this is necessary if we decide to combine non-arb swapAndLiquify events
                newArbTx.isArbitrage = sameHashPools.size > 1
                //newArbTx.isArbitrage = false
        
                newArbTx.isSell = netValue < 0
                newArbTx.arbPools = sameHashTransactions[0].pool + " - " + sameHashTransactions[1].pool
        
                newArbTx.value = Math.abs(netValue)
                newArbTx.tokenAmount = Math.abs(netTokenExchanged)
                newArbTx.txType = 'Arbitrage'
                arbTransactions.push(newArbTx)
            }
        }
    }
    return [arbTransactions, arbTransactionHashes]
  }


  estimateTxTime(store, currentBlockNumber, eventBlockNumber, now_ts) {
		let blockCount = currentBlockNumber - eventBlockNumber
		if (blockCount == 0) {
			return now_ts
		} else if (blockCount < 0) {
			return Date.now()
		}
		let secondsDiffThisBlock
		if (store.state.chain == 'bsc') {
			secondsDiffThisBlock= blockCount * 3
		} else if (store.state.chain == 'polygon') {
			secondsDiffThisBlock= blockCount * 2
		} else if (store.state.chain == 'avax') {
			secondsDiffThisBlock= blockCount * 3
		} else if (store.state.chain == 'ftm') {
			secondsDiffThisBlock= blockCount * 1
		} else if (store.state.chain == 'csc') {
			secondsDiffThisBlock= blockCount * 3
		} 
		//let txDate = moment().subtract(secondsDiffThisBlock, 'seconds').toDate()
		let txDate = now_ts - (secondsDiffThisBlock * 1000)
		return txDate
	}


	async getWalletAndDecodedInput(store, txhash) {
		try {
			 let txn = await store.state.web3s[store.state.chain].eth.getTransaction(txhash)
			 if (!txn) {
				  return null
			 }
			 let walletAddress = txn.from.toLowerCase()                                  
			 const decodedInput = store.state.decoder.decodeData(txn.input);
			 //console.log(decodedInput)
			 let fn_name = decodedInput.method
			 return [walletAddress, fn_name, txn, decodedInput]            
		} catch (err) {
			 console.log(err)
			 return null
		}
  }

  async getWalletFromTxhash(store, txhash) {
		try {
			 let txn = await store.state.web3s[store.state.chain].eth.getTransaction(txhash)
			 if (!txn) {
				  return null
			 }
			 let walletAddress = txn.from.toLowerCase()            
			 return walletAddress
		} catch (err) {
			 console.log(err)
			 return null
		}
  }
  
  async decodeTransferLogs(receipt) {
		const transfer_event = {"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"from","type":"address"},{"indexed":true,"internalType":"address","name":"to","type":"address"},{"indexed":false,"internalType":"uint256","name":"value","type":"uint256"}],"name":"Transfer","type":"event"}
		let event_sigs_transfer = {}
		event_sigs_transfer[Web3EthAbi.encodeEventSignature(transfer_event)] = transfer_event
		let decoded_logs = []
		for (let log of receipt.logs) {
			 //console.log(log)
		 let decoded_event_names = []
		 //log.topics = [log.topic0, log.topic1, log.topic2, log.topic3]
		 if (log.topics && log.topics.length >= 1) {
			 let topic = log.topics[0]				
			 if (topic in event_sigs_transfer) {
				 let descriptor = event_sigs_transfer[topic]
				 decoded_event_names.push(descriptor.name)					
				 try {
					 let decoded = Web3EthAbi.decodeLog(
						 descriptor.inputs,
						 log.data,
						 log.topics.slice(1)
					 )
					 decoded.name = descriptor.name
					 log.decoded = decoded
							 decoded_logs.push(log)
				 } catch (e) {
							 console.log(e)
					 //errored.push(log)
				 }
				 
			 }				
		 }
	 }
		return decoded_logs
  }
}




export const TradebookLib = new _TradebookLib();
