<template>
  <div
    v-if="$store.state.tokenWalletValue && $store.state.showBalance"
    class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4"
  >
    <span class="dark:bg-gray-500 bg-gray-300 p-2 md:block hidden dark:bg-opacity-20 bg-opacity-20 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
          clip-rule="evenodd"
        />
        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
      </svg>
    </span>
    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        >{{ $t("charts.components.topbar.bal") }}
        <div class="whitespace-normal">
          <InformationCircleIcon
            v-tippy="{
              placement: 'bottom',
              content: 'Total Tokens: ' + $store.state.tokenWalletValue.toFixed(2).toLocaleString('en-US'),
              allowHTML: true,
              appendTo: 'parent',
              theme: 'bog',
              animation: 'shift-toward-subtle',
              arrow: false,
              maxWidth: '350px',
            }"
            class="h-4 w-4 text-gray-500 ml-1"
            aria-hidden="true"
          /></div
      ></span>
      <h4 class="dark:text-white w-full text-gray-900 text-sm md:text-base 2xl:text-lg">
        {{ price(Math.floor($store.state.tokenWalletValue)) }}
      </h4>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
