<template>
  <div class="px-4">
    <Popover class="relative">
      <PopoverButton :class="open ? '' : 'text-opacity-90'" class="flex flex-row  items-center text-gray-500 text-base font-normal hover:text-gray-300 transition-colors duration-200">
        Trade
        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 transform h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel class="absolute z-10 w-screen max-w-sm px-4 mt-2 transform sm:px-0 lg:max-w-3xl">
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-gray-50 ring-opacity-5">
            <div class="relative grid gap-8 bg-gray-900 p-7 lg:grid-cols-2">
              <a
                v-for="item in solutions"
                :key="item.name"
                :href="item.href"
                target="_blank"
                class="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-secondary hover:bg-opacity-10 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
              >
                <div class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                  <div v-html="item.icon"></div>
                </div>
                <div class="ml-4">
                  <p class="text-sm font-medium text-secondary">
                    {{ item.name }}
                  </p>
                  <p class="text-sm text-gray-600">
                    {{ item.description }}
                  </p>
                </div>
              </a>
            </div>
            <div class="p-4 bg-gray-800">
              <a
                target="_blank"
                href="https://www.bogged.finance/academy"
                class="flow-root p-4 transition duration-150 ease-in-out rounded-md hover:bg-gray-900 hover:bg-opacity-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
              >
                <span class="flex items-center">
                  <span class="text-sm font-medium text-gray-200">
                    Lean how to use our tools
                  </span>
                </span>
                <span class="block text-sm text-gray-400">
                  Go to BogAcademy
                </span>
              </a>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronDownIcon, ViewGridIcon } from "@heroicons/vue/solid";
export default {
  data: function() {
    return {
      open: false,
    };
  },
  components: { Popover, PopoverButton, PopoverPanel, ChevronDownIcon, ViewGridIcon },
  setup() {
    return {
      solutions: [
        {
          name: "Dex Aggregator",
          description: "Get the best price possible",
          href: "https://app.bogged.finance/bsc/swap?tokenIn=BNB&tokenOut",
          icon: `
<div class="p-2 bg-secondary bg-opacity-10 rounded-md">
  <svg width="48" height="48" class="h-6 w-6 fill-current text-secondary" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.35"
      d="M42.828 23.1719C41.986 22.3299 32.432 12.7739 29.684 10.0279L32.698 7.0139C33.788 5.9239 33.022 4.0599 31.48 4.0519L20.52 3.9999C19.126 3.9939 17.994 5.1259 18 6.5199L18.052 17.4799C18.06 19.0199 19.924 19.7879 21.014 18.6979L24.028 15.6839C26.776 18.4319 36.33 27.9859 37.172 28.8279C38.734 30.3899 41.266 30.3899 42.828 28.8279C44.39 27.2659 44.39 24.7339 42.828 23.1719Z"
    />
    <path
      d="M5.17199 24.828C6.01399 25.67 15.568 35.226 18.316 37.9721L15.302 40.9861C14.212 42.0761 14.98 43.9401 16.52 43.9481L27.48 44.0001C28.874 44.0061 30.006 42.8741 30 41.4801L29.948 30.5201C29.94 28.9781 28.076 28.212 26.986 29.3021L23.972 32.3161C21.224 29.5681 11.67 20.014 10.828 19.172C9.26599 17.61 6.73399 17.61 5.17199 19.172C3.60999 20.734 3.60999 23.266 5.17199 24.828Z"
    />
  </svg>
</div>

`,
        },
        {
          name: "Stop Loss",
          description: "Prevent losses with ease",
          href: "https://app.bogged.finance/bsc/stoploss",
          icon: `
<div class="p-2 bg-secondary bg-opacity-10 rounded-md">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="h-6 w-6 fill-current text-secondary">
    <path d="M17 21H7a3 3 0 01-3-3V6a3 3 0 013-3h7l6 6v9a3 3 0 01-3 3z" opacity=".35"></path>
    <path
      d="M14 7V3l6 6h-4a2 2 0 01-2-2zM23 24h-7a1 1 0 01-1-1v-7a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1zM16 13h1.481l.3-.375a1 1 0 00-1.562-1.25l-1.567 1.96A2.961 2.961 0 0116 13zM12.985 15.419l-1.716-2.06a1.006 1.006 0 00-.757-.359c-.319.01-.573.12-.765.342l-3.5 4a1 1 0 001.506 1.316l2.729-3.118 1.75 2.101c.19.226.459.398.768.359l-.015-2.581z"
    ></path>
  </svg>
</div>
`,
        },
        {
          name: "Limit Order",
          description: "Take better control of your trades",
          href: "https://app.bogged.finance/bsc/trade",
          icon: `
<div class="p-2 bg-secondary bg-opacity-10 rounded-md">
  <svg width="48" height="48" class="h-6 w-6 fill-current text-secondary" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.35" d="M34 42H14C10.686 42 8 39.314 8 36V12C8 8.686 10.686 6 14 6H28L40 18V36C40 39.314 37.314 42 34 42Z" />
    <path d="M28 14V6L40 18H32C29.79 18 28 16.21 28 14Z" />
    <path d="M46.822 39.788L32.894 46.752C31.564 47.418 30 46.45 30 44.964V31.036C30 29.55 31.564 28.582 32.894 29.248L46.822 36.212C48.296 36.948 48.296 39.052 46.822 39.788Z" />
    <path
      d="M31.9999 25.034C32.9219 25.034 33.8499 25.254 34.6819 25.67L35.0699 25.864L35.5599 25.25C36.2499 24.388 36.1099 23.13 35.2479 22.438C34.3839 21.746 33.1299 21.89 32.4359 22.75L30.4199 25.272C30.9279 25.132 31.4499 25.034 31.9999 25.034Z"
    />
    <path
      d="M26.028 30.766L25.97 30.838L22.538 26.718C22.162 26.27 21.61 26.008 21.024 26C20.386 26.02 19.878 26.24 19.494 26.684L12.494 34.684C11.768 35.514 11.852 36.778 12.684 37.506C13.062 37.838 13.532 38 14 38C14.556 38 15.11 37.77 15.506 37.316L20.964 31.08L24.464 35.282C24.844 35.734 25.382 36.078 26 36V31.036C26 30.944 26.024 30.858 26.028 30.766Z"
    />
  </svg>
</div>
`,
        },
        {
          name: "Launch Sniper",
          description: "Be the first to buy a launch",
          href: "https://app.bogged.finance/bsc/sniper",
          icon: `
<div class="p-2 bg-secondary bg-opacity-10 rounded-md">
  <svg width="48" height="48" class="h-6 w-6 fill-current text-secondary" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.35"
      d="M24 4.00006C12.954 4.00006 4 12.9541 4 24.0001C4 35.0461 12.954 44.0001 24 44.0001C35.046 44.0001 44 35.0461 44 24.0001C44 12.9541 35.046 4.00006 24 4.00006ZM24 38.0001C16.268 38.0001 10 31.7321 10 24.0001C10 16.2681 16.268 10.0001 24 10.0001C31.732 10.0001 38 16.2681 38 24.0001C38 31.7321 31.732 38.0001 24 38.0001Z"
    />
    <path d="M28 5.99994C28 3.78994 26.21 1.99994 24 1.99994C21.79 1.99994 20 3.78994 20 5.99994C20 6.34794 20 11.6519 20 11.9999C20 14.2099 21.79 15.9999 24 15.9999C26.21 15.9999 28 14.2099 28 11.9999C28 11.6519 28 6.34794 28 5.99994Z" />
    <path d="M28 35.9999C28 33.7899 26.21 31.9999 24 31.9999C21.79 31.9999 20 33.7899 20 35.9999C20 36.3479 20 41.6519 20 41.9999C20 44.2099 21.79 45.9999 24 45.9999C26.21 45.9999 28 44.2099 28 41.9999C28 41.6519 28 36.3479 28 35.9999Z" />
    <path
      d="M42 27.9999C44.21 27.9999 46 26.2099 46 23.9999C46 21.7899 44.21 19.9999 42 19.9999C41.652 19.9999 36.348 19.9999 36 19.9999C33.79 19.9999 32 21.7899 32 23.9999C32 26.2099 33.79 27.9999 36 27.9999C36.348 27.9999 41.652 27.9999 42 27.9999Z"
    />
    <path d="M12 27.9999C14.21 27.9999 16 26.2099 16 23.9999C16 21.7899 14.21 19.9999 12 19.9999C11.652 19.9999 6.348 19.9999 6 19.9999C3.79 19.9999 2 21.7899 2 23.9999C2 26.2099 3.79 27.9999 6 27.9999C6.348 27.9999 11.652 27.9999 12 27.9999Z" />
  </svg>
</div>

`,
        },
        {
          name: "Cross-Chain Bridge",
          description: "Bridge from one chain to another",
          href: "https://app.bogged.finance/bsc/bridge",
          icon: `
<div class="p-2 bg-secondary bg-opacity-10 rounded-md">
<svg width="48" height="48" class="h-6 w-6 fill-current text-secondary" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1081_1281)">
<path opacity="0.35" d="M8.2 30.6C6.2 30.6 4.6 29.2 4.2 27.2C4 26.2 4 25 4 24C4 13 13 4 24 4C27.6 4 31 5 34 6.6C36 7.8 36.6 10.2 35.4 12C34.4 14 32 14.8 30 13.6C28.2 12.6 26.2 12 24 12C17.4 12 12 17.4 12 24C12 24.6 12 25.2 12.2 25.8C12.6 28 11 30 8.8 30.4C8.6 30.4 8.4 30.6 8.2 30.6Z"/>
<path opacity="0.35" d="M24 44C20.4 44 17 43 14 41.4C12 40.2 11.4 37.8 12.6 36C13.6 34 16 33.2 18 34.4C19.8 35.4 21.8 36 24 36C30.6 36 36 30.6 36 24C36 23.6 36 23.2 36 22.6C35.8 20.4 37.4 18.4 39.6 18.2C41.8 18 43.8 19.6 44 21.8C44 22.6 44.2 23.2 44.2 24C44 35 35 44 24 44Z"/>
<path d="M1.8 24H14.2C15.8 24 16.6 25.8 15.6 27L10.6 33C9.2 34.6 6.8 34.6 5.4 33L0.400004 27C-0.599996 25.8 0.200004 24 1.8 24Z"/>
<path d="M46.2 24H33.8C32.2 24 31.4 22.2 32.4 21L37.4 15C38.8 13.4 41.2 13.4 42.6 15L47.6 21C48.6 22.2 47.8 24 46.2 24Z"/>
</g>
<defs>
<clipPath id="clip0_1081_1281">
<rect width="48" height="48" fill="white"/>
</clipPath>
</defs>
</svg>


</div>

`,
        },
      ],
    };
  },
};
</script>
