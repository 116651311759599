<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
    <linearGradient id="0UBLLQYZhj60seIoxRE38a" x1="6.414" x2="41.774" y1="13.662" y2="39.488" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#28afea" />
      <stop offset="1" stop-color="#047ed6" />
    </linearGradient>
    <path fill="url(#0UBLLQYZhj60seIoxRE38a)" d="M6,40V12h36v28c0,1.1-0.9,2-2,2H8C6.9,42,6,41.1,6,40z" />
    <path
      d="M32.172,18.172L22,28.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,18.172	C34.219,17.391,32.953,17.391,32.172,18.172z"
      opacity=".05"
    />
    <path
      d="M20.939,35.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,29.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,35.646,21.525,35.646,20.939,35.061z"
      opacity=".07"
    />
    <path
      fill="#fff"
      d="M21.293,34.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,29.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,35.098,21.683,35.098,21.293,34.707z"
    />
    <linearGradient id="0UBLLQYZhj60seIoxRE38b" x1="6.42" x2="41.198" y1="6.608" y2="13.68" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#0077d2" />
      <stop offset="1" stop-color="#0b59a2" />
    </linearGradient>
    <path fill="url(#0UBLLQYZhj60seIoxRE38b)" d="M42,8v6H6V8c0-1.1,0.9-2,2-2h32C41.1,6,42,6.9,42,8z" />
    <path d="M35.5,12.5L35.5,12.5c-1.657,0-3-1.343-3-3V6h6v3.5C38.5,11.157,37.157,12.5,35.5,12.5z" opacity=".05" />
    <path d="M35.5,11.75L35.5,11.75c-1.243,0-2.25-1.007-2.25-2.25V6h4.5v3.5	C37.75,10.743,36.743,11.75,35.5,11.75z" opacity=".07" />
    <path fill="#3ccbf4" d="M35.5,11L35.5,11c-0.828,0-1.5-0.672-1.5-1.5v-4C34,4.672,34.672,4,35.5,4l0,0	C36.328,4,37,4.672,37,5.5v4C37,10.328,36.328,11,35.5,11z" />
    <path d="M12.5,12.5L12.5,12.5c-1.657,0-3-1.343-3-3V6h6v3.5C15.5,11.157,14.157,12.5,12.5,12.5z" opacity=".05" />
    <path d="M12.5,11.75L12.5,11.75c-1.243,0-2.25-1.007-2.25-2.25V6h4.5v3.5	C14.75,10.743,13.743,11.75,12.5,11.75z" opacity=".07" />
    <path fill="#3ccbf4" d="M12.5,11L12.5,11c-0.828,0-1.5-0.672-1.5-1.5v-4C11,4.672,11.672,4,12.5,4h0	C13.328,4,14,4.672,14,5.5v4C14,10.328,13.328,11,12.5,11z" />
  </svg>
</template>
