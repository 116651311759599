<template>
  <div class="flex flex-row w-full justify-between items-center group grabbable">
    <div class="flex flex-row items-center space-x-2">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-600 group-hover:text-gray-200 duration-200 transition-colors" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
      </svg>
      <span class="dark:text-gray-200 text-gray-700 text-sm">Social Score</span>
    </div>
    <Switch
      @click="toggleSocialScore"
      :class="[
        $store.state.showSocialScore ? 'bg-secondary' : 'dark:bg-gray-700 bg-gray-200',
        'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary',
      ]"
    >
      <span
        :class="[$store.state.showSocialScore ? 'translate-x-5 bg-white' : 'translate-x-0 bg-gray-400', 'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200']"
      >
      </span>
    </Switch>
  </div>
</template>

<script>
import { Switch } from "@headlessui/vue";

export default {
  components: {
    Switch,
  },
  created: function() {
    this.showSocialScore = this.$store.state.showSocialScore;
  },

  methods: {
    toggleSocialScore: function() {
      this.$store.commit("toggleSocialScore");
    },
  },
};
</script>
