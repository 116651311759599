<template>
  <div
    class="py-2 px-3 my-2 mx-2 dark:bg-gray-800 bg-gray-200 rounded-xl text-xs"
    v-bind:class="{
      'text-gray-500': isArbitrage,
      'dark:text-secondary text-secondary-dark hover:bg-secondary dark:hover:bg-secondary dark:hover:text-secondary hover:bg-opacity-30 dark:hover:bg-opacity-20 he-bought': !isSell && !isArbitrage,
      'dark:text-error-bright text-error hover:bg-error-bright dark:hover:bg-error dark:hover:text-error-bright hover:bg-opacity-30 dark:hover:bg-opacity-20 he-sold': isSell && !isArbitrage,
    }"
  >
    <a target="_blank" :href="'https://' + $store.state.blockExplorer + '/tx/' + txHash">
      <div class="flex flex-row justify-between w-full items-center mb-1.5">
        <div class="space-x-1">
          <span class="text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300">{{ shortFromNow }}</span>
          <span class="text-2xs py-0.5 px-1.5 rounded-md dark:bg-gray-900 bg-gray-300 bg-opacity-50 text-gray-700 dark:text-gray-300"> {{ pool }}</span>
          <span v-bind:class="{ 'dark:text-secondary bg-secondary text-secondary': pctChange >= 0, 'dark:text-error-bright bg-error-bright text-error-bright': pctChange < 0 }" class="text-2xs py-0.5 px-1.5 rounded-md bg-opacity-10"
            >{{ pctChange }}%</span
          >
        </div>
        <div class="ml-2 flex flex-row space-x-1">
          <div v-if="!isSell" :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'" class="w-4 h-1.5 rounded-sm"></div>
          <div v-else :class="value < 1000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'" class="w-4 h-1.5 rounded-sm"></div>
          <div v-if="!isSell" :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'" class="w-4 h-1.5 rounded-sm"></div>
          <div v-else :class="value < 5000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'" class="w-4 h-1.5 rounded-sm"></div>
          <div v-if="!isSell" :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-secondary bg-secondary-dark'" class="w-4 h-1.5 rounded-sm"></div>
          <div v-else :class="value < 10000 ? 'dark:bg-gray-700 bg-gray-300' : 'dark:bg-error-bright bg-error'" class="w-4 h-1.5 rounded-sm"></div>
        </div>
      </div>

      <div class="flex flex-row justify-between w-full items-center">
        <div v-if="!isArbitrage">
          <span v-if="!isSell && !isArbitrage" class="dark:text-gray-400 text-gray-600">Bought</span>
          <span v-if="isSell && !isArbitrage" class="dark:text-gray-400 text-gray-600">Sold</span>
          {{ bogintific(tokenAmount) }}
          <span class="dark:text-gray-400 text-gray-600">for</span>
          {{ priceMinDigits(value, 3) }}
          <span class="dark:text-gray-400 text-gray-600">(${{ maxDigits(value / tokenAmount, 3) }} ea.)</span>
        </div>
        <div v-else>
          ARB
        </div>
      </div></a
    >
  </div>
</template>

<script>
import moment from "moment";
import { maxDigits, priceMinDigits, bogintific } from "@/filters";
export default {
  props: ["token", "tokenAmount", "isSell", "date", "value", "id", "pool", "txHash", "isArbitrage", "arbPools", "pctChange"],
  setup() {},
  data: function() {
    return {
      fromNow: "",
      intervalID: null,
      cellStyle: "px-4 py-2 whitespace-nowrap text-sm font-medium",
    };
  },
  methods: {
    maxDigits,
    priceMinDigits,
    bogintific,
  },
  computed: {
    shortFromNow() {
      return this.$data.fromNow
        .replace("in a few seconds", "Now")
        .replace("a few seconds ago", "Now")
        .replace(" seconds", "s")
        .replace(" minutes", "m")
        .replace(" minute", "m")
        .replace("am", "1m")
        .replace(" hours", "h")
        .replace("an hour", "1h")
        .replace("a day", "1d")
        .replace(" days", "d");
    },
  },
  async created() {
    // moment.relativeTimeThreshold('ss', 3);  // enable this if you want to show "5 seconds ago" etc
    window.moment = moment;
    let self = this; // to pass to setInterval
    // set initial fromNow
    let mo = moment(self.$props.date).fromNow();
    self.$data.fromNow = mo;
    // update it periodically
    this.$data.intervalID = setInterval(function() {
      let mo = moment(self.$props.date).fromNow();
      self.$data.fromNow = mo;
    }, 5000);
  },
};
</script>
