<template>
  <span
    v-bind:class="{
      'bg-purple-bright dark:text-purple-bright text-purple': chain === 'polygon',
      'bg-primary dark:text-primary text-primary-dark': chain === 'bsc',
      'bg-primary dark dark:text-primary text-primary-dark': chain === 'avax',
      'bg-primary dark dark:text-primary text-primary-dark': chain === 'ftm',
      'bg-blue-bright dark:text-blue-bright text-blue': chain === 'csc',
    }"
    class="rounded-md px-2 py-0.5 uppercase bg-opacity-20 dark:bg-opacity-10 text-2xs"
  >
    {{ chain }}
  </span>
</template>

<script>
export default {
  props: ["chain"],
};
</script>
