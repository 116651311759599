<template>
  <div id="tokenSearch" class="xl:relative h-full">
    <div class="hidden xl:flex justify-between h-full items-center relative" data-children-count="1">
      <div class="outline-none z-50 rounded-r-md absolute pl-4 transition-colors top-0 bg-transparent h-full flex items-center justify-center focus:outline-none focus:border-none cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-0 text-gray-400 dark:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>
      <input
        @keydown.enter="
          loadToken();
          closeSearch();
        "
        @click="openSearch();"
        onfocus="this.select();"
        class="w-full text-white rounded-lg transition-colors dark:bg-opacity-40 focus:ring-0 dark:focus:border-gray-600 focus:border-gray-300 absolute z-40 border-gray-200 border h-11 shadow-inner text-gray-700 bg-gray-200 dark:border-gray-700 dark:hover:border-gray-600 dark:bg-gray-900 text-xs dark:text-secondary-light placeholder-gray-500 dark:placeholder-gray-400 py-3 pr-4 pl-10"
        type="text"
        placeholder="Search the market or enter a token address"
        :value="token"
        @input="tokenChanged"
        :class="searchIsOpen ? 'border border-gray-300 focus:ring-0 focus:border-gray-300 dark:border-gray-600 dark:focus:border-gray-600 tokenSearchsearchIsOpen' : ''"
        autocomplete="off"
      />
      <button @click="openSearch();" class="outline-none z-40 rounded-r-md absolute right-4 transition-colors top-0 bg-transparent h-full flex items-center justify-center focus:outline-none focus:border-none cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-0 text-gray-500 dark:text-gray-300" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div class="xl:justify-start justify-end ml-3 items-center xl:hidden h-full flex mx-auto">
      <input
        v-if="searchIsOpen"
        @keydown.enter="
          loadToken();
          closeSearch();
        "
        onfocus="this.select();"
        
        class="w-full text-white left-0 transition-colors dark:bg-opacity-80 focus:ring-0 dark:focus:border-gray-600 focus:border-gray-300 absolute z-40 border-gray-200 border h-11 shadow-inner text-gray-700 bg-gray-100 dark:border-gray-700 dark:hover:border-gray-600 dark:bg-gray-900 text-xs dark:text-secondary-light placeholder-gray-500 dark:placeholder-gray-400 py-3 pr-4 pl-4"
        type="text"
        placeholder="Search the market or enter a token address"
        :value="token"
        @input="tokenChanged"
        :class="searchIsOpen ? 'border border-primary focus:ring-0 focus:border-primary' : ''"
        autocomplete="off"
      />
      <button
        v-if="searchIsOpen"
        @click="
          loadToken;
          openSearch;
        "
        class="outline-none z-40 rounded-r-md absolute right-4 transition-colors top-0 bg-transparent h-full flex items-center justify-center focus:outline-none focus:border-none cursor-pointer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-0 text-gray-400 dark:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </div>

    <transition name="fade">
      <div v-if="searchIsOpen" class="top-16 h-full z-40 absolute inset-0">
        <div style="backdrop-filter: blur(4px);" class="py-2 dark:bg-opacity-80 border border-gray-800 flex flex-col overflow-hidden dark:bg-gray-800 bg-white border-white xl:rounded-xl dark:border-gray-700 ">
          <div class="overflow-y-auto flex-auto h-96 w-full">
            <div class="w-full" v-if="!searching">
              <search-tabs />
            </div>

            <div class="h-full" v-if="searching">
              <div v-for="(item, i) in searchResults" :key="'search-search-' + i" class="font-heading relative py-1.5 flex flex-row justify-between dark:hover:bg-grey-100 dark:hover:bg-opacity-5 hover:bg-gray-100">
                <div class="space-x-3 w-full flex flex-row items-center pl-2 pr-4">
                  <toggleFavorite v-bind:address="item.address" v-bind:tokenImageUrl="item.tokenImageUrl" v-bind:tokenName="item.tokenName" />
                  <div class="w-full">
                    <router-link :to="'/?page=chart&c=' + item.chain + '&t=' + item.address">
                      <div
                        class="flex flex-row items-center space-x-3 cursor-pointer"
                        @click="
                          // reset tab to chart on token load:
                          this.$store.state.defaultTabIndex = 0;
                          this.$store.state.page = 'chart';                          
                          this.$store.state.rerenderTabs = true;
                          $store.dispatch('loadToken', item.address);
                          closeSearch();
                        "
                      >
                        <span class="text-xs w-full flex flex-row items-center justify-between dark:text-white whitespace-nowrap overflow-hidden">
                          <div class="flex flex-row items-center">
                            <img class="w-7 h-7 mr-3 rounded-full bg-gray-900" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
                            <div class="flex flex-col space-y-1 items-start">
                              <span class="flex flex-row items-center">
                                {{ maxLength(item.tokenName, 32) }}
                                <span v-if="item.tokenName.length > 32">...</span>
                                <!-- show verified badge -->
                                <span v-if="item.verified" class="text-secondary ml-1">
                                  <svg viewBox="0 0 24 24" fill="none" class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      opacity="0.35"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M8.991 3.89701C9.562 2.80501 10.869 1.98801 12.342 1.98801C13.815 1.98801 15.123 2.80401 15.693 3.89701C16.869 3.52801 18.37 3.87501 19.413 4.91701C20.455 5.95901 20.802 7.46001 20.433 8.63701C21.525 9.20801 22.342 10.515 22.342 11.988C22.342 13.461 21.526 14.768 20.433 15.339C20.802 16.515 20.455 18.016 19.413 19.059C18.371 20.101 16.87 20.448 15.693 20.079C15.122 21.171 13.815 21.988 12.342 21.988C10.869 21.988 9.562 21.172 8.991 20.079C7.815 20.448 6.313 20.101 5.271 19.059C4.229 18.017 3.882 16.515 4.251 15.339C3.159 14.768 2.342 13.461 2.342 11.988C2.342 10.515 3.158 9.20701 4.251 8.63701C3.882 7.46101 4.229 5.96001 5.271 4.91701C6.313 3.87501 7.815 3.52901 8.991 3.89701Z"
                                    />
                                    <path
                                      d="M11 16C10.744 16 10.488 15.902 10.293 15.707L7.293 12.707C6.902 12.316 6.902 11.684 7.293 11.293C7.684 10.902 8.316 10.902 8.707 11.293L11 13.586L15.293 9.29301C15.684 8.90201 16.316 8.90201 16.707 9.29301C17.098 9.68401 17.098 10.316 16.707 10.707L11.707 15.707C11.512 15.902 11.256 16 11 16Z"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <span
                                v-tippy="{
                                  content: item.address,
                                  appendTo: 'parent',
                                  theme: 'bog',
                                  animation: 'shift-toward-subtle',
                                  arrow: false,
                                  maxWidth: 'none',
                                }"
                                class=" text-2xs text-gray-400"
                                >{{ item.symbol }}</span
                              >
                            </div>
                          </div>
                          <div class="flex flex-row items-center space-x-4">
                            <span
                              v-html="bogintificPrice(item.volume)"
                              v-if="!(item.volume == undefined)"
                              v-tippy="{
                                content: '24hr Volume',
                                appendTo: 'parent',
                                theme: 'bog',
                                animation: 'shift-toward-subtle',
                                arrow: false,
                                maxWidth: 'none',
                              }"
                              class="text-2xs"
                              >
                            </span>

                            <span
                              v-bind:class="{
                                'bg-purple-bright dark:text-purple-bright text-purple': item.chain === 'polygon',
                                'bg-primary dark:text-primary text-primary-dark': item.chain === 'bsc',
                              }"
                              class="rounded-md px-2 py-0.5 uppercase bg-opacity-20 dark:bg-opacity-10 text-2xs"
                              >{{ item.chain }}</span
                            >
                          </div>
                        </span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-if="!searchResults.length" class="flex flex-col h-full w-full items-center place-content-center z-20">
                <img class="invert hue-rotate-180 filter" src="@/assets/emptystate/diamond.svg" />
                <span class="dark:text-gray-500 text-gray-300 mt-1 overline block">No search results</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="searchIsOpen" @click="closeSearch()" style="backdrop-filter: blur(1.2px);" class="top-0 fixed w-full left-0 h-full bg-gray-900 bg-opacity-60 z-30"></div>
  </div>
</template>

<script>
import router from "@/router";
import { bogintificPrice, maxLength } from "@/filters";
import toggleFavorite from "@/components/Favorite/toggleFavorite";
import searchTabs from "./searchTabs/searchTabs.vue";
import {searchAPI_URL} from "../../constants"

export default {
  components: {
    toggleFavorite,
    searchTabs,
  },
  data() {
    return {
      token: this.$store.state.token,
      searching: false,
      searchResults: [],
    };
  },
  created: function() {
    //set throttledseach function using lodash
    this.throttledSearch = _.throttle(this.search, 300);
  },
  methods: {
    maxLength,
    openSearch: function() {
      this.$store.commit("openSearch");
    },
    closeSearch: function() {
      this.$store.commit("closeSearch");
    },
    bogintificPrice,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    loadToken() {
      this.searching = false;
      this.token = this.token.trim();
      // reset tab to chart on token load:
      this.$store.state.defaultTabIndex = 0
      this.$store.state.page = 'chart'
      //Services.updateURL(this.$store)
      this.$store.state.rerenderTabs = true
      router.push("/?page=chart&c=" + this.$store.state.chain + "&t=" + this.token);
      this.$store.dispatch("loadToken", this.token);
    },
    tokenChanged(e) {
      this.token = e.target.value;

      if (this.token.trim() === "") {
        this.searching = false;
      } else {
        this.searching = true;
        //throttle input to search api
        this.throttledSearch(this.token);
      }
    },
    async search() {
      
      //const res = await fetch(`${searchAPI_URL}/search?filter=all&query=${this.token}`);
      const res = await fetch(`${searchAPI_URL}/search?query=${this.token}`);
      //--res data structure
      // {
      //   status: number (httpstatus number),
      //   data: [
      //     {
      //       address: string
      //       chain: string
      //       id: string
      //       name: string
      //       sortScore: number
      //       symbol: string
      //       volume_usd: number (currently 24hr volume)
      //     }
      //   ]
      // }
      let hits = (await res.json()).data || [];
      //map hits into valid search results
      this.searchResults = hits.map((hit) => {
        return {
          address: hit.address,
          tokenImageUrl: `https://static.bogged.finance/${hit.chain}/assets/${hit.address}/logo.png`,
          tokenName: hit.name,
          symbol: hit.symbol,
          chain: hit.chain === "matic" ? "polygon" : hit.chain,
          volume: hit.volume_usd,
          verified: hit.sortScore >= 10 ** 10,
        };
      });

      // add clickable result for address if none found
      if (this.token && this.searchResults.length == 0 && this.token.startsWith("0x") && this.token.length == 42) {
        try {
          let address = this.$store.state.web3.utils.toChecksumAddress(this.token)
          this.searchResults.push({
            address: this.token,
            //tokenImageUrl: `https://static.bogged.finance/${this.$store.state.chain}/assets/${this.token}/logo.png`,
            tokenImageUrl: `https://static.bogged.finance/${this.$store.state.chain}/assets/${address}/logo.png`,
            tokenName: this.token,
            symbol: "",
            chain: this.$store.state.chain,
            volume: undefined,
            verified: false,
          });
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
  computed: {
    isWalletConnected: function() {
      return this.$store.getters.walletConnected;
    },
    searchIsOpen: function() {
      return this.$store.getters.searchOpen;
    },
    walletTokenList: function() {
      return this.$store.getters.accountWalletTokens == null
        ? []
        : this.$store.getters.accountWalletTokens
            .filter((t) => {
              return t && t.balance && t.value !== 0;
            })
            .sort((a, b) => {
              let stakeMismatch = a.isStake != b.isStake;
              if (stakeMismatch) {
                return b.isStake ? 1 : -1;
              }
              return b.balance - a.balance;
            });
    },
    tokenFixed: function() {
      return this.token.toLowerCase();
    },
  },
  watch: {
    "$store.state.token": function() {
      this.token = this.$store.state.token;
    },
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.2);
  width: 4px;
  height: 10px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  border: solid 3px transparent;
  box-shadow: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  border: none;
  box-shadow: inset 0 0 10px 10px #1bc870;
  border-radius: 20px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
</style>
