<template>
  <div v-html="value" class="fade-in"></div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {};
  },
};
</script>
