<template>
  <div v-if="alerts.length">
    <div v-for="alert in alerts" :key="alert">
      <div class="dark:bg-gray-700 bg-gray-100 rounded-md mt-4 p-4 w-full">
        <div class="flex flex-row justify-between">
          <span class="overline text-gray-600 dark:text-gray-300">Token</span>
          <span class="overline text-gray-900 dark:text-white flex flex-row">
            <img class="w-5 h-5 rounded-full mr-1.5" v-bind:src="getTokenURL(alert)" @error="altSymbol" />
            {{ alert.symbol }}
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <span class="overline text-gray-600 dark:text-gray-300">Trigger</span>
          <span class="overline text-gray-900 dark:text-white block">{{ alert.sign === "<" ? "Less than" : "Greater than" }} {{ maxDigits(alert.priceUSD, 5) }}</span>
        </div>
        <!-- <div class="flex flex-row justify-between">
          <span class="overline text-gray-600 dark:text-gray-300">Sign</span>
          <span class="overline text-gray-900 dark:text-white block">{{ alert.sign }}</span>
        </div> -->
        <div class="flex space-x-2 mt-2 dark:text-gray-300 text-gray-400 flex-row place-content-end">
          <!-- <button>Edit</button> -->
          <button @click="deleteAlert(alert)">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="w-full overflow-hidden relative h-96 bg-transparent rounded-lg">
    <div class="absolute left-0 space-y-6 z-10 top-0 text-center flex flex-col place-content-center items-center w-full h-full">
      <h3 class="font-semibold text-gray-900 dark:text-white">No alerts found</h3>
    </div>
    <div class="bg-gradient-to-t dark:from-gray-800 dark:to-transparent from-white to-transparent w-full h-full left-0 top-0 absolute"></div>
    <div v-for="index in 6" :key="index">
      <div class="dark:bg-gray-700 bg-gray-200 rounded-md mt-4 w-full h-20"></div>
    </div>
  </div>
</template>

<script>
import { maxDigits } from "@/filters";
const Web3 = require("web3");

export default {
  computed: {
    alerts() {
      return this.$store.getters.activeAlerts;
    },
  },
  methods: {
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    getTokenURL(alert) {
      return `https://static.bogged.finance/${alert.chain === "matic" ? "polygon" : alert.chain}/assets/${Web3.utils.toChecksumAddress(alert.token)}/logo.png`;
    },
    deleteAlert(alert) {
      this.$store.dispatch("deletePriceAlert", alert);
    },
  },
};
</script>
