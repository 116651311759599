<template>
  <div v-for="(item, i) in $store.state.trendingTokens" :key="'search-search-' + i" class="font-heading relative py-0.5 flex flex-row justify-between dark:hover:bg-grey-100 dark:hover:bg-opacity-10 hover:bg-gray-100">
    <div v-if="i == 0 && item.tokenName == 'Octavia'" class="space-x-3 w-full bg-gray-900 bg-opacity-50 flex flex-row items-center py-1 pl-2 pr-4">
      <div class="w-full ml-10">
        <a href="https://octavia.one" target="_blank">
          <div
            class="flex flex-row items-center space-x-3 cursor-pointer"
            @click="
              // reset tab to chart on token load:
              this.$store.state.defaultTabIndex = 0;
              this.$store.state.page = 'chart';
              //this.Services.updateURL(this.$store);
              this.$store.state.rerenderTabs = true;
              $store.dispatch('loadToken', item.address);
              closeSearch();
            "
          >
            <span class="text-xs w-full flex flex-row items-center justify-between dark:text-white whitespace-nowrap overflow-hidden">
              <div class="flex flex-row items-center">
                <img class="w-7 h-7 mr-3 rounded-full " v-bind:src="item.tokenImageUrl" @error="altSymbol" />
                <div class="flex flex-col space-y-1 items-start">
                  <span class="flex flex-row items-center">
                    Octavia - The web3 assistant  <a v-tippy='{ allowHTML : true }' href="https://octavia.one" class="rounded-full lg:block hidden ml-1 h-4 w-4 p-0.5 mb-auto  hover:opacity-80  bg-opacity-50 "  content='<div class="text-align-center" style="text-align:center"><strong class="text-sm"><svg viewBox="0 0 43 45" class="w-6 h-6 inline-block" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9931 5.59519L21.5 0.281738L16.0069 5.59519L8.4409 4.52447L7.11962 12.0503L0.36908 15.6336L3.72544 22.4999L0.36908 29.3639L7.11962 32.9472L8.4409 40.473L16.0069 39.4046L21.5 44.7181L26.9931 39.4046L34.5591 40.473L35.8804 32.9472L42.6309 29.3639L39.2745 22.4999L42.6309 15.6336L35.8804 12.0503L34.5591 4.52447L26.9931 5.59519ZM10.2349 23.4997L18.3374 31.6023L31.77 16.9336L28.5862 13.9861L18.1862 25.3197L13.2887 20.4223L10.2349 23.4997Z" fill="url(#paint0_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.466 7.21421L21.5 2.40894L16.534 7.21421L9.69124 6.24512L8.49761 13.0524L2.39233 16.2906L5.42724 22.4998L2.39233 28.7091L8.49761 31.9473L9.69124 38.7546L16.534 37.7855L21.5 42.5908L26.466 37.7855L33.3087 38.7546L34.5023 31.9473L40.6076 28.7091L37.5727 22.4998L40.6076 16.2906L34.5023 13.0524L33.3087 6.24512L26.466 7.21421ZM10.2325 23.4997L18.3374 31.6022L31.77 16.9335L28.5862 13.986L18.1862 25.3197L13.2887 20.4222L10.2325 23.4997Z" fill="url(#paint1_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2326 23.4998L18.3375 31.6023L31.692 17.0187L31.7818 16.9407L31.77 20.245L18.3375 34.9138L10.2326 26.8089L10.2255 23.4879L10.2373 23.495L10.2326 23.4998Z" fill="#FFA600"/>
              <defs>
              <linearGradient id="paint0_linear_72_1037" x1="5.92599" y1="2.39719" x2="38.2416" y2="47.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E8DB29"/>
              <stop offset="0.539" stop-color="#FAFF00"/>
              <stop offset="0.68" stop-color="#CB7B00"/>
              <stop offset="0.744792" stop-color="#F3C620"/>
              <stop offset="1" stop-color="#F4E72A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_72_1037" x1="8.15724" y1="4.52439" x2="34.1714" y2="41.7233" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFF6BC"/>
              <stop offset="0.406" stop-color="#FDE99E"/>
              <stop offset="0.989" stop-color="#E2B719"/>
              </linearGradient>
              </defs>
              </svg>  Verified Project</strong><br /><span class="text-xs">Octavia is a partner of Bogged, and founded by one of the co-founders of Bogged.</span><br /><br /><span class="text-2xs">Audited by Hacken, KYC with Assure, Proven track record.</span></div>'  >
               
                            <svg viewBox="0 0 43 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9931 5.59519L21.5 0.281738L16.0069 5.59519L8.4409 4.52447L7.11962 12.0503L0.36908 15.6336L3.72544 22.4999L0.36908 29.3639L7.11962 32.9472L8.4409 40.473L16.0069 39.4046L21.5 44.7181L26.9931 39.4046L34.5591 40.473L35.8804 32.9472L42.6309 29.3639L39.2745 22.4999L42.6309 15.6336L35.8804 12.0503L34.5591 4.52447L26.9931 5.59519ZM10.2349 23.4997L18.3374 31.6023L31.77 16.9336L28.5862 13.9861L18.1862 25.3197L13.2887 20.4223L10.2349 23.4997Z" fill="url(#paint0_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.466 7.21421L21.5 2.40894L16.534 7.21421L9.69124 6.24512L8.49761 13.0524L2.39233 16.2906L5.42724 22.4998L2.39233 28.7091L8.49761 31.9473L9.69124 38.7546L16.534 37.7855L21.5 42.5908L26.466 37.7855L33.3087 38.7546L34.5023 31.9473L40.6076 28.7091L37.5727 22.4998L40.6076 16.2906L34.5023 13.0524L33.3087 6.24512L26.466 7.21421ZM10.2325 23.4997L18.3374 31.6022L31.77 16.9335L28.5862 13.986L18.1862 25.3197L13.2887 20.4222L10.2325 23.4997Z" fill="url(#paint1_linear_72_1037)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2326 23.4998L18.3375 31.6023L31.692 17.0187L31.7818 16.9407L31.77 20.245L18.3375 34.9138L10.2326 26.8089L10.2255 23.4879L10.2373 23.495L10.2326 23.4998Z" fill="#FFA600"/>
              <defs>
              <linearGradient id="paint0_linear_72_1037" x1="5.92599" y1="2.39719" x2="38.2416" y2="47.337" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E8DB29"/>
              <stop offset="0.539" stop-color="#FAFF00"/>
              <stop offset="0.68" stop-color="#CB7B00"/>
              <stop offset="0.744792" stop-color="#F3C620"/>
              <stop offset="1" stop-color="#F4E72A"/>
              </linearGradient>
              <linearGradient id="paint1_linear_72_1037" x1="8.15724" y1="4.52439" x2="34.1714" y2="41.7233" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFF6BC"/>
              <stop offset="0.406" stop-color="#FDE99E"/>
              <stop offset="0.989" stop-color="#E2B719"/>
              </linearGradient>
              </defs>
              </svg>     
              </a>  
                  
                    
                  </span>
                  <span class="text-2xs text-gray-200  italic">From the co-founder of Bogged.</span>
                </div>
               
              </div>
              <div class="flex flex-row items-center space-x-4">
                <span
                  v-tippy="{
                    content: '',
                    appendTo: 'parent',
                    theme: 'bog',
                    animation: 'shift-toward-subtle',
                    arrow: false,
                    maxWidth: 'none',
                  }"
                  class="text-2xs"
                  >Launches Soon
                </span>
                  
                <span
                  class="bg-secondary dark:text-white text-secondary rounded-md px-2 py-0.5 bg-opacity-20 dark:bg-opacity-10 text-2xs"
                  >Find out more &raquo;</span
                >
              </div>
            </span>
          </div>
        </a>
      </div>
    </div>
    <div v-else class="space-x-3 w-full flex flex-row items-center my-1 pl-2 pr-4">
      <toggleFavorite v-bind:address="item.address" v-bind:tokenImageUrl="item.tokenImageUrl" v-bind:tokenName="item.tokenName" />
      <div class="w-full">
        <router-link :to="'/?page=chart&c=' + item.chain + '&t=' + item.address">
          <div
            class="flex flex-row items-center space-x-3 cursor-pointer"
            @click="
              // reset tab to chart on token load:
              this.$store.state.defaultTabIndex = 0;
              this.$store.state.page = 'chart';
              //this.Services.updateURL(this.$store);
              this.$store.state.rerenderTabs = true;
              $store.dispatch('loadToken', item.address);
              closeSearch();
            "
          >
            <span class="text-xs w-full flex flex-row items-center justify-between dark:text-white whitespace-nowrap overflow-hidden">
              <div class="flex flex-row items-center">
                <img class="w-7 h-7 mr-3 rounded-full bg-gray-900" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
                <div class="flex flex-col space-y-1 items-start">
                  <span class="flex flex-row items-center">
                    {{ maxLength(item.tokenName, 32) }}
                    <span v-if="item.tokenName.length > 32">...</span>
                  </span>
                  <span v-html="bogintificPrice(item.price, $store.state.useTruncatedBogintific)" class="text-2xs text-gray-400"></span>
                </div>
              </div>
              <div class="flex flex-row items-center space-x-4">
                <span
                  v-html="bogintificPrice(item.volume_usd_24h, $store.state.useTruncatedBogintific)"
                  v-tippy="{
                    content: '24hr Volume',
                    appendTo: 'parent',
                    theme: 'bog',
                    animation: 'shift-toward-subtle',
                    arrow: false,
                    maxWidth: 'none',
                  }"
                  class="text-2xs"
                  >
                </span>

                <span
                  v-bind:class="{
                    'bg-purple-bright dark:text-purple-bright text-purple': this.$store.state.chain === 'polygon',
                    'bg-primary dark:text-primary text-primary-dark': this.$store.state.chain === 'bsc',
                  }"
                  class="rounded-md px-2 py-0.5 uppercase bg-opacity-20 dark:bg-opacity-10 text-2xs"
                  >{{ this.$store.state.chain }}</span
                >
              </div>
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import toggleFavorite from "@/components/Favorite/toggleFavorite";
import { maxLength, bogintificPrice } from "@/filters";
export default {
  components: {
    toggleFavorite,
  },
  methods: {
    maxLength,
    bogintificPrice,
    closeSearch: function() {
      this.$store.commit("closeSearch");
    },
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
  },
};
</script>
