<template>
  <tbody class="divide-y divide-gray-200 dark:divide-gray-800">
    <slot></slot>
  </tbody>
</template>

<script>
export default {
  props: [],
};
</script>
