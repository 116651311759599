<template>
  <div
    v-if="$store.state.showTrustScore && this.$store.state.chain == 'bsc'"
    class="flex flex-row dark:bg-gray-700 bg-white dark:shadow-none shadow-smooth dark:bg-opacity-20 md:h-16.5 h-14 md:px-4 px-3 rounded-md items-center md:space-x-4"
  >
    <vc-donut
      class="md:block hidden"
      :size="40"
      :background="this.trustDonutBackground"
      foreground="#313D50"
      :thickness="25"
      :sections="[{ value: $store.state.trustscore, color: this.trustDonutColor }]"
    ></vc-donut>
    <span class="flex flex-col">
      <span class="text-sm font-heading whitespace-nowrap dark:text-gray-300 text-gray-500 flex flex-row items-center"
        >Trust Score
        <div class="whitespace-normal">
          <InformationCircleIcon
            v-tippy="{
              placement: 'bottom',
              content:
                'Trust score is calculated from a number of factors including: token age, liquidity, associated rugpulls, source code analysis, holder distribution and more',
              appendTo: 'parent',
              theme: 'bog',
              animation: 'shift-toward-subtle',
              arrow: false,
              maxWidth: '350px',
            }"
            class="h-4 w-4 text-gray-500 ml-1"
            aria-hidden="true"
          /></div
      ></span>
      <div v-if="$store.state.trustscore >= 80" class="text-sm md:text-base 2xl:text-lg inline-block rounded-md text-secondary">
        {{ $store.state.trustscore }}
      </div>
      <div
        v-else-if="$store.state.trustscore > 50"
        v-html="$store.state.trustscore"
        class="text-sm md:text-base 2xl:text-lg inline-block rounded-md text-alert-bright"
      ></div>
      <div
        v-else-if="$store.state.trustscore <= 50 && $store.state.trustscore != null"
        v-html="$store.state.trustscore"
        class="text-sm md:text-base 2xl:text-lg inline-block rounded-md text-error-bright"
      ></div>
      <div
        v-else-if="$store.state.trustscore == null"
        class="text-sm md:text-base 2xl:text-lg inline-block rounded-md text-gray-500"
      >
        N/A
      </div>
    </span>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import { maxDigits, bogintificPrice, bogintific, price, number, percent, numberfloor } from "@/filters";

export default {
  methods: {
    maxDigits,
    bogintificPrice,
    bogintific,
    price,
    number,
    percent,
    numberfloor,
  },
  computed: {
    trustDonutColor: function () {
      if (this.$store.state.trustscore >= 80) {
        return "#1BC870";
      } else if (this.$store.state.trustscore > 50) {
        return "#FFDE6A";
      } else if (this.$store.state.trustscore <= 50 && this.$store.state.trustscore != null) {
        return "#FF6A6A";
      }
    },
    trustDonutBackground: function () {
      if (this.$store.state.theme.theme == "dark") {
        return "#171E2B";
      } else {
        return "#FFFFFF";
      }
    },
    isWalletConnected: function () {
      return this.$store.getters.walletConnected;
    },
  },
  components: {
    InformationCircleIcon,
  },
};
</script>
