<template>
  <div class="w-full relative subPageHeightAdjust">
    <transition appear name="fade">
      <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full absolute">
        <div class="lg:w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
          <div class="w-full">
            <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
              <div class="px-6 py-8 bg-white dark:bg-transparent">
                <h4 class="mb-2 text-gray-800 dark:text-primary">Third Party Tools</h4>
                <h6 class="leading-relaxed text-gray-700 dark:text-gray-100">
                  Awesome tools from friends of Bogged.Finance.
                </h6>
              </div>
              <div class="flex flex-wrap">
                <a href="https://moonarch.app/" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                  <div class="flex flex-row items-center mb-3">
                    <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Moonarch</h6>
                  </div>
                  <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                    Trading tools for the Binance Smart Chain $BSC. Features: token rankings, wallet value, whale watch, new tokens... Powered by $MOONARCH token.
                  </p>
                </a>
                <a href="https://waterfallbot.info/" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                  <div class="flex flex-row items-center mb-3">
                    <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Waterfallbot</h6>
                  </div>
                  <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                    Telegram Channels that instantly display the latest trades for a token.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
