<template>
  <div class="text-white relative z-30 flex lg:flex-row flex-col items-center">
    <div class="lg:mr-3 w-full lg:w-auto flex-shrink-0">
      <token-icon  :key="updateTokenIcon" />
    </div>
    <cards :key="updateCards" />

    <div class="md:h-16.5 h-14 absolute bottom-0 right-0 w-12 bg-gradient-to-l from-background dark:from-gray-900 to-transparent">
      <div class="absolute right-0">
        <button @click="openChartSettingsModal" type="button" class="flex-shrink-0 p-2 text-gray-400 rounded-full hover:text-gray-500 dark:hover:text-white bg-white dark:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-600">
          <AdjustmentsIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
  <chart-settings-modal />
</template>
<script>
import tokenIcon from "@/views/Chart/components/Topbar/tokenIcon.vue";
import { AdjustmentsIcon } from "@heroicons/vue/solid";
import chartSettingsModal from "./chartSettingsModal";
import Cards from "./Cards.vue";

export default {
  data() {
    return {
      updateTokenIcon: 0,
      updateCards: 0,
    };
  },
  methods: {
    openChartSettingsModal: function() {
      this.$store.commit("openChartSettingsModal");
    },
  },
  watch: {
    "$store.state.currentExchange": function() {
      this.selected = this.$store.state.currentExchange;
      this.updateTokenIcon += 1;
    },
    "$store.state.topbarOrder": function() {
      this.updateCards += 1;
    },
  },
  components: {
    tokenIcon,
    AdjustmentsIcon,
    chartSettingsModal,
    Cards,
  },
};
</script>
