<template>
  <div class="w-full relative subPageHeightAdjust">
    <transition appear name="fade">
      <div class="px-2 py-3 md:py-4 sm:px-4 mx-auto md:pt-4 w-full absolute">
        <div class="lg:w-175 transform xl:-translate-x-17.5 flex flex-col space-y-4 mx-auto h-full">
          <router-link
            to="/promote"
            class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:hover:text-gray-100 dark:border-gray-700 dark:bg-gray-800 text-gray-300 transition-colors duration-200 h-auto p-4 text-center cursor-pointer button"
            >Promote Your Token With Us</router-link
          >
          <div class="shadow-smooth overflow-hidden rounded-2xl bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 text-white h-auto">
            <div class="px-6 py-8 bg-white dark:bg-transparent">
              <h4 class="mb-2 text-gray-800 dark:text-primary">Tools for Token Teams</h4>
              <h6 class="leading-relaxed text-gray-700 dark:text-gray-100">
                Launch, Grow and Thrive with these tools specifically to help you grow your project.
              </h6>
            </div>
            <div class="flex flex-wrap">
              <a href="/promotetelegram" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                <div class="flex flex-row items-center mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Telegram Pricebot</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">FREE</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Get a /price command and more with the Bog Price Bot. <b class="dark:text-primary text-gray-900">It's absolutely free - with absolutely no ads</b>. Don't pay $$$ for a bot with ads that aim to steal your investors, use the
                  Bogged.Finance Bot! It only takes a few moments to setup!
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </a>

              <a
                href="https://github.com/bogtools/bogged-token-list"
                class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6"
              >
                <div class="flex flex-row items-center flex-wrap mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Logo + Information on Charts.Bogged.Finance</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">FREE (or 0.5 BNB)</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Submit a pull request on Github and add your information and logo to your charts so you can put your best foot forward to potential investors. It's free for projects that link to their charts from their website AND/OR use the
                  Bogged.Finance Telegram Pricebot, alternatively there is a 0.5 BNB Processing Fee.
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </a>

              <a
                href="https://forms.gle/xjUdSmBNfT1CZASF8"
                class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6"
              >
                <div class="flex flex-row items-center mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Waterfall Telegram Bot</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">$200 (+$20 per extra pair)</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Get a waterfall bot displaying trades live in a custom waterfall telegram channel from our friends at @centralwaterfall.
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </a>

              <router-link to="/promotebogswap" class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6">
                <div class="flex flex-row items-center mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Embeddable BogSwap</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">FREE</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Embed BogSwap in your website to provide a seamless buying experience for your future token holders.
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </router-link>

              <a
                href="https://t.me/BOGforTokenTeams"
                class="h-full w-full flex justify-between flex-col border-t transition-all duration-300 hover:bg-gray-200 dark:hover:bg-gray-700 hover:bg-opacity-30 border-gray-200 dark:border-gray-700 p-6"
              >
                <div class="flex flex-row items-center mb-3">
                  <h6 class="mr-2 font-semibold dark:text-white text-primary-dark">Join BogForTokenTeams on Telegram</h6>
                  <span class="overline bg-secondary bg-opacity-20 text-secondary px-2 py-0 rounded-full">FREE</span>
                </div>
                <p class="font-heading text-sm text-gray-600 dark:text-gray-300">
                  Join @BogForTokenTeams and keep up to date with the latest releases that will help your token grow &amp; network with other tokens.
                </p>

                <div class="text-secondary transition-colors duration-200 font-heading text-sm mt-3 flex flex-row space-x-1.5">
                  <span>Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
