<template>
  <TransitionRoot appear :show="walletModalIsOpen" as="template">
    <Dialog as="div">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild @click="closeWalletModal" as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <div class="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 flex justify-between w-full items-center text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-700 px-6 py-4">
                Your Wallet
                <button @click="closeWalletModal" type="button" class="p-0.5 rounded-full text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white focus:outline-none focus:ring-0">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </DialogTitle>
              <div class="p-6">
                <div class="flex items-start flex-col">
                  <div class="mt-1 w-full overflow-hidden flex rounded-md shadow-sm">
                    <div class="relative flex items-stretch flex-grow focus-within:z-10">
                      <input v-model="address" type="text" class="focus:ring-transparent focus:border-transparent w-full rounded-r-none bg-gray-200 dark:bg-gray-900 border-none text-gray-600 dark:text-gray-400 rounded-full pl-4 py-2" />
                    </div>
                    <button
                      @click="copyAddress"
                      type="button"
                      class="-ml-px hover:text-secondary relative inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium rounded-r-full text-gray-500 dark:text-gray-700 shadow-long_left bg-gray-200 dark:bg-gray-900 focus:outline-none focus:ring-0 focus:border-none"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="mt-6 sm:flex sm:space-y-0 space-y-3 sm:space-x-3">
                  <a
                    class="inline-flex button w-full justify-center px-4 py-3 leading-none text-gray-100 bg-gray-700 border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-secondary"
                    :href="'https://'+ $store.state.blockExplorer + '/address/' + address"
                    target="_blank"
                  >
                    View on {{ $store.state.blockExplorer }}                  
                  </a>
                  <button
                    type="button"
                    class="inline-flex w-full justify-center px-4 py-3 leading-none text-sm font-medium text-error-bright bg-error-bright bg-opacity-20 border border-transparent rounded-md hover:bg-opacity-10 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-secondary"
                    @click="disconnectWallet"
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { maxLength } from "@/filters";
import { XIcon } from "@heroicons/vue/outline";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  data: function() {
    return {
      copied: false,
      walletAddress: this.$store.getters.account,
    };
  },
  methods: {
    maxLength,
    copyAddress: async function() {
      this.copied = true;
      window.navigator.clipboard.writeText(this.address);
    },
    disconnectWallet: async function() {
      this.$store.commit("disconnectWallet");
      this.$emit("close");
    },
    openWalletModal: function() {
      this.$store.commit("openWalletModal");
    },
    closeWalletModal: function() {
      this.$store.commit("closeWalletModal");
    },
  },
  computed: {
    address: function() {
      return this.$store.getters.account;
    },
    walletModalIsOpen: function() {
      return this.$store.getters.walletModalOpen;
    },
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    XIcon,
  },
};
</script>
