<template>
  <div v-if="!onSupportedChain">
    <div class="text-gray-600 flex flex-row items-center text-lg font-semibold mb-2">
      Hot Right Now
    </div>
    <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
      <div class="lg:w-1/3 fade-in rounded-2xl dark:bg-gray-800 bg-white duration-200 transition-all border border-gray-200 dark:border-gray-700 hover:bg-opacity-70" v-if="$store.state.trendingTokens.length">
        <div class="p-5">
          <div class="w-full mb-3 overflow-hidden flex items-center justify-between">
            <h4 class="text-error-bright select-none inline-block font-sans font-semibold text-sm">
              <trending-icon class="inline-block mr-2 w-5 h-5" />
              Trending
            </h4>
            <router-link class="text-gray-400 font-semibold hover:text-gray-300 transition-colors duration-200 text-sm" to="/trending-tokens">View All</router-link>
          </div>
          <div class="font-medium dark:text-gray-300 text-gray-900 mt-6 text-xs flex flex-row justify-between items-center" v-for="(item, i) in orderedTrendingByIndex.slice(0, 3)" :key="'promoted-trending-' + i">
            <router-link v-on:click="$store.dispatch('loadToken', item.address)" :to="'/' + item.address" class="flex flex-row items-center">
              <span class="w-6 h-6 rounded-md font-heading text-xs text-gray-600 bg-gray-100 dark:bg-gray-900 flex items-center place-content-center"> {{ i + 1 }}</span>
              <img class="w-6 h-6 rounded-full mx-3 bg-white" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
              {{ item.tokenName }}
            </router-link>
            <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.change_pct_24h >= 0, 'dark:text-error-bright text-error': item.change_pct_24h < 0 }" class="fade-in font-mono flex flex-row items-center">
              <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.change_pct_24h >= 0 }" class="h-5 w-5 ml-1" aria-hidden="true" />
              {{ item.change_pct_24h }}%
            </div>
          </div>
        </div>
      </div>
      <div style="height: 180px" v-else class="bg-gray-200 dark:bg-gray-800 lg:w-1/3 p-4 rounded-2xl animate-pulse-disable skeleton-loader"></div>
      <div class="lg:w-1/3 fade-in rounded-2xl dark:bg-gray-800 bg-white duration-200 transition-all border border-gray-200 dark:border-gray-700 hover:bg-opacity-70" v-if="gainersList.length">
        <div class="p-5">
          <div class="w-full mb-3 overflow-hidden flex items-center justify-between">
            <h4 class="text-primary select-none inline-block font-sans font-semibold text-sm">
              <gainers-icon class="inline-block mr-2 w-5 h-5" />
              Top Gainers
            </h4>
            <router-link class="text-gray-400 font-semibold hover:text-gray-300 transition-colors duration-200 text-sm" to="/gainers-losers">View All</router-link>
          </div>
          <div class="font-medium dark:text-gray-300 text-gray-900 mt-6 text-xs flex flex-row justify-between items-center" v-for="(item, i) in gainersList.slice(0, 3)" :key="'promoted-trending-' + i">
            <router-link v-on:click="$store.dispatch('loadToken', item.address)" :to="'/' + item.address" class="flex flex-row items-center">
              <span class="w-6 h-6 rounded-md font-heading text-xs text-gray-600 bg-gray-100 dark:bg-gray-900 flex items-center place-content-center"> {{ i + 1 }}</span>
              <img class="w-6 h-6 rounded-full mx-3 bg-white" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
              {{ item.tokenName }}
            </router-link>
            <div v-bind:class="{ 'dark:text-secondary text-secondary-dark': item.change_pct_24h >= 0, 'dark:text-error-bright text-error': item.change_pct_24h < 0 }" class="fade-in font-mono flex flex-row items-center">
              <ArrowSmDownIcon v-bind:class="{ 'rotate-180 transform': item.change_pct_24h >= 0 }" class="h-5 w-5 ml-1" aria-hidden="true" />
              {{ item.change_pct_24h }}%
            </div>
          </div>
        </div>
      </div>
      <div style="height: 180px" v-else class="bg-gray-200 dark:bg-gray-800 lg:w-1/3 p-4 rounded-2xl animate-pulse-disable skeleton-loader"></div>
      <div class="lg:w-1/3 fade-in rounded-2xl dark:bg-gray-800 bg-white duration-200 transition-all border border-gray-200 dark:border-gray-700 hover:bg-opacity-70" v-if="promotedList.length">
        <div class="p-5">
          <div class="w-full mb-3 overflow-hidden flex items-center justify-between">
            <h4 class="text-blue-bright select-none inline-block font-sans font-semibold text-sm">
              <promoted-icon class="inline-block mr-2 w-5 h-5" />
              Promoted Tokens
            </h4>
            <router-link class="text-gray-400 font-semibold hover:text-gray-300 transition-colors duration-200 text-sm" to="/trending-tokens">View All</router-link>
          </div>
          <div class="font-medium dark:text-gray-300 text-gray-900 mt-6 text-xs flex flex-row justify-between items-center" v-for="(item, i) in promotedList.slice(0, 3)" :key="'promoted-trending-' + i">
            <router-link v-on:click="$store.dispatch('switchChain', {chain:'bsc', token_to_load:item.address});" :to="'/' + item.address" class="flex flex-row items-center">
              <span class="w-6 h-6 rounded-md font-heading text-xs text-gray-600 bg-gray-100 dark:bg-gray-900 flex items-center place-content-center"> {{ i + 1 }}</span>
              <img class="w-6 h-6 rounded-full mx-3 bg-white" v-bind:src="item.tokenImageUrl" @error="altSymbol" />
              {{ item.tokenName }}
            </router-link>
          </div>
        </div>
      </div>
      <div style="height: 180px" v-else class="bg-gray-200 dark:bg-gray-800 lg:w-1/3 p-4 rounded-2xl animate-pulse-disable skeleton-loader"></div>
    </div>
  </div>
</template>

<script>
import { bogintificPrice, maxDigits } from "@/filters";
import Popper from "vue3-popper";
import { ArrowSmDownIcon } from "@heroicons/vue/outline";
import trendingIcon from "@/views/Cryptocurrencies/components/topCards/trendingIcon.vue";
import upcomingLaunchesIcon from "@/views/Cryptocurrencies/components/topCards/upcomingLaunchesIcon.vue";
import gainersIcon from "@/views/Cryptocurrencies/components/topCards/gainersIcon.vue";
import promotedIcon from "@/views/Cryptocurrencies/components/topCards/promotedIcon.vue";
import moment from "moment";
import notAvailable from "@/components/notAvailable";
import chains from "@/chains";

export default {
  data() {
    return {
      launches: [],
      maxLaunchDate: moment()
        .subtract(0, "days")
        .format(),
    };
  },
  components: {
    bogintificPrice,
    maxDigits,
    Popper,
    ArrowSmDownIcon,
    trendingIcon,
    upcomingLaunchesIcon,
    gainersIcon,
    promotedIcon,
    notAvailable,
  },
  async created() {
    this.launches = await this.getlaunches();
  },
  computed: {
    onSupportedChain() {
      if (chains[this.$store.state.chain].supports.includes("Trending") && chains[this.$store.state.chain].supports.includes("upcomingLaunches") && chains[this.$store.state.chain].supports.includes("gainerLosers")) {
        return false;
      } else {
        return true;
      }
    },
    sortTableUpcomingLaunches: function() {
      var app = this;
      if (this.maxLaunchDate)
        this.launches = this.launches.filter((item) => {
          return item.launchDate >= this.maxLaunchDate;
        });
      this.launches.sort(function(a, b) {
        if (a[app.sortType] < b[app.sortType]) {
          if (app.isReverse) return 1;
          else return -1;
        } else {
          if (app.isReverse) return -1;
          else return 1;
        }
      });
      return this.launches;
    },
    orderedTrendingByIndex() {
      return _.chain(this.$store.state.trendingTokens)
        .sortBy("tokenIndex")
        .partition("tokenIndex")
        .flatten()
        .value();
    },
    promotedList() {
      return _.chain(this.$store.state.promotedTokens)
        .flatten()
        .value();
    },
    gainersList() {
      return _.chain(this.$store.state.topMcapTokens)
        .sortBy("change_pct_24h")
        .reverse()
        .partition("change_pct_24h")
        .flatten()
        .value();
    },
  },
  methods: {
    bogintificPrice,
    maxDigits,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    dateTimeFormat(value) {
      return moment(value).format("MMMM Do YYYY, h:mm a");
    },
    dateTime(value) {
      return moment(value);
    },
    timeToLaunch(value) {
      return moment(value).fromNow();
    },
    getlaunches: async () => {
      const query = `{
  upcomingLaunchCollection {
    items {
      sys {
        id
        firstPublishedAt
      }
      name
      logo {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      kyc
      audit
      riskRating
  	  description
      launchDate
      contract
    }
  }
}`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) => response.json());
        return response.data.upcomingLaunchCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
};
</script>
