export function maxDigits(value, maxDigits) {
  if (value) {
    let parsed = parseFloat(value);
    let truncated;
    if (parsed > 1) {
      if (maxDigits > 20) {
        truncated = parsed.toFixed(20);
      } else {
        truncated = parsed.toFixed(maxDigits);
      }
    } else {
      let stringedValue = parsed.toFixed(20).toString();
      truncated = "";
      let pastLeading = false;
      let counter = 0;

      for (let i = 0; i < stringedValue.length; i++) {
        truncated += stringedValue[i];

        if (!pastLeading) {
          if (stringedValue[i] != "0" && stringedValue[i] != ".") {
            pastLeading = true;
          }
        } else {
          counter++;
          if (counter == maxDigits) {
            break;
          }
        }
      }
      if (truncated.slice(-1) == ".") {
        truncated = truncated.slice(0, -1);
      }
    }
    return truncated;
  } else {
    return "0";
  }
}

export function get16Colour(char) {
  let colours = [
    '#d81159',
    '#a461c3',
    '#426b8e',
    '#813456',
    '#ef767a',
    '#339e93',
    '#277d82',
    '#9f2656',
    '#336559',
    '#60ac99',
    '#db6775',
    '#7c69c2',
    '#465c69',
    '#bf6a5b',
    '#594351',
    '#98a886'
  ]
  let index = parseInt(char, 16)
  return colours[index]
}

export function colourise(hexstring) {
  
  let out_html = '<span style="color:#000; font-weight:bold;">'
  for (let char of hexstring) {    
    let index = get16Colour(char)
    out_html += '<span style="background-color:'+colours[index]+'">'+char+'</span>'
  }
  out_html += '</span>'
  return out_html
}

export function maxLength(value, maxDigits) {
  if (value) {
    let stringedValue = value.toString();
    let truncated = "";
    let i = 0;
    let counter = 0;
    while (counter < maxDigits && i < stringedValue.length) {
      truncated += stringedValue[i];
      if (stringedValue[i] != "." && stringedValue[i] != ",") {
        counter++;
      }
      i++;
    }
    return truncated;
  } else {
    return "0";
  }
}

export function price(value) {
  if (value) {
    return "$" + value.toLocaleString("en-US");
  } else {
    return "";
  }
}

export function roundToNSigFigs(x,n) { // Rounds numbers < 1 to n significant figures. Rounds numbers > 1 to n decimals.
    if (x == null || x == undefined) {
      return x
    }
		if (x == 0) return 0
    else if (x < 0) return Math.round(x,n)
		else if (x < 1) {      
			let sigfigs=n-parseInt(Math.floor(Math.log10(Math.abs(x))))-1      
			return Math.round(x*10**sigfigs) / 10**sigfigs
    }
		else return Math.round(x*10**n) / 10**n
}

export function bogintific(n, truncated = true, compact = false) { // max zeroes after decimal point before number is truncated
  let maxZeroes = 5
  // reference: https://stackoverflow.com/a/55987414
  if (n == 0) return 0;

  n = roundToNSigFigs(n,5)  

  if (compact && n >= 10 && n < 1000) {
    n = Math.round(n)
  }  

  let result = 0;
  let suffix = "";
  if (n < 1/10**(maxZeroes+1)) {
    if (truncated) {
      let parts = n.toExponential().split("e");
      let numAsInt = parseInt(parts[0] * 100);
      result = "0.0<b>..</b>" + numAsInt;
      let hoverText = maxDigits(n, 2);
      hoverText = price(hoverText);
      result = "<span v-b-tooltip.hover title='"+hoverText+"'>" + result + "</span>"

    } else {
      result = maxDigits(n,2)
    }
  } else if (n < 1e3) result = n.toLocaleString("en-US", {maximumFractionDigits: maxZeroes+3});
  else if (n >= 1e3 && n < 1e6) result = parseInt(n).toLocaleString("en-US");
  else if (n >= 1e6 && n < 1e9) {
    result = (n / 1e6).toLocaleString("en-US");
    suffix = "M";
  } else if (n >= 1e9 && n < 1e12) {
    result = (n / 1e9).toLocaleString("en-US");
    suffix = "B";
  } else if (n >= 1e12) {
    result = (n / 1e12).toLocaleString("en-US");
    suffix = "T";
  }
  return result + suffix;
}

export function bogintificPrice(value, truncated) {
  return "$" + bogintific(value, truncated);
}

export function bogintificValue(value, truncated) {
  return bogintific(value, truncated);
}

export function number(value) {
  if (value) {
    return value.toLocaleString("en-US");
  } else {
    return "";
  }
}

export function numberfloor(value) {
  if (value) {
    value = Math.floor(value);
    return value.toLocaleString("en-US");
  } else {
    return "";
  }
}

export function priceMinDigits(value, minDigits) {
  if (value != null && value != undefined) {
    return "$" + value.toLocaleString("en-US", { minimumFractionDigits: minDigits });
  } else {
    return "";
  }
}

export function toLocaleStringMinDigits(value, minDigits) {
  if (value) {
    return value.toLocaleString("en-US", { minimumFractionDigits: minDigits });
  } else {
    return "";
  }
}

export function percent(value) {
  if (value) {
    if (value < 1) {
      value *= 100;
    }
    return value.toFixed(2) + "%";
  } else {
    return "";
  }
}

export function minPrecision(value, precision) {
  if (!value) {
    return value;
  }
  value = parseFloat(value).toFixed(18);

  let trimmed = "";
  let inDecimals = false;
  let pastLeading = false;
  let precisionCounter = 0;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === ".") {
      if (precisionCounter >= precision) {
        break;
      } else {
        inDecimals = true;
      }
    } else {
      if (!pastLeading && value[i] !== "0") {
        pastLeading = true;
      }

      if (pastLeading) {
        precisionCounter++;
      }
    }
    trimmed += value[i];

    if (precisionCounter === precision && inDecimals) {
      break;
    }
  }

  return trimmed;
}
