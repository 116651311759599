<template>
  <Listbox v-model="selected" as="div" class="relative w-full lg:w-auto inline-block">
    <ListboxButton
      class="py-1 pr-4 lg:min-w-75 pl-3 group w-full lg:w-auto transition-colors duration-200 border border-opacity-50 bg-gradient-to-tr dark:from-gray-900 dark:to-gray-800 from-white to-white dark:border-gray-700 rounded-md flex items-center focus:outline-none"
    >
      <img
        v-if="$store.state.tokenImage"
        :src="$store.state.tokenImage"
        @error="altSymbol"
        class="md:w-12 md:h-12 w-8 h-8 md:mr-4 mr-2 rounded-full cursor-default"
      />
      <div
        v-else
        class="md:w-12 md:h-12 w-8 h-8 mr-4 flex flex-shrink-0 rounded-full bg-gray-200 dark:bg-gray-700 bg-opacity-60 animate-pulse-disable skeleton-loader"
      />
      <div class="flex w-full flex-col place-items-start">
        <div class="flex flex-row items-center justify-between w-full space-x-2">
          <div class="items-center">
            <div class="flex flex-row">
              <div  id="textfade" v-if="$store.state.tokenSymbol != '-'" 
                v-tippy="{
                  placement: 'bottom',
                  content: $store.state.tokenName,
                  appendTo: 'parent',
                  theme: 'bog',
                  animation: 'shift-toward-subtle',
                  arrow: false,
                  maxWidth: 'none',
                }"
                class="dark:text-white text-gray-800 md:text-med text-base font-semibold"
              >
                <span v-if="$store.state.masterTokenSymbol">
                  {{ $store.state.tokenSymbol }}/{{ $store.state.masterTokenSymbol }}
                </span>
                <span v-else>
                  {{ $store.state.tokenSymbol }}
                </span>
              </div>
              <verified-icon />
            </div>
          </div>
          <div class="flex flex-row items-center space-x-1">
            <template v-bind:key="option.name" v-for="option of this.$store.state.liquidityPoolsBog">
              <span
                class="md:block hidden dark:text-purple-bright text-purple bg-purple-bright bg-opacity-20 dark:bg-opacity-10 duration-200 transition-all py-0.5 px-2 rounded-full md:text-xs text-2xs font-medium"
                v-if="option.factory + option.qt == this.$store.state.currentExchangeQt"
                >{{ option.name }}</span
              >
            </template>
            <ChevronDownIcon class="h-4 w-4 text-gray-400 group-hover:text-purple-bright" aria-hidden="true" />
          </div>
        </div>

        <div class="flex flex-row items-center space-x-2 w-full justify-between">
          <div id="textfade" v-if="$store.state.tokenPrice">
          <h4
            v-if="$store.state.tokenPrice"
            class="dark:text-primary-xtra_light text-gray-800 text-lg lg:text-xl"
            v-html="bogintificPrice($store.state.tokenPrice, this.$store.state.useTruncatedBogintific)"
          ></h4>
          </div>
          <h4 v-else class="dark:text-primary-xtra_light text-gray-800 text-lg lg:text-xl"></h4>
          <h4
            v-if="$store.state.percentChange != null"
            v-bind:class="{
              'dark:text-secondary text-secondary-dark': $store.state.percentChange >= 0,
              'dark:text-error-bright text-error': $store.state.percentChange < 0,
            }"
            class="flex flex-row items-center text-xs font-semibold lg:font-normal lg:text-base"
          >
            <ArrowSmDownIcon
              v-bind:class="{ 'rotate-180 transform': $store.state.percentChange >= 0 }"
              class="h-4 w-4"
              aria-hidden="true"
            />
            {{ $store.state.percentChange }}%
          </h4>
          <div
            v-else
            class="bg-gray-200 dark:bg-gray-700 bg-opacity-60 rounded-md mt-0.5 w-10 h-5 animate-pulse-disable skeleton-loader"
          ></div>
        </div>
      </div>
    </ListboxButton>
    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <ListboxOptions
        style="backdrop-filter: blur(4px)"
        class="origin-top-left absolute max-h-52 sm:min-w-75 z-40 left-0 mt-2 w-full rounded-md shadow-lg bg-opacity-90 overflow-y-auto bg-gray-800 border border-gray-700"
      >
        <ListboxOption
          @click="switchPool"
          as="template"
          v-bind:key="'s2' + option.factory + option.qt"
          v-for="option of this.$store.state.liquidityPoolsBog"
          v-bind:value="option.factory + option.qt"
          v-slot="{ active }"
        >
          <li
            :class="[active ? 'bg-secondary bg-opacity-10' : '', 'cursor-default select-none text-sm relative py-1.5 pl-3 pr-9']"
          >
            <div class="flex">
              <span class="flex flex-col" :class="[active ? 'text-secondary' : 'text-gray-200']">
                <span class="font-medium"> {{ option.name }}</span>
                <span class="text-2xs text-gray-300">{{ option.qt_symbol }}</span>
              </span>
              <span v-if="option.liq_usd" class="absolute font-normal text-gray-200 inset-y-0 right-0 flex items-center pr-10">{{
                price(option.liq_usd)
              }}</span>
            </div>

            <span
              v-if="this.$store.state.currentExchangeQt == option.factory + option.qt"
              class="absolute inset-y-0 text-secondary right-0 flex items-center pr-2"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>
<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, ArrowSmDownIcon } from "@heroicons/vue/solid";
import { exchangeLogos } from "@/constants";
import { maxDigits, bogintificPrice, price } from "@/filters";
import verifiedIcon from "@/views/Chart/components/Topbar/verifiedIcon.vue";

export default {
  data() {
    return {
      exchangeLogos: exchangeLogos,
    };
  },
  components: {
    maxDigits,
    bogintificPrice,
    price,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
    ArrowSmDownIcon,
    verifiedIcon,
  },
  watch: {
    "$store.state.currentExchange": function () {
      this.selected = this.$store.state.currentExchangeQt;
    },
  },
  methods: {
    maxDigits,
    bogintificPrice,
    price,
    altSymbol(event) {
      event.target.src = "/unknown.svg";
    },
    switchPool: function () {
      this.$store.dispatch("switchPool", this.selected);
    },
  },
};
</script>

<style></style>
