<template>
  <div class="flex flex-col font-mono">
    <div class="overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow">
          <table class="min-w-full">
            <slot></slot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
};
</script>
